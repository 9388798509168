import { message } from '../ui/views/Message';
import i18n from '../../i18n';

const ERROR_MESSAGES = {
  database_error: {
    titleKey: 'database-error',
  },
  image_load_error: {
    titleKey: 'image-load-error',
  },
  image_too_big_error: {
    titleKey: 'image-too-big-error',
  },
  image_too_small_error: {
    titleKey: 'image-too-small-error',
  },
  json_size_error: {
    titleKey: 'json-size-error',
  },
  no_neg_balance_error: {
    titleKey: 'no-neg-balance-error',
    messageKey: 'plan-not-allow-this-action',
  },
  no_subscription_error: {
    titleKey: 'no-subscription-error',
    messageKey: 'user-not-on-plan',
  },
  org_name_not_found: {
    titleKey: 'org-name-not-found',
  },
  org_already_exists: {
    titleKey: 'org-already-exists',
  },
  org_not_found: {
    titleKey: 'org-not-found',
  },
  permission_denied: {
    titleKey: 'permission-denied',
  },
  plan_not_found: {
    titleKey: 'plan-not-found',
  },
  subscription_downgrade_error: {
    titleKey: 'subscription-downgrade-error',
  },
  user_not_found: {
    titleKey: 'user-not-found',
  },
  unknown_error: {
    titleKey: 'unknown-error',
    messageKey: 'could-not-complete-action',
  },
  user_error: {
    titleKey: 'something-went-wrong',
    messageKey: 'please-try-again',
  },
  max_doc_size_exceeded: {
    titleKey: 'max-doc-size-exceeded',
    messageKey: 'max-file-size-exceeded',
  },
  mimetype_not_accepted: {
    titleKey: 'mimetype-not-accepted',
  },
  dicom_image_load_error: {
    titleKey: 'dicom-image-load-error',
    messageKey: 'dicom-image-not-supported',
  },
  subscription_expired: {
    titleKey: 'subscription-expired',
    messageKey: 'could-not-complete-action',
  },
  autotrace_unavailable: {
    titleKey: 'autotrace-unavailable',
    messageKey: 'image-not-associated-with-patient',
  },
  autotrace_limit_reached: {
    titleKey: 'autotrace-limit-reached',
    messageKey: 'max-N-autotrace-image',
  },
  user_limit_reached: {
    titleKey: 'user-limit-reached',
    messageKey: 'max-N-active-users',
  },
};
/** ERROR CODES
 "database_error"
 "image_load_error"
 "image_too_big_error"
 "image_too_small_error"
 "json_size_error" - data is too big
 "no_neg_balance_error" - Your plan does not allow this action
 description="Plan does not allow negative patient balance"
 "no_subscription_error" - user is not on a plan (read only mode)
 "org_name_not_found"
 "org_already_exists"
 "org_not_found"
 "permission_denied"
 "plan_not_found"
 "subscription_downgrade_error"
 "user_not_found"
 "unknown_error"
 "user_error"
 "max_doc_size_exceeded" = {
  details: {
    file_size=doc_file_size
    max_size=max_size
  }
 }
 "mimetype_not_accepted" - when uploading iamges
 "dicom_image_load_error" - this particular DICOM image is not supported
 "subscription_expired",
 "autotrace_unavailable" - if image is not assocciated with the patient
 "autotrace_limit_reached" - 5 auto trace limit
 */
const errorHandler = (error, configs) => {
  /* example error object
    const error = {
      responseJson: {
        detail: {
          description: 'Organization with that id does not exist.',
          error: 'max_doc_size_exceeded',
          // file_size: 1000, // field will come with max_doc_size_exceeded error code
          // max_size: 500, // field will come with max_doc_size_exceeded error code
          org_id: 12312312,
        },
      },
      response: {
        status: 400,
      },
    };
  */
  if (error.name === 'AbortError') {
    console.error('Fetch aborted');
  }

  const { status } = error.response;
  if (
    error.responseJson &&
    error.responseJson.detail &&
    error.responseJson.detail.error &&
    ERROR_MESSAGES[error.responseJson.detail.error]
  ) {
    const errorConfig = ERROR_MESSAGES[error.responseJson.detail.error];

    // by default, all keys in error.responseJson.detail will be available for
    // formatting in i18n:
    const details = Object.entries(error.responseJson.detail).reduce(
      (obj, [k, v]) => ({ ...obj, [k]: v }),
      {},
    );
    message.danger(
      i18n.t(errorConfig.titleKey, details),
      // conditionally add messageKey if exists to not render message body if messageKey is null
      errorConfig.messageKey != null ? i18n.t(errorConfig.messageKey, details) : undefined,
      configs.messageGroupKey,
    );
  } else if (status === 400) {
    message.danger(
      i18n.t('bad-request-error'),
      i18n.t('could-not-complete-action'),
      configs.messageGroupKey,
    );
  } else if (status === 401) {
    message.danger(
      i18n.t('not-authorized'),
      i18n.t('could-not-complete-action'),
      configs.messageGroupKey,
    );
  } else if (status === 404) {
    message.danger(
      i18n.t('not-found-error'),
      i18n.t('could-not-complete-action'),
      configs.messageGroupKey,
    );
  } else if (status >= 500) {
    message.danger(
      i18n.t('internal-server-error'),
      i18n.t('could-not-complete-action'),
      configs.messageGroupKey,
    );
  } else {
    message.danger(
      i18n.t('unknown-error'),
      i18n.t('could-not-complete-action'),
      configs.messageGroupKey,
    );
  }
};

export default errorHandler;
