import React from 'react';
import PropTypes from 'prop-types';

function SinglePageButton({ title, buttonText, onClick }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(80vh)',
      }}
    >
      <div className="level-item">
        <p>{title}</p>
      </div>
      <div className="level-item" style={{ marginTop: '1em' }}>
        {onClick != null && (
          <button type="button" className="button is-primary" onClick={onClick}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

SinglePageButton.defaultProps = {
  title: '',
  buttonText: 'OK',
  onClick: undefined,
};

SinglePageButton.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.string,
};

export default SinglePageButton;
