/* eslint-disable no-extend-native  */
/* eslint-disable no-param-reassign  */
/* eslint-disable func-names  */
// polyfill Object.prototype.fromEntries()
if (!Object.prototype.fromEntries) {
  // see: https://github.com/feross/fromentries
  Object.defineProperty(Object.prototype, 'fromEntries', {
    value(iterable) {
      return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {});
    },
    configurable: true,
  });
}

if (!Error.prototype.toJSON) {
  // see: https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
  Object.defineProperty(Error.prototype, 'toJSON', {
    value() {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });
}
