import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import VisitImage from '../views/VisitImage';
import { FILE_TYPE_CONFIGS } from '../../configs/constants';
import DeleteButton from '../views/DeleteButton';
import { useDroppableAreaFiles } from '../../utils/contexts';

const imageStyle = { height: 92 };
const imageContainerStyle = { position: 'relative' };

function FileField({ name, accept, disabled, label, displayWithIcons }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { state: { files: droppableAreaFiles = [] } = {}, clearFiles } =
    useDroppableAreaFiles() ?? {};

  const files = values[name] ?? [];

  const setFileState = useCallback(
    (newFiles) => {
      if (Array.isArray(values[name])) {
        setFieldValue(name, [...values[name], ...newFiles]);
      } else {
        setFieldValue(name, newFiles);
      }
    },
    [setFieldValue, name, values],
  );

  useEffect(() => {
    if (droppableAreaFiles == null || droppableAreaFiles.length === 0) return;

    const filesArr = [];
    for (let i = 0; i < droppableAreaFiles.length; i += 1) {
      filesArr.push(droppableAreaFiles[i]);
    }
    setFileState(filesArr);
    clearFiles();
  }, [droppableAreaFiles, clearFiles, setFileState]);

  const onFileButtonChanged = (event) => {
    const { target } = event;
    const { files: targetFiles } = target;

    if (targetFiles.length > 0) {
      const filesArr = [];
      for (let i = 0; i < targetFiles.length; i += 1) {
        filesArr.push(targetFiles[i]);
      }
      setFileState(filesArr);
    }

    // clear file input
    try {
      target.value = null;
    } catch (err) {
      // continue
    }
  };

  const handleRemoveImage = (index) => {
    setFieldValue(
      name,
      values[name].filter((_, i) => index !== i),
    );
  };

  return (
    <>
      <div className="is-flex is-flex-wrap-wrap">
        {files.map((file, index) => {
          if (
            !displayWithIcons &&
            (file.image != null ||
              (FILE_TYPE_CONFIGS.isFileAcceptedAsImage(file.type, file.name) &&
                !FILE_TYPE_CONFIGS.isFileAcceptedAsPDF(file.type)))
          ) {
            return (
              <div
                key={file.image?.url ?? file.name ?? URL.createObjectURL(file)}
                className="Visit__document extra-column-gap-br"
                style={imageContainerStyle}
              >
                <DeleteButton onClick={() => handleRemoveImage(index)} />
                <VisitImage
                  index={index}
                  imageStyle={imageStyle}
                  url={file.image?.url ?? URL.createObjectURL(file)}
                />
              </div>
            );
          }
          return (
            <div
              key={file.name}
              style={{
                position: 'relative',
                textAlign: 'center',
              }}
              title={file.name}
            >
              <DeleteButton onClick={() => handleRemoveImage(index)} />
              <FontAwesomeIcon
                icon={FILE_TYPE_CONFIGS.getFileTypeIcon(file.mimetype ?? file.type)}
                size="6x"
              />
              <div>
                <span
                  className="Visit__document_name"
                  style={{ paddingTop: 5, fontSize: '1em', width: 128 }}
                >
                  {file.name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="file is-light">
        <label className="file-label">
          <input
            multiple
            className="file-input button"
            type="file"
            accept={accept}
            name={name}
            disabled={disabled}
            onChange={onFileButtonChanged}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon="upload" />
            </span>
            <span className="file-label">{t(label)}</span>
          </span>
        </label>
      </div>
    </>
  );
}

FileField.defaultProps = {
  accept: undefined,
  displayWithIcons: false,
  disabled: false,
};

FileField.propTypes = {
  // `accept` it can be comma separated file types or mimetypes.
  // Example: ".xls,.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  displayWithIcons: PropTypes.bool,
};

export default FileField;
