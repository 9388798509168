import React, { useCallback, useMemo } from 'react';
import sort from 'ramda/src/sort';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Document from '../views/Document';
import { getLabelOrder } from '../../utils/utils';

import { documentType, numberOrStringType } from '../../utils/types';
import FlippedImageCollection from '../views/FlippedImageCollection';
import { FILE_TYPE_CONFIGS } from '../../configs/constants';
import { openImageEditor } from '../../../orthodx/components/ImageEditor';
import { openImageCarousel } from './ImageCarousel';
import ThreeDotLoader from '../views/ThreeDotLoader';
import VideoDocuments from './VideoDocuments';

function Documents({
  orgId,
  patientKey,
  documents,
  isEditing,
  uploading,
  onImageEdit,
  onImageClick,
  onImageDelete,
  onImageLabelChange,
  onDocumentDelete,
  onDocumentClick,
  onImageEditTransformations,
}) {
  const imageDocuments = documents.filter((doc) => doc.image != null);
  const sortedImageDocuments = sort(
    (a, b) => getLabelOrder(a.image.label) - getLabelOrder(b.image.label),
    imageDocuments,
  );
  const videoDocuments = useMemo(
    () =>
      documents.filter((document) => FILE_TYPE_CONFIGS.isFileAcceptedAsVideo(document.mimetype)),
    [documents],
  );

  // if document is not image and it is not video
  const onlyDocuments = documents.filter(
    (doc) => doc.image == null && !FILE_TYPE_CONFIGS.isFileAcceptedAsVideo(doc.mimetype),
  );

  const handleImageClick = useCallback(
    (index) => {
      let imageCarousel = {};
      const toolbarButtons = [
        <button
          key="edit"
          type="button"
          // lightbox's classes
          className="ril__toolbarItemChild ril__builtinButton"
          style={{
            background: 'transparent',
          }}
          onClick={() => {
            if (typeof imageCarousel.handleCancel === 'function') {
              imageCarousel.handleCancel();
            }
            openImageEditor(sortedImageDocuments[index].image, onImageEditTransformations);
          }}
        >
          <FontAwesomeIcon icon="edit" color="white" style={{ fontSize: 20 }} />
        </button>,
      ];

      imageCarousel = openImageCarousel(
        sortedImageDocuments.map((d) => d.image),
        index,
        toolbarButtons,
        videoDocuments,
      );
    },
    [sortedImageDocuments, videoDocuments, onImageEditTransformations],
  );

  const handleDocumentClick = useCallback(
    (clickedDocument) => {
      const isVideo = FILE_TYPE_CONFIGS.isFileAcceptedAsVideo(clickedDocument.mimetype);
      if (isVideo) {
        const documentIndex = videoDocuments.findIndex((d) => d.key === clickedDocument.key);
        handleImageClick(
          sortedImageDocuments.length + documentIndex,
          sortedImageDocuments,
          videoDocuments,
        );
      } else {
        window.open(clickedDocument.url, '_blank');
      }
    },
    [sortedImageDocuments, videoDocuments, handleImageClick],
  );

  const handleImageEditClick = useCallback(
    (image) => {
      openImageEditor(image, onImageEditTransformations);
    },
    [onImageEditTransformations],
  );

  return (
    <div>
      <FlippedImageCollection
        orgId={orgId}
        imageDocuments={sortedImageDocuments}
        isEditing={isEditing}
        onImageEdit={onImageEdit ?? handleImageEditClick}
        onImageDelete={onImageDelete}
        onImageClick={onImageClick ?? handleImageClick}
        onLabelChange={onImageLabelChange}
      >
        {onlyDocuments?.map((document) => (
          <Document
            key={document.name}
            orgId={orgId}
            patientKey={patientKey}
            document={document}
            isEditing={isEditing}
            onClick={onDocumentClick ?? handleDocumentClick}
            onDelete={onDocumentDelete}
          />
        ))}
        {uploading && <ThreeDotLoader key="threeDotLoader" />}
      </FlippedImageCollection>
      <VideoDocuments
        orgId={orgId}
        documents={videoDocuments}
        isEditing={isEditing}
        onDelete={onDocumentDelete}
      />
    </div>
  );
}

Documents.defaultProps = {
  isEditing: false,
  uploading: false,
  onImageEdit: undefined,
  onImageDelete: undefined,
  onImageEditTransformations: undefined,
  onImageClick: undefined,
  onDocumentClick: undefined,
  onDocumentDelete: undefined,
  onImageLabelChange: undefined,
};

Documents.propTypes = {
  orgId: numberOrStringType.isRequired,
  documents: PropTypes.arrayOf(documentType).isRequired,
  patientKey: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  uploading: PropTypes.bool,
  onImageDelete: PropTypes.func,
  onImageEdit: PropTypes.func,
  onImageClick: PropTypes.func,
  onImageEditTransformations: PropTypes.func,
  onDocumentClick: PropTypes.func,
  onDocumentDelete: PropTypes.func,
  onImageLabelChange: PropTypes.func,
};

export default Documents;
