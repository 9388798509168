/*
This file defines the various cephalometric analyses that are available.

The analyses are defined using JSONLogic formulas (defined in cephLogic.js).
The variables available include data from landmarks and the current view.
These variables are defined when used (e.g., AnalysisResultsView.jsx),
though in the future this should maybe be moved into cephLogic.js:

For now analyses are hardcoded here. In the future, this could be saved in the
DB per org or per user.
*/
import masterLandmarks from './common/data/master-landmarks.json';
import masterPACephLandmarks from './common/data/master-pa-ceph-landmarks.json';

export const ANALYSIS_NAMES = {
  STEINER: 'Steiner Analysis',
  RICKETTS: 'Ricketts Analysis',
  JARABAK: 'Jarabak Analysis',
  ALL_LANDMARKS: 'All landmarks',
};

export const DEFAULT_ANALYSIS_NAMES = {
  ceph: 'Steiner Analysis',
  sto: 'Steiner Analysis',
  'pa-ceph': 'Ricketts Analysis (PA)',
};

// Supported types of annotations:
export const ANNOTATION_PLANE = 1;
export const ANNOTATION_CURVE = 2;
export const ANNOTATION_SHAPE = 3;

// Supported types of landmarks:
export const POINT_CONTROL = 1;
export const POINT_LANDMARK = 2;

export const FIT_METHOD_INCISOR = 1; // TODO: rename to FIT_METHOD_2_POINT
export const FIT_METHOD_MOLAR = 2; // TODO: rename to FIT_METHOD_3_POINT

export const UNITS = (s) => {
  const sLowercase = typeof s === 'string' ? s.toLowerCase() : '';
  switch (sLowercase) {
    case 'degrees':
    case 'degree':
    case '\xB0':
      return '\xB0';
    case 'percent':
    case '%':
      return '%';
    case 'mm':
      return 'mm';
    default:
      return s;
  }
};

// All insepected landmarks
const ALL_INSPECTED_CEPH_LANDMARKS = Object.keys(masterLandmarks)
  .map((x) => (masterLandmarks[x].inspected === 'Inspected' ? x : []))
  .flat();

const ALL_INSPECTED_PA_CEPH_LANDMARKS = Object.keys(masterPACephLandmarks)
  .map((x) => (masterPACephLandmarks[x].inspected === 'Inspected' ? x : []))
  .flat();

// Prefix to name control points (to filter out from display)
export const CONTROL_POINT_PREFIX = 'CtrlPt';

export const SEGMENTS = {
  'Pog-N': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Pog', 'N'],
  }, // Facial plane
  'N-A': { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['N', 'A'] },
  'N-B': { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['N', 'B'] },
  'A-B': { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['A', 'B'] },
  'Frankfort Horizontal': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Por', 'Or'],
  }, // Frankfort Horizontal
  'A-Pog': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['A', 'Pog'],
  },
  'Go-Me': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Go', 'Me'],
  }, // Mandibular plane (1)
  'Go-Gn': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Go', 'Gn'],
  }, // Mandibular plane (2)
  'S-Gn': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['S', 'Gn'],
  },
  'Occ Plane': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['L6', 'L1'],
  },
  U1: { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['U1', 'U1R'] },
  L1: { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['L1', 'L1R'] },
  'S-N': { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['S', 'N'] },
  'S-Ar': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['S', 'Ar'],
  },
  'Ar-Go': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Ar', 'Go'],
  },
  'Go-N': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Go', 'N'],
  },
  'S-Go': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['S', 'Go'],
  },
  'E Plane': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Prn', "Pog'"],
  },
  'Occ Plane (Functional)': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['L6', 'L4'],
  },
  // "PTV": {
  //   imageLabel: 'ceph',
  //   type: ANNOTATION_PLANE,
  //   landmarks: ['', '']},  // TODO: Pt ⊥ Frankfort Horizontal
  'Ba-N': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Ba', 'N'],
  }, // NOTE: Basion (Ba) is NOT the same as B-point
  'Facial Axis': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Pt', 'Gn'],
  },
  // "Facial Plane": {
  //   imageLabel: 'ceph',
  //   type: ANNOTATION_PLANE,
  //   landmarks: ['N', 'Pog']}, // Duplicate of Pog-N
  'Condylar Axis': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Xi', 'DC'],
  },
  'Corpus Axis': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Xi', 'Pm'],
  },

  // for Ricketts
  'N-CF': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['N', 'CF'],
  },
  'Pt-CF': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Pt', 'CF'],
  }, // aka PTV line
  // 'CF-A': { imageLabel: 'ceph', type: ANNOTATION_PLANE, landmarks: ['CF', 'A'] },
  'CF-A': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['CF', 'Or'],
  },
  'ANS-PNS': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['ANS', 'PNS'],
  },
  'CF-Xi': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['CF', 'Xi'],
  },
  'DC-Xi': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['DC', 'Xi'],
  },
  'Xi-Pm': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Xi', 'Pm'],
  },
  'ANS-Xi': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['ANS', 'Xi'],
  },
  'Xi-Pog': {
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Xi', 'Pog'],
  },

  // PA-ceph planes
  AG: {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.AG (r)', 'pa.AG (l)'],
  },
  'J-AG (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.J (r)', 'pa.AG (r)'],
  },
  'J-AG (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.J (l)', 'pa.AG (l)'],
  },
  FZS: {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.FZS (r)', 'pa.FZS (l)'],
  },
  ZA: {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.ZA (r)', 'pa.ZA (l)'],
  },
  'FZS-AG (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.FZS (r)', 'pa.AG (r)'],
  },
  'FZS-AG (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.FZS (l)', 'pa.AG (l)'],
  },
  'Midsagittal plane': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.CG', 'pa.ANS'],
  },
  'Occlusal plane (PA)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['pa.L6 (r)', 'pa.L6 (l)'],
  },

  // For calibration tests
  'Test Frankfort Horizontal': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test Por', 'Test Or'],
  },
  'Test A-B': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test A', 'Test B'],
  },
  'Test C-D': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test C', 'Test D'],
  },
  'Test E-F': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test E', 'Test F'],
  },
  'Test A-G': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test A', 'Test G'],
  },
  'Test G-E': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test G', 'Test E'],
  },
  'Test H/Por-Or': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test H', 'Test H/Por-Or'],
  },
  'Test I-J': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test I', 'Test J'],
  },
  'Test K/I-J': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test K', 'Test K/I-J'],
  },

  'Test L-M': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test L', 'Test M'],
  },
  'Test Xi-R4a': {
    exclude: true,
    imageLabel: 'ceph',
    type: ANNOTATION_PLANE,
    landmarks: ['Test Xi', 'Test R4a'],
  },

  // ///////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////
  //
  //                           CURVE DEFINITIONS
  //
  // ///////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // NOTE: The way these curves are saved, one should be VERY CAREFUL changing
  // these definitions once people are using them.
  //
  // In general, adding or removing points to the definition can cause the
  // curves to BREAK (either becoming inaccessible, or altering their
  // appearance).
  //
  // In order to alter their definition, one could either:
  //
  // 1.) make the change and
  // then backfill all saved curves for every patient in a way that preserves
  // their appearance.
  //
  // 2.) make the change and then DELETE any modifications made by users
  // for every patient (any modifications to the control points would be lost)
  //
  // 3.) Create a new, distinctly named, curve and manage showing the right
  // one to the user at the right time.

  EXAMPLE_CURVE: {
    type: ANNOTATION_CURVE,
    exclude: true,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: 'Test Por', type: POINT_LANDMARK, position: [0, 0] },
      {
        name: `${CONTROL_POINT_PREFIX}-EXAMPLE_CURVE-01`,
        type: POINT_CONTROL,
        position: [0.25, 0.1],
      },
      { name: 'Test Or', type: POINT_LANDMARK, position: [0.5, 0] },
      { name: 'Test A', type: POINT_LANDMARK, position: [1.0, 0.5] },
      {
        name: `${CONTROL_POINT_PREFIX}-EXAMPLE_CURVE-03`,
        type: POINT_CONTROL,
        position: [1.1, 0.6],
      },
    ],
  },

  'Nasal cavity (r)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: true,
    definition: [
      {
        name: 'pa.NCr (r)',
        type: 2,
        position: [419, 258.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-01',
        type: 1,
        position: [406, 329.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-02',
        type: 1,
        position: [375, 382.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-03',
        type: 1,
        position: [346, 486.34],
      },
      {
        name: 'pa.NAP (r)',
        type: 2,
        position: [330, 548.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-05',
        type: 1,
        position: [343, 578.34],
      },
      {
        name: 'pa.NCf (r)',
        type: 2,
        position: [378, 596.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-07',
        type: 1,
        position: [410, 578.34],
      },
      {
        name: 'pa.NCm (r)',
        type: 2,
        position: [420, 533.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-09',
        type: 1,
        position: [412, 413.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (r)-10',
        type: 1,
        position: [426, 337.34],
      },
    ],
  },

  'Nasal cavity (l)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: true,
    definition: [
      { name: 'pa.NCr (l)', type: 2, position: [692.33, 265.34] },
      {
        name: 'CtrlPt-Nasal cavity (l)-01',
        type: 1,
        position: [699.33, 335.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (l)-02',
        type: 1,
        position: [723.33, 401.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (l)-03',
        type: 1,
        position: [748.33, 477.34],
      },
      { name: 'pa.NAP (l)', type: 2, position: [764.33, 548.34] },
      {
        name: 'CtrlPt-Nasal cavity (l)-05',
        type: 1,
        position: [750.33, 582.34],
      },
      { name: 'pa.NCf (l)', type: 2, position: [718.33, 597.34] },
      {
        name: 'CtrlPt-Nasal cavity (l)-07',
        type: 1,
        position: [682.33, 579.34],
      },
      { name: 'pa.NCm (l)', type: 2, position: [667.33, 530.34] },
      {
        name: 'CtrlPt-Nasal cavity (l)-09',
        type: 1,
        position: [683.33, 428.34],
      },
      {
        name: 'CtrlPt-Nasal cavity (l)-10',
        type: 1,
        position: [681.33, 356.34],
      },
    ],
  },

  'Mandible (PA)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'CtrlPt-Mandible-00',
        type: 1,
        position: [316.33, 247.34],
      },
      {
        name: 'CtrlPt-Mandible-01',
        type: 1,
        position: [305.33, 206.34],
      },
      {
        name: 'CtrlPt-Mandible-02',
        type: 1,
        position: [312.33, 150.34],
      },
      {
        name: 'pa.Co (r)',
        type: 2,
        position: [274.33, 122.34],
      },
      {
        name: 'CtrlPt-Mandible-04',
        type: 1,
        position: [233.33, 163.34],
      },
      {
        name: 'CtrlPt-Mandible-05',
        type: 1,
        position: [253.33, 221.34],
      },
      {
        name: 'pa.MA (r)',
        type: 2,
        position: [275.33, 289.34],
      },
      {
        name: 'CtrlPt-Mandible-07',
        type: 1,
        position: [288.33, 389.34],
      },
      {
        name: 'pa.AG (r)',
        type: 2,
        position: [322.33, 508.34],
      },
      {
        name: 'CtrlPt-Mandible-09',
        type: 1,
        position: [395.33, 613.34],
      },
      {
        name: 'CtrlPt-Mandible-10',
        type: 1,
        position: [474.33, 667.34],
      },
      {
        name: 'pa.Me (r)',
        type: 2,
        position: [572.33, 707.34],
      },
      {
        name: 'pa.Me',
        type: 2,
        position: [615.33, 700.34],
      },
      {
        name: 'pa.Me (l)',
        type: 2,
        position: [655.33, 698.34],
      },
      {
        name: 'CtrlPt-Mandible-14',
        type: 1,
        position: [727.33, 665.34],
      },
      {
        name: 'CtrlPt-Mandible-15',
        type: 1,
        position: [802.33, 607.34],
      },
      {
        name: 'pa.AG (l)',
        type: 2,
        position: [861.33, 503.34],
      },
      {
        name: 'CtrlPt-Mandible-17',
        type: 1,
        position: [877.33, 396.34],
      },
      {
        name: 'pa.MA (l)',
        type: 2,
        position: [892.33, 291.34],
      },
      {
        name: 'CtrlPt-Mandible-19',
        type: 1,
        position: [904.33, 214.34],
      },
      {
        name: 'CtrlPt-Mandible-20',
        type: 1,
        position: [927.33, 147.34],
      },
      {
        name: 'pa.Co (l)',
        type: 2,
        position: [893.33, 119.34],
      },
      {
        name: 'CtrlPt-Mandible-22',
        type: 1,
        position: [854.33, 154.34],
      },
      {
        name: 'CtrlPt-Mandible-23',
        type: 1,
        position: [857.33, 194.34],
      },
      {
        name: 'CtrlPt-Mandible-24',
        type: 1,
        position: [853.33, 232.34],
      },
    ],
  },

  'Orbita (r)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: true,
    definition: [
      {
        name: 'pa.OrR (r)',
        type: 2,
        position: [428.33, 302.34],
      },
      {
        name: 'CtrlPt-Orbita (r)-01',
        type: 1,
        position: [361.33, 310.34],
      },
      {
        name: 'pa.FZS (r)',
        type: 2,
        position: [322.33, 359.34],
      },
      {
        name: 'CtrlPt-Orbita (r)-03',
        type: 1,
        position: [337.33, 470.34],
      },
      {
        name: 'pa.OrF (r)',
        type: 2,
        position: [418.33, 517.34],
      },
      {
        name: 'CtrlPt-Orbita (r)-05',
        type: 1,
        position: [478.33, 495.34],
      },
      {
        name: 'pa.OrM (r)',
        type: 2,
        position: [508.33, 427.34],
      },
      {
        name: 'CtrlPt-Orbita (r)-07',
        type: 1,
        position: [508.33, 354.34],
      },
    ],
  },

  'Orbita (l)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: true,
    definition: [
      {
        name: 'pa.OrR (l)',
        type: 2,
        position: [766.33, 288.34],
      },
      {
        name: 'CtrlPt-Orbita (l)-01',
        type: 1,
        position: [806.33, 293.34],
      },
      {
        name: 'pa.FZS (l)',
        type: 2,
        position: [867.33, 338.34],
      },
      {
        name: 'CtrlPt-Orbita (l)-03',
        type: 1,
        position: [854.33, 469.34],
      },
      {
        name: 'pa.OrF (l)',
        type: 2,
        position: [763.33, 513.34],
      },
      {
        name: 'CtrlPt-Orbita (l)-05',
        type: 1,
        position: [694.33, 491.34],
      },
      {
        name: 'pa.OrM (l)',
        type: 2,
        position: [659.33, 408.34],
      },
      {
        name: 'CtrlPt-Orbita (l)-07',
        type: 1,
        position: [690.33, 318.34],
      },
    ],
  },

  'Mastoid curve (r)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'pa.MAT (r)',
        type: 2,
        position: [388.33, 253.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (r)-01',
        type: 1,
        position: [389.33, 321.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (r)-02',
        type: 1,
        position: [404.33, 413.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (r)-03',
        type: 1,
        position: [417.33, 502.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (r)-04',
        type: 1,
        position: [439.33, 554.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (r)-05',
        type: 1,
        position: [456.33, 588.34],
      },
      {
        name: 'pa.MA (r)',
        type: 2,
        position: [491.33, 595.34],
      },
    ],
  },

  'Mastoid curve (l)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'pa.MAT (l)',
        type: 2,
        position: [878.33, 248.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (l)-01',
        type: 1,
        position: [874.33, 322.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (l)-02',
        type: 1,
        position: [853.33, 405.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (l)-03',
        type: 1,
        position: [843.33, 488.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (l)-04',
        type: 1,
        position: [820.33, 547.34],
      },
      {
        name: 'CtrlPt-Mastoid curve (l)-05',
        type: 1,
        position: [794.33, 589.34],
      },
      {
        name: 'pa.MA (l)',
        type: 2,
        position: [751.33, 595.34],
      },
    ],
  },

  'Zygomatic bone (r)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'CtrlPt-Zygomatic bone (r)-00',
        type: 1,
        position: [387.33, 267.34],
      },
      {
        name: 'pa.FZSL (r)',
        type: 2,
        position: [399.33, 305.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-02',
        type: 1,
        position: [391.33, 344.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-03',
        type: 1,
        position: [395.33, 399.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-04',
        type: 1,
        position: [380.33, 441.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-05',
        type: 1,
        position: [352.33, 475.34],
      },
      {
        name: 'pa.ZA (r)',
        type: 2,
        position: [319.33, 506.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-07',
        type: 1,
        position: [324.33, 525.34],
      },
      {
        name: 'pa.ZAi (r)',
        type: 2,
        position: [339.33, 534.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-09',
        type: 1,
        position: [374.33, 527.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-10',
        type: 1,
        position: [396.33, 515.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (r)-11',
        type: 1,
        position: [418.33, 503.34],
      },
    ],
  },

  'Zygomatic bone (l)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'CtrlPt-Zygomatic bone (l)-00',
        type: 1,
        position: [841.33, 275.34],
      },
      {
        name: 'pa.FZSL (l)',
        type: 2,
        position: [843.33, 314.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-02',
        type: 1,
        position: [858.33, 379.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-03',
        type: 1,
        position: [864.33, 451.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-04',
        type: 1,
        position: [904.33, 501.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-05',
        type: 1,
        position: [932.33, 517.34],
      },
      {
        name: 'pa.ZA (l)',
        type: 2,
        position: [942.33, 541.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-07',
        type: 1,
        position: [937.33, 563.34],
      },
      {
        name: 'pa.ZAi (l)',
        type: 2,
        position: [916.33, 573.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-09',
        type: 1,
        position: [888.33, 565.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-10',
        type: 1,
        position: [846.33, 534.34],
      },
      {
        name: 'CtrlPt-Zygomatic bone (l)-11',
        type: 1,
        position: [787.33, 525.34],
      },
    ],
  },

  'Zygomatic arch (l)': {
    type: 2,
    imageLabel: 'pa-ceph',
    closed: false,
    definition: [
      {
        name: 'pa.ZAi (l)',
        type: 2,
        position: [896.33, 294.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (l)-01',
        type: 1,
        position: [871.33, 315.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (l)-02',
        type: 1,
        position: [837.33, 337.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (l)-03',
        type: 1,
        position: [803.33, 357.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (l)-04',
        type: 1,
        position: [775.33, 375.34],
      },
      {
        name: 'pa.J (l)',
        type: 2,
        position: [745.33, 398.34],
      },
    ],
  },

  'Zygomatic arch (r)': {
    type: 2,
    closed: false,
    definition: [
      {
        name: 'pa.ZAi (r)',
        type: 2,
        position: [297.33, 296.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (r)-01',
        type: 1,
        position: [322.33, 314.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (r)-02',
        type: 1,
        position: [357.33, 332.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (r)-03',
        type: 1,
        position: [393.33, 350.34],
      },
      {
        name: 'CtrlPt-Zygomatic arch (r)-04',
        type: 1,
        position: [420.33, 367.34],
      },
      {
        name: 'pa.J (r)',
        type: 2,
        position: [439.33, 386.34],
      },
    ],
    imageLabel: 'pa-ceph',
  },

  'Upper lip and nose': {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: "G'", type: POINT_LANDMARK, position: [0.375, 0.115] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-01`,
        type: POINT_CONTROL,
        position: [0.358, 0.166],
      },
      { name: "N'", type: POINT_LANDMARK, position: [0.34, 0.198] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-03`,
        type: POINT_CONTROL,
        position: [0.354, 0.238],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-04`,
        type: POINT_CONTROL,
        position: [0.38, 0.269],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-05`,
        type: POINT_CONTROL,
        position: [0.397, 0.294],
      }, // FIXME: Nasal Hump
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-06`,
        type: POINT_CONTROL,
        position: [0.444, 0.341],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-07`,
        type: POINT_CONTROL,
        position: [0.489, 0.388],
      },
      { name: 'Prn', type: POINT_LANDMARK, position: [0.501, 0.429] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-09`,
        type: POINT_CONTROL,
        position: [0.484, 0.467],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-10`,
        type: POINT_CONTROL,
        position: [0.446, 0.486],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-11`,
        type: POINT_CONTROL,
        position: [0.424, 0.49],
      },
      { name: 'Sn', type: POINT_LANDMARK, position: [0.409, 0.494] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-13`,
        type: POINT_CONTROL,
        position: [0.4, 0.517],
      },
      { name: "A'", type: POINT_LANDMARK, position: [0.401, 0.547] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-15`,
        type: POINT_CONTROL,
        position: [0.405, 0.579],
      },
      { name: 'Ls', type: POINT_LANDMARK, position: [0.416, 0.601] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-17`,
        type: POINT_CONTROL,
        position: [0.411, 0.614],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-18`,
        type: POINT_CONTROL,
        position: [0.397, 0.626],
      },
      { name: 'Sts', type: POINT_LANDMARK, position: [0.382, 0.631] },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-20`,
        type: POINT_CONTROL,
        position: [0.362, 0.626],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Upper lip and nose-21`,
        type: POINT_CONTROL,
        position: [0.354, 0.609],
      },
    ],
  },

  'Lower lip and chin': {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-00`,
        type: POINT_CONTROL,
        position: [0.144, 0.548],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-01`,
        type: POINT_CONTROL,
        position: [0.199, 0.533],
      }, // FIXME: Throat
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-02`,
        type: POINT_CONTROL,
        position: [0.254, 0.52],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-03`,
        type: POINT_CONTROL,
        position: [0.32, 0.516],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-04`,
        type: POINT_CONTROL,
        position: [0.411, 0.528],
      },
      { name: "Me'", type: POINT_LANDMARK, position: [0.484, 0.538] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-06`,
        type: POINT_CONTROL,
        position: [0.519, 0.521],
      },
      { name: "Gn'", type: POINT_LANDMARK, position: [0.536, 0.502] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-08`,
        type: POINT_CONTROL,
        position: [0.546, 0.485],
      },
      { name: "Pog'", type: POINT_LANDMARK, position: [0.553, 0.459] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-10`,
        type: POINT_CONTROL,
        position: [0.551, 0.446],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-11`,
        type: POINT_CONTROL,
        position: [0.546, 0.426],
      },
      { name: "B'", type: POINT_LANDMARK, position: [0.537, 0.404] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-13`,
        type: POINT_CONTROL,
        position: [0.544, 0.391],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-14`,
        type: POINT_CONTROL,
        position: [0.559, 0.376],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-15`,
        type: POINT_CONTROL,
        position: [0.578, 0.359],
      },
      { name: 'Li', type: POINT_LANDMARK, position: [0.591, 0.338] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-17`,
        type: POINT_CONTROL,
        position: [0.589, 0.319],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-18`,
        type: POINT_CONTROL,
        position: [0.582, 0.308],
      },
      { name: 'Sti', type: POINT_LANDMARK, position: [0.567, 0.297] },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-20`,
        type: POINT_CONTROL,
        position: [0.551, 0.304],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Lower lip and chin-21`,
        type: POINT_CONTROL,
        position: [0.535, 0.317],
      },
    ],
  },

  Mandible: {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: 'Me', type: POINT_LANDMARK, position: [0.569, 0.65] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-01`,
        type: POINT_CONTROL,
        position: [0.467, 0.593],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-02`,
        type: POINT_CONTROL,
        position: [0.357, 0.52],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-03`,
        type: POINT_CONTROL,
        position: [0.252, 0.464],
      },
      { name: 'Go', type: POINT_LANDMARK, position: [0.211, 0.424] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-05`,
        type: POINT_CONTROL,
        position: [0.203, 0.395],
      },
      { name: 'R2', type: POINT_LANDMARK, position: [0.201, 0.37] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-07`,
        type: POINT_CONTROL,
        position: [0.196, 0.301],
      },
      { name: 'Ar', type: POINT_LANDMARK, position: [0.179, 0.229] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-09`,
        type: POINT_CONTROL,
        position: [0.171, 0.185],
      },
      { name: 'Co', type: POINT_LANDMARK, position: [0.201, 0.167] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-11`,
        type: POINT_CONTROL,
        position: [0.239, 0.212],
      },
      { name: 'R3', type: POINT_LANDMARK, position: [0.279, 0.27] },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-13`,
        type: POINT_CONTROL,
        position: [0.333, 0.243],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-14`,
        type: POINT_CONTROL,
        position: [0.376, 0.2],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Mandible-15`,
        type: POINT_CONTROL,
        position: [0.373, 0.292],
      },
      { name: 'R1', type: POINT_LANDMARK, position: [0.368, 0.339] },
    ],
  },

  Orbitale: {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: 'Or', type: POINT_LANDMARK, position: [0.414, 0.471] },
      {
        name: `${CONTROL_POINT_PREFIX}-Orbitale-01`,
        type: POINT_CONTROL,
        position: [0.372, 0.449],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Orbitale-02`,
        type: POINT_CONTROL,
        position: [0.359, 0.386],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Orbitale-03`,
        type: POINT_CONTROL,
        position: [0.406, 0.317],
      },
    ],
  },

  'External acoustic meatus': {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: true,
    definition: [
      { name: 'Por', type: POINT_LANDMARK, position: [0.377, 0.388] },
      {
        name: `${CONTROL_POINT_PREFIX}-External acoustic meatus-01`,
        type: POINT_CONTROL,
        position: [0.391, 0.409],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-External acoustic meatus-02`,
        type: POINT_CONTROL,
        position: [0.378, 0.415],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-External acoustic meatus-03`,
        type: POINT_CONTROL,
        position: [0.365, 0.399],
      },
    ],
  },

  Maxilla: {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: 'U1l', type: POINT_LANDMARK, position: [0.497, 0.451] }, // Palatal Gingival Border of U1 is "U1l"
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-01`,
        type: POINT_CONTROL,
        position: [0.48, 0.436],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-02`,
        type: POINT_CONTROL,
        position: [0.437, 0.375],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-03`,
        type: POINT_CONTROL,
        position: [0.38, 0.324],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-04`,
        type: POINT_CONTROL,
        position: [0.304, 0.307],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-05`,
        type: POINT_CONTROL,
        position: [0.22, 0.3],
      },
      { name: 'PNS', type: POINT_LANDMARK, position: [0.144, 0.292] },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-07`,
        type: POINT_CONTROL,
        position: [0.209, 0.286],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-08`,
        type: POINT_CONTROL,
        position: [0.333, 0.277],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-09`,
        type: POINT_CONTROL,
        position: [0.396, 0.281],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-10`,
        type: POINT_CONTROL,
        position: [0.443, 0.276],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-11`,
        type: POINT_CONTROL,
        position: [0.47, 0.289],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-12`,
        type: POINT_CONTROL,
        position: [0.493, 0.298],
      },
      { name: 'ANS', type: POINT_LANDMARK, position: [0.51, 0.305] },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-14`,
        type: POINT_CONTROL,
        position: [0.501, 0.325],
      },
      { name: 'A', type: POINT_LANDMARK, position: [0.499, 0.352] },
      {
        name: `${CONTROL_POINT_PREFIX}-Maxilla-16`,
        type: POINT_CONTROL,
        position: [0.524, 0.388],
      },
      { name: 'Pr', type: POINT_LANDMARK, position: [0.548, 0.433] }, // Labial Gingival Border of U1 is Prosthion (Pr)
    ],
  },

  Symphysis: {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: false,
    definition: [
      { name: 'L1l', type: POINT_LANDMARK, position: [0.368, 0.378] }, // Linugal Gingival Border of L1 is "L1l"
      {
        name: `${CONTROL_POINT_PREFIX}-Symphysis-01`,
        type: POINT_CONTROL,
        position: [0.336, 0.414],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Symphysis-02`,
        type: POINT_CONTROL,
        position: [0.301, 0.442],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Symphysis-03`,
        type: POINT_CONTROL,
        position: [0.281, 0.477],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Symphysis-04`,
        type: POINT_CONTROL,
        position: [0.302, 0.537],
      },
      { name: 'Me', type: POINT_LANDMARK, position: [0.332, 0.555] },
      { name: 'Gn', type: POINT_LANDMARK, position: [0.346, 0.543] },
      { name: 'Pog', type: POINT_LANDMARK, position: [0.358, 0.521] },
      {
        name: `${CONTROL_POINT_PREFIX}-Symphysis-08`,
        type: POINT_CONTROL,
        position: [0.36, 0.482],
      },
      { name: 'B', type: POINT_LANDMARK, position: [0.366, 0.439] },
      { name: 'Id', type: POINT_LANDMARK, position: [0.401, 0.394] }, // Labial Gingival Border of L1 is infradentale ("Id")
    ],
  },

  'Pterygomaxillary fissure': {
    type: ANNOTATION_CURVE,
    imageLabel: 'ceph',
    closed: true,
    definition: [
      { name: 'Pt', type: POINT_LANDMARK, position: [0.199, 0.1735] },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-01`,
        type: POINT_CONTROL,
        position: [0.206, 0.168],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-02`,
        type: POINT_CONTROL,
        position: [0.2215, 0.1745],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-03`,
        type: POINT_CONTROL,
        position: [0.2155, 0.192],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-04`,
        type: POINT_CONTROL,
        position: [0.212, 0.2425],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-05`,
        type: POINT_CONTROL,
        position: [0.205, 0.2085],
      },
      {
        name: `${CONTROL_POINT_PREFIX}-Pterygomaxillary fissure-06`,
        type: POINT_CONTROL,
        position: [0.201, 0.1845],
      },
    ],
  },

  'Upper incisor': {
    imageLabel: 'ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/upper-incisor.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      { name: 'U1', type: POINT_LANDMARK, position: [27, 61] },
      { name: 'U1R', type: POINT_LANDMARK, position: [-30, -61] },
    ],
  },

  'Lower incisor': {
    imageLabel: 'ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/lower-incisor.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      { name: 'L1', type: POINT_LANDMARK, position: [41, -52] },
      { name: 'L1R', type: POINT_LANDMARK, position: [-39, 51] },
    ],
  },

  'Upper 1st Molar': {
    imageLabel: 'ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/upper-1st-molar.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      { name: 'U6m', type: POINT_LANDMARK, position: [26, 34] },
      { name: 'U6d', type: POINT_LANDMARK, position: [-26, 28] },
      { name: 'U6', type: POINT_LANDMARK, position: [6, 46] },
    ],
  },

  'Lower 1st Molar': {
    imageLabel: 'ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/lower-1st-molar.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      { name: 'L6m', type: POINT_LANDMARK, position: [32, -26] },
      { name: 'L6d', type: POINT_LANDMARK, position: [-23, -36] },
      { name: 'L6', type: POINT_LANDMARK, position: [21, -46] },
    ],
  },

  'Upper incisor (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-upper-incisor-r.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      {
        name: 'pa.U1 (r)',
        type: POINT_LANDMARK,
        position: [21.1 - 48.9 / 2, 137.9 - 141.1 / 2],
      },
      {
        name: 'pa.U1R (r)',
        type: POINT_LANDMARK,
        position: [33.5 - 48.9 / 2, 2.7 - 141.1 / 2],
      },
      {
        name: 'pa.U1m (r)',
        type: POINT_LANDMARK,
        position: [45.1 - 48.9 / 2, 90.4 - 141.1 / 2],
      },
    ],
  },

  'Upper incisor (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-upper-incisor-l.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      {
        name: 'pa.U1 (l)',
        type: POINT_LANDMARK,
        position: [48.9 - 21.1 - 48.9 / 2, 137.9 - 141.1 / 2],
      },
      {
        name: 'pa.U1R (l)',
        type: POINT_LANDMARK,
        position: [48.9 - 33.5 - 48.9 / 2, 2.7 - 141.1 / 2],
      },
      {
        name: 'pa.U1m (l)',
        type: POINT_LANDMARK,
        position: [48.9 - 45.1 - 48.9 / 2, 90.4 - 141.1 / 2],
      },
    ],
  },

  'Upper 1st Molar (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-upper-1st-molar-r.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      {
        name: 'pa.U6 (r)',
        type: POINT_LANDMARK,
        position: [9.7 - 76.6 / 2, 95.8 - 110.5 / 2],
      },
      {
        name: 'pa.U6R (r)',
        type: POINT_LANDMARK,
        position: [40 - 76.6 / 2, 2.5 - 110.5 / 2],
      },
    ],
  },

  'Upper 1st Molar (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-upper-1st-molar-l.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      {
        name: 'pa.U6 (l)',
        type: POINT_LANDMARK,
        position: [76.6 - 9.7 - 76.6 / 2, 95.8 - 110.5 / 2],
      },
      {
        name: 'pa.U6R (l)',
        type: POINT_LANDMARK,
        position: [76.6 - 40 - 76.6 / 2, 2.5 - 110.5 / 2],
      },
    ],
  },

  'Lower incisor (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-lower-incisor-r.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      {
        name: 'pa.L1R (r)',
        type: POINT_LANDMARK,
        position: [8.3 - 33.3 / 2, 111 - 114 / 2],
      },
      {
        name: 'pa.L1 (r)',
        type: POINT_LANDMARK,
        position: [16.7 - 33.3 / 2, 2.8 - 114 / 2],
      },
      {
        name: 'pa.L1m (r)',
        type: POINT_LANDMARK,
        position: [26.6 - 33.3 / 2, 44 - 114 / 2],
      },
    ],
  },

  'Lower incisor (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-lower-incisor-l.svg',
    fitMethod: FIT_METHOD_MOLAR,
    definition: [
      {
        name: 'pa.L1R (l)',
        type: POINT_LANDMARK,
        position: [33.3 - 8.3 - 33.3 / 2, 111 - 114 / 2],
      },
      {
        name: 'pa.L1 (l)',
        type: POINT_LANDMARK,
        position: [33.3 - 16.7 - 33.3 / 2, 2.8 - 114 / 2],
      },
      {
        name: 'pa.L1m (l)',
        type: POINT_LANDMARK,
        position: [33.3 - 26.6 - 33.3 / 2, 44 - 114 / 2],
      },
    ],
  },

  'Lower 1st Molar (r)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-lower-1st-molar-r.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      {
        name: 'pa.L6R (r)',
        type: POINT_LANDMARK,
        position: [12.8 - 89.2 / 2, 123.5 - 133.9 / 2],
      },
      {
        name: 'pa.L6 (r)',
        type: POINT_LANDMARK,
        position: [21 - 89.2 / 2, 2.8 - 133.9 / 2],
      },
    ],
  },

  'Lower 1st Molar (l)': {
    imageLabel: 'pa-ceph',
    type: ANNOTATION_SHAPE,
    svg: '/images/structures/pa-lower-1st-molar-l.svg',
    fitMethod: FIT_METHOD_INCISOR,
    definition: [
      {
        name: 'pa.L6R (l)',
        type: POINT_LANDMARK,
        position: [89.2 - 12.8 - 89.2 / 2, 123.5 - 133.9 / 2],
      },
      {
        name: 'pa.L6 (l)',
        type: POINT_LANDMARK,
        position: [89.2 - 21 - 89.2 / 2, 2.8 - 133.9 / 2],
      },
    ],
  },
};

// Add `index` to any ANNOTATION_CURVE segments
Object.values(SEGMENTS).forEach((segment) => {
  if (segment.type === ANNOTATION_CURVE) {
    segment.definition.forEach((p, index) => {
      p.index = index; // eslint-disable-line no-param-reassign
    });
  }
});

// get list of landmarks involved in curves
const CURVE_LANDMARKS_SET = {
  ceph: new Set(),
  'pa-ceph': new Set(),
};
const CURVE_NAMES = {
  ceph: [],
  'pa-ceph': [],
};
['ceph', 'pa-ceph'].forEach((imageLabel) => {
  Object.keys(SEGMENTS).forEach((c) => {
    if (SEGMENTS[c].imageLabel === imageLabel && !SEGMENTS[c].exclude) {
      if (SEGMENTS[c].type === ANNOTATION_CURVE || SEGMENTS[c].type === ANNOTATION_SHAPE) {
        CURVE_NAMES[imageLabel].push(c);
        SEGMENTS[c].definition.forEach((d) => {
          if (d.type === POINT_LANDMARK) CURVE_LANDMARKS_SET[imageLabel].add(d.name);
        });
      }
    }
  });
});
const CURVE_LANDMARKS = {
  ceph: [...CURVE_LANDMARKS_SET.ceph],
  'pa-ceph': [...CURVE_LANDMARKS_SET['pa-ceph']],
};

// TODO: fix analyses.test
export const CONSTRUCTED_LANDMARKS = {
  A6: {
    code: "project(get('U6d'), get('L6'), get('L4'))",
  },
  B6: {
    code: "project(get('L6d'), get('L6'), get('L4'))",
  },
  CF: {
    code: "project(get('Pt'), get('Por'), get('Or'))",
  },
  CC: {
    code: "intersection(get('Pt'), get('Gn'), get('Ba'), get('N'))",
  },
  R2a: {
    code: "project(get('R2'), get('Por'), get('Or'))",
  },
  R3a: {
    code: "project(get('R3'), get('R2a'), get('R2'))",
  },
  R1a: {
    code: "project(get('R1'), get('Por'), get('Or'))",
  },
  R3b: {
    code: "project(get('R3'), get('R1a'), get('R1'))",
  },
  R4a: {
    code: "project(get('R4'), get('R2a'), get('R2'))",
  },
  R4b: {
    code: "project(get('R4'), get('R1a'), get('R1'))",
  },
  Xi: {
    code: "intersection(get('R3a'), get('R4b'), get('R4a'), get('R3b'))",
  },

  DT: undefined,
  'Gn (Cons.)': undefined,
  'Go (Cons.)': undefined,
  P: undefined,
  PO: undefined,
  TI: undefined,
  'Point O': undefined,

  'Test R2a': {
    code: "project(get('Test R2'), get('Test Por'), get('Test Or'))",
  },
  'Test R3a': {
    code: "project(get('Test R3'), get('Test R2a'), get('Test R2'))",
  },
  'Test R1a': {
    code: "project(get('Test R1'), get('Test Por'), get('Test Or'))",
  },
  'Test R3b': {
    code: "project(get('Test R3'), get('Test R1a'), get('Test R1'))",
  },
  'Test R4a': {
    code: "project(get('Test R4'), get('Test R2a'), get('Test R2'))",
  },
  'Test R4b': {
    code: "project(get('Test R4'), get('Test R1a'), get('Test R1'))",
  },
  'Test Xi': {
    code: "intersection(get('Test R3a'), get('Test R4b'), get('Test R4a'), get('Test R3b'))",
  },
  'Test K/I-J': {
    code: "intersection(get('Test I'), get('Test J'), get('Test K'), parallel(get('Test K'), get('Test Por'), get('Test Or')))",
  },
  'Test H/Por-Or': {
    code: "project(get('Test H'), get('Test Por'), get('Test Or'))",
  },
};

export const CANONICAL_LANDMARK_ORDER = {
  ceph: [
    'Ruler1',
    'Ruler2',

    // CHRONOLOGY
    // 1) Soft Tissue Landmarks
    "G'",
    "N'",
    'Prn',
    'Sn',
    "A'",
    'Ls',
    'Li',
    'Sts',
    'Sti',
    'St', // synonym of "Em"
    "B'",
    "Pog'",
    "Gn'",
    "Me'",

    // 2) Skeletal Landmarks/Soft Tissue
    'G',
    'N',
    'ANS',
    'A',
    'Pr',
    'PNS',
    'B',
    'Pm',
    'Pog',
    'Gn',
    'Me',
    'Go',
    'Or',
    'Te',
    'Pt',
    'S',
    'Si',
    'Sp',
    'Por',
    'Ba',
    'Ar',
    'Co',
    'C1',
    'DC', // (Actually NOT constructed -- it cannot be derived from other current landmarks)
    'R1',
    'R2',
    'R3',
    'R4',
    'Seg',
    'PHW',

    // 3) Dental Landmarks
    'U1',
    'U1R',
    'L1',
    'L1R',
    'U3',
    'L3',
    'L4',
    'U6',
    'U6m',
    'U6d',
    'L6',
    'L6m',
    'L6d',

    // Constructed landmarks (as opposed to "inspected" or placed landmarks)
    'Point O',

    'A6',
    'B6',
    'CC',
    'CF',
    'DT',
    'Gn (Cons.)',
    'Go (Cons.)',
    'P',
    'PO',
    'TI',
    'Xi',

    // Additional landmarks; not sure about order:
    'Bo',
    'Col',
    'Hy',
    'Id',
    'L1l',
    'PBT',
    "TMJ'",
    'U1l',

    'C2ia',
    'C2id',
    'C2ip',
    'C3ia',
    'C3id',
    'C3ip',
    'C3sa',
    'C3sp',
    'C4ia',
    'C4id',
    'C4ip',
    'C4sa',
    'C4sp',

    // New (unplaced...)  # FIXME
    'Cor',
    'GT',
    'LLt',
    'OrR',
    'Tr',
    'ULt',
  ],
  'pa-ceph': [
    'Ruler1',
    'Ruler2',

    // TODO: reorder pa-ceph landmarks
    // (start of curve-related landmarks)
    'pa.NCr (r)',
    'pa.NAP (r)',
    'pa.NCf (r)',
    'pa.NCm (r)',
    'pa.NCr (l)',
    'pa.NAP (l)',
    'pa.NCf (l)',
    'pa.NCm (l)',

    // 'pa.Co (r)', // (duplicate...)  // FIXME: look into why duplicate defn here affects analyses table values
    // 'pa.MA (r)', // (duplicate...)
    // 'pa.AG (r)', // (duplicate...)
    'pa.Me (r)',
    // 'pa.Me', // (duplicate...)
    'pa.Me (l)',
    // 'pa.AG (l)', // (duplicate...)
    // 'pa.MA (l)', // (duplicate...)
    // 'pa.Co (l)', // (duplicate...)

    'pa.OrR (r)',
    // 'pa.FZS (r)', // (duplicate...)
    'pa.OrF (r)',
    'pa.OrM (r)',
    'pa.OrR (l)',
    // 'pa.FZS (l)', // (duplicate...)
    'pa.OrF (l)',
    'pa.OrM (l)',

    'pa.MAT (r)',
    'pa.MAT (l)',

    // 'pa.FZSL (r)', // (duplicate...)
    // 'pa.ZA (r)', // (duplicate...)
    'pa.ZAi (r)',
    // 'pa.FZSL (l)', // (duplicate...)
    // 'pa.ZA (l)', // (duplicate...)
    'pa.ZAi (l)',
    // (end of curve-related landmarks)

    'pa.AG (l)',
    'pa.AG (r)',
    'pa.ANS',
    'pa.CG',
    'pa.Co (l)',
    'pa.Co (r)',
    'pa.Cor (l)',
    'pa.Cor (r)',
    'pa.FR (l)',
    'pa.FR (r)',
    'pa.FZS (l)',
    'pa.FZS (r)',
    'pa.FZSL (l)',
    'pa.FZSL (r)',
    'pa.Go (l)',
    'pa.Go (r)',
    'pa.IF',
    'pa.J (l)',
    'pa.J (r)',
    'pa.L1 (l)',
    'pa.L1 (m)',
    'pa.L1 (r)',
    'pa.L1m (l)',
    'pa.L1m (r)',
    'pa.L1R (l)',
    'pa.L1R (m)',
    'pa.L1R (r)',
    'pa.L3 (l)',
    'pa.L3 (r)',
    'pa.L6 (l)',
    'pa.L6 (r)',
    'pa.L6R (l)',
    'pa.L6R (r)',
    'pa.MA (l)',
    'pa.MA (r)',
    'pa.MF (l)',
    'pa.MF (r)',
    'pa.Me',
    'pa.NAP (l)',
    'pa.NAP (r)',
    'pa.Or (m)',
    'pa.OrC (l)',
    'pa.OrC (r)',
    'pa.OrM (l)',
    'pa.OrM (r)',
    'pa.TNS',
    'pa.U1 (l)',
    'pa.U1 (m)',
    'pa.U1 (r)',
    'pa.U1m (l)',
    'pa.U1m (r)',
    'pa.U1R (l)',
    'pa.U1R (r)',
    'pa.U3 (l)',
    'pa.U3 (r)',
    'pa.U6 (l)',
    'pa.U6 (r)',
    'pa.U6l (l)',
    'pa.U6l (r)',
    'pa.U6R (l)',
    'pa.U6R (r)',
    'pa.ZA (l)',
    'pa.ZA (r)',
    'pa.ZMS (l)',
    'pa.ZMS (r)',
  ],
};

const orderLandmarks = (imageLabel, landmarks) => {
  const dedupedLandmarks = [...new Set(landmarks)];
  dedupedLandmarks.sort(
    (a, b) =>
      CANONICAL_LANDMARK_ORDER[imageLabel].indexOf(a) -
      CANONICAL_LANDMARK_ORDER[imageLabel].indexOf(b),
  );
  return dedupedLandmarks;
};

export const displayedColumnsForExport = [0, 1, 4, 2, 3, 5];

export const analyses = {
  'Steiner Analysis': {
    name: 'Steiner Analysis',
    imageLabel: 'ceph',
    landmarks: orderLandmarks(
      'ceph',
      [
        'Ruler1',
        'Ruler2',
        'S',
        'N',
        'A',
        'B',
        'U1',
        'U1R',
        'L1',
        'L1R',
        'L6',
        'Go',
        'Gn',
        'Pog',
      ].concat(CURVE_LANDMARKS.ceph),
    ),
    segments: CURVE_NAMES.ceph.concat(['S-N', 'N-A', 'N-B', 'U1', 'L1', 'Occ Plane', 'Go-Gn']),
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      [
        'SNA Angle',
        'Sella-Nasion-A Point Angle',
        82,
        1,
        {
          code: "angle(get('A'), get('N'), get('S'))",
        },
        'degrees',
      ],
      [
        'SNB Angle',
        'Sella-Nasion-B Point',
        80,
        1,
        {
          code: "angle(get('B'), get('N'), get('S'))",
        },
        'degrees',
      ],
      [
        'ANB Angle',
        'A Point-Nasion-B Point',
        2,
        1,
        {
          code: "angle(get('A'), get('N'), get('B'))",
        },
        'degrees',
      ],
      [
        'U1 to NA (mm)',
        'Upper Incisor edge to NA (mm)',
        4,
        1,
        {
          code: "normal(get('U1'), get('N'), get('A'))",
        },
        'mm',
      ],
      [
        'U1 to NA (angle)',
        'Upper incisor to NA (angle)',
        22,
        1,
        {
          code: "angle4(get('U1R'), get('U1'), get('N'), get('A'))",
        },
        'degrees',
      ],
      [
        'L1 to NB (mm)',
        'Lower incisor edge to NB (mm)',
        4,
        1,
        {
          code: "normal(get('L1'), get('N'), get('B'))",
        },
        'mm',
      ],
      [
        'L1 to NB (angle)',
        'Lower incisor to NB (angle) ',
        25,
        1,
        {
          code: "angle4(get('N'), get('B'), get('L1'), get('L1R'))",
        },
        'degrees',
      ],
      [
        'U1 to L1 (angle)',
        'Upper incisor to lower incisor angle',
        131,
        1,
        {
          code: "angle4(get('L1R'), get('L1'), get('U1R'), get('U1'))",
        },
        'degrees',
      ],
      [
        'Occ Plane to SN',
        'Occlusal Plane to SN angle',
        14,
        1,
        {
          code: "angle4(get('N'), get('S'), get('L1'), get('L6'))",
        },
        'degrees',
      ],
      [
        'Go-Gn / SN',
        'Mandibular Plane to SN',
        32,
        2,
        {
          code: "angle4(get('N'), get('S'), get('Gn'), get('Go'))",
        },
        'degrees',
      ],
      [
        'Pog – NB (mm)',
        'Pogonion to NB Plane',
        4,
        1,
        {
          code: "normal(get('Pog'), get('N'), get('B'))",
        },
        'mm',
      ],
    ],
  },
  "Downs' Analysis": {
    name: "Downs' Analysis",
    imageLabel: 'ceph',
    landmarks: orderLandmarks(
      'ceph',
      [
        'Ruler1',
        'Ruler2',
        'Por',
        'S',
        'N',
        'Or',
        'A',
        'B',
        'Pog',
        'Gn',
        'Me',
        'Go',
        'U1',
        'U1R',
        'L1',
        'L1R',
        'L6',
      ].concat(CURVE_LANDMARKS.ceph),
    ),
    segments: CURVE_NAMES.ceph.concat([
      'Frankfort Horizontal',
      'Pog-N',
      'N-A',
      'A-Pog',
      'A-B',
      'Go-Me',
      'S-Gn',
      'Occ Plane',
      'L1',
      'U1',
    ]),
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      ['#Skeletal Measurements', '', '', '', '', ''],
      [
        'Facial Angle (N-Pog/Frankfort Horizontal)',
        '',
        87.8,
        3.6,
        {
          code: "angle4(get('Pog'), get('N'), get('Por'), get('Or'))",
        },
        'degrees',
      ],
      [
        'N-A-Pog',
        '',
        0,
        5.1,
        {
          code: "angle4(get('A'), get('N'), get('Pog'), get('A'))",
        },
        'degrees',
      ],
      [
        'A-B/N-Pog',
        '',
        -4.6,
        3.7,
        {
          code: "angle4(get('B'), get('A'), get('Pog'), get('N'))",
        },
        'degrees',
      ],
      [
        'Frankfort Horizontal/Go-Me',
        '',
        21.9,
        3.2,
        {
          code: "angle4(get('Or'), get('Por'), get('Me'), get('Go'))",
        },
        'degrees',
      ],
      [
        'Y Axis (Frankfort Horizontal/S-Gn)',
        '',
        59.4,
        3.8,
        {
          code: "angle4(get('Or'), get('Por'), get('Gn'), get('S'))",
        },
        'degrees',
      ],
      ['#Dental Measurements', '', '', '', '', ''],
      [
        'Frankfort Horizontal/Occ Plane Angle',
        '',
        9.3,
        3.8,
        {
          code: "angle4(get('Or'), get('Por'), get('L1'), get('L6'))",
        },
        'degrees',
      ],
      [
        'Interincisal Angle (U1/L1)',
        '',
        135.4,
        5.8,
        {
          code: "angle4(get('L1R'), get('L1'), get('U1R'), get('U1'))",
        },
        'degrees',
      ],
      [
        'L1 / Occ Plane Angle',
        '',
        14.5,
        3.5,
        {
          code: "subtract(90, angle4(get('L1R'), get('L1'), get('L6'), get('L1')))",
        },
        'degrees',
      ],
      [
        'Go-Me / L1 Angle',
        '',
        2.7,
        1.8,
        {
          code: "subtract(angle4(get('Go'), get('Me'), get('L1'), get('L1R')), 90)",
        },
        'degrees',
      ],
      [
        'U1 / A-Pog (mm)',
        '',
        2.7,
        1.8,
        {
          code: "normal(get('U1'), get('A'), get('Pog'))",
        },
        'mm',
      ],
    ],
  },
  'Ricketts Analysis': {
    name: 'Ricketts Analysis',
    imageLabel: 'ceph',
    landmarks: orderLandmarks(
      'ceph',
      [
        'Ruler1',
        'Ruler2',

        // regular landmarks
        'Gn',
        'Pt',
        'Ba',
        'N',
        'Pog',
        'Por',
        'Or',
        'Go',
        'Me',
        'A',
        'Pm',
        'ANS',
        'PNS',
        'L1',
        'U1',
        'L1R',
        'U1R',
        'L6',
        'L3',
        'U3',
        'L4',
        'St',

        "Pog'",
        'Prn',
        'Li',

        // C1 is an inspected point but not part of any measurement
        'C1',

        // DC is *actually* inspected (because it cannot be automatically
        // derived from other landmarks (unless we had some additional
        // currently-implicit landmarks.) WE SHOULD LEARN THIS POINT.
        // NOTE also: DC should be on the line Ba --> N
        'DC',

        // Required for constructed landmarks:
        'L6d',
        'U6d', // Required for A6 / B6

        'R1',
        'R2',
        'R3',
        'R4', // Required for Xi
      ].concat(CURVE_LANDMARKS.ceph),
    ),

    // NOTE: The order of constructed landmarks matters!
    // Any landmarks another depends on must appear in this list
    // first.
    constructedLandmarks: [
      // Compute (but do not show) these "intermediate" constructed landmarks
      'R2a',
      'R3a',
      'R1a',
      'R3b',
      'R4a',
      'R4b', // (required for Xi)

      // Main computed landmarks
      'Xi',
      'A6',
      'B6',
      'CC',
      'CF',
    ],

    // Only display landmarks listed below
    visibleConstructedLandmarks: ['Xi', 'A6', 'B6', 'CC', 'CF'],

    segments: CURVE_NAMES.ceph.concat([
      'Facial Axis',
      'Ba-N',
      'Frankfort Horizontal',
      'Pog-N',
      'Go-Me',
      'N-A',

      'N-CF',
      'CF-A',
      'ANS-PNS',
      'CF-Xi',
      'Pt-CF',

      'DC-Xi',
      'Xi-Pm',

      'ANS-Xi',
      'Xi-Pog',

      'A-Pog',
      'L1',
      'U1',

      'Occ Plane (Functional)',

      'E Plane',
    ]),

    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      ['#Craniofacial Relationship', '', '', '', '', ''],
      [
        'Facial Depth',
        {
          code: 'cat("87° at age 9 +0.33° per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(87, get('age'), 9, 0.33)",
        },
        3,
        {
          code: "angle4(get('Pog'), get('N'), get('Por'), get('Or'))",
        },
        'degrees',
      ],
      [
        'Facial Axis',
        '',
        90,
        3.5,
        {
          code: "angle4(get('Gn'), get('Pt'), get('Ba'), get('N'))",
        },
        'degrees',
      ],
      [
        'Facial Taper',
        '',
        68,
        3.5,
        {
          code: "angle4(get('Go'), get('Me'), get('N'), get('Pog'))",
        },
        'degrees',
      ],
      [
        'Mandibular Plane Angle',
        {
          code: 'cat("26° at age 9 -0.3° per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(26, get('age'), 9, -0.3)",
        },
        4.5,
        {
          code: "angle4(get('Or'), get('Por'), get('Me'), get('Go'))",
        },
        'degrees',
      ],
      [
        'Maxillary Depth',
        '',
        90,
        3,
        {
          code: "angle4(get('A'), get('N'), get('Por'), get('Or'))",
        },
        'degrees',
      ],
      [
        'Maxillary Height',
        {
          code: 'cat("53° at age 9 +0.4° per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(53, get('age'), 9, 0.4)",
        },
        3,
        {
          code: "angle(get('N'), get('CF'), get('A'))",
        },
        'degrees',
      ],
      [
        'Palatal Plane',
        '',
        1,
        3.5,
        {
          code: "angle4(get('ANS'), get('PNS'), get('Or'), get('Por'))",
        },
        'degrees',
      ],
      ['#Internal Structure', '', '', '', '', ''],
      [
        'Cranial Deflection',
        '',
        27,
        3,
        {
          code: "angle4(get('N'), get('Ba'), get('Or'), get('Por'))",
        },
        'degrees',
      ],
      [
        'Cranial Length Anterior',
        {
          code: 'cat("55mm at age 8.5 +0.79mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(55, get('age'), 8.5, 0.79)",
        },
        2.5,
        {
          code: "dist(get('CC'), get('N'))",
        },
        'mm',
      ],
      [
        'Posterior Facial Height',
        {
          code: 'cat("55mm at age 8.5 (should be corrected for size).", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(55, get('age'), 8.5, 0)",
        },
        3.3,
        {
          code: "dist(get('Go'), get('CF'))",
        },
        'mm',
      ],
      [
        'Ramus Position',
        '',
        76,
        3,
        {
          code: "angle4(get('CF'), get('Xi'), get('Or'), get('Por'))",
        },
        'degrees',
      ],
      [
        'Porion Location',
        {
          code: 'cat("-39mm at age 9.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(-39, get('age'), 9, 0)",
        },
        2.2,
        {
          code: "product(-1,normal(get('Por'), get('Pt'), get('CF')))",
        },
        'mm',
      ],
      [
        'Mandibular Arch',
        {
          code: 'cat("26° at age 8.5 +0.5° per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(26, get('age'), 8.5, 0.5)",
        },
        4,
        {
          code: "angle4(get('Xi'), get('Pm'), get('DC'), get('Xi'))",
        },
        'degrees',
      ],
      [
        'Corpus Length',
        {
          code: 'cat("65mm at age 8.5 +1.6mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(65, get('age'), 8.5, 1.6)",
        },
        2.7,
        {
          code: "dist(get('Xi'), get('Pog'))",
        },
        'mm',
      ],
      ['#Skeletal Measurements', '', '', '', '', ''],
      [
        'Convexity',
        {
          code: 'cat("2mm at age 8.5 -0.2mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(2, get('age'), 8.5, -0.2)",
        },
        2,
        {
          code: "product(sign(get('A'), get('Pog'), get('N')),normal(get('A'), get('N'), get('Pog')))",
        },
        'mm',
      ],
      [
        'Lower Facial Height',
        '',
        47,
        4,
        {
          code: "angle(get('ANS'), get('Xi'), get('Pog'))",
        },
        'degrees',
      ],
      ['#Dental to Skeletal Measurements', '', '', '', '', ''],
      [
        'Upper Molar Position',
        {
          code: 'cat("Age of patient in years +3mm until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "add(get('age'), 3)",
        },
        3,
        {
          code: "normal(get('A6'), get('Pt'), get('CF'))",
        },
        'mm',
      ],
      [
        'Mandibular Incisor Protrusion',
        '',
        1,
        2.3,
        {
          code: "product(sign(get('L1'), get('Pog'), get('A')),normal(get('L1'), get('A'), get('Pog')))",
        },
        'mm',
      ],
      [
        'Mandibular Incisor Inclination',
        '',
        22,
        4,
        {
          code: "angle4(get('A'), get('Pog'), get('L1'), get('L1R'))",
        },
        'degrees',
      ],
      [
        'Maxillary Incisor Protrusion',
        '',
        3.5,
        2.3,
        {
          code: "product(sign(get('U1'), get('Pog'), get('A')),normal(get('U1'), get('A'), get('Pog')))",
        },
        'mm',
      ],
      [
        'Maxillary Incisor Inclination',
        '',
        28,
        4,
        {
          code: "angle4(get('U1'), get('U1R'), get('Pog'), get('A'))",
        },
        'degrees',
      ],
      [
        'Occlusal Plane (functional) to Ramus',
        {
          code: 'cat("0mm at 9.5 years -0.5mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(0, get('age'), 9.5, -0.5)",
        },
        3,
        {
          code: "product(sign(get('Xi'), get('L6'), get('L4')),normal(get('Xi'), get('L6'), get('L4')))",
        },
        'mm',
      ],
      [
        'Occlusal Plane (functional) Inclination',
        {
          code: 'cat("22° at age 8 +0.5° per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(22, get('age'), 8, 0.5)",
        },
        4,
        {
          code: "angle4(get('L4'), get('L6'), get('Pm'), get('Xi'))",
        },
        'degrees',
      ],
      ['#Dental Measurements', '', '', '', '', ''],
      [
        'Molar Relation Class',
        '-6mm <= Class I <= 0mm; Class II > 0mm; Class III < -6mm',
        '',
        '',
        {
          // CLASS III < -6
          // -6 < CLASS I < 0
          // 0 < CLASS II
          code: "set('molarRelation', product(sign4(get('A6'), get('B6'), get('L4'), get('L6')),dist(get('A6'), get('B6'))), if(missing('Ruler1', 'Ruler2', 'calibration'), 'RULERS_AND_CALIBRATION_REQUIRED', lessThan(get('molarRelation'),-6),'CLASS III',lessThanOrEqualTo(get('molarRelation'), 0),'CLASS I','CLASS II'))",
        },
        '',
      ],
      [
        'Molar Relation',
        '',
        -3,
        3,
        {
          code: "set('molarRelation', product(sign4(get('A6'), get('B6'), get('L4'), get('L6')),dist(get('A6'), get('B6'))), if(missing('Ruler1', 'Ruler2', 'calibration'), 'RULERS_AND_CALIBRATION_REQUIRED', get('molarRelation'))",
        },
        'mm',
      ],
      [
        'Canine Relation Class',
        '-5mm <= Class I <= 1mm; Class II > 1mm; Class III < -5mm',
        '',
        '',
        {
          code: "set('canineRelation', product(sign4(get('U3'), get('L3'), get('L4'), get('L6')),dist(get('U3'), get('L3'))), if(missing('Ruler1', 'Ruler2', 'calibration'), 'RULERS_AND_CALIBRATION_REQUIRED', lessThan(get('canineRelation'),-5),'CLASS III',lessThanOrEqualTo(get('canineRelation'), 1),'CLASS I','CLASS II'))",
        },
        '',
      ],
      [
        'Canine Relation',
        '',
        -2,
        3,
        {
          code: "set('canineRelation', product(sign4(get('U3'), get('L3'), get('L4'), get('L6')),dist(get('U3'), get('L3'))), if(missing('Ruler1', 'Ruler2', 'calibration'), 'RULERS_AND_CALIBRATION_REQUIRED', get('canineRelation')))",
        },
        'mm',
      ],
      [
        'Incisor Overjet',
        '',
        -2.5,
        2.5,
        {
          code: "product(sign4(get('L1'), get('U1'), get('L4'), get('L6')),dist(get('U1'), get('L1')))",
        },
        'mm',
      ],
      [
        'Lower Incisor Extrusion',
        '',
        1.25,
        2,
        {
          code: "product(sign(get('L1'), get('L4'), get('L6')),normal(get('L1'), get('L6'), get('L4')))",
        },
        'mm',
      ],
      [
        'Interincisal Angle',
        '',
        130,
        6,
        {
          code: "angle4(get('L1R'), get('L1'), get('U1R'), get('U1'))",
        },
        'degrees',
      ],
      ['#Esthetic Measurements', '', '', '', '', ''],
      [
        'Lip Protrusion',
        {
          code: 'cat("-2mm at age 8.5 -0.2mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(-2, get('age'), 8.5, -0.2)",
        },
        2,
        {
          code: "product(sign(get('Li'), get(\"Pog'\"), get('Prn')),dist(get('Li'), intersection(get('Prn'), get(\"Pog'\"), get('Li'), parallel(get('Li'), get('Por'), get('Or')))))",
        },
        'mm',
      ],
      [
        'Upper Lip Length',
        {
          code: 'cat("24mm at age 8.5.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(24, get('age'), 8.5, 0)",
        },
        2,
        {
          code: "dist(get('St'), get('ANS'))",
        },
        'mm',
      ],
      [
        'Lip Embrasure to Occlusal Plane (functional)',
        {
          code: 'cat("-3.5mm at age 8.5 +0.1mm per year until 18.", " Patient age: ", get(\'age\'))',
        },
        {
          code: "meanByAge(-3.5, get('age'), 8.5, 0.1)",
        },
        'N/A',
        {
          code: "product(sign(get('St'), get('L6'), get('L4')),normal(get('St'), get('L6'), get('L4')))",
        },
        'mm',
      ],
    ],
  },
  'Jarabak Analysis': {
    name: 'Jarabak Analysis',
    imageLabel: 'ceph',
    landmarks: orderLandmarks(
      'ceph',
      ['Ruler1', 'Ruler2', 'S', 'N', 'Ar', 'Go', 'Me'].concat(CURVE_LANDMARKS.ceph),
    ),
    segments: CURVE_NAMES.ceph.concat(['S-N', 'S-Ar', 'Ar-Go', 'Go-Me', 'Go-N']),
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      ['#Skeletal Measurements', '', '', '', '', ''],
      [
        'Saddle Angle',
        '',
        123,
        5,
        {
          code: "angle(get('N'), get('S'), get('Ar'))",
        },
        'degrees',
      ],
      [
        'Articular Angle',
        '',
        143,
        5,
        {
          code: "angle(get('S'), get('Ar'), get('Go'))",
        },
        'degrees',
      ],
      [
        'Gonial Angle',
        '',
        130,
        7,
        {
          code: "angle(get('Ar'), get('Go'), get('Me'))",
        },
        'degrees',
      ],
      [
        'Upper Gonial Angle',
        '',
        53.5,
        1.5,
        {
          code: "angle(get('Ar'), get('Go'), get('N'))",
        },
        'degrees',
      ],
      [
        'Lower Gonial Angle',
        '',
        72.5,
        2.5,
        {
          code: "angle(get('N'), get('Go'), get('Me'))",
        },
        'degrees',
      ],
      [
        'Anterior Cranial Base Length',
        '',
        71,
        3,
        {
          code: "dist(get('S'), get('N'))",
        },
        'mm',
      ],
      [
        'Mandibular Corpus Length',
        '',
        71,
        5,
        {
          code: "dist(get('Go'), get('Me'))",
        },
        'mm',
      ],
      [
        'Ramus Height',
        '',
        44,
        5,
        {
          code: "dist(get('Ar'), get('Go'))",
        },
        'mm',
      ],
      [
        'Posterior to Anterior Face Height Class',
        'A ratio less than 62% is hyperdivergent; over 65% is hypodivergent; and, otherwise is normodivergent.',
        '',
        '',
        {
          code: "set('posteriorHeightRatio', product(100, divide(dist(get('S'), get('Go')), dist(get('N'), get('Me')))), if(missing('Ruler1', 'Ruler2', 'calibration'), 'RULERS_AND_CALIBRATION_REQUIRED', lessThan(get('posteriorHeightRatio'),62),'Vertical grower (hyperdivergent)',lessThanOrEqualTo(get('posteriorHeightRatio'), 65),'Normal grower (normodivergent)','Horizontal grower (hypodivergent)'))",
        },
        '',
      ],
      [
        'Posterior to Anterior Face Height Ratio',
        'Ratio between S-Go distance and N-Me distance.',
        63.5,
        1.5,
        {
          code: "product(100, divide(dist(get('S'), get('Go')), dist(get('N'), get('Me'))))",
        },
        'percent',
      ],
    ],
  },

  'Burstone Analysis - (Female Norms)': {
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    constructedLandmarks: ['R2a', 'R3a', 'R1a', 'R3b', 'R4a', 'R4b', 'Xi', 'A6', 'B6', 'CC', 'CF'],
    descriptionColumn: 1,
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    headingColumn: 0,
    imageLabel: 'ceph',
    landmarks: [
      'Ruler1',
      'Ruler2',
      "G'",
      "N'",
      'Prn',
      'Sn',
      "A'",
      'Ls',
      'Li',
      'Sts',
      'Sti',
      'St',
      "B'",
      "Pog'",
      "Gn'",
      "Me'",
      'G',
      'N',
      'ANS',
      'A',
      'Pr',
      'PNS',
      'B',
      'Pm',
      'Pog',
      'Gn',
      'Me',
      'Go',
      'Or',
      'Te',
      'Pt',
      'S',
      'Si',
      'Sp',
      'Por',
      'Ba',
      'Ar',
      'Co',
      'C1',
      'DC',
      'R1',
      'R2',
      'R3',
      'R4',
      'Seg',
      'PHW',
      'U1',
      'U1R',
      'L1',
      'L1R',
      'U3',
      'L3',
      'L4',
      'U6',
      'U6m',
      'U6d',
      'L6',
      'L6m',
      'L6d',
      'Bo',
      'Col',
      'Hy',
      'Id',
      'L1l',
      'PBT',
      "TMJ'",
      'U1l',
      'C2ia',
      'C2id',
      'C2ip',
      'C3ia',
      'C3id',
      'C3ip',
      'C3sa',
      'C3sp',
      'C4ia',
      'C4id',
      'C4ip',
      'C4sa',
      'C4sp',
      'Cor',
      'GT',
      'LLt',
      'OrR',
      'Tr',
      'ULt',
    ],
    name: 'Burstone Analysis - (Female Norms)',
    rows: [
      ['#Cranial Base', '', '', '', { code: "set('S1',rotate(get('S'),get('N'),7))" }, ''],
      [
        'Ar-PtM (II HP)',
        '',
        '32.8',
        '1.9',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),dist(project(get('Ar'),get('S1'),get('N')),project(get('Pt'),get('S1'),get('N'))))\n",
        },
        'mm',
      ],
      [
        'PtM-N (II HP)',
        '',
        '50.9',
        '3',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),dist(project(get('Pt'),get('S1'),get('N')),get('N')))",
        },
        'mm',
      ],
      ['#Horizontal (Skeletal)', '', '', '', '', ''],
      [
        'N-A-Pog',
        'Facial Convexity',
        '2.6',
        '5.1',
        { code: "angle4(get('N'),get('A'),get('A'),get('Pog'),)" },
        'degrees',
      ],
      [
        'N-A (II HP)',
        '',
        '-2',
        '3.7',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('A'),get('N'),get('S1'),get('N')),dist(project(get('A'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        'N-B (II HP)',
        '',
        '-6.9',
        '4.3',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('B'),get('N'),get('S1'),get('N')),dist(project(get('B'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        'N-Pog (II HP)',
        '',
        '-6.5',
        '5.1',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('Pog'),get('N'),get('S1'),get('N')),dist(project(get('Pog'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        '#Vertical (skeletal, dental)',
        '',
        '',
        '',
        { code: "set('S2',rotate(get('S'),get('N'),277))" },
        '',
      ],
      [
        'N-ANS (\u27c2 HP)',
        '',
        '50',
        '2.4',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('ANS'),get('S2'),get(\"N\")),get('N')))",
        },
        'mm',
      ],
      [
        'ANS-Gn  (\u27c2 HP)',
        '',
        '61.3',
        '3.3',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('ANS'),get('S2'),get(\"N\")),project(get('Gn'),get('S2'),get(\"N\"))))",
        },
        'mm',
      ],
      [
        'MP-HP (angle)',
        '',
        '24.2',
        '5',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('N'),get('S1'),get('Me'),get('Go')))",
        },
        'degrees',
      ],
      [
        'PNS-N  (\u27c2 HP)',
        '',
        '50.6',
        '2.2',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('PNS'),get('S2'),get(\"N\")),get(\"N\")))",
        },
        'mm',
      ],
      [
        'U1-NF (\u27c2 NF)',
        'U1 to Nasal Floor (NF) distance',
        '27.5',
        '1.7',
        { code: "normal(get('U1'),get('ANS'),get('PNS'),)" },
        'mm',
      ],
      [
        'L1-MP (\u27c2MP)',
        'L1 to Mandibular Plane (MP) distance',
        '40.8',
        '1.8',
        { code: "normal(get('L1'),get('Go'),get('Me'),)" },
        'mm',
      ],
      [
        'U6-NF (\u27c2 NF)',
        'U6 to Nasal Floor (NF) distance',
        '23',
        '1.3',
        { code: "normal(get('U6'),get('PNS'),get('ANS'),)" },
        'mm',
      ],
      [
        'L6-MP (\u27c2 MP)',
        'L6 to Mandibular Plane (MP) distance',
        '32.1',
        '1.9',
        { code: "normal(get('L6'),get('Go'),get('Me'))" },
        'mm',
      ],
      ['#Maxilla, Mandible', '', '', '', '', ''],
      [
        'PNS-ANS (II HP)',
        'PNS-ANS distance parallel to Horizontal Plane',
        '52.6',
        '3.5',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),dist(project(get('PNS'),get('S1'),get('N')),project(get('ANS'),get('S1'),get('N'))))",
        },
        'mm',
      ],
      ['Ar-Go (linear)', '', '46.8', '2.5', { code: "dist(get('Ar'),get('Go'),)" }, 'mm'],
      ['Go-Pog (linear)', '', '74.3', '5.8', { code: "dist(get('Go'),get('Pog'),)" }, 'mm'],
      [
        'B-Pog (II MP)',
        '',
        '7.2',
        '1.9',
        {
          code: "dist(project(get('B'),get('Go'),get('Me')),project(get('Pog'),get('Go'),get('Me')))",
        },
        'mm',
      ],
      [
        'Ar-Go-Gn (angle)',
        '',
        '122',
        '6.9',
        { code: "angle(get('Ar'),get('Go'),get('Gn'),)" },
        'degrees',
      ],
      ['#Dental', '', '', '', '', ''],
      [
        'OP upper - HP (angle)',
        'Upper occlusal plane - Horizontal Plane angle',
        '7.1',
        '2.5',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('S1'),get('N'),get('U6'),get('U1')))",
        },
        'degrees',
      ],
      [
        'OP lower - HP (angle)',
        'Lower occlusal plane - Horizontal Plane angle',
        '7.1',
        '2.5',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('S1'),get('N'),get('L6'),get('L1')))",
        },
        'degrees',
      ],
      [
        'A-B (II OP)',
        '',
        '-0.4',
        '2.5',
        {
          code: "set('A1', project(get('A'),get('L6'),get('L1')),set('B1',project(get('B'),get('L6'),get('L1')), product(sign(get('A1'),get('B'),get('B1')),dist(get('A1'),get('B1'))))) ",
        },
        'mm',
      ],
      [
        'U1 - NF (angle)',
        '',
        '112.5',
        '5.3',
        { code: "angle4(get('U1R'),get('U1'),get('ANS'),get('PNS'),)" },
        'degrees',
      ],
      [
        'L1 - MP (angle)',
        '',
        '95.9',
        '5.7',
        { code: "angle4(get('Me'),get('Go'),get('L1R'),get('L1'),)" },
        'degrees',
      ],
    ],
    segments: [
      'Upper lip and nose',
      'Lower lip and chin',
      'Mandible',
      'Orbitale',
      'External acoustic meatus',
      'Maxilla',
      'Symphysis',
      'Pterygomaxillary fissure',
      'Upper incisor',
      'Lower incisor',
      'Upper 1st Molar',
      'Lower 1st Molar',
      'Pog-N',
      'N-A',
      'N-B',
      'A-B',
      'Frankfort Horizontal',
      'A-Pog',
      'Go-Me',
      'Go-Gn',
      'S-Gn',
      'Occ Plane',
      'U1',
      'L1',
      'S-N',
      'S-Ar',
      'Ar-Go',
      'Go-N',
      'S-Go',
      'E Plane',
      'Occ Plane (Functional)',
      'Ba-N',
      'Facial Axis',
      'Condylar Axis',
      'Corpus Axis',
    ],
    visibleConstructedLandmarks: ['Xi', 'A6', 'B6', 'CC', 'CF'],
  },

  'Burstone Analysis - (Male Norms)': {
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    constructedLandmarks: ['R2a', 'R3a', 'R1a', 'R3b', 'R4a', 'R4b', 'Xi', 'A6', 'B6', 'CC', 'CF'],
    descriptionColumn: 1,
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    headingColumn: 0,
    imageLabel: 'ceph',
    landmarks: [
      'Ruler1',
      'Ruler2',
      "G'",
      "N'",
      'Prn',
      'Sn',
      "A'",
      'Ls',
      'Li',
      'Sts',
      'Sti',
      'St',
      "B'",
      "Pog'",
      "Gn'",
      "Me'",
      'G',
      'N',
      'ANS',
      'A',
      'Pr',
      'PNS',
      'B',
      'Pm',
      'Pog',
      'Gn',
      'Me',
      'Go',
      'Or',
      'Te',
      'Pt',
      'S',
      'Si',
      'Sp',
      'Por',
      'Ba',
      'Ar',
      'Co',
      'C1',
      'DC',
      'R1',
      'R2',
      'R3',
      'R4',
      'Seg',
      'PHW',
      'U1',
      'U1R',
      'L1',
      'L1R',
      'U3',
      'L3',
      'L4',
      'U6',
      'U6m',
      'U6d',
      'L6',
      'L6m',
      'L6d',
      'Bo',
      'Col',
      'Hy',
      'Id',
      'L1l',
      'PBT',
      "TMJ'",
      'U1l',
      'C2ia',
      'C2id',
      'C2ip',
      'C3ia',
      'C3id',
      'C3ip',
      'C3sa',
      'C3sp',
      'C4ia',
      'C4id',
      'C4ip',
      'C4sa',
      'C4sp',
      'Cor',
      'GT',
      'LLt',
      'OrR',
      'Tr',
      'ULt',
    ],
    name: 'Burstone Analysis - (Male Norms)',
    rows: [
      ['#Cranial Base', '', '', '', { code: "set('S1',rotate(get('S'),get('N'),7))" }, ''],
      [
        'Ar-PtM (II HP)',
        '',
        '37.1',
        '2.8',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),dist(project(get('Ar'),get('S1'),get('N')),project(get('Pt'),get('S1'),get('N'))))\n",
        },
        'mm',
      ],
      [
        'PtM-N (II HP)',
        '',
        '52.8',
        '4.1',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),dist(project(get('Pt'),get('S1'),get('N')),get('N')))",
        },
        'mm',
      ],
      ['#Horizontal (Skeletal)', '', '', '', '', ''],
      [
        'N-A-Pog',
        'Facial Convexity',
        '3.9',
        '6.4',
        { code: "angle4(get('N'),get('A'),get('A'),get('Pog'),)" },
        'degrees',
      ],
      [
        'N-A (II HP)',
        '',
        '0',
        '3.7',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('A'),get('N'),get('S1'),get('N')),dist(project(get('A'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        'N-B (II HP)',
        '',
        '-5.3',
        '6.7',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('B'),get('N'),get('S1'),get('N')),dist(project(get('B'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        'N-Pog (II HP)',
        '',
        '-4.3',
        '8.5',
        {
          code: "set('S1', rotate(get('S'),get('N'),7),product(sign4(get('Pog'),get('N'),get('S1'),get('N')),dist(project(get('Pog'),get('S1'),get('N')),get('N'))))",
        },
        'mm',
      ],
      [
        '#Vertical (skeletal, dental)',
        '',
        '',
        '',
        { code: "set('S2',rotate(get('S'),get('N'),277))" },
        '',
      ],
      [
        'N-ANS (\u27c2 HP)',
        '',
        '54.7',
        '3.2',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('ANS'),get('S2'),get(\"N\")),get('N')))",
        },
        'mm',
      ],
      [
        'ANS-Gn  (\u27c2 HP)',
        '',
        '68.6',
        '3.8',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('ANS'),get('S2'),get(\"N\")),project(get('Gn'),get('S2'),get(\"N\"))))",
        },
        'mm',
      ],
      [
        'PNS-N  (\u27c2 HP)',
        '',
        '53.9',
        '1.7',
        {
          code: "set('S2',rotate(get('S'),get('N'),277),dist(project(get('PNS'),get('S2'),get(\"N\")),get(\"N\")))",
        },
        'mm',
      ],
      [
        'MP-HP (angle)',
        '',
        '23',
        '5.9',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('N'),get('S1'),get('Me'),get('Go')))",
        },
        'degrees',
      ],
      [
        'U1-NF (\u27c2 NF)',
        'U1 to Nasal Floor (NF) distance',
        '30.5',
        '2.1',
        { code: "normal(get('U1'),get('ANS'),get('PNS'),)" },
        'mm',
      ],
      [
        'L1-MP (\u27c2MP)',
        'L1 to Mandibular Plane (MP) distance',
        '45',
        '2.1',
        { code: "normal(get('L1'),get('Go'),get('Me'),)" },
        'mm',
      ],
      [
        'U6-NF (\u27c2 NF)',
        'U6 to Nasal Floor (NF) distance',
        '26.2',
        '2',
        { code: "normal(get('U6'),get('PNS'),get('ANS'),)" },
        'mm',
      ],
      [
        'L6-MP (\u27c2 MP)',
        'L6 to Mandibular Plane (MP) distance',
        '35.8',
        '2.6',
        { code: "normal(get('L6'),get('Go'),get('Me'))" },
        'mm',
      ],
      ['#Maxilla, Mandible', '', '', '', '', ''],
      [
        'PNS-ANS (II HP)',
        'PNS-ANS distance parallel to Horizontal Plane',
        '57.7',
        '2.5',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),dist(project(get('PNS'),get('S1'),get('N')),project(get('ANS'),get('S1'),get('N'))))",
        },
        'mm',
      ],
      ['Ar-Go (linear)', '', '52', '4.2', { code: "dist(get('Ar'),get('Go'),)" }, 'mm'],
      ['Go-Pog (linear)', '', '83.7', '4.6', { code: "dist(get('Go'),get('Pog'),)" }, 'mm'],
      [
        'B-Pog (II MP)',
        '',
        '8.9',
        '1.7',
        {
          code: "dist(project(get('B'),get('Go'),get('Me')),project(get('Pog'),get('Go'),get('Me')))",
        },
        'mm',
      ],
      [
        'Ar-Go-Gn (angle)',
        '',
        '119.1',
        '6.5',
        { code: "angle(get('Ar'),get('Go'),get('Gn'),)" },
        'degrees',
      ],
      ['#Dental', '', '', '', '', ''],
      [
        'OP upper - HP (angle)',
        'Upper occlusal plane - Horizontal Plane angle',
        '6.2',
        '5.1',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('S1'),get('N'),get('U6'),get('U1')))",
        },
        'degrees',
      ],
      [
        'OP lower - HP (angle)',
        'Lower occlusal plane - Horizontal Plane angle',
        '6.2',
        '5.1',
        {
          code: "set('S1',rotate(get('S'),get('N'),7),angle4(get('S1'),get('N'),get('L6'),get('L1')))",
        },
        'degrees',
      ],
      [
        'A-B (II OP)',
        '',
        '-1.1',
        '2',
        {
          code: "set('A1', project(get('A'),get('L6'),get('L1')),set('B1',project(get('B'),get('L6'),get('L1')), product(sign(get('A1'),get('B'),get('B1')),dist(get('A1'),get('B1'))))) ",
        },
        'mm',
      ],
      [
        'U1 - NF (angle)',
        '',
        '111',
        '4.7',
        { code: "angle4(get('U1R'),get('U1'),get('ANS'),get('PNS'),)" },
        'degrees',
      ],
      [
        'L1 - MP (angle)',
        '',
        '95.9',
        '5.2',
        { code: "angle4(get('Me'),get('Go'),get('L1R'),get('L1'),)" },
        'degrees',
      ],
    ],
    segments: [
      'Upper lip and nose',
      'Lower lip and chin',
      'Mandible',
      'Orbitale',
      'External acoustic meatus',
      'Maxilla',
      'Symphysis',
      'Pterygomaxillary fissure',
      'Upper incisor',
      'Lower incisor',
      'Upper 1st Molar',
      'Lower 1st Molar',
      'Pog-N',
      'N-A',
      'N-B',
      'A-B',
      'Frankfort Horizontal',
      'A-Pog',
      'Go-Me',
      'Go-Gn',
      'S-Gn',
      'Occ Plane',
      'U1',
      'L1',
      'S-N',
      'S-Ar',
      'Ar-Go',
      'Go-N',
      'S-Go',
      'E Plane',
      'Occ Plane (Functional)',
      'Ba-N',
      'Facial Axis',
      'Condylar Axis',
      'Corpus Axis',
    ],
    visibleConstructedLandmarks: ['Xi', 'A6', 'B6', 'CC', 'CF'],
  },

  'All landmarks': {
    name: 'All landmarks',
    imageLabel: 'ceph',
    landmarks: orderLandmarks('ceph', ALL_INSPECTED_CEPH_LANDMARKS),

    // NOTE: The order of constructed landmarks matters!
    // Any landmarks another depends on must appear in this list
    // first.
    constructedLandmarks: [
      // Compute (but do not show) these "intermediate" constructed landmarks
      'R2a',
      'R3a',
      'R1a',
      'R3b',
      'R4a',
      'R4b', // (required for Xi)

      // Main computed landmarks
      'Xi',
    ],

    // Only display landmarks listed below
    visibleConstructedLandmarks: ['Xi'],

    segments: CURVE_NAMES.ceph.concat([
      // planes
      'Pog-N',
      'N-A',
      'N-B',
      'A-B',
      'Frankfort Horizontal',
      'A-Pog',
      'Go-Me',
      'Go-Gn',
      'S-Gn',
      'Occ Plane',
      'U1',
      'L1',
      'S-N',
      'S-Ar',
      'Ar-Go',
      'Go-N',
      'S-Go',
      'E Plane',
      'Occ Plane (Functional)',
      // "PTV",
      'Ba-N',
      'Facial Axis',
      // "Facial Plane",
      'Condylar Axis',
      'Corpus Axis',
    ]),
    displayedColumns: [],
    editableColumns: [],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [],
    rows: [],
  },

  'Ricketts Analysis (PA)': {
    name: 'Ricketts Analysis (PA)',
    imageLabel: 'pa-ceph',
    landmarks: orderLandmarks(
      'pa-ceph',
      [
        'Ruler1',
        'Ruler2',

        // 'pa.NCr (r)',
        // 'pa.NAP (r)',
        // 'pa.NCf (r)',
        // 'pa.NCm (r)',
        // 'pa.NCr (l)',
        // 'pa.NAP (l)',
        // 'pa.NCf (l)',
        // 'pa.NCm (l)',

        // 'pa.Co (r)',
        // 'pa.MA (r)',
        // 'pa.AG (r)',
        // 'pa.Me (r)',
        // 'pa.Me',
        // 'pa.Me (l)',
        // 'pa.AG (l)',
        // 'pa.MA (l)',
        // 'pa.Co (l)',

        // 'pa.OrR (r)',
        // 'pa.FZS (r)',
        // 'pa.OrF (r)',
        // 'pa.OrM (r)',
        // 'pa.OrR (l)',
        // 'pa.FZS (l)',
        // 'pa.OrF (l)',
        // 'pa.OrM (l)',

        // 'pa.MAT (r)',
        // 'pa.MAT (l)',

        // 'pa.FZSL (r)',
        // 'pa.ZA (r)',
        // 'pa.ZAi (r)',
        // 'pa.FZSL (l)',
        // 'pa.ZA (l)',
        // 'pa.ZAi (l)',

        'pa.AG (l)',
        'pa.AG (r)',
        'pa.ANS',
        'pa.CG', // used in plane
        // 'pa.Co (l)',
        // 'pa.Co (r)',
        // 'pa.Cor (l)',
        // 'pa.Cor (r)',
        // 'pa.FR (l)',
        // 'pa.FR (r)',
        'pa.FZS (l)',
        'pa.FZS (r)',
        // 'pa.FZSL (l)',
        // 'pa.FZSL (r)',
        // 'pa.Go (l)',
        // 'pa.Go (r)',
        // 'pa.IF',
        'pa.J (l)',
        'pa.J (r)',
        // 'pa.L1 (l)',
        'pa.L1 (m)',
        // 'pa.L1 (r)',
        // 'pa.L1R (l)',
        // 'pa.L1R (m)',
        // 'pa.L1R (r)',
        // 'pa.L3 (l)',
        // 'pa.L3 (r)',
        'pa.L6 (l)',
        'pa.L6 (r)',
        // 'pa.MA (l)',
        // 'pa.MA (r)',
        // 'pa.MF (l)',
        // 'pa.MF (r)',
        'pa.Me',
        'pa.NAP (l)',
        'pa.NAP (r)',
        // 'pa.Or (m)',
        // 'pa.OrC (l)',
        // 'pa.OrC (r)',
        // 'pa.OrM (l)',
        // 'pa.OrM (r)',
        // 'pa.TNS',
        // 'pa.U1 (l)',
        'pa.U1 (m)',
        // 'pa.U1 (r)',
        // 'pa.U1R (l)',
        // 'pa.U1R (r)',
        // 'pa.U3 (l)',
        // 'pa.U3 (r)',
        'pa.U6 (l)',
        'pa.U6 (r)',
        // 'pa.U6l (l)',
        // 'pa.U6l (r)',
        'pa.ZA (l)',
        'pa.ZA (r)',
        // 'pa.ZMS (l)',
        // 'pa.ZMS (r)',
      ], // .concat(CURVE_LANDMARKS['pa-ceph'])  // for now, hide curves
    ),

    // NOTE: The order of constructed landmarks matters!
    // Any landmarks another depends on must appear in this list
    // first.
    constructedLandmarks: [],

    // Only display landmarks listed below
    visibleConstructedLandmarks: [],

    segments: [
      // CURVE_NAMES['pa-ceph'].concat(...) // for now, hide curves
      'AG',
      'J-AG (r)',
      'J-AG (l)',
      'FZS',
      'ZA',
      'FZS-AG (r)',
      'FZS-AG (l)',
      'Midsagittal plane',
      'Occlusal plane (PA)',
    ],
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      ['#Dental Relations', '', '', '', '', ''],
      [
        'Molar relation (right)',
        'Distance between U6 (r) to L6 (r) along Occlusal Plane',
        1.5,
        2,
        {
          code: "dist(project(get('pa.U6 (r)'), get('pa.L6 (r)'), get('pa.L6 (l)')), get('pa.L6 (r)'))",
        },
        'mm',
      ],
      [
        'Molar relation (left)',
        'Distance between U6 (l) to L6 (l) along Occlusal Plane',
        1.5,
        2,
        {
          code: "dist(project(get('pa.U6 (l)'), get('pa.L6 (r)'), get('pa.L6 (l)')), get('pa.L6 (l)'))",
        },
        'mm',
      ],
      [
        'Inter-1st-molar width',
        'Distance between L6 (r) to L6 (l)',
        55,
        2,
        {
          code: "dist(get('pa.L6 (r)'), get('pa.L6 (l)'))",
        },
        'mm',
      ],
      [
        'Inter-canine width',
        'Distance between L3 (r) to L3 (l)',
        22.7,
        2,
        {
          code: "dist(get('pa.L3 (r)'), get('pa.L3 (l)'))",
        },
        'mm',
      ],
      [
        'Denture midline',
        'Distance between U1 (m) to L1 (m) along Occlusal Plane',
        0,
        1.5,
        {
          code: "dist(project(get('pa.U1 (m)'), get('pa.L6 (r)'), get('pa.L6 (l)')), project(get('pa.L1 (m)'), get('pa.L6 (r)'), get('pa.L6 (l)')))",
        },
        'mm',
      ],
      ['#Skeletal Relations', '', '', '', '', ''],
      [
        'Maxillomandibular width (right)',
        'Distance between J (r) to the line: AG (r) to FZS (r)',
        11,
        1.5,
        {
          code: "normal(get('pa.J (r)'), get('pa.AG (r)'), get('pa.FZS (r)'))",
        },
        'mm',
      ],
      [
        'Maxillomandibular width (left)',
        'Distance between J (l) to the line: AG (l) to FZS (l)',
        11,
        1.5,
        {
          code: "normal(get('pa.J (l)'), get('pa.AG (l)'), get('pa.FZS (l)'))",
        },
        'mm',
      ],
      [
        'Maxillomandibular midline',
        'The angle between the line ANS-Me and the line perpendicular to the Z-plane that crosses ANS.',
        0,
        2,
        {
          code: "product(-1, angle4(project(get('pa.ANS'), get('pa.FZS (r)'), get('pa.FZS (l)')), get('pa.ANS'), get('pa.ANS'), get('pa.Me')))",
        },
        'degrees',
      ],
      [
        'Maxillary width',
        'Distance between J (r) to J (l)',
        61.9,
        2,
        {
          code: "dist(get('pa.J (r)'), get('pa.J (l)'))",
        },
        'mm',
      ],
      [
        'Mandibular width',
        'Distance between AG (r) to AG (l)',
        76.1,
        2,
        {
          code: "dist(get('pa.AG (r)'), get('pa.AG (l)'))",
        },
        'mm',
      ],
      [
        'Lower molar to jaw (right)',
        'Distance between L6 (r) and the plane J (r) to AG (r)',
        6.6,
        1.7,
        {
          code: "normal(get('pa.L6 (r)'), get('pa.J (r)'), get('pa.AG (r)'))",
        },
        'mm',
      ],
      [
        'Lower molar to jaw (left)',
        'Distance between L6 (l) and the plane J (l) to AG (l)',
        6.6,
        1.7,
        {
          code: "normal(get('pa.L6 (l)'), get('pa.J (l)'), get('pa.AG (l)'))",
        },
        'mm',
      ],
      ['#Dental To Skeletal Relations', '', '', '', '', ''],
      [
        'Denture to jaw midline',
        'Distance between L1 (m) and the plane ANS to Me',
        0,
        1.5,
        {
          code: "product(sign(get('pa.L1 (m)'), get('pa.Me'), get('pa.ANS')), normal(get('pa.L1 (m)'), get('pa.Me'), get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'Occlusal Plane tilt (dist.)',
        'The differnce between U6 (l) to the Z-plane and U6 (r) to the Z-plane',
        0,
        2,
        {
          code: "subtract(normal(get('pa.U6 (l)'), get('pa.FZS (r)'), get('pa.FZS (l)')), normal(get('pa.U6 (r)'), get('pa.FZS (r)'), get('pa.FZS (l)')))",
        },
        'mm',
      ],
      ['#Jaw To Cranium Relations', '', '', '', '', ''],
      [
        'Postural symmetry',
        'The differnce between the angle FZS (r) - AG (r) - ZA (r), and the angle FZS (l) - AG (l) - ZA (l)',
        0,
        2,
        {
          code: "subtract(angle(get('pa.ZA (r)'), get('pa.AG (r)'), get('pa.FZS (r)')), angle(get('pa.FZS (l)'), get('pa.AG (l)'), get('pa.ZA (l)')))",
        },
        'degrees',
      ],
      ['#Inner Structures', '', '', '', '', ''],
      [
        'Nasal width',
        'Distance between NAP (l) to NAP (r)',
        25,
        2,
        {
          code: "dist(get('pa.NAP (l)'), get('pa.NAP (r)'))",
        },
        'mm',
      ],
      [
        'Nasal height',
        'Distance between ANS to Z-plane',
        44.5,
        3,
        {
          code: "normal(get('pa.ANS'), get('pa.FZS (r)'), get('pa.FZS (l)'))",
        },
        'mm',
      ],
      [
        'Facial width',
        'Distance between ZA (l) to ZA (r)',
        116,
        3,
        {
          code: "dist(get('pa.ZA (l)'), get('pa.ZA (r)'))",
        },
        'mm',
      ],
    ],
  },

  'Grummons - Frontal Asymmetry Analysis': {
    columns: [
      {
        t: 'analyses.name',
      },
      {
        t: 'analyses.description',
      },
      {
        t: 'analyses.mean',
      },
      {
        t: 'analyses.std-dev',
      },
      {
        t: 'analyses.measurement',
      },
      {
        t: 'analyses.unit',
      },
    ],
    constructedLandmarks: [],
    descriptionColumn: 1,
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    headingColumn: 0,
    imageLabel: 'pa-ceph',
    landmarks: [
      'Ruler1',
      'Ruler2',
      'pa.NCr (r)',
      'pa.NAP (r)',
      'pa.NCf (r)',
      'pa.NCm (r)',
      'pa.NCr (l)',
      'pa.NAP (l)',
      'pa.NCf (l)',
      'pa.NCm (l)',
      'pa.Me (r)',
      'pa.Me (l)',
      'pa.OrR (r)',
      'pa.OrF (r)',
      'pa.OrM (r)',
      'pa.OrR (l)',
      'pa.OrF (l)',
      'pa.OrM (l)',
      'pa.MAT (r)',
      'pa.MAT (l)',
      'pa.ZAi (r)',
      'pa.ZAi (l)',
      'pa.AG (l)',
      'pa.AG (r)',
      'pa.ANS',
      'pa.CG',
      'pa.Co (l)',
      'pa.Co (r)',
      'pa.Cor (l)',
      'pa.Cor (r)',
      'pa.FR (l)',
      'pa.FR (r)',
      'pa.FZS (l)',
      'pa.FZS (r)',
      'pa.FZSL (l)',
      'pa.FZSL (r)',
      'pa.Go (l)',
      'pa.Go (r)',
      'pa.IF',
      'pa.J (l)',
      'pa.J (r)',
      'pa.L1 (l)',
      'pa.L1 (m)',
      'pa.L1 (r)',
      'pa.L1m (l)',
      'pa.L1m (r)',
      'pa.L1R (l)',
      'pa.L1R (m)',
      'pa.L1R (r)',
      'pa.L3 (l)',
      'pa.L3 (r)',
      'pa.L6 (l)',
      'pa.L6 (r)',
      'pa.L6R (l)',
      'pa.L6R (r)',
      'pa.MA (l)',
      'pa.MA (r)',
      'pa.MF (l)',
      'pa.MF (r)',
      'pa.Me',
      'pa.Or (m)',
      'pa.OrC (l)',
      'pa.OrC (r)',
      'pa.TNS',
      'pa.U1 (l)',
      'pa.U1 (m)',
      'pa.U1 (r)',
      'pa.U1m (l)',
      'pa.U1m (r)',
      'pa.U1R (l)',
      'pa.U1R (r)',
      'pa.U3 (l)',
      'pa.U3 (r)',
      'pa.U6 (l)',
      'pa.U6 (r)',
      'pa.U6l (l)',
      'pa.U6l (r)',
      'pa.U6R (l)',
      'pa.U6R (r)',
      'pa.ZA (l)',
      'pa.ZA (r)',
      'pa.ZMS (l)',
      'pa.ZMS (r)',
    ],
    name: 'Grummons - Frontal Asymmetry Analysis',
    rows: [
      ['#Frontal Asymmetry', '', '', '', '', ''],
      [
        'Z - MSR Angle',
        'Angle between the Z plane and Midsagittal Reference Line',
        '90',
        '1',
        {
          code: "angle4(get('pa.CG'),get('pa.ANS'),get('pa.FZS (l)'),get('pa.FZS (r)'),)",
        },
        'degrees',
      ],
      [
        'Occ Plane - MSR Angle',
        'Angle between the occlusal plane and Midsagittal Reference Line',
        '90',
        '1',
        {
          code: "angle4(get('pa.CG'),get('pa.ANS'),get('pa.L6 (l)'),get('pa.L6 (r)'),)",
        },
        'degrees',
      ],
      [
        'Ag - MSR Angle',
        'Angle between Antegonial Notch Plane and Midsagittal Reference Line',
        '90',
        '1',
        {
          code: "angle4(get('pa.CG'),get('pa.ANS'),get('pa.AG (l)'),get('pa.AG (r)'),)",
        },
        'degrees',
      ],
      [
        'Ag Angle (R)',
        'Right Co-Ag-Me Angle ',
        '',
        '',
        {
          code: "angle(get('pa.Co (r)'),get('pa.AG (r)'),get('pa.Me'),)",
        },
        'degrees',
      ],
      [
        'Ag Angle (L)',
        'Left Co-Ag-Me Angle ',
        '',
        '',
        {
          code: "angle(get('pa.Me'),get('pa.AG (l)'),get('pa.Co (l)'),)",
        },
        'degrees',
      ],
      [
        'Z Distance (R)',
        'Frontozygomatic suture to MSR distance (R)',
        '47.2',
        '3',
        {
          code: "normal(get('pa.FZS (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Z Distance (L)',
        'Frontozygomatic suture to MSR distance (L)',
        '47.2',
        '3',
        {
          code: "normal(get('pa.FZS (l)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Co Distance (R)',
        'Condylion Right to MSR distance',
        '',
        '',
        {
          code: "normal(get('pa.Co (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Co Distance (L)',
        'Condylion Left to MSR distance',
        '',
        '',
        {
          code: "normal(get('pa.Co (l)'),get('pa.CG'),get('pa.ANS'))",
        },
        'mm',
      ],
      [
        'ZA Distance (R)',
        'Zygomatic arch right to MSR distance',
        '70.7',
        '3',
        {
          code: "normal(get('pa.ZA (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'ZA Distance (L)',
        'Zygomatic arch left to MSR distance',
        '70.7',
        '3',
        {
          code: "normal(get('pa.ZA (l)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'NC Distance (R)',
        'Apertura piriformis right to MSR distance',
        '',
        '',
        {
          code: "normal(get('pa.NAP (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'NC Distance (L)',
        'Apertura piriformis left to MSR distance',
        '',
        '',
        {
          code: "normal(get('pa.NAP (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'J Distance (R)',
        'Right Jugular point to MSR distance',
        '34.8',
        '3',
        {
          code: "normal(get('pa.J (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'J Distance (L)',
        'Left Jugular point to MSR distance',
        '34.8',
        '3',
        {
          code: "normal(get('pa.J (l)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Ag Distance (R)',
        'Antegonial notch right to MSR distance',
        '47',
        '3',
        {
          code: "normal(get('pa.AG (r)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Ag Distance (L)',
        'Antegonial notch left to MSR distance',
        '47',
        '3',
        {
          code: "normal(get('pa.AG (l)'),get('pa.CG'),get('pa.ANS'),)",
        },
        'mm',
      ],
      [
        'Mandibular Offset (at Me)',
        'Me to MSR distance - Minus sign means Me is on the left side of the MSR',
        '0',
        '2',
        {
          code: "product(sign(get('pa.Me'),get('pa.CG'),get('pa.ANS')),normal(get('pa.Me'),get('pa.CG'),get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'A1 Offset',
        'U1 mid-incisal point to MSR distance - Minus sign means U1 mid-incisal point is on the left side of the MSR',
        '0',
        '1',
        {
          code: "product(sign(get('pa.U1 (m)'),get('pa.CG'),get('pa.ANS')),normal(get('pa.U1 (m)'),get('pa.CG'),get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'B1 Offset',
        'L1 mid-incisal point to MSR distance - Minus sign means L1 mid-incisal point is on the left side of the MSR',
        '0',
        '1',
        {
          code: "product(sign(get('pa.L1 (m)'),get('pa.CG'),get('pa.ANS')),normal(get('pa.L1 (m)'),get('pa.CG'),get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'A6 Height (R)',
        'Right upper first molar to Jugular perpendicular line to MSR',
        '',
        '',
        {
          code: "normal(get('pa.U6 (r)'),get('pa.J (r)'),perpendicular(get('pa.J (r)'),get('pa.CG'),get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'A6 Height (L)',
        'Left upper first molar to Jugular perpendicular line to MSR',
        '',
        '',
        {
          code: "normal(get('pa.U6 (l)'),get('pa.J (l)'),perpendicular(get('pa.J (l)'),get('pa.CG'),get('pa.ANS')))",
        },
        'mm',
      ],
      [
        'Co-Ag (R)',
        'Right condylion to antegonial notch distance',
        '',
        '',
        {
          code: "dist(get('pa.Co (r)'),get('pa.AG (r)'),)",
        },
        'mm',
      ],
      [
        'Co-Ag (L)',
        'Left condylion to antegonial notch distance',
        '',
        '',
        {
          code: "dist(get('pa.Co (l)'),get('pa.AG (l)'),)",
        },
        'mm',
      ],
      [
        'Co-Me (R)',
        'Right condylion to menton distance',
        '',
        '',
        {
          code: "dist(get('pa.Co (r)'),get('pa.Me'),)",
        },
        'mm',
      ],
      [
        'Co-Me (L)',
        'Left condylion to menton distance',
        '',
        '',
        {
          code: "dist(get('pa.Co (l)'),get('pa.Me'),)",
        },
        'mm',
      ],
      [
        'Me-Ag (R)',
        'Right antegonial notch to menton distance',
        '49.3',
        '2.5',
        {
          code: "dist(get('pa.AG (r)'),get('pa.Me'),)",
        },
        'mm',
      ],
      [
        'Me-Ag (L)',
        'Left antegonial notch to menton distance',
        '49.3',
        '2.5',
        {
          code: "dist(get('pa.AG (l)'),get('pa.Me'),)",
        },
        'mm',
      ],
      ['#Esthetics', '', '', '', '', ''],
      [
        'Upper facial ratio',
        'Cg-ANS/Cg-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.CG'),get('pa.ANS')),dist(get('pa.CG'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Lower facial ratio',
        'ANS-Me/Cg-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.Me'),get('pa.ANS')),dist(get('pa.CG'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Maxillary ratio',
        'ANS-U1(mid-incisal)/ANS-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.U1 (m)'),get('pa.ANS')),dist(get('pa.ANS'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Total Maxillary ratio',
        'ANS-U1(mid-incisal)/Cg-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.U1 (m)'),get('pa.ANS')),dist(get('pa.CG'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Mandibular ratio',
        'Me-L1(mid-incisal)/ANS-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.L1 (m)'),get('pa.Me')),dist(get('pa.ANS'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Totatl Mandibular ratio',
        'Me-L1(mid-incisal)/Cg-Me',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.L1 (m)'),get('pa.Me')),dist(get('pa.CG'),get('pa.Me'))),100)",
        },
        'percent',
      ],
      [
        'Maxillomandibular ratio',
        'ANS-U1(mid-incisal)/Me-L1(mid-incisal)',
        '',
        '',
        {
          code: "product(divide(dist(get('pa.U1 (m)'),get('pa.ANS')),dist(get('pa.L1 (m)'),get('pa.Me'))),100)",
        },
        'percent',
      ],
    ],
    segments: [
      'Nasal cavity (r)',
      'Nasal cavity (l)',
      'Mandible (PA)',
      'Orbita (r)',
      'Orbita (l)',
      'Mastoid curve (r)',
      'Mastoid curve (l)',
      'Zygomatic bone (r)',
      'Zygomatic bone (l)',
      'Zygomatic arch (l)',
      'Zygomatic arch (r)',
      'Upper incisor (r)',
      'Upper incisor (l)',
      'Upper 1st Molar (r)',
      'Upper 1st Molar (l)',
      'Lower incisor (r)',
      'Lower incisor (l)',
      'Lower 1st Molar (r)',
      'Lower 1st Molar (l)',
      'AG',
      'J-AG (r)',
      'J-AG (l)',
      'FZS',
      'ZA',
      'FZS-AG (r)',
      'FZS-AG (l)',
      'Midsagittal plane',
      'Occlusal plane (PA)',
    ],
    visibleConstructedLandmarks: [],
  },

  'All landmarks (PA)': {
    name: 'All landmarks (PA)',
    imageLabel: 'pa-ceph',
    landmarks: orderLandmarks(
      'pa-ceph',
      ALL_INSPECTED_PA_CEPH_LANDMARKS.concat(['Ruler1', 'Ruler2']),
    ),

    // NOTE: The order of constructed landmarks matters!
    // Any landmarks another depends on must appear in this list
    // first.
    constructedLandmarks: [],

    // Only display landmarks listed below
    visibleConstructedLandmarks: [],

    segments: CURVE_NAMES['pa-ceph'].concat([
      'AG',
      'J-AG (r)',
      'J-AG (l)',
      'FZS',
      'ZA',
      'FZS-AG (r)',
      'FZS-AG (l)',
      'Midsagittal plane',
      'Occlusal plane (PA)',
    ]),
    displayedColumns: [],
    editableColumns: [],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [],
    rows: [],
  },
};

// Display names for landmarks.
// For now, just strip off the "pa." prefix from
// the PA-ceph landmark names:
export const LANDMARK_DISPLAY_NAMES = {};
Object.keys(masterLandmarks).forEach((s) => {
  LANDMARK_DISPLAY_NAMES[s] = s;
});
Object.keys(masterPACephLandmarks).forEach((s) => {
  LANDMARK_DISPLAY_NAMES[s] = s.replace(/^pa\./, '');
});
LANDMARK_DISPLAY_NAMES.Ruler1 = 'Ruler 1';
LANDMARK_DISPLAY_NAMES.Ruler2 = 'Ruler 2';

// Full names for landmarks.
//
// TODO: LANDMARK_FULL_NAMES (and LANDMARK_DISPLAY_NAMES) contain
// names from both lateral and PA cephs. This may cause a problem
// if both define the same landmark with a different full name or meaning.
// These should be refactored to consider the imageLabel.
export const LANDMARK_FULL_NAMES = {};
Object.keys(masterLandmarks).forEach((s) => {
  LANDMARK_FULL_NAMES[s] = masterLandmarks[s].name || LANDMARK_DISPLAY_NAMES[s];
});
Object.keys(masterPACephLandmarks).forEach((s) => {
  LANDMARK_FULL_NAMES[s] = masterPACephLandmarks[s].name || LANDMARK_DISPLAY_NAMES[s];
});

const getSegmentLandmarks = (segmentName) =>
  SEGMENTS[segmentName].definition.filter((d) => d.type === POINT_LANDMARK).map((d) => d.name);
const mandibleLandmarks = getSegmentLandmarks('Mandible');
const symphysisLandmarks = getSegmentLandmarks('Symphysis');
const maxillaLandmarks = getSegmentLandmarks('Maxilla');
const lowerLipAndChinLandmarks = getSegmentLandmarks('Lower lip and chin');
const upperLipAndNoseLandmarks = getSegmentLandmarks('Upper lip and nose');

export const STO_REQUIRED_LANDMARKS = [
  'Ruler1',
  'Ruler2',
  ...mandibleLandmarks,
  ...symphysisLandmarks,
  ...maxillaLandmarks,
  ...lowerLipAndChinLandmarks,
  ...upperLipAndNoseLandmarks,
];

// only show "Calibration test" analysis if
// debug value is set.
if (window.localStorage.getItem('calibrate') === 'true') {
  analyses['Calibration test'] = {
    name: 'Calibration test',
    exclude: true,
    imageLabel: 'ceph',
    landmarks: [
      'Ruler1',
      'Ruler2',
      'Test Por',
      'Test Or',
      'Test A',
      'Test B',
      'Test C',
      'Test D',
      'Test E',
      'Test F',
      'Test G',
      'Test H',
      'Test I',
      'Test J',
      'Test K',
      'Test L',
      'Test M',
      'Test R1',
      'Test R2',
      'Test R3',
      'Test R4',
    ],
    constructedLandmarks: [
      'Test R2a',
      'Test R3a',
      'Test R1a',
      'Test R3b',
      'Test R4a',
      'Test R4b',
      'Test Xi',
      'Test K/I-J',
      'Test H/Por-Or',
    ],
    visibleConstructedLandmarks: [
      'Test R2a',
      'Test R3a',
      'Test R1a',
      'Test R3b',
      'Test R4a',
      'Test R4b',
      'Test Xi',
      'Test K/I-J',
      'Test H/Por-Or',
    ],
    segments: [
      'Test Frankfort Horizontal',
      'Test A-B',
      'Test C-D',
      'Test E-F',
      'Test A-G',
      'Test G-E',
      'Test H/Por-Or',
      'Test I-J',
      'Test K/I-J',
      'Test L-M',
      'Test Xi-R4a',
      'EXAMPLE_CURVE',
    ],
    displayedColumns: [0, 4, 2],
    editableColumns: [0, 4, 2, 3, 1, 5],
    descriptionColumn: 1,
    headingColumn: 0,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [
      ['#Angles', '', '', '', '', ''],
      [
        'AB/CD',
        '',
        15,
        '',
        {
          code: "angle4(get('Test A'), get('Test B'), get('Test C'), get('Test D'))",
        },
        'degrees',
      ],
      [
        'CD/AB',
        '',
        -15,
        '',
        {
          code: "angle4(get('Test C'), get('Test D'), get('Test A'), get('Test B'))",
        },
        'degrees',
      ],
      [
        'AB/EF',
        '',
        105,
        '',
        {
          code: "angle4(get('Test A'), get('Test B'), get('Test E'), get('Test F'))",
        },
        'degrees',
      ],
      [
        'EF/AB',
        '',
        255,
        '',
        {
          code: "angle4(get('Test E'), get('Test F'), get('Test A'), get('Test B'))",
        },
        'degrees',
      ],
      [
        'AGE',
        '',
        105,
        '',
        {
          code: "angle(get('Test A'), get('Test G'), get('Test E'))",
        },
        'degrees',
      ],
      [
        'EGA',
        '',
        255,
        '',
        {
          code: "angle(get('Test E'), get('Test G'), get('Test A'))",
        },
        'degrees',
      ],
      ['#Distances (mm)', '', '', '', '', ''],
      [
        'H to Por-Or',
        '',
        2,
        '',
        {
          code: "product(sign(get('Test H'), get('Test Or'), get('Test Por')), normal(get('Test H'), get('Test Or'), get('Test Por')))",
        },
        'mm',
      ],
      [
        'L to M (\u00B1 along Por-Or)',
        '',
        6,
        '',
        {
          code: `product(
          sign4(get('Test L'), get('Test M'), get('Test Por'), get('Test Or')),
          dist(get('Test L'), get('Test M')))`,
        },
        'mm',
      ],

      ['#Constructed landmarks', '', '', '', '', ''],

      [
        'Xi to R4a',
        '',
        5,
        '',
        {
          code: `dist(get('Test Xi'), get('Test R4a'))`,
        },
        'mm',
      ],

      [
        'Test R3a to Test R4a',
        '',
        8,
        '',
        {
          code: `dist(get('Test R3a'), get('Test R4a'))`,
        },
        'mm',
      ],

      [
        'Test R4a to Test R4b',
        '',
        6,
        '',
        {
          code: `dist(get('Test R4a'), get('Test R4b'))`,
        },
        'mm',
      ],
    ],
  };
}

export const defaultAnalysisStructure = {
  ceph: {
    name: '',
    imageLabel: 'ceph',
    landmarks: analyses['All landmarks'].landmarks,
    segments: analyses['All landmarks'].segments,
    constructedLandmarks: analyses['Ricketts Analysis'].constructedLandmarks,
    visibleConstructedLandmarks: analyses['Ricketts Analysis'].visibleConstructedLandmarks,
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    headingColumn: 0,
    descriptionColumn: 1,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [],
  },
  'pa-ceph': {
    name: '',
    imageLabel: 'pa-ceph',
    landmarks: analyses['All landmarks (PA)'].landmarks,
    segments: analyses['All landmarks (PA)'].segments,
    constructedLandmarks: analyses['Ricketts Analysis (PA)'].constructedLandmarks,
    visibleConstructedLandmarks: analyses['Ricketts Analysis (PA)'].visibleConstructedLandmarks, // FIXME
    displayedColumns: [0, 4, 2, 3],
    editableColumns: [0, 4, 2, 3, 1, 5],
    headingColumn: 0,
    descriptionColumn: 1,
    columns: [
      { t: 'analyses.name' },
      { t: 'analyses.description' },
      { t: 'analyses.mean' },
      { t: 'analyses.std-dev' },
      { t: 'analyses.measurement' },
      { t: 'analyses.unit' },
    ],
    rows: [],
  },
};
