/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import RenderPortal from './RenderPortal';
import './Modal.css';
import { appScroll } from '../../utils/utils';

const Modal = ({ isActive, children, toggle }) => (
  <RenderPortal elementId="portal">
    <div
      className={classNames('is-z-100 modal is-z-100', { 'is-active': isActive })}
      style={{ maxWidth: 'calc(100vw)' }}
    >
      <div
        role="button"
        className="Modal__background modal-background"
        aria-label="close"
        onClick={toggle}
      />
      <div className="Modal__card modal-card px-4">{children}</div>
      <button type="button" className="modal-close is-large" aria-label="close" onClick={toggle} />
    </div>
  </RenderPortal>
);

export const displayModal = (title, body, footer, allContent, options) => {
  const { closeOnBackgroundClick = true, id, afterCloseEffect, modalCardStyle } = options ?? {};

  // If id is provided and if we have an element with the given id don't open the modal
  // This feature can be used to prevent opening multiple modals
  if (id != null && document.getElementById(id) != null) return;

  const portal = document.getElementById('portal');
  const element = document.createElement('div');
  if (id != null) {
    element.id = id;
  }

  const onClose = () => {
    try {
      // Enable scroll
      appScroll.scroll();
      // Apply closing animation.
      const modalCard = element.querySelector('[data-test=modal-card]');
      const modalBackground = element.querySelector('[data-test=modal-background]');
      modalCard.classList.add('Modal__close');
      modalBackground.classList.add('Modal__backgroundClose');

      setTimeout(() => {
        try {
          if (typeof afterCloseEffect === 'function') {
            afterCloseEffect();
          }
          // TODO: unmount react element ReactDOM.unmountComponentAtNode(portal);
          portal.removeChild(element);
        } catch (error) {
          console.error(error);
        }
      }, 300);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('WARNING: element is already removed', error.message);
    }
  };

  // Hide document scroll
  appScroll.hide();

  ReactDOM.render(
    <div className={classNames('modal is-z-100', { 'is-active': true })}>
      <div
        role="button"
        data-test="modal-background"
        className="Modal__background modal-background"
        aria-label="close"
        onClick={closeOnBackgroundClick ? onClose : () => {}}
      />
      <div data-test="modal-card" className="modal-card Modal__card px-4" style={modalCardStyle}>
        {title && (
          <header className="modal-card-head">
            <p className="modal-card-title" style={{ maxWidth: 'calc(100% - 20px)' }}>
              {title}
            </p>
            <button type="button" className="delete" aria-label="close" onClick={onClose} />
          </header>
        )}
        {body && (
          <section className="modal-card-body">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <body.type {...body.props} onClose={onClose} />
          </section>
        )}
        {footer != null && (
          <footer className="modal-card-foot">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <footer.type {...footer.props} onClose={onClose} />
          </footer>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {allContent != null && <allContent.type {...allContent.props} onClose={onClose} />}
      </div>
      {/* <button type="button" className="modal-close is-large" aria-label="close" onClick={onClose} /> */}
    </div>,
    element,
  );
  portal.appendChild(element);
};

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Modal;
