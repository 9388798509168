import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Message = ({ title, message, modifier, timeout, closeButtonProps, onClose }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        onClose();
      }, timeout);
    }
  }, [timeout, onClose]);

  let body = null;
  if (typeof message === 'function') {
    body = message({ onClose });
  } else if (typeof message === 'string') {
    body = t(message);
  } else if (message != null) {
    body = <message.type {...message.props} onClose={onClose} />; // eslint-disable-line react/jsx-props-no-spreading
  }

  return (
    <article className={`message ${modifier}`}>
      <div className="message-header">
        {typeof title === 'string' ? <p>{t(title)}</p> : title}
        <button
          type="button"
          className="delete"
          aria-label="delete"
          onClick={onClose}
          {...closeButtonProps} // eslint-disable-line react/jsx-props-no-spreading
        />
      </div>
      {body != null && <div className="message-body">{body}</div>}
    </article>
  );
};

Message.defaultProps = {
  modifier: 'is-info',
  closeButtonProps: {},
  message: undefined,
  timeout: undefined,
};

Message.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  modifier: PropTypes.string,
  closeButtonProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  timeout: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

/**
 * displays message box on portal
 * @param title: string
 * @param msg: string message
 * @param positions [bottom | right | left | top]
 * @param timeout: number milliseconds
 * @param modifier css className
 */
const displayMessage = (
  title,
  msg,
  positions = ['bottom', 'right'],
  timeout = 10000,
  modifier,
  groupByKey,
) => {
  const element = document.createElement('div');
  const portal = document.getElementById('message');
  if (groupByKey != null) {
    element.setAttribute('data-id', groupByKey);
  }
  portal.style.position = 'fixed';
  positions.forEach((position) => {
    portal.style[position] = '0';
  });
  const onClose = () => {
    try {
      // TODO: add `ReactDOM.unmountComponentAtNode(element);` to unmount react components
      portal.removeChild(element);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('WARNING: element is already removed');
    }
  };
  ReactDOM.render(
    <div
      style={{
        zIndex: 100100,
        width: 300,
        margin: '1em',
      }}
    >
      <Message
        key={Math.random()}
        title={title}
        message={msg}
        timeout={timeout}
        modifier={modifier}
        closeButtonProps={
          groupByKey != null
            ? {
                'data-test': `${groupByKey}-close-button`,
              }
            : {}
        }
        onClose={onClose}
      />
    </div>,
    element,
  );
  if (groupByKey != null) {
    portal.childNodes.forEach((node) => {
      if (node.getAttribute('data-id') === groupByKey) {
        portal.removeChild(node);
      }
    });
  }
  portal.appendChild(element);
};

export const message = {
  success: (title, msg, groupByKey, position) =>
    displayMessage(title, msg, position, 10000, 'is-success', groupByKey),
  danger: (title, msg, groupByKey, position) =>
    displayMessage(title, msg, position, 10000, 'is-danger', groupByKey),
  info: (title, msg, groupByKey, position) =>
    displayMessage(title, msg, position, 10000, 'is-info', groupByKey),
};

export default Message;
