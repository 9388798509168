import { DENTAL_CHART_ITEM_TYPES } from './constants';

export function getTooth(adultTeeth, name) {
  return adultTeeth.children.find((child) => child.name === name);
}

export function getGap(adultTeethGaps, name) {
  return adultTeethGaps.children.find((child) => child.name === name);
}

export function lockItem(item) {
  item.locked = true;
}

export function unlockItem(item) {
  item.locked = false;
}

export function lockItems(items) {
  items.forEach(lockItem);
}
export function unlockItems(items) {
  items.forEach(unlockItem);
}
export const lockTeethFunction = (items) => lockItems(items);
export const lockGapsFunction = (items) => lockItems(items);
export const unlockTeeth = (items) => unlockItems(items);
export const unlockGaps = (items) => unlockItems(items);

export function hideItem(item) {
  item.visible = false;
}

export function showItem(item) {
  item.visible = true;
}

export function extentPoints(pointA, pointB, extend) {
  // compute end points, given extent
  const pd = pointA.subtract(pointB);

  const d = Math.sqrt(pd.x ** 2 + pd.y ** 2);
  const r = extend / d;

  const pointAExtended = pointA.add(pd.multiply(r));
  const pointBExtended = pointB.subtract(pd.multiply(r));
  return { first: pointAExtended, second: pointBExtended };
}

// Returns display name of the gap with the given key
// UR12 -> UR1 & 2
export const getTitleOfGap = (gapKey) => {
  if (typeof gapKey !== 'string') {
    return '';
  }

  // this means the gap between middle teeth like U11 or L11
  if (gapKey.length === 3) {
    return `${gapKey[0]}R${gapKey[1]} & ${gapKey[0]}L${gapKey[2]}`;
  }
  if (gapKey.length === 4) {
    const beginning = gapKey.slice(0, 2);
    return `${beginning}${gapKey[2]} & ${gapKey[3]}`;
  }
  return gapKey;
};

const gapNameRegex = /^\s*(U|L)\s*(R|L)?\s*([1-8])[\s&,ULR-]*([1-8])\s*$/i;
export const getKeyOfGap = (val) => {
  if (typeof val !== 'string') return val;
  // UL3 5
  const result = val.match(gapNameRegex);
  if (result == null) {
    return val;
  }

  const [, arch, side, firstToothString, secondToothString] = result;
  const firstTooth = Number(firstToothString);
  const secondTooth = Number(secondToothString);
  let parsedValue = val;

  const diff = firstTooth - secondTooth;
  if (firstTooth === 1 && secondTooth === 1) {
    parsedValue = `${arch.toUpperCase()}11`;
  } else if (side != null && (diff === 1 || diff === -1)) {
    parsedValue = `${arch.toUpperCase()}${side.toUpperCase()}${Math.min(
      firstTooth,
      secondTooth,
    )}${Math.max(firstTooth, secondTooth)}`;
  }

  return parsedValue;
};

export const makeTooltip = (paper, name) => {
  const bgItem = new paper.Path.Rectangle({
    size: [40, 24],
    fillColor: 'rgba(0,0,0,0.6)',
    strokeColor: 'rgba(255,255,255,0.3)',
  });

  const textItem = new paper.PointText({
    point: [20, 14],
    justification: 'center',
    fillColor: 'white',
    content: '',
  });

  const tooltipGroup = new paper.Group({
    children: [bgItem, textItem],
    name,
    locked: true,
    visible: false,
  });

  // add reference to text and background
  tooltipGroup.textItem = textItem;
  tooltipGroup.bgItem = bgItem;

  // add this raster to image layer
  const tooltipLayer = paper.project.getItem({ name: 'tooltipLayer' });
  tooltipLayer.addChild(tooltipGroup);

  return tooltipGroup;
};

export const defaultHover = (item) => item.scale(1.1);
export const defaultUnhover = (item) => item.scale(1 / 1.1);

export function displayTooltip(paperScope, item) {
  const tooltip = paperScope.project.getItem({ name: 'tooltip' });
  const displayName = item?.data?.displayName ?? item.name;

  if (tooltip && item != null && displayName != null) {
    if (item.data.type === DENTAL_CHART_ITEM_TYPES.GAP) {
      tooltip.textItem.content = getTitleOfGap(displayName);
    } else {
      tooltip.textItem.content = displayName;
    }
    tooltip.visible = true;
    const p = item.position.clone();
    if (p.y < 48) {
      p.y += 24;
    } else {
      p.y -= 24;
    }
    tooltip.bgItem.bounds.width = 24 + tooltip.textItem.bounds.width;
    tooltip.textItem.position = tooltip.bgItem.position;
    tooltip.position = p;
  } else if (tooltip) {
    tooltip.visible = false;
  }
}

export function removeTooltip(paper) {
  const tooltip = paper.project.getItem({ name: 'tooltip' });
  tooltip.visible = false;
}

export const preventDefault = (e) => {
  e.preventDefault();
  return false;
};

export const getBracketItemKey = (toothName) => `bracket:${toothName}`;
export const getGapItemKey = (gapName) => `gap:${gapName}`;
export const getToothItemKey = (toothName) => `tooth:${toothName}`;
export const getToothLabelItemKey = (toothName) => `toothLabel:${toothName}`;
export const getCustomToothLabelItemKey = (toothName) => `customToothLabel:${toothName}`;
export const getWireDottedLineItemKey = (key) => `${key}:dotted`;

export const makeId = (length = 16) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// 'helpers' will be exposed to client
export const helpers = {
  getBracketItemKey,
  getGapItemKey,
  getToothItemKey,
  getToothLabelItemKey,
  getCustomToothLabelItemKey,
  getWireDottedLineItemKey,
};
