/* eslint-disable */
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/number';
import 'core-js/es/array';
import 'core-js/modules/es.symbol';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass'; // imports bulma.sass as well
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import { BrowserRouter, Route } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import ErrorBoundary from './common/ui/components/ErrorBoundary';

// imported in this file so it gets bundled
import './i18n';

// bulma
import 'bulma/css/bulma.css';

// bulma-extensions
import 'bulma-extensions/dist/css/bulma-extensions.min.css';

// bulma-tooltip
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.css';

import 'react-dental-diagram/dist/index.css';
import './common/utils/polyfills';

const preventDefault = (event) => event.preventDefault();

ReactDOM.render(
  <ErrorBoundary onReset={() => window.location.reload()}>
    <React.StrictMode>
      <div
        onDragOver={preventDefault}
        onDragEnter={preventDefault}
        onDragLeave={preventDefault}
        onDrop={preventDefault}
      >
        <BrowserRouter>
          <Route component={App} />
        </BrowserRouter>
      </div>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// registerServiceWorker();
unregister();
