import { useRef } from 'react';
import ReactDOM from 'react-dom';

/**
 * renders children into element that has an id with given elementId
 * @param elementId
 * @param children
 * @returns {*|{children, implementation, containerInfo, $$typeof, key}}
 * @constructor
 */
const RenderPortal = ({ elementId, children }) => {
  const independentDivRef = useRef(document.getElementById(elementId));
  return ReactDOM.createPortal(children, independentDivRef.current);
};

export default RenderPortal;
