import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

import { styleType } from '../../utils/types';

const defaultStyle = { width: '100%', height: '100%' };
function VideoPlayer({ src, type, options, style }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current == null) return;
    const playerOptions = {
      ...options,
    };

    videojs(ref.current, playerOptions, function onPlayerReady() {
      videojs.log('Your player is ready!');

      // In this context, `this` is the player that was created by Video.js.
      // this.play();

      // `videojs` binds created video to this function so that we can use it with `this` keyword.
      // eslint-disable-next-line react/no-this-in-sfc
      this.on('ended', () => {
        videojs.log('video is ended?!');
      });
    });
  }, [options]);

  const videoStyle = useMemo(() => ({ ...defaultStyle, ...style }), [style]);

  return (
    <video-js ref={ref} controls style={videoStyle}>
      <source src={src} type={type} />
    </video-js>
  );
}

VideoPlayer.defaultProps = {
  style: {},
  options: {},
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // This is videojs options
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  style: styleType,
};

export default VideoPlayer;
