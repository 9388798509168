import React from 'react';
import { Route } from 'react-router-dom';
import { DOCUMENT_ROUTE_CONFIG } from '../configs/constants';
import LegalDocuments from '../ui/pages/LegalDocuments';

function getLegalDocumentRoutes() {
  if (DOCUMENT_ROUTE_CONFIG == null) return [];

  const legalDocRoutePaths = Object.keys(DOCUMENT_ROUTE_CONFIG);
  return legalDocRoutePaths.map((path) => {
    return <Route exact key={path} path={path} component={LegalDocuments} />;
  });
}

export default getLegalDocumentRoutes;
