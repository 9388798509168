import React, { useEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import api from '../../utils/api';

// eslint-disable-next-line no-unused-vars
export function ErrorFallback({ className, error, resetErrorBoundary }) {
  const { t } = useTranslation();
  useEffect(() => {
    api.error('ErrorFallback', 'something went wrong', { error });
  }, [error]);
  return (
    <div className={className} role="alert">
      <div className="level is-marginless">
        <div className="level-item" style={{ width: '100%' }}>
          <div className="has-text-centered">
            <h6 className="subtitle is-6">{t('something-went-wrong-please-try-again')}</h6>
            <button
              type="button"
              className="button is-rounded is-primary has-margin-top"
              style={{ marginTop: 8 }}
              onClick={resetErrorBoundary}
            >
              <FontAwesomeIcon icon="redo-alt" style={{ marginRight: 8 }} />
              {t('try-again')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorFallback.defaultProps = {
  className: undefined,
};

ErrorFallback.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

// If component specific structure needed, this is the best place to add
// First, add a unique type to `ERROR_COMPONENT_TYPES` and then
// map that component in `ERROR_COMPONENTS`
export const ERROR_COMPONENT_TYPES = {
  VISIT: 'VISIT',
};

const ERROR_COMPONENTS = {
  [ERROR_COMPONENT_TYPES.VISIT]: ErrorFallback,
};

export default function ErrorBoundary({ className, componentType, children, onReset }) {
  const FallbackComponent = ERROR_COMPONENTS[componentType] ?? ErrorFallback;
  return (
    <ReactErrorBoundary
      // eslint-disable-next-line react/jsx-props-no-spreading
      FallbackComponent={(props) => <FallbackComponent {...props} className={className} />}
      onReset={onReset}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorBoundary.defaultProps = {
  className: undefined,
  componentType: undefined,
  onReset: undefined,
};

ErrorBoundary.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  componentType: PropTypes.string,
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func,
};
