import React from 'react';
import { displayModal } from './Modal';
import services from '../../utils/services';
import { message } from './Message';
import { isCephImageAvailableForSTO } from '../../utils/utils';
import navigation from '../../utils/navigation';

// we are not using default export here because this is kinda helper function.
// In the future, if there is a need for duplicate STO component, then we can add it here.
// eslint-disable-next-line import/prefer-default-export
export const duplicateSTO = async (orgId, image, history, t, onFail = () => {}) => {
  const { isAvailable, isCalibrated, notPlacedLandmarks } = await isCephImageAvailableForSTO(
    orgId,
    image.patient,
    image.visit,
    image.id,
  );
  if (isAvailable === false) {
    let messageText;
    const data = {
      isCalibrated,
      notPlacedLandmarks,
    };
    if (data != null) {
      if (data.isCalibrated && data.notPlacedLandmarks.length > 0) {
        messageText = t('please-trace-the-image-before-creating-sto');
      } else if (!data.isCalibrated && data.notPlacedLandmarks.length === 0) {
        messageText = t('please-calibrate-the-image-before-creating-sto');
      } else if (!data.isCalibrated && data.notPlacedLandmarks.length > 0) {
        messageText = t('please-calibrate-and-trace-the-image-before-creating-sto');
      }
    }
    // eslint-disable-next-line react/prop-types
    const FooterButton = ({ onClose }) => {
      // onClose is used for closing modal, passed automatically
      return (
        <button
          className="button is-success"
          type="button"
          onClick={() => {
            const state = {};
            if (!data?.isCalibrated) {
              state.calibrate = true;
            }
            if (data?.notPlacedLandmarks?.length !== 0) {
              state.notPlacedLandmarks = data.notPlacedLandmarks;
            }
            onClose();
            history.push({
              pathname: image.href,
              state,
            });
          }}
        >
          {t('go-to-analysis')}
        </button>
      );
    };
    displayModal(undefined, <p>{messageText}</p>, <FooterButton />);
    onFail({
      isCalibrated,
      notPlacedLandmarks,
    });
    return;
  }
  const transformations = image.transformations == null ? {} : image.transformations;
  const duplicatedImage = await services.duplicateImage(
    orgId,
    image.id,
    false,
    'sto',
    transformations,
  );
  message.info(t('sto-duplicated'));
  window.gtag('event', 'sto_created'); // google analytics
  history.push(
    navigation.pages.STO_VIEW.getUrl(
      orgId,
      duplicatedImage.patient.key,
      duplicatedImage.visit,
      duplicatedImage.id,
    ),
  );
};
