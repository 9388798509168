import { useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styleType } from '../../utils/types';
import { modal } from './ConfirmModal';

export default function FormikSubmitButton({
  className,
  style,
  buttonName,
  disabled,
  loading,
  confirmationText,
}) {
  const { isSubmitting, handleSubmit } = useFormikContext();
  const shouldConfirm = confirmationText != null;

  return (
    <button
      type="button"
      className={classNames('button is-success is-outlined', {
        [className]: className != null,
        'is-loading': loading || isSubmitting,
      })}
      disabled={disabled}
      style={style}
      onClick={() => {
        if (shouldConfirm) {
          modal.confirm(confirmationText, handleSubmit, { okText: 'yes' });
        } else {
          handleSubmit();
        }
      }}
    >
      {buttonName}
    </button>
  );
}

FormikSubmitButton.defaultProps = {
  className: '',
  buttonName: 'submit',
  confirmationText: null,
  disabled: false,
  loading: false,
  style: undefined,
};

FormikSubmitButton.propTypes = {
  className: PropTypes.string,
  style: styleType,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  buttonName: PropTypes.string,
  confirmationText: PropTypes.string,
};
