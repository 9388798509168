import {
  faEye,
  faEyeSlash,
  faUpload,
  faCaretUp,
  faCaretDown,
  faEdit,
  faEllipsisH,
  faTh,
  faTrashAlt,
  faClinicMedical,
  faEnvelope,
  faCheck,
  faCheckCircle,
  faUser,
  faTimesCircle,
  faThumbtack,
  faSearch,
  faFileImage,
  faFile,
  faFilePdf,
  faFileArchive,
  faFileCsv,
  faFileWord,
  faFileExcel,
  faDownload,
  faFilePowerpoint,
  faFileAudio,
  faFileVideo,
  faArrowsAltV,
  faArrowsAltH,
  faUndoAlt,
  faRedoAlt,
  faSave,
  faCaretLeft,
  faCaretRight,
  faTable,
  faEraser,
  faInfoCircle,
  faSquareRootAlt,
  faBars,
  faFileImport,
  faPlus,
  faExclamation,
  faColumns,
  faWindowMaximize,
  faCloudDownloadAlt,
  faEnvelopeOpenText,
  faTimes,
  faBell,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// load icons
library.add([
  faEye,
  faEyeSlash,
  faUpload,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faEdit,
  faEllipsisH,
  faTh,
  faTrashAlt,
  faEnvelope,
  faCheck,
  faCheckCircle,
  faUser,
  faClinicMedical,
  faThumbtack,
  faTimesCircle,
  faSearch,
  faFileImage,
  faFile,
  faFilePdf,
  faFileArchive,
  faFileCsv,
  faFileWord,
  faFileExcel,
  faDownload,
  faFilePowerpoint,
  faFileAudio,
  faFileVideo,
  faArrowsAltV,
  faArrowsAltH,
  faUndoAlt,
  faRedoAlt,
  faSave,
  faTable,
  faEraser,
  faTimesCircle,
  faInfoCircle,
  faSquareRootAlt,
  faBars,
  faFileImport,
  faPlus,
  faExclamation,
  faColumns,
  faWindowMaximize,
  faCloudDownloadAlt,
  faEnvelopeOpenText,
  faTimes,
  faBell,
  faLock,
]);
