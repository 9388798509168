export const PAGE_KEYS = {
  PATIENT_LIST_VIEW: 'PATIENT_LIST_VIEW',
  PATIENT_VIEW: 'PATIENT_VIEW',
  ENROLL_PATIENT_VIEW: 'ENROLL_PATIENT_VIEW',
  EDIT_PATIENT_VIEW: 'EDIT_PATIENT_VIEW',
  ANALYSIS_VIEW: 'ANALYSIS_VIEW',
  STO_VIEW: 'STO_VIEW',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  EDIT_USER_VIEW: 'EDIT_USER_VIEW',
  ENROLL_USER_VIEW: 'ENROLL_USER_VIEW',
  CHANGELOG_VIEW: 'CHANGELOG_VIEW',
  PAYMENT_VIEW: 'PAYMENT_VIEW',
  PAYMENT_SUCCESS_VIEW: 'PAYMENT_SUCCESS_VIEW',
  MODEL_VIEWER_VIEW: 'MODEL_VIEWER_VIEW',
  DISTRIBUTOR_ADMIN_VIEW: 'DISTRIBUTOR_ADMIN_VIEW',
  SIGN_IN_VIEW: 'SIGNIN_VIEW',
  SIGN_UP_VIEW: 'SIGNUP_VIEW',
  LOGOUT_VIEW: 'LOGOUT_VIEW',
  ADD_TREATMENT_PLAN_VIEW: 'ADD_TREATMENT_PLAN_VIEW',
  EDIT_TREATMENT_PLAN_VIEW: 'EDIT_TREATMENT_PLAN_VIEW',
};

const navigation = {
  pages: {
    PATIENT_LIST_VIEW: {
      path: '/:org_key/patients',
      getUrl: (orgKey) => `/${orgKey}/patients`,
    },
    PATIENT_VIEW: {
      path: '/:org_key/patients/:patient_key',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}`,
    },
    ENROLL_PATIENT_VIEW: {
      path: '/:org_key/patients/enroll',
      getUrl: (orgKey) => `/${orgKey}/patients/enroll`,
    },
    EDIT_PATIENT_VIEW: {
      path: '/:org_key/patients/edit/:patient_key',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/edit/${patientKey}`,
    },
    ENROLL_USER_VIEW: {
      path: '/:org_key/users/enroll',
      getUrl: (orgKey) => `/${orgKey}/users/enroll`,
    },
    EDIT_USER_VIEW: {
      path: '/:org_key/users/edit/:user_id',
      getUrl: (orgKey, userId) => `/${orgKey}/users/edit/${userId}`,
    },
    ANALYSIS_VIEW: {
      path: '/:org_key/patients/:patient_key/visits/:visit_id/images/:image_id/analysis',
      getUrl: (orgKey, patientKey, visitId, imageId) =>
        `/${orgKey}/patients/${patientKey}/visits/${visitId}/images/${imageId}/analysis`,
    },
    STO_VIEW: {
      path: '/:org_key/patients/:patient_key/visits/:visit_id/images/:image_id/sto',
      getUrl: (orgKey, patientKey, visitId, imageId) =>
        `/${orgKey}/patients/${patientKey}/visits/${visitId}/images/${imageId}/sto`,
    },
    ACCOUNT_SETTINGS: {
      path: '/:org_key/account-settings',
      getUrl: (orgKey) => `/${orgKey}/account-settings`,
    },
    CHANGELOG_VIEW: {
      path: '/:org_key/changes',
      getUrl: (orgKey) => `/${orgKey}/changes`,
    },
    PAYMENT_VIEW: {
      path: '/:org_key/payment/:plan_id',
      getUrl: (orgKey, planId) => `/${orgKey}/payment/${planId}`,
    },
    PAYMENT_SUCCESS_VIEW: {
      path: '/:org_key/payment/:plan_id/success',
      getUrl: (orgKey, planId) => `/${orgKey}/payment/${planId}/success`,
    },
    MODEL_VIEWER_VIEW: {
      path: '/:org_key/org/:org_id/patients/:patient_key/visits/:visit_id/documents/:document_id/model-viewer',
      getUrl: (orgKey, patientOrgId, patientKey, visitId, documentId) =>
        `/${orgKey}/org/${patientOrgId}/patients/${patientKey}/visits/${visitId}/documents/${documentId}/model-viewer`,
    },
    DISTRIBUTOR_ADMIN_VIEW: {
      path: '/:org_key/admin/:admin_key',
      getUrl: (orgKey, adminKey) => `/${orgKey}/admin/${adminKey}`,
    },
    SIGN_IN_VIEW: {
      path: '/signin',
      getUrl: () => '/signin',
    },
    SIGN_UP_VIEW: {
      path: '/signup',
      getUrl: () => '/signup',
    },
    LOGOUT_VIEW: {
      path: '/logout',
      getUrl: () => '/logout',
    },
    ADD_TREATMENT_PLAN_VIEW: {
      path: '/:org_key/patients/:patient_key/treatment-plans',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}/treatment-plans`,
    },
    EDIT_TREATMENT_PLAN_VIEW: {
      path: '/:org_key/patients/:patient_key/treatment-plans/edit',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}/treatment-plans/edit`,
    },
    DIAGNOSIS_VIEW: {
      // We are using `Patient analysis` term instead of `Diagnosis` to
      // avoid implying that the dentist has no role in diagnosing
      // the patient themselves.
      path: '/:org_key/patients/:patient_key/patient-analysis',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}/patient-analysis`,
    },
    EDIT_DIAGNOSIS_VIEW: {
      path: '/:org_key/patients/:patient_key/patient-analysis/edit',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}/patient-analysis/edit`,
    },
    ADD_EXECUTION_PLAN_VIEW: {
      path: '/:org_key/patients/:patient_key/execution-plan',
      getUrl: (orgKey, patientKey) => `/${orgKey}/patients/${patientKey}/execution-plan`,
    },
    EDIT_EXECUTION_PLAN_VIEW: {
      path: '/:org_key/patients/:patient_key/execution-plan/edit/:visit_id?', // question mark at the end indicates visit id can be omitted
      getUrl: (orgKey, patientKey, visitId) =>
        `/${orgKey}/patients/${patientKey}/execution-plan/edit${
          visitId != null ? `/${visitId}` : ''
        }`,
    },
    UNKNOWN_PAGE: {
      path: '/404',
      getUrl: () => '/404',
    },
  },
};

export default navigation;
