import api from './api';
import { windowVariables } from '../configs/constants';

const createPath = (base, options) => {
  const optionsString = Object.entries(options)
    .filter(([, v]) => v != null)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  return [base, optionsString].join('?');
};

/**
 *
 * @param orgId
 * @param patientId
 * @param cv = label | pa-ceph | ceph | none
 * @param isTemporary
 * @param formData
 * @param configs
 * @returns {Promise<* | undefined> | Promise<Response>}
 */
const uploadPatientImage = (orgId, patientId, cv, isTemporary, formData, configs) =>
  api
    .call(
      `orgs/${orgId}/patients/${patientId}/images?cv=${cv}&is_temporary=${isTemporary}&stream_id=${api.auth.streamId}`,
      { method: 'POST', body: formData },
      false, // does return JSON
      true, // is multipart/form-data content,
      configs,
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });

/**
 *
 * @param orgId
 * @param cv = label | pa-ceph | ceph | none
 * @param isTemporary
 * @param formData
 * @param configs
 * @returns {Promise<* | undefined> | Promise<Response>}
 */
const uploadImageToOrganization = (orgId, cv, isTemporary, formData, configs) =>
  api
    .call(
      `orgs/${orgId}/images?cv=${cv}&is_temporary=${isTemporary}&stream_id=${api.auth.streamId}`,
      { method: 'POST', body: formData },
      false, // does return JSON
      true, // is multipart/form-data content,
      configs,
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });

const getImage = (orgId, imageId) => api.call(`orgs/${orgId}/images/${imageId}`);

const getPatientImages = (orgId, patientId) =>
  api.call(`orgs/${orgId}/patients/${patientId}/images`);

const deleteImage = (orgId, imageId, configs) =>
  api.call(
    `orgs/${orgId}/images/${imageId}`,
    { method: 'DELETE' },
    true, // no JSON returned
    undefined,
    configs,
  );

const updateImage = (orgId, imageId, data) =>
  api.call(`orgs/${orgId}/images/${imageId}`, { method: 'PUT', body: JSON.stringify(data) }, false);

const analyzeImage = (orgId, imageId, cv) =>
  api.call(
    `orgs/${orgId}/images/${imageId}/analyze?cv=${cv}&stream_id=${api.auth.streamId}`,
    {
      method: 'POST',
    },
    true,
  );

const duplicateImage = (orgId, imageId, isTemporary, label, transformations) =>
  api.call(
    `orgs/${orgId}/images/${imageId}/duplicate`,
    {
      method: 'POST',
      body: JSON.stringify({ is_temporary: isTemporary, label, transformations }),
    },
    false,
  );

const makePayment = (orgId, data) =>
  api.call(
    `orgs/${orgId}/payment`, // TODO: change path when api endpoint determined
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  );

const checkEmail = (email) =>
  api.call(
    `users/email/${email}/check`,
    {
      method: 'GET',
      headers: {
        Authorization: 'bearer Anon',
      },
    },
    undefined,
    undefined,
    { handleError: false },
  );

const checkOrgName = (orgName) =>
  api.call(
    `orgs/name/${orgName}/check`,
    {
      method: 'GET',
      headers: {
        Authorization: 'bearer Anon',
      },
    },
    undefined,
    undefined,
    { handleError: false },
  );

const signUp = (data) =>
  api
    .call('orgs', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: 'bearer Anon',
      },
    })
    .then((value) => {
      window.gtag('event', 'sign_up'); // google analytics
      return value;
    });

const getPlans = (onlyAvailable = false) =>
  api.call(`plans?only_available=${onlyAvailable}`, {
    method: 'GET',
  });

const getDocument = (orgId, docId) =>
  api.call(`orgs/${orgId}/documents/${docId}`, {
    method: 'GET',
  });

const getDocumentWithKey = (docKey) =>
  api.call(`documents/${docKey}`, {
    method: 'GET',
  });

const getDocuments = (orgId, patientId, visitId /* optional */) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/documents${
      visitId != null ? `'?visit_id=${visitId}` : ''
    }`,
    {
      method: 'GET',
      // body: JSON.stringify(visitId ? { visit_id: visitId } : null),
    },
  );

const uploadDocument = (
  orgId,
  patientId,
  formData,
  isTemporary = false,
  configs = undefined,
  cv = 'none',
  kind,
) =>
  api.call(
    createPath(`orgs/${orgId}/patients/${patientId}/documents`, {
      cv,
      kind,
      is_temporary: isTemporary,
      stream_id: api.auth.streamId,
    }),
    {
      method: 'POST',
      body: formData,
    },
    false, // does return JSON
    true, // is multipart/form-data content
    configs,
  );

const uploadDocumentToOrganization = (orgId, formData, isTemporary = false, configs, cv = 'none') =>
  api.call(
    createPath(`orgs/${orgId}/documents`, {
      cv,
      is_temporary: isTemporary,
      stream_id: api.auth.streamId,
    }),
    {
      method: 'POST',
      body: formData,
    },
    false, // does return JSON
    true, // is multipart/form-data content
    configs,
  );

const deleteDocument = (orgId, docId, configs) =>
  api.call(
    `orgs/${orgId}/documents/${docId}`,
    {
      method: 'DELETE',
    },
    true,
    undefined,
    configs,
  );

const createVisit = (orgId, patientId, body, configs) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/visits`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    undefined,
    undefined,
    configs,
  );

const getVisit = (orgId, patientId, visitId) =>
  api.call(`orgs/${orgId}/patients/${patientId}/visits/${visitId}`, { method: 'GET' });

const getVisits = (
  orgId,
  patientId,
  page = 1,
  pageSize = 1000,
  includeUserInfo = true,
  includeDocuments = false,
) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/visits?page=${page}&page_size=${pageSize}&include_user_info=${includeUserInfo}&include_documents=${includeDocuments}`,
  );

const updateVisit = (orgId, patientId, visitId, data, configs) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/visits/${visitId}`,
    { method: 'PUT', body: JSON.stringify(data) },
    true, // no JSON returned,
    undefined,
    configs,
  );

const deleteVisit = (orgId, patientId, visitId) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/visits/${visitId}`,
    { method: 'DELETE' },
    true, // no JSON returned,
  );

const getPatient = (orgId, patientId) => api.call(`orgs/${orgId}/patients/${patientId}`);

const getPatientWithKey = (patientKey) => api.call(`patients/${patientKey}`);

const updatePatient = (orgId, patientId, data) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}`,
    { method: 'PUT', body: JSON.stringify(data) },
    true,
  );

const deletePatient = (orgId, patientId) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}`,
    { method: 'DELETE' },
    true, // no JSON returned
  );

const getAnnotations = (orgId, patientId, visitId, imageId) =>
  api.call(`orgs/${orgId}/patients/${patientId}/visits/${visitId}/images/${imageId}/annotations`);

const patchAnnotations = (orgId, patientId, visitId, imageId, kind, body) =>
  api.call(
    `orgs/${orgId}/patients/${patientId}/visits/${visitId}/images/${imageId}/annotations?${
      kind != null ? `kind=${kind}` : ''
    }`,
    { method: 'PATCH', body: JSON.stringify(body) },
  );

const createPaymentIntent = (planId) =>
  api.call('vendor/stripe/payment_intent', {
    method: 'POST',
    body: JSON.stringify({ plan_id: planId }),
  });

const successfulPayment = (paymentIntentId) =>
  api.call('vendor/stripe/successful_payment', {
    method: 'POST',
    body: JSON.stringify({ payment_intent_id: paymentIntentId }),
  });

const getUserPreferences = (kind, options = {}) =>
  api.call(`users/current/preferences/${kind}`, { method: 'GET', ...options });

const setUserPreferences = (kind, body, options = {}) =>
  api.call(
    `users/current/preferences/${kind}`,
    { method: 'POST', body: JSON.stringify(body), ...options },
    true,
  );

const getOrgPreferences = (orgId, kind) =>
  api.call(`orgs/${orgId}/preferences/${kind}`, { method: 'GET' });

const setOrgPreferences = (orgId, kind, body) =>
  api.call(
    `orgs/${orgId}/preferences/${kind}`,
    { method: 'POST', body: JSON.stringify(body) },
    true,
  );

const getSitePreferences = (kind) => api.call(`site/preferences/${kind}`, { method: 'GET' });

const getManagementAccounts = () => api.call(`management`, { method: 'GET' });

const getAdminPageInfo = (managementKey) =>
  api.call(`management/${managementKey}`, { method: 'GET' });

const getAdminPageOrgList = (managementKey, page = 1, pageSize = 1000, search, orderBy) =>
  api.call(
    createPath(`management/${managementKey}/orgs`, {
      page,
      page_size: pageSize,
      search,
      order_by: orderBy,
    }),
    {
      method: 'GET',
    },
  );

const getAdminPageCaseList = (managementKey, page = 1, pageSize = 1000) =>
  api.call(
    createPath(`management/${managementKey}/cases`, {
      page,
      page_size: pageSize,
    }),
    {
      method: 'GET',
    },
  );

const updateOrganizationManagement = (
  managementKey,
  orgKey,
  { userQuota, active, notes, accountType },
) =>
  api.call(`management/${managementKey}/orgs/${orgKey}`, {
    method: 'POST',
    body: JSON.stringify({ user_quota: userQuota, active, notes, account_type: accountType }),
  });

const updateOrganizationUserQuota = (managementKey, orgKey, userQuota) =>
  updateOrganizationManagement(managementKey, orgKey, { userQuota });
const updateOrganizationStatus = (managementKey, orgKey, status) =>
  updateOrganizationManagement(managementKey, orgKey, { active: status });
const updateOrganizationNote = (managementKey, orgKey, notes) =>
  updateOrganizationManagement(managementKey, orgKey, { notes });
const updateOrganizationAccountType = (managementKey, orgKey, accountType) =>
  updateOrganizationManagement(managementKey, orgKey, { accountType });

const setAdminPageOrgSubscription = (adminId, orgKey, planId) =>
  api.call(`management/${adminId}/orgs/${orgKey}/subscriptions`, {
    method: 'POST',
    body: JSON.stringify({ plan_id: planId }),
  });

const getSubscriptions = (adminKey, orgKey, page, pageSize = 1000) =>
  api.call(
    `management/${adminKey}/orgs/${orgKey}/subscriptions?page=${page}&page_size=${pageSize}`,
    {
      method: 'GET',
    },
  );

const getNotification = (notificationKey) =>
  api.call(`notifications/${notificationKey}`, {}, false, false, {
    handleError: true,
  });

const getNotifications = (page, pageSize, options = {}) =>
  api.call(`notifications?page=${page}&page_size=${pageSize}`, options, false, false, {
    handleError: false,
  });

const sendNotification = (body) =>
  api.call('notifications', { method: 'POST', body: JSON.stringify(body) }, true);

const updateNotification = (notificationKey, { read, resolved }) => {
  const body = {};

  if (read != null) {
    body.read = read;
  }
  if (resolved != null) {
    body.resolved = resolved;
  }

  return api.call(`notifications/${notificationKey}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};
const readNotification = (notificationKey) => updateNotification(notificationKey, { read: true });
const resolveNotification = (notificationKey) =>
  updateNotification(notificationKey, { resolved: true });

const updateAllNotifications = ({ read, resolved }) => {
  const body = {};

  if (read != null) {
    body.read = read;
  }
  if (resolved != null) {
    body.resolved = resolved;
  }

  return api.call(`notifications`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};
const markAsReadNotifications = () => updateAllNotifications({ read: true });
const resolveAllNotifications = () => updateAllNotifications({ resolved: true });

const updateCase = (
  caseKey,
  action,
  data,
  noNotification = windowVariables.getValue(
    // eslint-disable-next-line no-underscore-dangle
    windowVariables.keys._ACM_SHOULD_SEND_EMAIL_ON_ACTION,
  ) ?? false,
) =>
  api.call(`cases/${caseKey}?no_notify=${noNotification}`, {
    method: 'PUT',
    body: JSON.stringify({ action, data }),
  });
const getCaseByKey = (caseKey) => api.call(`cases/${caseKey}`, { method: 'GET' });
const getPatientCases = (patientKey) => api.call(`patients/${patientKey}/cases`, { method: 'GET' });

const sendDevCommand = (commandKey, body) =>
  api.call(`dev/${commandKey}`, { method: 'POST', body: JSON.stringify(body) });
const resetCase = (caseId) => sendDevCommand('reset_case', { case_id: caseId });
const clearNotifications = (userId) => sendDevCommand('clear_notifications', { user_id: userId });
const setCaseState = (caseId, state, body) =>
  sendDevCommand('set_case_state', { case_id: caseId, state, body });

const getOrthodontists = () => api.call(`cases/orthodontists`, { method: 'GET' });

const promptForExportOptions = () => {
  // eslint-disable-next-line no-alert
  // return prompt('Please set any options (set to 1 to enable):', 'has-bite-turbos=0') || '';
  return '';
};

const exportLabels = (organization, dentist, patient, executionPlan, options) => {
  return api
    .call(
      `vendor/acm-export-service/labels?${promptForExportOptions()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        body: JSON.stringify({
          organization,
          dentist,
          patient,
          executionPlan,
          options,
        }),
      },
      true, // does not return JSON
      false, // is not multipart/form-data content,
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });
};

const exportPouchCards = (
  organization,
  dentist,
  patient,
  executionPlan,
  options,
  additionalQueryParams,
) =>
  api
    .call(
      `vendor/acm-export-service/pouch-cards?${promptForExportOptions()}&${additionalQueryParams}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        body: JSON.stringify({
          organization,
          dentist,
          patient,
          executionPlan,
          options,
        }),
      },
      true, // does not return JSON
      false, // is not multipart/form-data content,
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });

const getPouchCardsHTML = (executionPlan) =>
  api
    .call(
      `vendor/acm-export-service/pouch-cards?html=1`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        body: JSON.stringify({
          executionPlan,
        }),
      },
      true, // does not return JSON
      false, // is not multipart/form-data content,
    )
    .then((response) => response.text())
    .then((value) => {
      // put steps into array
      const div = document.createElement('div');
      div.innerHTML = value;
      const steps = div.querySelectorAll('.step');
      const stepHTMLs = [];

      steps.forEach((step) => {
        const tempDiv = document.createElement('div');
        tempDiv.appendChild(step);
        stepHTMLs.push(tempDiv.innerHTML);
      });

      return stepHTMLs;
    });

const updateOrganization = (orgId, data) =>
  api.call(`orgs/${orgId}`, { method: 'PUT', body: JSON.stringify(data) }, true);
const updateBillingAddress = (orgId, address) =>
  updateOrganization(orgId, { billing_address: address });
const updatePhysicalAddress = (orgId, address) =>
  updateOrganization(orgId, { physical_address: address });
const updateShippingAddress = (orgId, address) =>
  updateOrganization(orgId, { shipping_address: address });

const getOrgCases = (managementKey, orgKey, page = 1, pageSize = 1000) =>
  api.call(`management/${managementKey}/orgs/${orgKey}/cases?page=${page}&page_size=${pageSize}`, {
    method: 'GET',
  });

const updateOrgCase = (managementKey, caseKey, body) =>
  api.call(`management/${managementKey}/cases/${caseKey}`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

const getOrgUsers = (orgId, page, pageSize) =>
  api.call(
    createPath(`orgs/${orgId}/users`, {
      page,
      page_size: pageSize,
    }),
    {
      method: 'GET',
    },
  );

const downloadShippingLabel = (caseKey) =>
  api.call(`vendor/shippo/cases/${caseKey}/ship_impressions`, {
    method: 'POST',
  });

const downloadRxForm = (caseId, patientName, currentDate) =>
  api.call(
    `vendor/docx/dibs/rx-form`,
    {
      method: 'POST',
      body: JSON.stringify({
        impression_date: currentDate, // current date
        patient_name: patientName, // patient's name
        special_instructions: '', // optional special instructions (for now, blank)
        case_id: caseId, // case id for the patient.
      }),
    },
    true,
    false,
  );

const uploadVisitFiles = (orgId, patientId, visitId, formData) =>
  api
    .call(
      `orgs/${orgId}/patients/${patientId}/visits/${visitId}/files`,
      {
        method: 'POST',
        body: formData,
      },
      false, // does return JSON
      true, // is multipart/form-data content
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });

const uploadPatientFiles = (orgId, patientId, formData) =>
  api
    .call(
      `orgs/${orgId}/patients/${patientId}/files`,
      {
        method: 'POST',
        body: formData,
      },
      false, // does return JSON
      true, // is multipart/form-data content
    )
    .then((value) => {
      window.gtag('event', 'upload_file'); // google analytics
      return value;
    });

const uploadOrganizationFiles = (orgId, formData) =>
  api.call(`orgs/${orgId}`, { method: 'POST', body: formData }, false, true).then((value) => {
    window.gtag('event', 'upload_file'); // google analytics
    return value;
  });

const getPatientFiles = (orgId, patientId) =>
  api.call(`orgs/${orgId}/patients/${patientId}/files`, { method: 'GET' });

export default {
  orgs: {
    updateOrganization,
    updateBillingAddress,
    updatePhysicalAddress,
    updateShippingAddress,
  },
  documents: {
    getDocumentWithKey,
    getDocument,
    getDocuments,
    uploadDocumentToOrganization,
    uploadDocument,
    deleteDocument,
  },
  // 'images' endpoint is DEPRECATED. Please use `documents` where necessary.
  images: {
    getImage,
    updateImage,
    uploadImageToOrganization,
    uploadPatientImage,
  },
  notifications: {
    getNotification,
    getNotifications,
    sendNotification,
  },
  patients: {
    getPatient,
    updatePatient,
    deletePatient,
    getPatientWithKey,
  },
  cases: {
    updateCase,
    getCaseByKey,
    getPatientCases,
    getOrthodontists,
  },
  visits: {
    createVisit,
    getVisits,
    updateVisit,
    deleteVisit,
    getVisit,
  },
  management: {
    getAdminPageOrgList,
    getAdminPageCaseList,
    getAdminPageInfo,
    getManagementAccounts,
    updateOrganizationManagement,
    updateOrganizationNote,
    updateOrganizationStatus,
    updateOrganizationUserQuota,
    updateOrganizationAccountType,
    getOrgCases,
    updateOrgCase,
  },
  dev: {
    resetCase,
    clearNotifications,
    setCaseState,
  },
  exports: {
    exportLabels,
    exportPouchCards,
    getPouchCardsHTML,
  },
  plans: {
    getPlans,
  },
  vendor: {
    docx: {
      downloadRxForm,
    },
    shippo: {
      downloadShippingLabel,
    },
  },
  users: {
    getOrgUsers,
  },
  files: {
    uploadVisitFiles,
    uploadOrganizationFiles,
    uploadPatientFiles,
    getPatientFiles,
  },
  preferences: {
    getUserPreferences,
    setUserPreferences,
    getOrgPreferences,
    setOrgPreferences,
    getSitePreferences,
  },
  // This export is DEPRECATED. Now, we will use the scoped functions like services.images.uploadPatientImage.
  // Please see above.
  uploadPatientImage,
  deleteImage,
  updateImage,
  getImage,
  getPatientImages,
  analyzeImage,
  duplicateImage,
  makePayment,
  checkEmail,
  checkOrgName,
  signUp,
  getPlans,
  createVisit,
  getVisits,
  updateVisit,
  getPatient,
  getPatientWithKey,
  updatePatient,
  getAnnotations,
  patchAnnotations,
  createPaymentIntent,
  successfulPayment,
  getUserPreferences,
  setUserPreferences,
  getOrgPreferences,
  setOrgPreferences,
  getManagementAccounts,
  getAdminPageInfo,
  getAdminPageOrgList,
  getAdminPageCaseList,
  setAdminPageOrgSubscription,
  getSubscriptions,
  updateOrganizationUserQuota,
  updateOrganizationStatus,
  updateOrganizationNote,
  getNotifications,
  updateNotification,
  readNotification,
  resolveNotification,
  markAsReadNotifications,
  resolveAllNotifications,
  getPatientCases,
};
