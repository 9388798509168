import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDReactComponent from 'markdown-react-js';
import moment from 'moment';
import { useToggle } from '../../common/utils/hooks';
import SendMessageModalContent from './SendMessageModalContent';
import auth from '../../common/utils/auth';
import {
  historyType,
  notificationType,
  numberOrStringType,
  userType,
} from '../../common/utils/types';
import { getUserFullName } from '../../common/utils/utils';
import navigation from '../../common/utils/navigation';
import Documents from '../../common/ui/components/Documents';

function SenderName({ sender, shouldShowSender }) {
  const { t } = useTranslation();
  if (sender != null) {
    const label = 'from';
    const value = shouldShowSender ? getUserFullName(sender) : 'OrthoTx';
    return (
      shouldShowSender && (
        <div>
          <span className="has-text-weight-bold">{`${t(label)}:`}</span>
          <span className="ml-2">
            {`${value} `}
            <FontAwesomeIcon icon="lock" />
          </span>
        </div>
      )
    );
  }
  return null;
}

SenderName.defaultProps = {
  sender: null,
  shouldShowSender: false,
};

SenderName.propTypes = {
  sender: userType,
  shouldShowSender: PropTypes.bool,
};

function NotificationModalContent({ orgId, currentPatientKey, history, notification, onClose }) {
  const { t } = useTranslation();
  const {
    isOrthoTxManager,
    isOrthoTxOrthodontist,
    isOrthoTxPackaging,
    isOrthoTxDentist,
    isOrthoTxDeveloper,
  } = auth.getRoles();
  const [isReplying, toggleReplySection] = useToggle(false);
  const {
    public_header_plaintext: publicHeader,
    public_text_plaintext: publicText,
    private_text_plaintext: privateText,
    patient,
    thread_reference: threadReference,
    date_created: dateCreated,
    sender,
    documents,
  } = notification;
  const patientId = patient?.id;
  const patientKey = patient?.key;
  const isPatientExists = patientKey != null;
  const orgKey = auth.orgInfo.key;

  return (
    <div style={{ width: '100%', backgroundColor: 'white', padding: '1em', overflowY: 'scroll' }}>
      <h5 className="title is-5">{publicHeader}</h5>
      <SenderName
        sender={sender}
        shouldShowSender={
          isOrthoTxManager || isOrthoTxOrthodontist || isOrthoTxPackaging || isOrthoTxDeveloper
        }
      />
      <div>
        <span className="has-text-weight-bold">{`${t('sent-at')}:`}</span>
        <span className="ml-2">
          {`${t('date', { date: moment(dateCreated) })} ${t('date-time', {
            date: moment(dateCreated),
          })}`}
        </span>
      </div>
      {isPatientExists && (
        <div>
          <span className="has-text-weight-bold">{`${t('patient-name')}:`}</span>
          <span className="ml-2">{getUserFullName(patient)}</span>
        </div>
      )}
      <p>
        <MDReactComponent text={publicText} markdownOptions={{ breaks: true }} />
      </p>
      {privateText?.length > 0 && (
        <div className="notification is-info is-light is-marginless mt-2">
          <MDReactComponent text={privateText} markdownOptions={{ breaks: true }} />
        </div>
      )}
      <div className="is-flex is-flex-wrap-wrap">
        <Documents orgId={orgId} patientKey={patientKey} documents={documents} />
      </div>
      <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mt-4">
        <div>
          <button
            type="button"
            className="button is-outlined"
            // user that has management role can't reply to a notification that has no sender org
            disabled={!isOrthoTxDentist && sender?.organization == null}
            onClick={() => toggleReplySection()}
          >
            {t('reply')}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="button is-outlined"
            onClick={() => {
              if (patientId == null || currentPatientKey === patientKey) {
                history.push(history.location.pathname);
                onClose();
              } else {
                onClose();
                history.push(navigation.pages.PATIENT_VIEW.getUrl(orgKey, patientKey));
              }
            }}
          >
            {patientId == null || currentPatientKey === patientKey
              ? t('close')
              : t('go-to-patient-page')}
          </button>
        </div>
      </div>
      {isReplying && (isOrthoTxDentist || isOrthoTxManager || isOrthoTxOrthodontist) && (
        <div className="card">
          <h5
            className="title is-5 is-marginless"
            style={{
              padding: '1em 1em 0 1em',
            }}
          >
            {isOrthoTxDentist
              ? t('send-a-secure-message-to-orthotx')
              : t('send-a-secure-message-to-the-dentist')}
          </h5>
          <SendMessageModalContent
            patientId={patientId}
            threadReference={threadReference}
            recipientId={sender?.id}
            senderOrganizationId={sender?.organization}
            isDentist={isOrthoTxDentist}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
}

NotificationModalContent.defaultProps = {
  currentPatientKey: null,
  onClose: () => {},
};

NotificationModalContent.propTypes = {
  orgId: numberOrStringType.isRequired,
  currentPatientKey: PropTypes.string,
  history: historyType.isRequired,
  notification: notificationType.isRequired,
  onClose: PropTypes.func,
};

export default NotificationModalContent;
