import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './EditableSelector.sass';
import { numberOrStringType } from '../../common/utils/types';

const EditableSelector = ({
  className,
  customTooltipText,
  disabled,
  initialValue,
  data,
  width,
  onChange,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const selectedLabelKey = data.find((d) => d.value === value)?.label ?? '';

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSelect = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <div
      data-tooltip={customTooltipText ?? t(selectedLabelKey)}
      className="has-tooltip-arrow has-tooltip-bottom"
    >
      <div
        title={t(selectedLabelKey)}
        className={`EditableSelector__editableInput ${className}`}
        style={{ width }}
      >
        <select value={value} onChange={handleSelect} disabled={disabled}>
          {data.map(({ key, value: val, label }) => (
            <option key={key} value={val}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

EditableSelector.defaultProps = {
  initialValue: undefined,
  customTooltipText: undefined,
  className: '',
  width: undefined,
  disabled: false,
  data: [],
  onChange: () => {},
};

const propTypeStringOrNumber = [PropTypes.number, PropTypes.string];
EditableSelector.propTypes = {
  className: PropTypes.string,
  customTooltipText: PropTypes.string,
  initialValue: numberOrStringType,
  disabled: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: numberOrStringType,
      label: PropTypes.string,
    }),
  ),
  width: PropTypes.oneOfType(propTypeStringOrNumber),
  onChange: PropTypes.func,
};

export default EditableSelector;
