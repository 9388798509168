export const ADULT_TEETH_NUMBER = 32;
export const ADULT_TEETH_LABEL = ADULT_TEETH_NUMBER + 2;
export const ADULT_TEETH_GAPS = ADULT_TEETH_NUMBER + 2;

export const UPPER_REFERENCE_TOOTH_NAME = 'UR7';
export const LOWER_REFERENCE_TOOTH_NAME = 'LR7';

export const DEFAULTS = {
  INCREASE_THICKNESS_NUMBER: 2,
  BACKGROUND_COLOR: 'rgb(244,244,244)',
  WIRE_COLOR: '#3298dc',
  WIRE_BEND_COLOR: '#3298dc',
  INTERPROXIMAL_REDUCTION_COLOR: '#E60466',
  GAP_FILL_COLOR: 'rgba(197,199,215,0.3)',
  ELASTIC_COLOR: 'orange',
  CHAIN_COLOR: 'red',
  STOP_COLOR: 'green',
  BUTTON_COLOR: 'red',
  SPRING_COLOR: 'black',
  CIRCLE_TEXT_BACKGROUND: '#3298dc',
  WIRE_LABEL_COLOR: 'white',
  WIRE_LABEL_BACKGROUND_COLOR: '#3298dc',
};

export const DENTAL_CHART_ITEM_TYPES = {
  GAP: 'GAP',
  GAP_LABEL: 'GAP_LABEL',
  TOOTH: 'TOOTH',
  TOOTH_LABEL: 'TOOTH',
  // items on teeth
  SPRING: 'SPRING',
  BRACKET: 'BRACKET',
  BUTTON: 'BUTTON',
  CHAIN: 'CHAIN',
  STOP: 'STOP',
  ELASTIC: 'ELASTIC',
  INTERPROXIMAL_REDUCTION: 'INTERPROXIMAL_REDUCTION',
  WIRE: 'WIRE',
  WIRE_BEND: 'WIRE_BEND',
  SINGLE_WIRE_BEND_LINE: 'SINGLE_WIRE_BEND_LINE',
  SINGLE_WIRE_BEND: 'SINGLE_WIRE_BEND',
  // Labels component item types
  LABEL: 'LABEL',
  LABEL_BACKGROUND: 'LABEL_BACKGROUND',
  LABEL_CONNECTOR_LINE: 'LABEL_CONNECTOR_LINE',
  LABEL_SHAPE_INDICATOR: 'LABEL_SHAPE_INDICATOR',
  // CircleText component item types
  CIRCLE_TEXT: 'CIRCLE_TEXT',
  CIRCLE_TEXT_BACKGROUND: 'CIRCLE_TEXT_BACKGROUND',
  CIRCLE_TEXT_GROUP: 'CIRCLE_TEXT_GROUP',
  CUSTOM_TOOTH_LABEL: 'CUSTOM_TOOTH_LABEL',
};

// index matters: maps to element ordering in paper elements after svg import
export const CHILD_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'UAA',
  'LAA',
  'URAB',
  'ULAB',
  'LRAB',
  'LLAB',
  'URBC',
  'ULBC',
  'LRBC',
  'LLBC',
  'URCD',
  'ULCD',
  'LRCD',
  'LLCD',
  'URDE',
  'ULDE',
  'LRDE',
  'LLDE',
];

export const CHILD_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'URA',
  'LRA',
  'ULA',
  'LLA',
  'URB',
  'LRB',
  'ULB',
  'LLB',
  'URC',
  'LRC',
  'ULC',
  'LLC',
  'URD',
  'LRD',
  'ULD',
  'LLD',
  'URE',
  'LRE',
  'ULE',
  'LLE',
];

export const ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'U11',
  'L11',
  'UR12',
  'UL12',
  'LR12',
  'LL12',
  'UR23',
  'UL23',
  'LR23',
  'LL23',
  'UR34',
  'UL34',
  'LR34',
  'LL34',
  'UR45',
  'UL45',
  'LR45',
  'LL45',
  'UR56',
  'UL56',
  'LR56',
  'LL56',
  'UR67',
  'UL67',
  'LR67',
  'LL67',
  'UR78',
  'UL78',
  'LR78',
  'LL78',
  // 'LL8',
  // 'LR8',
  // 'UL8',
  // 'UR8',
];

export const ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'UR8',
  'LR8',
  'UL8',
  'LL8',
  'UR7',
  'LR7',
  'UL7',
  'LL7',
  'UR6',
  'LR6',
  'UL6',
  'LL6',
  'UR5',
  'LR5',
  'UL5',
  'LL5',
  'UR4',
  'LR4',
  'UL4',
  'LL4',
  'UR3',
  'LR3',
  'UL3',
  'LL3',
  'UR2',
  'LR2',
  'UL2',
  'LL2',
  'UR1',
  'LR1',
  'UL1',
  'LL1',
];

export const ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_TEETH_LABELS = [
  'UR8',
  'UL8',
  'UR7',
  'UL7',
  'UR6',
  'UL6',
  'UR5',
  'UL5',
  'UR4',
  'UL4',
  'UR3',
  'UL3',
  'UR2',
  'UL2',
  'UR1',
  'UL1',
  'LR8',
  'LL8',
  'LR7',
  'LL7',
  'LR6',
  'LL6',
  'LR5',
  'LL5',
  'LR4',
  'LL4',
  'LR3',
  'LL3',
  'LR2',
  'LL2',
  'LR1',
  'LL1',
];

export const BUTTON_POSITION_MAPPED_WITH_PLACEMENT = {
  RIGHT: {
    distolingual: [0.2, 0.5],
    lingual: [0.5, 0.5],
    mesiolingual: [0.8, 0.5],
  },
  LEFT: {
    mesiolingual: [0.2, 0.5],
    lingual: [0.5, 0.5],
    distolingual: [0.8, 0.5],
  },
};

export const GAP_INDEX_MAPPED_BY_NAME = {
  U11: 0,
  L11: 0,
  UR12: -1,
  UL12: 1,
  LR12: -1,
  LL12: 1,
  UR23: -2,
  UL23: 2,
  LR23: -2,
  LL23: 2,
  UR34: -3,
  UL34: 3,
  LR34: -3,
  LL34: 3,
  UR45: -4,
  UL45: 4,
  LR45: -4,
  LL45: 4,
  UR56: -5,
  UL56: 5,
  LR56: -5,
  LL56: 5,
  UR67: -6,
  UL67: 6,
  LR67: -6,
  LL67: 6,
  UR78: -7,
  UL78: 7,
  LR78: -7,
  LL78: 7,
  // 'LL8',
  // 'LR8',
  // 'UL8',
  // 'UR8',
};

export const TOOTH_INDEX_MAPPED_BY_NAME = {
  UR8: 1,
  LR8: 1,
  UL8: 16,
  LL8: 16,
  UR7: 2,
  LR7: 2,
  UL7: 15,
  LL7: 15,
  UR6: 3,
  LR6: 3,
  UL6: 14,
  LL6: 14,
  UR5: 4,
  LR5: 4,
  UL5: 13,
  LL5: 13,
  UR4: 5,
  LR4: 5,
  UL4: 12,
  LL4: 12,
  UR3: 6,
  LR3: 6,
  UL3: 11,
  LL3: 11,
  UR2: 7,
  LR2: 7,
  UL2: 10,
  LL2: 10,
  UR1: 8,
  LR1: 8,
  UL1: 9,
  LL1: 9,
};

export const GAP_NAME_MAPPED_BY_INDEX = (isUpper) =>
  Object.entries(GAP_INDEX_MAPPED_BY_NAME)
    .filter(([k]) => (isUpper ? k[0] === 'U' : k[0] === 'L'))
    .reduce((acc, [k, v]) => {
      acc[v] = k;
      return acc;
    }, {});

export const TOOTH_NAME_MAPPED_BY_INDEX = (isUpper) =>
  Object.entries(TOOTH_INDEX_MAPPED_BY_NAME)
    .filter(([k]) => (isUpper ? k[0] === 'U' : k[0] === 'L'))
    .reduce((acc, [k, v]) => {
      acc[v] = k;
      return acc;
    }, {});

export default {
  DENTAL_CHART_ITEM_TYPES,
  DEFAULTS,
  // add additional constants here if you want to expose them to client
};
