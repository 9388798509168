import React, { useEffect, useState } from 'react';
import gifshot from 'gifshot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from '../../utils/auth';
import { FILE_TYPE_CONFIGS } from '../../configs/constants';
import CallbackSpinner from '../views/CallbackSpinner';
import api from '../../utils/api';
import { documentType } from '../../utils/types';

function VideoDocument({ document }) {
  const [{ data: previewGifData, loading, error }, setState] = useState({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const cachedGifData = auth.getCachedGifData(document.url);

    if (cachedGifData != null) {
      setState({
        data: cachedGifData,
        loading: false,
        error: null,
      });
    } else {
      const documentNameSplitted = document.name.split('.');
      const extension = documentNameSplitted[documentNameSplitted.length - 1];
      if (gifshot.isExistingVideoGIFSupported([extension])) {
        gifshot.takeSnapShot(
          {
            video: [document.url],
          },
          (obj) => {
            if (obj.error === true) {
              api.error('VideoDocument', 'unable to create video preview gif', { error: obj });
              setState({
                data: null,
                loading: false,
                error: obj.errorMsg,
              });
            } else {
              setState({
                data: obj.image,
                loading: false,
                error: null,
              });
              auth.cacheGifData(document.url, obj.image);
            }
          },
        );
      } else {
        setState({
          data: null,
          loading: false,
          error: 'mimetype is not supported by gifshot',
        });
      }
    }
  }, [document]);

  let content = null;

  if (loading) {
    content = <CallbackSpinner />;
  } else if (previewGifData != null) {
    content = <img src={previewGifData} alt="video preview" style={{ height: 96 }} />;
  } else if (error != null) {
    content = (
      <FontAwesomeIcon icon={FILE_TYPE_CONFIGS.getFileTypeIcon(document.mimetype)} size="6x" />
    );
  }

  return <div style={{ height: 96 }}>{content}</div>;
}

VideoDocument.propTypes = {
  document: documentType.isRequired,
};

export default VideoDocument;
