import PropTypes from 'prop-types';
import { toothType } from 'react-dental-diagram/src/utils/types';
import { SURGICAL_CUTS } from '../ceph/constants';

export const numberOrStringType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const surgicalCutType = PropTypes.shape({
  visible: PropTypes.bool,
  segments: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
});

export const surgeryType = PropTypes.shape({
  [SURGICAL_CUTS.BSSO]: surgicalCutType,
  [SURGICAL_CUTS.LEFORT1]: surgicalCutType,
  [SURGICAL_CUTS.MAXILLA]: surgicalCutType,
  [SURGICAL_CUTS.GENIOPLASTY]: surgicalCutType,
});

export const surgicalTreatmentType = PropTypes.shape({
  annotationId: PropTypes.number,
  surgeryFinalized: PropTypes.bool,
  surgery: surgeryType,
});

// TODO: make more strict type with adding value of [number, number]
export const landmarksType = PropTypes.objectOf(PropTypes.string);

export const transformationsType = PropTypes.shape({
  // TODO: init
});

export const imageType = PropTypes.shape({
  id: PropTypes.number,
  source: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  transformations: transformationsType,
  href: PropTypes.string,
  patient: PropTypes.number, // id
  visit: PropTypes.number, // id
});

export const viewType = PropTypes.shape({
  scaling: PropTypes.number,
  rotation: PropTypes.number,
  position: PropTypes.arrayOf(PropTypes.number),
  brightness: PropTypes.number,
  contrast: PropTypes.number,
});

export const metaType = PropTypes.shape({
  calibration: PropTypes.number,
});

export const curvesType = PropTypes.shape({
  // TODO: init
});

export const deletedAutoLandmarksType = PropTypes.shape({
  // TODO: init
});

export const visibilityType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  landmarks: PropTypes.object, // TODO: init
  // eslint-disable-next-line react/forbid-prop-types
  segments: PropTypes.object, // TODO: init
  showAutoLandmarks: PropTypes.bool,
});

export const cephDataType = PropTypes.shape({
  surgicalTreatment: surgicalTreatmentType,
  landmarks: landmarksType,
  deletedAutoLandmarks: deletedAutoLandmarksType,
  autoLandmarks: landmarksType,
  curves: curvesType,
  image: imageType,
  visibility: visibilityType,
  view: viewType,
  meta: metaType,
});

export const visitType = PropTypes.shape({
  id: numberOrStringType,
  date: PropTypes.string,
  images: PropTypes.arrayOf(imageType),
  documents: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  chartNotes: PropTypes.string,
  tags: PropTypes.string,
  dateCreated: PropTypes.string,
  dateUpdated: PropTypes.string,
  lastUpdatedBy: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  author: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
});

export const documentType = PropTypes.shape({
  id: PropTypes.number,
  url: PropTypes.string,
  name: PropTypes.string,
  patient: PropTypes.number, // patient id
  visit: PropTypes.number, // visit id
  mimetype: PropTypes.string,
});

export const patientType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  dob: PropTypes.string,
  referenceID: PropTypes.string,
  // arbitrary patient info
  info: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  profileImageManuallySet: PropTypes.bool,
  profileImage: PropTypes.number,
  // raw database patient object useful especially when exporting pdf
  originalPatientObject: PropTypes.object, // eslint-disable-line react/forbid-prop-types
});

export const checkboxOptionType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
  }),
);

export const styleType = PropTypes.object; // eslint-disable-line react/forbid-prop-types

export const selectorOptionType = PropTypes.shape({
  key: numberOrStringType,
  value: numberOrStringType,
  label: numberOrStringType,
});

export const selectorOptionsType = PropTypes.arrayOf(selectorOptionType);

export const wireType = PropTypes.shape({
  upper: PropTypes.shape({
    key: numberOrStringType,
    label: PropTypes.string,
    type: PropTypes.string,
    isCinched: PropTypes.bool,
  }),
  lower: PropTypes.shape({
    key: numberOrStringType,
    label: PropTypes.string,
    type: PropTypes.string,
    isCinched: PropTypes.bool,
  }),
});

export const wireBendType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  startGapName: PropTypes.string,
  endGapName: PropTypes.string,
  amount: PropTypes.string,
});

export const chainType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  startToothName: PropTypes.string,
  endToothName: PropTypes.string,
});

export const elasticType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  teeth: PropTypes.arrayOf(PropTypes.string),
});

export const interproximalReductionType = PropTypes.shape({
  key: numberOrStringType,
  reduceNumber: numberOrStringType,
  gapName: PropTypes.string,
});

export const stopType = PropTypes.shape({
  key: numberOrStringType,
  gapName: PropTypes.string,
});

export const dentalButtonType = PropTypes.shape({
  key: numberOrStringType,
  toothName: PropTypes.string,
  placement: PropTypes.string,
});

export const closedCoilType = PropTypes.shape({
  key: numberOrStringType,
  length: PropTypes.string,
  startToothName: PropTypes.string,
  endToothName: PropTypes.string,
});

export const openCoilType = PropTypes.shape({
  key: numberOrStringType,
  startToothName: PropTypes.string,
  endToothName: PropTypes.string,
});

export const planType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const optionType = PropTypes.shape({
  key: numberOrStringType,
  value: numberOrStringType,
  label: PropTypes.string,
});

export const tableColumnType = PropTypes.shape({
  name: PropTypes.string,
  style: styleType,
});

export const notificationType = PropTypes.shape({
  key: PropTypes.string,
  patient: patientType,
  public_text: PropTypes.string,
  private_text: PropTypes.string,
  // TODO: add additional notif types here
});

export const caseType = PropTypes.shape({
  // TODO: init case type
});

export const treatmentPlanType = PropTypes.shape({
  // TODO: init
});

export const userType = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
});

// This is react-router-dom history object.
// Please refer to docs to see the content of it.
export const historyType = PropTypes.object; // eslint-disable-line react/forbid-prop-types

export const addressType = PropTypes.shape({
  country_code: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
  zip_code: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string,
});

export const organizationType = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  addresses: PropTypes.shape({
    billing_address: addressType,
    physical_address: addressType,
    shipping_address: addressType,
  }),
  // TODO: init
});

export const bondBracketsType = PropTypes.shape({
  entireUpperArch: PropTypes.bool,
  entireLowerArch: PropTypes.bool,
  includeTeeth: PropTypes.arrayOf(toothType),
  excludeTeeth: PropTypes.arrayOf(toothType),
});

export const bondBiteTurbosType = PropTypes.shape({
  entireUpperArch: PropTypes.bool,
  entireLowerArch: PropTypes.bool,
  includeTeeth: PropTypes.arrayOf(toothType),
  excludeTeeth: PropTypes.arrayOf(toothType),
});

export const executionPlanStepType = PropTypes.shape({
  key: PropTypes.string,
  wires: wireType,
  buttons: PropTypes.arrayOf(dentalButtonType),
  wireBends: PropTypes.arrayOf(wireBendType),
  elastics: PropTypes.arrayOf(elasticType),
  chains: PropTypes.arrayOf(chainType),
  closedCoils: PropTypes.arrayOf(closedCoilType),
  openCoils: PropTypes.arrayOf(openCoilType),
  interproximalReductions: PropTypes.arrayOf(interproximalReductionType),
  stops: PropTypes.arrayOf(stopType),
  additionalNotes: PropTypes.string,
  requestUpdatedRecords: PropTypes.bool,
  bondBrackets: PropTypes.bool,
  debondBrackets: PropTypes.bool,
  bondBiteTurbos: PropTypes.bool,
  adjustBiteTurbos: PropTypes.bool,
  debondBiteTurbos: PropTypes.bool,
  requestRetainerImpressions: PropTypes.bool,
});
