import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import VideoPlayer from './VideoPlayer';
import './VideoDocuments.css';
import { downloadFile } from '../../utils/utils';
import { documentType, numberOrStringType } from '../../utils/types';
import DeleteButton from '../views/DeleteButton';

const buttonVisibleStyle = {
  visibility: 'visible',
  display: 'block',
  position: 'absolute',
  right: 4,
  top: 4,
};

function DownloadButton({ orgId, document }) {
  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        setLoading(true);
        await downloadFile(document, orgId);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [document, orgId],
  );

  return (
    <button
      type="button"
      disabled={loading}
      className={classNames('VideoDocuments__hoverIcon button is-small is-light', {
        'is-loading': loading,
      })}
      style={loading ? buttonVisibleStyle : undefined}
      onClick={handleDownload}
    >
      <FontAwesomeIcon icon="download" size="sm" />
    </button>
  );
}

DownloadButton.propTypes = {
  orgId: numberOrStringType.isRequired,
  document: documentType.isRequired,
};

function VideoDocuments({ orgId, documents, isEditing, onDelete }) {
  const handleDelete = useCallback(
    (event, document) => {
      event.stopPropagation();
      onDelete(document);
    },
    [onDelete],
  );

  return (
    <div className="VideoDocuments__container">
      {documents.map((document) => (
        <div key={document.id} className="pb-3 pr-3">
          <div
            className={classNames({
              VideoDocuments__videoContainer: !isEditing,
              'VideoDocuments__videoContainer-editing': isEditing,
            })}
          >
            {isEditing && onDelete != null && (
              <DeleteButton
                style={{ zIndex: 2 }}
                onClick={(event) => handleDelete(event, document)}
              />
            )}
            <VideoPlayer src={document.url} type={document.mimetype} />
            {!isEditing && <DownloadButton orgId={orgId} document={document} />}
          </div>
        </div>
      ))}
    </div>
  );
}

VideoDocuments.defaultProps = {
  isEditing: false,
  onDelete: undefined,
};

VideoDocuments.propTypes = {
  orgId: numberOrStringType.isRequired,
  documents: PropTypes.arrayOf(documentType).isRequired,
  isEditing: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default VideoDocuments;
