import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import DeleteButton from './DeleteButton';
import { downloadFile, getModelDocumentTypeByExtension } from '../../utils/utils';
import { FILE_TYPE_CONFIGS } from '../../configs/constants';
import { documentType } from '../../utils/types';
import navigation from '../../utils/navigation';
import auth from '../../utils/auth';
import VideoDocument from '../components/VideoDocument';
import { useVisitConfig } from '../../utils/contexts';

function Document({
  orgId,
  patientKey,
  document,
  size,
  isEditing,
  disableModelViewerNavigation,
  onDelete,
  onClick,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const configs = useVisitConfig();
  const [downloading, setDownloading] = useState(false);
  const { openDocumentInNewWindow = false } = configs ?? {};
  const orgKey = auth.orgInfo.key;
  const isVideo = FILE_TYPE_CONFIGS.isFileAcceptedAsVideo(document.mimetype);

  const handleClick = useCallback(() => {
    onClick(document);
  }, [onClick, document]);

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete(document);
    },
    [onDelete, document],
  );

  const DisplayContent = isVideo ? (
    <VideoDocument document={document} />
  ) : (
    <FontAwesomeIcon
      icon={FILE_TYPE_CONFIGS.getFileTypeIcon(document.mimetype)}
      size={size ?? '6x'}
    />
  );

  const handleDocumentClick = (event) => {
    event.stopPropagation();
    const url = navigation.pages.MODEL_VIEWER_VIEW.getUrl(
      orgKey,
      document.organization,
      patientKey,
      document.visit,
      document.id,
    );

    if (openDocumentInNewWindow) {
      window.open(url, '_blank', 'height=200;width=200;');
    } else {
      history.push(url);
    }
  };

  const handleDownload = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        setDownloading(true);
        await downloadFile(document, orgId);
      } catch (error) {
        console.error(error);
      } finally {
        setDownloading(false);
      }
    },
    [document, orgId],
  );

  return (
    <div
      key={document.url}
      tabIndex={0}
      role="button"
      className="Visit__document mr-3 mb-3"
      title={document.name}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {isEditing && <DeleteButton onClick={handleDelete} />}
      {disableModelViewerNavigation === false &&
        !isEditing &&
        getModelDocumentTypeByExtension(document.name) != null && (
          <div
            style={{
              position: 'absolute',
              top: 8,
              marginLeft: -8,
            }}
          >
            <button
              type="button"
              className="button is-small is-success"
              onClick={handleDocumentClick}
            >
              {t('view-three-d')}
            </button>
          </div>
        )}
      {DisplayContent}
      {!isEditing && (
        <div
          className="Visit__hover_icon"
          style={downloading ? { display: 'inline-block' } : undefined}
        >
          <button
            type="button"
            className={classNames('button is-small is-light', { 'is-loading': downloading })}
            onClick={handleDownload}
          >
            <FontAwesomeIcon icon="download" size="sm" />
          </button>
        </div>
      )}
      <div data-tooltip={document.name} className="has-tooltip-arrow has-tooltip-bottom">
        <span
          className="Visit__document_name"
          style={{ paddingTop: 5, fontSize: '1em', width: isVideo ? 96 : 60 }}
        >
          {document.name}
        </span>
      </div>
    </div>
  );
}

Document.defaultProps = {
  isEditing: false,
  size: undefined,
  disableModelViewerNavigation: false,
  onDelete: () => {},
  onClick: () => {},
};

Document.propTypes = {
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  patientKey: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  size: PropTypes.string,
  disableModelViewerNavigation: PropTypes.bool,
  document: documentType.isRequired,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

export default Document;
