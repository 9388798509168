import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { displayModal } from './Modal';

const modalCardStyle = {
  backgroundColor: 'white',
  padding: '1rem',
  width: 400,
  maxWidth: '100%',
};
const CustomConfirmModalContent = ({
  message,
  cancelText,
  okText,
  okButtonClassName,
  onOk,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    try {
      const res = onOk();
      const isPromise = res instanceof Promise;
      if (isPromise) {
        setLoading(true);
        await res;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <div className="is-size-5">{message}</div>
      <div className="level mt-4">
        <div className="level-left" />
        <div className="level-right">
          <button
            type="button"
            data-test="confirm-modal-cancel-button"
            className="button mr-2"
            onClick={() => {
              onClose();
              if (typeof onCancel === 'function') {
                onCancel();
              }
            }}
          >
            {t(cancelText)}
          </button>
          <button
            type="button"
            data-test="confirm-modal-ok-button"
            disabled={loading}
            className={classNames('button', {
              [okButtonClassName]: okButtonClassName != null,
              'is-loading': loading,
            })}
            onClick={handleOk}
          >
            {t(okText)}
          </button>
        </div>
      </div>
    </div>
  );
};

CustomConfirmModalContent.defaultProps = {
  cancelText: 'cancel',
  okText: 'ok',
  okButtonClassName: 'is-info',
  onCancel: undefined,
  onOk: undefined,
  onClose: () => {},
};

CustomConfirmModalContent.propTypes = {
  message: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  okButtonClassName: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export const modal = {
  confirm: (message, onOk, additionalProps = {}) => {
    const { okButtonClassName, okText, closeText, onCancel } = additionalProps;
    displayModal(
      undefined,
      undefined,
      undefined,
      <CustomConfirmModalContent
        message={message}
        closeText={closeText}
        okText={okText}
        okButtonClassName={okButtonClassName}
        onOk={onOk}
        onCancel={onCancel}
      />,
      { modalCardStyle },
    );
  },
};

export default CustomConfirmModalContent;
