import {
  LANDMARK_COLOR,
  LANDMARK_TYPE_AUTO,
  LANDMARK_TYPE_CONSTRUCTED,
  LANDMARK_TYPE_REGULAR,
  LANDMARK_TYPE_SURGERY_ADJUSTED,
  PLANE_COLOR,
  PLANE_EXTENT,
  PLANE_EXTENTS,
} from './constants';
import {
  ANNOTATION_CURVE,
  CONSTRUCTED_LANDMARKS,
  defaultAnalysisStructure,
  POINT_CONTROL,
  POINT_LANDMARK,
  SEGMENTS,
} from '../../analyses';
import cephLogic from './cephLogic';
import Phi from '../phi/Phi';

export const getCurrentAnalysis = (analyses, analysisKey, imageLabel) => {
  return (
    analyses.common?.[analysisKey] ??
    analyses.org?.[analysisKey] ??
    defaultAnalysisStructure[imageLabel]
  );
};
// convert relative image coordinates (0..1) to uniform image coordinates
// (i.e., where a pixel has an equal width and height, which would not
// be true of the relative coordinates if the image is not square).
export const uniformCoordinate = (relativeCoord, width, height) =>
  relativeCoord ? [relativeCoord[0] * width, relativeCoord[1] * height] : null;

export const unUniformCoordinate = (uniformCoord, width, height) =>
  uniformCoord ? [uniformCoord[0] / width, uniformCoord[1] / height] : null;

export const selectedControlPoint = (items) =>
  items && items.type === POINT_CONTROL ? items.value : null;

export const analysisLandmarks = (analyses, analysis, imageLabel) =>
  getCurrentAnalysis(analyses, analysis, imageLabel)?.landmarks ?? [];

export const analysisConstructedLandmarks = (analyses, selectionAnalysis, imageLabel) =>
  getCurrentAnalysis(analyses, selectionAnalysis, imageLabel)?.visibleConstructedLandmarks ?? [];

// get the position and type of a landmark named `name`.
// note that providing `constructedLandmarks` object is unnecessary if not
// looking up a constructed landmark (e.g., "Xi").
export const getLandmarkInfo = (state, name, analyses, analysis, constructedLandmarks = {}) => {
  if (
    analysisConstructedLandmarks(analyses, analysis, state.cephData.image?.label).includes(name)
  ) {
    return {
      ...constructedLandmarks[name],
      type: LANDMARK_TYPE_CONSTRUCTED,
    };
  }
  // this is used in STO page, in AnalysisView we are counting on not having adjustedLandmarks in cephData
  if (
    state.cephData.surgicalTreatment.surgeryFinalized &&
    state.cephData.surgicalTreatment.adjustedLandmarks?.[name]?.position
  ) {
    return {
      ...state.cephData.surgicalTreatment.adjustedLandmarks[name],
      type: LANDMARK_TYPE_SURGERY_ADJUSTED,
    };
  }
  if (state.cephData.landmarks[name] && state.cephData.landmarks[name].position) {
    return {
      ...state.cephData.landmarks[name],
      type: LANDMARK_TYPE_REGULAR,
    };
  }
  if (state.cephData.visibility.showAutoLandmarks) {
    if (
      state.cephData.deletedAutoLandmarks[name] !== true &&
      state.cephData.autoLandmarks[name] &&
      state.cephData.autoLandmarks[name].position
    ) {
      return {
        ...state.cephData.autoLandmarks[name],
        type: LANDMARK_TYPE_AUTO,
      };
    }
  }

  return {};
};

// see: https://github.com/paperjs/paper.js/pull/1563
function clearGlobalMatrix(item) {
  // this function mutates paper object in order to position the object correctly
  item._globalMatrix = undefined; // eslint-disable-line no-underscore-dangle, no-param-reassign
  if (item.parent) {
    clearGlobalMatrix(item.parent);
  }
}

// convert image coordinate to paper js coordinates
export const posImageToGlobal = (
  refOriginalImage,
  imageTransformations,
  coord,
  paper,
  instanceId,
) => {
  const image = paper.project.getItem({ name: 'image', instanceId });
  if (refOriginalImage != null && image !== null && coord) {
    let c = new paper.Point(coord);

    // absolute coordinates
    c.x *= refOriginalImage.width;
    c.y *= refOriginalImage.height;

    // center
    c.x -= refOriginalImage.width / 2;
    c.y -= refOriginalImage.height / 2;

    // scale
    c.x *= imageTransformations.scale_x || 1;
    c.y *= imageTransformations.scale_y || 1;

    // rotate
    c = c.rotate(imageTransformations.rotate || 0);

    // un-center
    c.x += refOriginalImage.width / 2;
    c.y += refOriginalImage.height / 2;

    // get original image origin
    const p = image.bounds.topLeft.clone();
    p.x -= refOriginalImage.x;
    p.y -= refOriginalImage.y;

    // place
    p.x += c.x;
    p.y += c.y;

    clearGlobalMatrix(image);
    return image.localToGlobal(p);
  }
  return coord;
};

export const posGlobalToImage = (
  refOriginalImage,
  paper,
  imageTransformations,
  coord,
  instanceId,
) => {
  const image = paper.project.getItem({ name: 'image', instanceId });
  if (refOriginalImage != null && image !== null && coord) {
    clearGlobalMatrix(image);
    let c = image.globalToLocal(coord);

    // get original image origin
    const p = image.bounds.topLeft.clone();
    p.x -= refOriginalImage.x;
    p.y -= refOriginalImage.y;

    // get point relative to original image origin
    c.x -= p.x;
    c.y -= p.y;

    // center
    c.x -= refOriginalImage.width / 2;
    c.y -= refOriginalImage.height / 2;

    // un-rotate
    c = c.rotate(-imageTransformations.rotate || 0);

    // un-scale
    c.x /= imageTransformations.scale_x || 1;
    c.y /= imageTransformations.scale_y || 1;

    // un-center
    c.x += refOriginalImage.width / 2;
    c.y += refOriginalImage.height / 2;

    // relative coordinates
    c.x /= refOriginalImage.width;
    c.y /= refOriginalImage.height;

    return c;
  }
  return coord;
};

// Split curve definitions into overlapping sections of curves with
// exactly two landmarks, if possible, or at least one.
// c1 c2 L1 c3 c4 L2 c5 L3 c6  -->  c1 c2 L1 c3 c4 L2  /  L2 c5 L3 c6
// or, if closed:
// c1 c2 L1 c3 c4 L2 c5 L3 c6  -->  L1 c3 c4 L2  /  L2 c5 L3 c6 c1 c2 L1

const splitCurve = (definition, isClosed = false) => {
  const sections = [];
  let lastLandmarkIndex = null;
  let startIndex = 0;
  definition.forEach((point, i) => {
    if (point.type === POINT_LANDMARK) {
      if (lastLandmarkIndex !== null) {
        // add section
        sections.push({
          curveSectionDefinition: definition.slice(startIndex, i + 1),
        });
        startIndex = i;
      }
      lastLandmarkIndex = i;
    }
  });

  if (startIndex < definition.length - 1) {
    const lastBit = definition.slice(startIndex);
    if (sections.length > 0) {
      if (isClosed) {
        // get index of first landmark in definition
        const firstLM = definition.findIndex((x) => x.type === POINT_LANDMARK);

        // get prefix of definition up through and including first landmark
        const firstBit = definition.slice(0, firstLM + 1);

        // add the prefix to the end of the curve
        sections.push({
          curveSectionDefinition: lastBit.concat(firstBit),
        });

        // remove the prefix control points (if any) from start of curve
        sections[0].curveSectionDefinition = sections[0].curveSectionDefinition.slice(firstLM);
      } else {
        // get last section
        const firstBit = sections[sections.length - 1].curveSectionDefinition;

        // add remaining control points to last section (avoiding duplicating shared landmark)
        firstBit.pop();
        sections[sections.length - 1].curveSectionDefinition = firstBit.concat(lastBit);
      }
    } else {
      // add the entirety of the definition to the (only) section
      sections.push({
        curveSectionDefinition: lastBit,
      });
    }
  }
  return sections;
};

// Index curve definitions
export const CURVE_SECTIONS_FOR_CURVE = {};
export const INDEXED_CURVE_SECTION_DEFINITIONS = {};
export const CURVE_SECTIONS_BY_CONTROL_POINT = {};
// const CONTROL_POINT_INDICES = {};
Object.keys(SEGMENTS).forEach((name) => {
  const annotationInfo = SEGMENTS[name];
  if (annotationInfo.type === ANNOTATION_CURVE) {
    // // index control point indicies for this curve, by name
    // CONTROL_POINT_INDICES[name] = {};

    // split curve
    const curveSections = splitCurve(annotationInfo.definition, annotationInfo.closed);
    CURVE_SECTIONS_FOR_CURVE[name] = curveSections;

    // index curve setions
    curveSections.forEach((curveSection, i) => {
      const { curveSectionDefinition } = curveSection;

      // name for this section of the curve
      const curveSectionName = `${name}-${i}`;

      // names of (up to two) landmarks per curve section
      const landmarks = [];
      const landmarkIndices = [];

      curveSectionDefinition.forEach((pointInfo, j) => {
        if (pointInfo.type === POINT_CONTROL) {
          // record name of section per control point
          CURVE_SECTIONS_BY_CONTROL_POINT[pointInfo.name] = curveSectionName;
        } else if (pointInfo.type === POINT_LANDMARK) {
          // record list of landmarks in this curve section
          landmarks.push(pointInfo.name);
          landmarkIndices.push(j);
        }
      });

      // record curve section info
      INDEXED_CURVE_SECTION_DEFINITIONS[curveSectionName] = {
        curveSectionDefinition,
        curveName: name,
        curveSectionName,
        landmarks,
        landmarkIndices,
      };
    });
  }
});

export const makeLandmark = (paper, name, type = POINT_LANDMARK, instanceId) => {
  const a = new paper.Path.Circle({
    center: [0, 0],
    radius: 10,
    strokeWidth: 0.75,
    strokeColor: 'rgba(0, 0, 0, 0.001)',
    fillColor: 'rgba(0, 0, 0, 0.001)',
    opacity: 0.6,
    landmarkName: name, // custom param (for hitTest matching)
    instanceId,
    landmarkType: type, // custom param (for hitTest matching)
    targetSize: 'big', // custom param (for hitTest matching)
  });

  const b = new paper.Path.Circle({
    center: [0, 0],
    radius: 2,
    strokeWidth: 0.5,
    strokeColor: '#000000',
    fillColor: LANDMARK_COLOR,
    landmarkName: name, // custom param (for hitTest matching)
    instanceId, // custom param (for hitTest matching)
    landmarkType: type, // custom param (for hitTest matching)
    targetSize: 'small', // custom param (for hitTest matching)
  });

  const landmarkGroup = new paper.Group({
    children: [a, b],
    name,
    instanceId,
  });

  // add reference for small (visible) circle
  landmarkGroup.smallCircle = b;

  return landmarkGroup;
};

// all the points when clicked and sort by distance
// get closest point
export const landmarkHitTest = (paper, point, smallOnly = false) => {
  // test small target first
  const hitResultSmall = paper.project.hitTest(point, {
    fill: true,
    stroke: true,
    match: (o) => o.item.landmarkName !== undefined && o.item.targetSize === 'small',
  });
  if (hitResultSmall || smallOnly) return hitResultSmall;

  // test larger target
  const hitResultLarge = paper.project.hitTestAll(point, {
    fill: true,
    stroke: true,
    match: (o) => o.item.landmarkName !== undefined && o.item.targetSize === 'big',
  });
  if (!hitResultLarge || hitResultLarge.length === 0) return null;

  // sort results by distance to center
  hitResultLarge.sort((a, b) => {
    const da = point.getDistance(a.item.bounds.center);
    const db = point.getDistance(b.item.bounds.center);
    return da - db;
  });

  // return closest selection
  return hitResultLarge[0];
};

export const makePlane = (paper, name, instanceId) => {
  const lineItem = new paper.Path.Line({
    from: [100, 100],
    to: [200, 200],
    strokeWidth: 1,
    strokeColor: PLANE_COLOR,
    // name,
    locked: true,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowBlur: 1.0,
    shadowOffset: [0, 0],
  });

  const c1 = new paper.Path.Circle({
    center: [0, 0],
    radius: 5,
    locked: true,
    visible: true,
    strokeWidth: 1,
    strokeColor: PLANE_COLOR,
    shadowColor: 'rgba(0, 0, 0, 0.7)',
    shadowBlur: 1.0,
    shadowOffset: [0, 0],
  });

  const c2 = new paper.Path.Circle({
    center: [0, 0],
    radius: 5,
    locked: true,
    visible: true,
    strokeWidth: 1,
    strokeColor: PLANE_COLOR,
    shadowColor: 'rgba(0, 0, 0, 0.7)',
    shadowBlur: 1.0,
    shadowOffset: [0, 0],
  });

  const planeGroup = new paper.Group({
    children: [lineItem, c1, c2],
    // children: [lineItem],
    name,
    instanceId,
  });

  const extent = PLANE_EXTENTS[name] || PLANE_EXTENT;

  // add custom method to position line.
  planeGroup.positionPlane = (point1, point2) => {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);

    // position circles
    c1.setPosition(p1);
    c2.setPosition(p2);

    // compute end points, given extent
    const pd = p1.subtract(p2);

    if (pd.x === 0 && pd.y === 0) {
      lineItem.visible = false;
    } else {
      lineItem.visible = true;

      const d = Math.sqrt(pd.x ** 2 + pd.y ** 2);
      const r = extent / d;

      lineItem.segments[0].point = p1.add(pd.multiply(r));
      lineItem.segments[1].point = p2.subtract(pd.multiply(r));
    }
  };

  return planeGroup;
};

export const makeTooltip = (paper, name) => {
  const bgItem = new paper.Path.Rectangle({
    size: [40, 24],
    fillColor: 'rgba(0,0,0,0.6)',
    strokeColor: 'rgba(255,255,255,0.3)',
  });

  const textItem = new paper.PointText({
    point: [20, 14],
    justification: 'center',
    fillColor: 'white',
    content: '',
  });

  const tooltipGroup = new paper.Group({
    children: [bgItem, textItem],
    name,
    locked: true,
    visible: false,
  });

  // add reference to text and background
  tooltipGroup.textItem = textItem;
  tooltipGroup.bgItem = bgItem;

  // add this raster to image layer
  const tooltipLayer = paper.project.getItem({ name: 'tooltipLayer' });
  tooltipLayer.addChild(tooltipGroup);

  return tooltipGroup;
};

export const placeStructureSection = (
  paper,
  points,
  l1Position,
  l1Index,
  l2Position = null,
  l2Index = null,
) => {
  const p1 = new paper.Point(points[l1Index]);
  const p1Target = new paper.Point(l1Position);
  let p2 = null;
  let p2Target = null;

  if (l2Position !== null && l2Index !== null) {
    p2 = new paper.Point(points[l2Index]);
    p2Target = new paper.Point(l2Position);
  }

  const orig = {
    angle: p2 !== null ? p2.subtract(p1).angle : 0,
    distance: p2 !== null ? p2.getDistance(p1) : 0,
    offset: p1,
  };

  const target = {
    angle: p2 !== null ? p2Target.subtract(p1Target).angle : 0,
    distance: p2 !== null ? p2Target.getDistance(p1Target) : 0,
    offset: p1Target,
  };

  const result = points.map((p) => {
    let newP = new paper.Point(p);
    newP = newP.subtract(orig.offset);
    newP.angle += target.angle - orig.angle;
    if (orig.distance !== 0) {
      newP = newP.multiply(target.distance / orig.distance);
    }
    newP = newP.add(target.offset);
    return newP;
  });

  return result;
};

// compute the positions for constructed landmarks for given analysis
export const constructLandmarks = (state, analyses, analysis) => {
  const imageWidth = state.cephData.image && state.cephData.image.width;
  const imageHeight = state.cephData.image && state.cephData.image.height;
  const imageLabel = state.cephData.image?.label;

  if (!imageWidth || !imageHeight) return {};

  // get list of names of constructed landmarks for current analysis
  const analysisAllConstructedLandmarks =
    getCurrentAnalysis(analyses, analysis, imageLabel)?.constructedLandmarks || [];

  const results = {};

  // Variables accessible to cephLogic/jsonLogic formulas
  const vars = {};

  // convert landmarks' relative coordinates into a uniform coordinate system
  // and add them to the available vars.
  // Note that we must merge with auto-landmarks (and regular landmarks override
  // auto-landmarks).
  let landmarkLists = [];
  if (state.cephData.visibility.showAutoLandmarks) {
    landmarkLists = [
      state.cephData.autoLandmarks,
      state.cephData.landmarks,
      state.cephData.surgicalTreatment.adjustedLandmarks,
    ];
  } else {
    landmarkLists = [state.cephData.landmarks];
  }

  landmarkLists.forEach((lm) => {
    Object.keys(lm).forEach((k) => {
      if (lm[k] && lm[k].position) {
        vars[k] = uniformCoordinate(
          lm[k] && lm[k].position,
          state.cephData.image.width,
          state.cephData.image.height,
        );
      }
    });
  });

  // add constructed landmarks.
  // coordinates should be stored as image-relative coordinates,
  // but get converted to uniform coordinates for computation.
  analysisAllConstructedLandmarks.forEach((k) => {
    if (CONSTRUCTED_LANDMARKS[k]) {
      let logic = CONSTRUCTED_LANDMARKS[k];
      if (logic?.code) {
        // constructed landmarks parsed with using ceph grammar
        logic = Phi.parse(logic.code);
      }
      // check that formula exists for this one
      results[k] = {
        position: unUniformCoordinate(
          cephLogic.execute(logic, vars), // compute position from formula
          imageWidth,
          imageHeight,
        ),
      };
    }
  });

  return results;
};

export function getAllLandmarks(allAnalyses, selectedAnalysis, imageLabel) {
  const currentAnalysis = getCurrentAnalysis(allAnalyses, selectedAnalysis, imageLabel) ?? {};
  const regularLandmarks = currentAnalysis.landmarks || [];
  const constructedLandmarks = currentAnalysis.visibleConstructedLandmarks || [];
  const allLandmarks = regularLandmarks.concat(constructedLandmarks);
  return allLandmarks;
}

export function isLandmarkPlaced(analyses, selectedAnalysis, cephData, selectedLandmark) {
  const isPlaced =
    (cephData.landmarks[selectedLandmark] && cephData.landmarks[selectedLandmark].position) ||
    (cephData.visibility.showAutoLandmarks &&
      cephData.deletedAutoLandmarks[selectedLandmark] !== true &&
      cephData.autoLandmarks[selectedLandmark] &&
      cephData.autoLandmarks[selectedLandmark].position) ||
    analysisConstructedLandmarks(analyses, selectedAnalysis, cephData.image?.label)?.includes(
      selectedLandmark,
    );
  return isPlaced;
}

export function findNotPlacedRulerPoints(allAnalyses, selectedAnalysis, cephData) {
  return getAllLandmarks(allAnalyses, selectedAnalysis, cephData.image?.label).reduce(
    (acc, landmarkKey) => {
      const { isOnlyRulerPoints } = acc;
      // this means it found some other landmark other than ruler points that is not placed
      if (isOnlyRulerPoints === false) {
        return acc;
      }
      if (isLandmarkPlaced(allAnalyses, selectedAnalysis, cephData, landmarkKey) === false) {
        if (['Ruler1', 'Ruler2'].includes(landmarkKey)) {
          if (isOnlyRulerPoints == null || isOnlyRulerPoints === true) {
            acc.notPlacedRulerPoints.push(landmarkKey);
            acc.isOnlyRulerPoints = true; // eslint-disable-line no-param-reassign
            return acc;
          }
          return acc;
        }
        acc.isOnlyRulerPoints = false; // eslint-disable-line no-param-reassign
        return acc;
      }
      return acc;
    },
    { isOnlyRulerPoints: null, notPlacedRulerPoints: [] },
  );
}

// if nothing is focused or canvas is focused or not input element is focused return true
// since we want normal functionality of key events in input (e.g ctrl + z)
export const isCephComponentFocused = (cephViewRef) => {
  return (
    document.activeElement == null ||
    document.activeElement === cephViewRef.current ||
    document.activeElement.tagName !== 'INPUT'
  );
};

// Returns display name of the gap with the given key
// UR12 -> UR1 & 2
export const getTitleOfGap = (gapKey) => {
  if (typeof gapKey !== 'string') {
    return '';
  }

  // this means the gap between middle teeth like U11 or L11
  if (gapKey.length === 3) {
    return `${gapKey[0]}R${gapKey[1]} & ${gapKey[0]}L${gapKey[2]}`;
  }
  if (gapKey.length === 4) {
    const beginning = gapKey.slice(0, 2);
    return `${beginning}${gapKey[2]} & ${gapKey[3]}`;
  }
  return gapKey;
};

const gapNameRegex = /^\s*(U|L)\s*(R|L)?\s*([1-8])[\s&,ULR-]*([1-8])\s*$/i;
export const getKeyOfGap = (val) => {
  if (typeof val !== 'string') return val;
  // UL3 5
  const result = val.match(gapNameRegex);
  if (result == null) {
    return val;
  }

  const [, arch, side, firstToothString, secondToothString] = result;
  const firstTooth = Number(firstToothString);
  const secondTooth = Number(secondToothString);
  let parsedValue = val;

  const diff = firstTooth - secondTooth;
  if (firstTooth === 1 && secondTooth === 1) {
    parsedValue = `${arch.toUpperCase()}11`;
  } else if (side != null && (diff === 1 || diff === -1)) {
    parsedValue = `${arch.toUpperCase()}${side.toUpperCase()}${Math.min(
      firstTooth,
      secondTooth,
    )}${Math.max(firstTooth, secondTooth)}`;
  }

  return parsedValue;
};

export function getTooth(adultTeeth, name) {
  return adultTeeth.children.find((child) => child.name === name);
}
