import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function FormikErrorMessage({ name }) {
  const { errors } = useFormikContext() ?? {};
  return <div className="has-text-danger">{errors[name]}</div>;
}

FormikErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

const FormikFieldContainer = React.forwardRef(
  (
    {
      title,
      required,
      name,
      style,
      className,
      childElementStyle,
      noIndent,
      children,
      removeErrorField,
      shouldUseDivInErrorMessage,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div
        ref={ref}
        className={classNames('field', { [className]: className != null })}
        style={style}
      >
        <label className="label">
          {required && (
            <span className="has-text-danger" title={t('required-field')}>
              *
            </span>
          )}
          {title}
        </label>
        <div className="control">
          <div
            className="control is-expanded"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: noIndent ? 'initial' : '1em',
              ...childElementStyle,
            }}
          >
            {children}
          </div>
          <div style={{ marginLeft: noIndent ? 'initial' : '1em' }}>
            {/* Some cases ErrorMessage component of the Formik does not work */}
            {/* In that case we need to explicitly display the error message like below */}
            {shouldUseDivInErrorMessage === true && removeErrorField !== true && required && (
              <FormikErrorMessage name={name} />
            )}
            {shouldUseDivInErrorMessage === false && removeErrorField !== true && required && (
              <ErrorMessage className="has-text-danger" name={name} component="div" />
            )}
          </div>
        </div>
      </div>
    );
  },
);

FormikFieldContainer.defaultProps = {
  className: undefined,
  required: false,
  noIndent: false,
  shouldUseDivInErrorMessage: false,
  name: undefined,
  style: undefined,
  childElementStyle: {},
  title: undefined,
  removeErrorField: false,
};

FormikFieldContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  noIndent: PropTypes.bool,
  required: PropTypes.bool,
  removeErrorField: PropTypes.bool,
  shouldUseDivInErrorMessage: PropTypes.bool,
  name: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  childElementStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
};

export default FormikFieldContainer;
