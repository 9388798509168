import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import auth from './common/utils/auth';
import { usePrevious } from './common/utils/hooks';
import api from './common/utils/api';

import './App.css';
import AppOrthoDx from './App.orthodx';
import AppOrthoTx from './App.orthotx';
import { isOrthoTx } from './common/configs/constants';
import { loadDataCallback } from './common/ui/views/LoadDataCallback';

import './common/configs/icons';
import navigation from './common/utils/navigation';

function useRenewSession(history, isLoadingAuth, setIsRenewingSession) {
  // on startup, re-authenticate if possible
  useEffect(() => {
    // wait for auth to load because auth0 instance is asynchronous
    if (isLoadingAuth) return;

    const authenticate = async () => {
      if (window.localStorage.getItem('isLoggedIn') === 'true') {
        await auth.renewSession(
          history,
          (err) => {
            // redirect to patient list page only if location pathname is root
            // when user is in different page and refreshes the pages that action will also runs this code
            // and we don't want to redirect user to patient list page instead user needs to stay where he/she is
            if (err == null && history.location.pathname === '/') {
              history.replace({
                pathname: navigation.pages.PATIENT_LIST_VIEW.getUrl(auth.orgInfo.key),
              });
            }
            setIsRenewingSession(false);
          },
          loadDataCallback,
        );
      } else {
        setIsRenewingSession(false);
      }
    };

    authenticate();
  }, [history, isLoadingAuth, setIsRenewingSession]);
}

function useCreateAuth0(setIsLoadingAuth) {
  useEffect(() => {
    const createAuth = async () => {
      try {
        await auth.createAuth0();
      } catch (error) {
        console.error(error);
        api.error('useCreateAuth0', 'unable to create auth0', { error });
        // TODO: add message
      } finally {
        setIsLoadingAuth(false);
      }
    };

    createAuth();
  }, [setIsLoadingAuth]);
}

// See: https://github.com/ReactTraining/react-router/issues/4105 for
//      difference between <Route component /> vs <Route render />
const App = ({ location, history }) => {
  const { i18n } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRenewingSession, setIsRenewingSession] = useState(true);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  // We are exposing history object because in cypress, we need history object to navigate between pages.
  // `window.Cypress` should evaluate to true only when cypress is running.
  if (window.Cypress || process.env.NODE_ENV === 'development') {
    // We are allowing dangling here to make this variable private looking.
    // eslint-disable-next-line no-underscore-dangle
    window.__reactRouterHistory = history;
  }

  useCreateAuth0(setIsLoadingAuth);
  useRenewSession(history, isLoadingAuth, setIsRenewingSession);

  // on startup, log info about client environment
  useEffect(() => {
    api.info('App', 'environment', {
      screenSize: { width: window.screen.width, height: window.screen.height },
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      devicePixelRatio: window.devicePixelRatio,
      userAgent: window.navigator.userAgent,
      language: i18n && i18n.language,
    });
  }, [i18n]);

  // on location.path change, send event to google analytics
  const { pathname } = location;
  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
      // 'page_title' : 'homepage',  // TODO: change pages titles and record
      page_path: pathname,
    });
  }, [pathname]);

  // log whenever pathname changes
  const previousPathname = usePrevious(location.pathname);
  if (previousPathname !== location.pathname) {
    api.info('App', 'route changed', { pathname: location.pathname });
  }

  // get notified of authentication changes to re-render
  auth.onAuthStateChange = (newIsAuthenticated) => {
    if (newIsAuthenticated) {
      const source = api.openNotificationConnection();
      if (process.env.NODE_ENV !== 'production') {
        source.onopen = () => {
          // eslint-disable-next-line no-console
          console.log('connection opened.');
        };
        source.onmessage = (event) => {
          // eslint-disable-next-line no-console
          console.log('received message', JSON.parse(event.data));
        };
      }
      source.onerror = (error) => {
        // eslint-disable-next-line no-console
        console.error('ERROR: EventSource failed.', error);
      };
    }
    setIsAuthenticated(newIsAuthenticated);
  };

  useEffect(() => {
    return () => {
      api.closeNotificationConnection();
    };
  }, []);

  return isOrthoTx ? (
    <AppOrthoTx
      history={history}
      location={location}
      isAuthenticated={isAuthenticated}
      isRenewingSession={isRenewingSession}
    />
  ) : (
    <AppOrthoDx
      history={history}
      location={location}
      isAuthenticated={isAuthenticated}
      isRenewingSession={isRenewingSession}
    />
  );
};

App.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
};

export default App;
