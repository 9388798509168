// see: http://jsonlogic.com/
import { PaperScope } from 'paper/dist/paper-core';
import i18n from 'i18next';
import { ANALYSIS_TABLE_ERRORS } from '../configs/constants';
import Phi from '../phi/Phi';

const paper = new PaperScope();

// translate
export const t = (key, parameters) => i18n.t(key, parameters);

// scale image-coordinates to millimeters
// point1 --> point2 is dist1 in mm; scale dist2
export const mm = (point1, point2, dist1, dist2) => {
  if (point1 && point2 && dist1 > 0 && dist2 > 0) {
    const d = Math.abs(new paper.Point(point1).getDistance(point2));
    const r = dist1 / d;
    return dist2 * r;
  }
  if (point1 == null || point2 == null || dist1 == null || dist1 === 0) {
    throw new Error(ANALYSIS_TABLE_ERRORS.RULERS_AND_CALIBRATION_REQUIRED);
  }
  return NaN;
};

// trig
export const sin = (angleInDegrees) => {
  const angle = angleInDegrees * (Math.PI / 180);
  return Math.sin(angle);
};

export const cos = (angleInDegrees) => {
  const angle = angleInDegrees * (Math.PI / 180);
  return Math.cos(angle);
};

// adds point1 and point2
export const vectorAdd = (point1, point2) => {
  if (point1 && point2) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const r = p1.add(p2);
    return [r.x, r.y];
  }
  return null;
};

// subtracts point2 from point1
export const vectorSubtract = (point1, point2) => {
  if (point1 && point2) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const r = p1.subtract(p2);
    return [r.x, r.y];
  }
  return null;
};

// Returns a point whose line through point1 is parallel
// to line point2 --> point3
export const parallel = (point1, point2, point3) => {
  if (point1 && point2 && point3) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);
    const r = p3.subtract(p2).add(p1);
    return [r.x, r.y];
  }
  return null;
};

// Returns a point whose line through point1 is perpendicular
// to line point2 --> point3
export const perpendicular = (point1, point2, point3) => {
  if (point1 && point2 && point3) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);

    // first rotate point 2 centered on point 3
    const p4 = p2.rotate(90, p3);

    // get the vector from p3 to p4
    const p5 = p4.subtract(p3);

    // add that vector to the reference point
    const r = p5.add(p1);

    return [r.x, r.y];
  }
  return null;
};

// distance from point1 to point2
export function dist(point1, point2) {
  if (point1 && point2) {
    const dist2 = new paper.Point(point1).getDistance(point2);
    return mm(this.Ruler1, this.Ruler2, this.calibration, dist2);
  }
  return NaN;
}

// Compute angle defined by four points:
// line1 = point1 --> point2
// line2 = point3 --> point4
// Imagine spokes on a wheel where both lines are pointing
// in the direction of the center. The angle between the
// two lines is returned. (NOTE: directionality matters!)
// Further, an angle is positive if its "spokes" are in
// clockwise order, and negative otherwise.
// The discontinuity (e.g., -180/+180) can be controlled by setting
// the `midpoint` parameter, which by default is 90, meaning angles
// will range from (-90 ... 270).
export const angle4 = (point1, point2, point3, point4, midpoint) => {
  const m = Math.max(-179.99, Math.min(179.99, midpoint === undefined ? 90 : midpoint));
  if (point1 && point2 && point3 && point4) {
    const a0 = new paper.Point(point1).subtract(point2).getAngle();
    const a1 = new paper.Point(point1)
      .subtract(point2)
      .rotate(-m - a0)
      .getAngle();
    const a2 = new paper.Point(point3)
      .subtract(point4)
      .rotate(-m - a0)
      .getAngle();
    return a2 - a1;
  }
  return NaN;
};

// angle going from point1 --> point2 --> point3
export const angle = (point1, point2, point3, midpoint) =>
  angle4(point1, point2, point3, point2, midpoint);

// rotate point1 by given degrees clockwise
// centered on point2
// to result in a new point, point1*.
export const rotate = (point1, point2, degrees) => {
  if (point1 && point2) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);

    // rotate p1 by given degrees centered on p2
    const p1Star = p1.rotate(degrees, p2);
    return [p1Star.x, p1Star.y];
  }
  return null;
};

// get the x coordinate for a point
export const x = (point1) => {
  if (point1) {
    const p1 = new paper.Point(point1);
    return p1.x;
  }
  return null;
};

// get the y coordinate for a point
export const y = (point1) => {
  if (point1) {
    const p1 = new paper.Point(point1);
    return p1.y;
  }
  return null;
};

// construct a new point from an x and y coordinate
export const point = (coordX, coordY) => {
  if (Number.isFinite(coordX) && Number.isFinite(coordY)) {
    return [coordX, coordY];
  }
  return null;
};

// Return the point where two lines intersect
// line1 = (point1 --> point2)
// line2 = (point3 --> point4)
export const intersection = (point1, point2, point3, point4) => {
  if (point1 && point2 && point3 && point4) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);
    const p4 = new paper.Point(point4);

    // Check if none of the lines are of length 0
    if ((p1.x === p2.x && p1.y === p2.y) || (p3.x === p4.x && p3.y === p4.y)) {
      return NaN;
    }

    const denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);

    // Lines are parallel
    if (denominator === 0) {
      return NaN;
    }

    const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator;

    // Return a object with the x and y coordinates of the intersection
    const resultX = p1.x + ua * (p2.x - p1.x);
    const resultY = p1.y + ua * (p2.y - p1.y);

    return [resultX, resultY];
  }
  return null;
};

// perpendicular distance from point1 to line point2 --> point3
export function normal(point1, point2, point3) {
  if (point1 && point2 && point3) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);
    const numerator = Math.abs(
      (p3.y - p2.y) * p1.x - (p3.x - p2.x) * p1.y + p3.x * p2.y - p3.y * p2.x,
    );
    const denominator = p2.getDistance(p3);
    const dist2 = numerator / denominator;
    return mm(this.Ruler1, this.Ruler2, this.calibration, dist2);
  }
  return NaN;
}

// returns point coordinates of point1 projected to line point2 --> point3
export const project = (point1, point2, point3) => {
  if (point1 && point2 && point3) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);

    const pr = p1.subtract(p2).project(p3.subtract(p2)).add(p2);
    return [pr.x, pr.y];
  }
  return null;
};

// determine what side of a line (point2 --> point3) a point, point1, is.
// Imagine point2 as the origin, increasing positively toward point3.
// This function returns the sign of point1: it is positive if point1 lies
// to the right of the line, and negative otherwise.
export const sign = (point1, point2, point3) => {
  if (point1 && point2 && point3) {
    const p1 = new paper.Point(point1);
    const p2 = new paper.Point(point2);
    const p3 = new paper.Point(point3);

    // in paperjs, angle 0 is along the x-axis, so we rotate to 0
    // and observe the sign on the y-axis.
    const a = -p3.subtract(p2).angle;
    return Math.sign(p1.subtract(p2).rotate(a).y);
  }
  return NaN;
};

// determine the sign (direction) of a pair of points, (point1 and point2)
// projected onto a line point3 --> point4 (in the direction of positivity)
export const sign4 = (point1, point2, point3, point4) => {
  if (point1 && point2 && point3 && point4) {
    const p3 = new paper.Point(point3);
    const p4 = new paper.Point(point4);

    // projected points onto line p3 --> p4
    const p1 = new paper.Point(project(point1, p3, p4));
    const p2 = new paper.Point(project(point2, p3, p4));

    return Math.sign(p2.subtract(p1).dot(p4.subtract(p3)));
  }
  return NaN;
};

export const meanByAge = (initialValue, age, ageStartsAt, difference) => {
  const MAX_GROW_AGE = 18;
  if (age == null) {
    throw new Error(ANALYSIS_TABLE_ERRORS.AGE_REQUIRED);
  }
  if (age <= ageStartsAt) return initialValue;
  return initialValue + Math.min(MAX_GROW_AGE - ageStartsAt, age - ageStartsAt) * difference;
};

const customOperations = [
  { t },
  { dist },
  { angle },
  { angle4 },
  { rotate },
  { x },
  { y },
  { point },
  { intersection },
  { normal },
  { mm },
  { sin },
  { cos },
  { project },
  { vectorAdd },
  { vectorSubtract },
  { parallel },
  { perpendicular },
  { sign },
  { sign4 },
  { meanByAge },
];

const phi = new Phi(customOperations, ANALYSIS_TABLE_ERRORS);

export default phi;
