import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'cropperjs/dist/cropper.css';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useIsMobile } from '../../common/utils/hooks';

// Convert Cropper.js data structure to data structure used by
// back end. In back end, 1.) crop values are relative to
// the size of the *original* (un-transformed) image, and
// 2.) crop values are required to be defined (even if for the whole image).
// An empty transformation object (`{}`) represents a an image
// with no transformations applied.
const cropperDataToAutoCephTransformations = (image, cropperData) => {
  const { data, canvasData, imageData } = cropperData;
  const offsetX = canvasData.naturalWidth / 2 - imageData.naturalWidth / 2;
  const offsetY = canvasData.naturalHeight / 2 - imageData.naturalHeight / 2;
  const noTransformations =
    data.rotate === 0 &&
    data.scaleX === 1 &&
    data.scaleY === 1 &&
    data.x === 0 &&
    data.y === 0 &&
    data.width === 0 &&
    data.height === 0;

  let transformations;

  if (noTransformations) {
    transformations = {};
  } else {
    transformations = {
      rotate: data.rotate,
      scale_x: data.scaleX,
      scale_y: data.scaleY,
      crop_x: (data.x - offsetX) / imageData.naturalWidth,
      crop_y: (data.y - offsetY) / imageData.naturalHeight,
      crop_width: (data.width || canvasData.naturalWidth) / imageData.naturalWidth,
      crop_height: (data.height || canvasData.naturalHeight) / imageData.naturalHeight,
    };
  }
  return transformations;
};

const calculateRotation = (rotation) => {
  if (rotation > 180) {
    return rotation - 360;
  }
  if (rotation < -180) {
    return rotation + 360;
  }
  return rotation;
};

const aspectRatios = [
  [3, 4],
  [4, 3],
  [1, 1],
];

function RotationInput({ className, rotation, cropper, disabled, setRotation }) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'is-flex is-flex-direction-column is-align-items-center is-justify-content-center px-2 py-2',
        { [className]: className != null },
      )}
      style={{ width: 300, maxWidth: '100%' }}
    >
      <span style={{ color: 'white' }}>{`${t('interface.rotation')} ${rotation}`}</span>
      <input
        type="range"
        min={-180}
        max={180}
        step={1}
        disabled={disabled}
        value={rotation}
        className="slider is-circle is-fullwidth is-marginless"
        onChange={(e) => {
          const value = parseFloat(e.target.value);
          // const rotation = ;
          setRotation(value);
          cropper.current.cropper.rotateTo(value);
        }}
      />
    </div>
  );
}

RotationInput.defaultProps = {
  className: undefined,
  disabled: false,
};

RotationInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rotation: PropTypes.number.isRequired,
  cropper: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setRotation: PropTypes.func.isRequired,
};

const Toolbar = ({ cropper, disabled, onImageChange, onSave, onCancel }) => {
  const { t } = useTranslation();
  const [rotation, setRotation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [isMobile] = useIsMobile(false);

  useEffect(() => {
    if (disabled === false) {
      setRotation(cropper.current.cropper.getData().rotate);
    }
  }, [cropper, disabled]);

  const renderRotationInput = (className) => (
    <RotationInput
      className={className}
      rotation={rotation}
      cropper={cropper}
      disabled={disabled}
      setRotation={setRotation}
    />
  );

  return (
    <div
      className="ImageEditor__toolbar"
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height: 50,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1001,
      }}
    >
      <div className="ImageEditor__toolbarRightSide is-marginless px-2 py-2">
        <div className="buttons has-addons mt-1">
          {aspectRatios.map((ratio) => {
            const ratioString = `${ratio[0]}:${ratio[1]}`;
            return (
              <button
                key={ratioString}
                type="button"
                className={classNames('button is-marginless', {
                  'is-info is-selected': aspectRatio === ratioString,
                  'is-small': isMobile,
                })}
                onClick={() => {
                  setAspectRatio(ratioString);
                  cropper.current.cropper.setAspectRatio(ratio[0] / ratio[1]);
                }}
              >
                {ratioString}
              </button>
            );
          })}
          <button
            key="free"
            type="button"
            className={classNames('button is-marginless', {
              'is-info is-selected': aspectRatio === null,
              'is-small': isMobile,
            })}
            onClick={() => {
              setAspectRatio(null);
              cropper.current.cropper.setAspectRatio(null);
            }}
          >
            {t('free')}
          </button>
        </div>
      </div>
      {renderRotationInput('is-hidden-mobile')}
      <div className="ImageEditor__toolbarRightSide is-marginless is-flex px-2 py-2">
        <div className="buttons has-addons">
          <button
            key="reset"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('erase-transformations')}
            onClick={() => {
              setRotation(0);
              onImageChange();
            }}
          >
            <FontAwesomeIcon icon="eraser" />
          </button>
          <button
            key="rotateClockwise"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('rotate-45-clockwise')}
            onClick={() => {
              setRotation((prev) => calculateRotation(prev + 45));
              cropper.current.cropper.rotate(45);
            }}
          >
            <FontAwesomeIcon icon="redo-alt" />
          </button>
          <button
            key="rotateCounterClockwise"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('rotate-45-counter-clockwise')}
            onClick={() => {
              setRotation((prev) => calculateRotation(prev - 45));
              cropper.current.cropper.rotate(-45);
            }}
          >
            <FontAwesomeIcon icon="undo-alt" />
          </button>
          <button
            key="flipHorizontal"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('flip-horizontal')}
            onClick={() => {
              cropper.current.cropper.scaleX(-cropper.current.cropper.getData().scaleX || -1);
            }}
          >
            <FontAwesomeIcon icon="arrows-alt-h" />
          </button>
          <button
            key="flipVertical"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('flip-vertical')}
            onClick={() => {
              cropper.current.cropper.scaleY(-cropper.current.cropper.getData().scaleY || -1);
            }}
          >
            <FontAwesomeIcon icon="arrows-alt-v" />
          </button>
          <button
            key="close"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('close')}
            onClick={onCancel}
          >
            <FontAwesomeIcon icon="times-circle" />
          </button>
          <button
            key="save"
            type="button"
            className={classNames('button is-marginless', { 'is-small': isMobile })}
            disabled={disabled}
            title={t('save')}
            onClick={onSave}
          >
            <FontAwesomeIcon icon="save" />
          </button>
        </div>
      </div>
      {renderRotationInput('is-hidden-tablet')}
    </div>
  );
};

Toolbar.defaultProps = {
  disabled: false,
};

Toolbar.propTypes = {
  cropper: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  onImageChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ImageEditor = ({ image, onSave, onCancel }) => {
  // Mutable state / refs
  const cropper = useRef();
  const [isCropperLoaded, setIsCropperLoaded] = useState(false);
  const handleSave = useCallback(() => {
    // see:
    //   https://github.com/fengyuanchen/cropperjs#getdatarounded
    //   https://github.com/fengyuanchen/cropperjs#getcanvasdata
    const cropperData = {
      data: cropper.current.cropper.getData(),
      canvasData: cropper.current.cropper.getCanvasData(),
      imageData: cropper.current.cropper.getImageData(),
    };
    const transformations = cropperDataToAutoCephTransformations(image, cropperData);
    onSave(transformations);
  }, [image, onSave]);
  const handleImageChange = useCallback(() => {
    cropper.current.cropper.reset();
    cropper.current.cropper.clear();
  }, []);
  const handleReady = useCallback(() => {
    if (image.transformations != null && Object.keys(image.transformations).length > 0) {
      const {
        rotate,
        scale_x: scaleX,
        scale_y: scaleY,
        crop_x: cropX,
        crop_y: cropY,
        crop_width: cropWidth,
        crop_height: cropHeight,
      } = image.transformations;

      cropper.current.cropper.rotateTo(rotate);
      cropper.current.cropper.scale(scaleX, scaleY);

      const canvasData = cropper.current.cropper.getCanvasData();
      const imageData = cropper.current.cropper.getImageData();

      const offsetX = canvasData.naturalWidth / 2 - imageData.naturalWidth / 2;
      const offsetY = canvasData.naturalHeight / 2 - imageData.naturalHeight / 2;

      const x = cropX * imageData.naturalWidth + offsetX;
      const y = cropY * imageData.naturalHeight + offsetY;
      const width = cropWidth * imageData.naturalWidth;
      const height = cropHeight * imageData.naturalHeight;

      cropper.current.cropper.setData({
        rotate,
        scaleX,
        scaleY,
        x,
        y,
        width,
        height,
      });
    }
    setIsCropperLoaded(true);
  }, [image]);
  return (
    <div
      className="ImageEditor"
      style={{
        zIndex: 1010,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        color: 'rgb(74, 74, 74)',
      }}
    >
      <Toolbar
        cropper={cropper}
        disabled={!isCropperLoaded}
        onSave={handleSave}
        onCancel={onCancel}
        onImageChange={handleImageChange}
      />
      <div
        style={{
          position: 'absolute',
          paddingTop: 50,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Cropper
          ref={cropper}
          viewMode={2}
          src={image.uneditedUrl}
          style={{ height: '100%', width: '100%' }}
          // Cropper.js options:
          // this prop can be used to set aspectRatio
          // there is also setAspectRatio that we can use it to manually change it on action
          // aspectRatio={4/3}
          guides={false}
          background={false}
          dragMode="crop"
          toggleDragModeOnDblclick={false}
          checkCrossOrigin={false}
          autoCrop={image.transformations != null}
          ready={handleReady}
        />
      </div>
    </div>
  );
};

ImageEditor.defaultProps = {};

ImageEditor.propTypes = {
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export function openImageEditor(image, saveTransformations = () => {}) {
  const element = document.createElement('div');
  const portal = document.getElementById('portal');

  const handleCancel = () => {
    // unmounts react component and cleans up event handlers
    ReactDOM.unmountComponentAtNode(element);
    // removes container element from portal
    portal.removeChild(element);
  };

  ReactDOM.render(
    <ImageEditor
      image={image}
      onCancel={handleCancel}
      onSave={(transformations) => {
        // remove
        handleCancel();
        saveTransformations(image, transformations);
      }}
    />,
    element,
  );
  portal.appendChild(element);
}

export default ImageEditor;
