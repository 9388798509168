import React from 'react';
import PropTypes from 'prop-types';
import loading from './LoadingAnimation.svg';

const initialStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '90%',
  width: '100%',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
};
const imageStyle = { width: 120 };

const CallbackSpinner = ({ style }) => {
  return (
    <div style={{ ...initialStyle, ...style }}>
      <img src={loading} alt="loading" style={imageStyle} />
    </div>
  );
};

CallbackSpinner.defaultProps = {
  style: {},
};
CallbackSpinner.propTypes = {
  style: PropTypes.shape({}),
};

export default CallbackSpinner;
