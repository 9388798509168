import React from 'react';
import PropTypes from 'prop-types';
import auth from '../../utils/auth';

const VisitImage = React.memo(
  ({ index, imageData, url, imageStyle, imageClassName, onImageClick }) => {
    const handleKeyPress = (e) => {
      if (e.key === ' ') {
        onImageClick(index, imageData);
        e.preventDefault();
      }
    };
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          onImageClick(index, imageData);
        }}
        onKeyPress={handleKeyPress}
      >
        <img
          className={`${imageClassName} is-hidden-print`}
          style={imageStyle}
          src={auth.getCachedImageUrl(url)}
          alt={imageData?.label ?? ''}
        />
      </div>
    );
  },
);

VisitImage.defaultProps = {
  imageClassName: 'Visit__thumbnail',
  imageData: undefined,
  imageStyle: {},
  onImageClick: () => {},
  url: null,
};

VisitImage.propTypes = {
  index: PropTypes.number.isRequired,
  url: PropTypes.string,
  imageData: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    thumbnail: PropTypes.string,
    editedThumbnail: PropTypes.string,
    uneditedThumbnail: PropTypes.string,
    url: PropTypes.string,
    editedUrl: PropTypes.string,
    uneditedUrl: PropTypes.string,
    href: PropTypes.string,
  }),
  imageClassName: PropTypes.string,
  imageStyle: PropTypes.shape({}),
  onImageClick: PropTypes.func,
};

export default VisitImage;
