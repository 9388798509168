export const PLANE_EXTENT = 25;
export const PLANE_EXTENTS = {
  'Ar-Go': 500,
};
export const PLANE_COLOR = 'rgb(76, 155, 255)';
export const LANDMARK_COLOR = 'rgb(76, 255, 255)';
export const LANDMARK_COLOR_AUTO = 'orange'; // TODO pick better colors
export const LANDMARK_COLOR_CONSTRUCTED = 'red'; // TODO pick better colors
export const LANDMARK_SELECTION_COLOR = 'rgb(255, 255, 60)';

export const LANDMARK_TYPE_REGULAR = 1;
export const LANDMARK_TYPE_AUTO = 2;
export const LANDMARK_TYPE_CONSTRUCTED = 3;
export const LANDMARK_TYPE_SURGERY_ADJUSTED = 4;

export const MIN_SCALE = 0.5;
export const MAX_SCALE = 4.0;

export const ONE_YEAR_AS_SECONDS = 31536000;

export const ANNOTATION_MANUAL_CEPH = 0;
export const ANNOTATION_AUTO_CEPH = 1;
export const ANNOTATION_SURGERY_CEPH = 2;

export const SURGICAL_CUTS = {
  BSSO: 'bsso',
  GENIOPLASTY: 'genioplasty',
  MAXILLA: 'maxilla',
  LEFORT1: 'leFort1',
};

// For OrthoTx
export const ADULT_TEETH_NUMBER = 32;
export const ADULT_TEETH_LABEL = ADULT_TEETH_NUMBER + 2;
export const ADULT_TEETH_GAPS = ADULT_TEETH_NUMBER - 2;

export const CHILD_TEETH_NUMBER = 20;
export const CHILD_TEETH_LABEL = CHILD_TEETH_NUMBER + 2;
export const CHILD_TEETH_GAPS = CHILD_TEETH_NUMBER - 2;

export const DEFAULT_GAPS_FILL_COLOR = 'rgba(197,199,215,0.3)';

export const UPPER_REFERENCE_TOOTH_NAME = 'UR7';
export const LOWER_REFERENCE_TOOTH_NAME = 'LR7';

export const INCREASE_THICKNESS_NUMBER = 2;
