import React from 'react';
import PropTypes from 'prop-types';

import NotificationModalContent from './NotificationModalContent';
import CallbackSpinner from '../../common/ui/views/CallbackSpinner';
import { historyType, numberOrStringType } from '../../common/utils/types';
import { useNotification } from '../../common/utils/hooks';

function NotificationModalContentContainer({
  orgId,
  history,
  patientKey,
  notificationKey,
  onClose,
}) {
  const [{ data: notification, loading }] = useNotification(notificationKey);

  if (loading) {
    return (
      <div
        className="is-flex is-justify-content-center is-align-items-center px-4 py-4 is-full-w"
        style={{ width: '100%', backgroundColor: 'white' }}
      >
        <CallbackSpinner style={{ height: 'unset', position: 'unset' }} />
      </div>
    );
  }
  return (
    <NotificationModalContent
      orgId={orgId}
      history={history}
      currentPatientKey={patientKey}
      notification={notification}
      onClose={onClose}
    />
  );
}

NotificationModalContentContainer.defaultProps = {
  patientKey: undefined,
  onClose: () => {},
};

NotificationModalContentContainer.propTypes = {
  history: historyType.isRequired,
  orgId: numberOrStringType.isRequired,
  patientKey: PropTypes.string,
  notificationKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default NotificationModalContentContainer;
