import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import auth from '../../common/utils/auth';
import services from '../../common/utils/services';
import api from '../../common/utils/api';
import FileDroppableArea from '../../common/ui/components/FileDroppableArea';
import FormikFieldContainer from '../../common/ui/views/FormikFieldContainer';
import CustomTextareaComponent from '../../common/ui/views/CustomTextareaComponent';
import FormikSubmitButton from '../../common/ui/views/FormikSubmitButton';
import { numberOrStringType } from '../../common/utils/types';
import { message } from '../../common/ui/views/Message';
import { getUserFullName, getBase64 } from '../../common/utils/utils';
import FileField from '../../common/ui/components/FileField';

function FormikUserSelector({ orgId, name }) {
  const { t } = useTranslation();
  const [{ data: users, loading }, setUsers] = useState({ data: null, loading: true, error: null });

  useEffect(() => {
    services.users
      .getOrgUsers(orgId, 1, 1000)
      .then((response) => {
        setUsers({
          data: response.results,
          loading: false,
          error: null,
        });
      })
      .catch((err) => {
        console.error(err);
        api.error('FormikUserSelector', 'unable to get organization users', { err });
        message.danger(t('unable-to-get-organization-users'), t('please-try-again'));
        setUsers({
          data: null,
          loading: false,
          error: t('unable-to-get-organization-users'),
        });
      });
  }, [orgId, t]);

  const options = users?.map((user) => {
    return {
      key: user.id,
      value: user.id,
      label: `${getUserFullName(user)} <${user.email}>`,
    };
  });

  return (
    <div className={classNames('select is-fullwidth', { 'is-loading': loading })}>
      <Field as="select" name={name} disabled={loading}>
        <option value="" key="__no_selection__">
          {t('please-select-the-recipient')}
        </option>
        {options?.map(({ key, value: optionValue, label }) => (
          <option key={key} value={optionValue}>
            {label}
          </option>
        ))}
      </Field>
    </div>
  );
}

FormikUserSelector.propTypes = {
  orgId: numberOrStringType.isRequired,
  name: PropTypes.string.isRequired,
};

function SendMessageModalContent({
  patientId,
  threadReference,
  recipientId,
  senderOrganizationId,
  isDentist,
  onClose,
}) {
  const { t } = useTranslation();
  const ref = useRef();
  const orgId = auth.orgInfo.id;

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const { privateText, documents, recipientId: formRecipientIdStr } = values;
        const formRecipientIdNum = Number(formRecipientIdStr);
        let caseData = {};
        const notificationDocuments = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < documents.length; i++) {
          const file = documents[i];

          notificationDocuments.push({
            // eslint-disable-next-line no-await-in-loop
            contents_b64: await getBase64(file),
            filename: file.name,
          });
        }

        if (patientId != null) {
          const { key: patientKey } = await services.patients.getPatient(orgId, patientId);
          const cases = await services.cases.getPatientCases(patientKey);
          if (cases[0] == null) {
            throw new Error('unable to get case');
          }
          const [firstCase] = cases;
          caseData = firstCase;
        }

        const { id: caseId } = caseData;

        const body = {
          document_files: notificationDocuments,
          // thread_reference: parentNotification.thread_id, // leave blank if we are not replying to anything
          private_text: privateText,
          public_header: 'You have a new message on OrthoTx',
          public_text: 'You have a message waiting for you on OrthoTx.',
          // send all messages to OrthoTx regardless
          send_to_orthotx: true,
          // set the recipient, if given.
          ...(formRecipientIdNum != null ? { recipient_id: formRecipientIdNum } : {}),
          ...(caseId != null ? { case_id: caseId } : {}),
          ...(threadReference != null ? { thread_reference: threadReference } : {}),
        };

        await services.notifications.sendNotification(body);
        onClose();
        message.success(t('successfully-sent-your-message'));
      } catch (error) {
        console.error(error);
        api.error('SendMessageToOrthoTxButton', 'unable to send message to orthotx', { error });
        message.danger(t('unable-to-send-message'), t('please-try-again'));
      }
    },
    [orgId, patientId, threadReference, onClose, t],
  );

  return (
    <Formik
      initialValues={{
        recipientId: recipientId ?? '',
        privateText: '',
        documents: [],
      }}
      validate={(values) => {
        const errors = {};

        const requiredNames = ['privateText'];

        // if dentist is sending the message, then recipient id is not required in the form
        if (isDentist === false) {
          requiredNames.push('recipientId');
        }

        requiredNames.forEach((name) => {
          if (values[name] == null || values[name] === '') {
            errors[name] = t('required');
          }
        });

        return errors;
      }}
      onSubmit={handleSubmit}
    >
      <FileDroppableArea>
        <div
          style={{ width: '100%', backgroundColor: 'white', padding: '1em', overflowY: 'scroll' }}
        >
          {!isDentist && senderOrganizationId != null && (
            <FormikFieldContainer title={t('to')} name="recipientId" noIndent required>
              <FormikUserSelector orgId={senderOrganizationId} name="recipientId" />
            </FormikFieldContainer>
          )}

          <FormikFieldContainer
            title={t('your-message')}
            name="privateText"
            noIndent
            required
            removeErrorField
          >
            <Field
              innerRef={ref}
              name="privateText"
              className="textarea is-family-code"
              component={CustomTextareaComponent}
            />
          </FormikFieldContainer>

          <FormikFieldContainer title={t('add-image-and-documents')} noIndent>
            <FileField name="documents" label="upload" />
          </FormikFieldContainer>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <button type="button" className="button is-normal is-outlined" onClick={onClose}>
                    {t('cancel')}
                  </button>
                </div>
                <div className="control">
                  <FormikSubmitButton buttonName={t('send-message')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FileDroppableArea>
    </Formik>
  );
}

SendMessageModalContent.defaultProps = {
  patientId: null,
  recipientId: null,
  threadReference: null,
  senderOrganizationId: undefined,
  isDentist: true,
  onClose: () => {},
};

SendMessageModalContent.propTypes = {
  patientId: numberOrStringType,
  recipientId: numberOrStringType,
  threadReference: PropTypes.string,
  senderOrganizationId: numberOrStringType,
  isDentist: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SendMessageModalContent;
