import nearley from 'nearley';

// The file grammar.js is auto generated at build time.
// It exports the grammar object globally; access it with window.grammar.
import './grammar';

const { grammar } = window;

// *Phi* is heavily inspired by jsonLogic (https://github.com/jwadhams/json-logic-js)
// It's purpose is to execute a code with given json and share that json with frontend and backend securely.
class Phi {
  // simple operations that does not call `run`
  static #operations = {
    equals(a, b) {
      return a === b;
    },
    notEquals(a, b) {
      return a !== b;
    },
    greaterThan(a, b) {
      return a > b;
    },
    greaterThanOrEqualTo(a, b) {
      return a >= b;
    },
    lessThan(a, b, c) {
      return c === undefined ? a < b : a < b && b < c;
    },
    lessThanOrEqualTo(a, b, c) {
      return c === undefined ? a <= b : a <= b && b <= c;
    },
    isTrue(a) {
      return Phi.truthy(a);
    },
    isFalse(a) {
      return !Phi.truthy(a);
    },
    mod(a, b) {
      return a % b;
    },
    log(a) {
      // eslint-disable-next-line no-console
      console.log(a);
      return a;
    },
    in(a, b) {
      if (!b || typeof b.indexOf === 'undefined') return false;
      return b.indexOf(a) !== -1;
    },
    cat(...args) {
      return Array.prototype.join.call(args, '');
    },
    substr(source, start, end) {
      if (end < 0) {
        // JavaScript doesn't support negative end, this emulates PHP behavior
        const temp = String(source).substr(start);
        return temp.substr(0, temp.length + end);
      }
      return String(source).substr(start, end);
    },
    add(...args) {
      return Array.prototype.reduce.call(
        args,
        (a, b) => {
          return Number(a) + Number(b);
        },
        0,
      );
    },
    product(...args) {
      return Array.prototype.reduce.call(args, (a, b) => {
        return Number(a) * Number(b);
      });
    },
    subtract(a, b) {
      if (b === undefined) {
        return -a;
      }
      return a - b;
    },
    divide(a, b) {
      return a / b;
    },
    min(...args) {
      return Math.min.apply({}, args);
    },
    max(...args) {
      return Math.max.apply({}, args);
    },
    merge(...args) {
      return Array.prototype.reduce.call(
        args,
        (a, b) => {
          return Array.prototype.concat(a, b);
        },
        [],
      );
    },
  };

  // Becarefull when changing `complexOperations` because global this will be bind to `complexOperations` functions
  // meaning that functions can reach any variable inside of the *Phi* instance.
  // This is necessary because we want to call `run` function recursively in the `complexOperations`
  static #complexOperations = {
    if(...args) {
      const [values, data] = args;
      /* 'if' should be called with a odd number of parameters, 3 or greater
      This works on the pattern:
      if( 0 ){ 1 }else{ 2 };
      if( 0 ){ 1 }else if( 2 ){ 3 }else{ 4 };
      if( 0 ){ 1 }else if( 2 ){ 3 }else if( 4 ){ 5 }else{ 6 };
      The implementation is:
      For pairs of values (0,1 then 2,3 then 4,5 etc)
      If the first evaluates truthy, evaluate and return the second
      If the first evaluates falsy, jump to the next pair (e.g, 0,1 to 2,3)
      given one parameter, evaluate and return it. (it's an Else and all the If/ElseIf were false)
      given 0 parameters, return NULL (not great practice, but there was no Else)
      */
      let i;
      for (i = 0; i < values.length - 1; i += 2) {
        if (Phi.truthy(this.run(values[i], data))) {
          return this.run(values[i + 1], data);
        }
      }
      if (values.length === i + 1) return this.run(values[i], data);
      return null;
    },
    and(...args) {
      const [values, data] = args;
      let current;
      for (let i = 0; i < values.length; i += 1) {
        current = this.run(values[i], data);
        if (!Phi.truthy(current)) {
          return current;
        }
      }
      return current; // Last
    },
    or(...args) {
      const [values, data] = args;
      let current;
      for (let i = 0; i < values.length; i += 1) {
        current = this.run(values[i], data);
        if (Phi.truthy(current)) {
          return current;
        }
      }
      return current; // Last
    },
    filter(...args) {
      const [values, data] = args;
      const scopedData = this.run(values[0], data);
      const scopedLogic = values[1];

      if (!Array.isArray(scopedData)) {
        return [];
      }
      // Return only the elements from the array in the first argument,
      // that return truthy when passed to the logic in the second argument.
      // For parity with JavaScript, reindex the returned array
      return scopedData.filter((datum) => {
        return Phi.truthy(this.run(scopedLogic, { current: datum }));
      });
    },
    map(...args) {
      const [values, data] = args;
      const scopedData = this.run(values[0], data);
      const scopedLogic = values[1];

      if (!Array.isArray(scopedData)) {
        return [];
      }

      return scopedData.map((datum) => {
        return this.run(scopedLogic, { current: datum });
      });
    },
    reduce(...args) {
      const [values, data] = args;
      const scopedData = this.run(values[0], data);
      const scopedLogic = values[1];
      const initial = typeof values[2] !== 'undefined' ? values[2] : null;

      if (!Array.isArray(scopedData)) {
        return initial;
      }

      return scopedData.reduce((accumulator, current) => {
        return this.run(scopedLogic, { current, accumulator });
      }, initial);
    },
    all(...args) {
      const [values, data] = args;
      const scopedData = this.run(values[0], data);
      const scopedLogic = values[1];
      // All of an empty set is false. Note, some and none have correct fallback after the for loop
      if (!scopedData.length) {
        return false;
      }
      for (let i = 0; i < scopedData.length; i += 1) {
        if (!Phi.truthy(this.run(scopedLogic, { current: scopedData[i] }))) {
          return false;
        }
      }
      return true;
    },
    none(...args) {
      const [values, data] = args;
      const filtered = this.run({ filter: values }, data);
      return filtered.length === 0;
    },
    some(...args) {
      const [values, data] = args;
      const filtered = this.run({ filter: values }, data);
      return filtered.length > 0;
    },
    missing(...args) {
      /*
      Missing can receive many keys as many arguments, like {"missing:[1,2]}
      Missing can also receive *one* argument that is an array of keys,
      which typically happens if it's actually acting on the output of another command
      (like 'if' or 'merge')
      */

      const [values, data] = args;
      const missing = [];
      const keys = Array.isArray(values[0]) ? values[0] : values;

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = this.run({ get: key }, data);
        if (value == null || value === '') {
          missing.push(key);
        }
      }

      return missing;
    },
    missingSome(...args) {
      const [values, data] = args;
      const [needCount, options] = values;
      // missingSome takes two arguments, how many (minimum) items must be present,
      // and an array of keys (just like 'missing') to check for presence.
      const areMissing = this.run({ missing: options }, data);

      if (options.length - areMissing.length >= needCount) {
        return [];
      }
      return areMissing;
    },
    get(...args) {
      const [values, data] = args;
      const [a, b] = values;
      const notFound = b === undefined ? null : b;

      if (typeof a === 'undefined' || a === '' || a === null) {
        return notFound;
      }
      // don't use hasOwnProperty in the data directly! see: https://eslint.org/docs/rules/no-prototype-builtins
      if (Object.prototype.hasOwnProperty.call(data, String(a))) {
        return data[String(a)];
      }
      return notFound;
    },
    set(...args) {
      const [values, data] = args;
      const [untrimmedKey, value, logic] = values;
      const key = untrimmedKey.trim();
      const calculatedValue = this.run(value, data);
      // if there is a same key in the data, then it will not effect and value in the data will be used
      // in order to work correctly, specifying unique key is needed
      if (/^[a-zA-Z0-9]/.test(key)) {
        return this.run(logic, { [key]: calculatedValue, ...data });
      }
      throw Error(`${key} is an invalid variable name.`);
    },
  };

  static #operationsMap = new Map(Object.entries(Phi.#operations));

  static #complexOperationsMap = new Map(Object.entries(Phi.#complexOperations));

  // User defined operations
  #customOperationsMap = new Map();

  #errors = {};

  constructor(customOperations, errors) {
    if (Array.isArray(customOperations)) {
      for (let i = 0; i < customOperations.length; i += 1) {
        const operationObj = customOperations[i];
        const [key, value] = Object.entries(operationObj)[0];
        this.#customOperationsMap.set(key, value);
      }
    }
    if (errors != null) {
      this.#errors = errors;
    }
    this.run.bind(this);
  }

  static isLogic(logic) {
    if (typeof logic === 'object' && Object.keys(logic).length > 1) {
      throw Error('Object can only contain one key.');
    }
    return (
      typeof logic === 'object' && // object
      logic !== null && // is not null
      !Array.isArray(logic) && // not an array
      Object.keys(logic).length === 1 // with exactly one key
    );
  }

  static truthy(value) {
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }
    return !!value;
  }

  static isIn(map, key) {
    const value = map.get(key);
    return value != null && typeof value === 'function';
  }

  run(logic, data) {
    // store `this` object in `that` to prevent using redefined `this` in inner scope
    const that = this;
    // Does this array contain logic? Only one way to find out.
    if (Array.isArray(logic)) {
      return logic.map((l) => {
        return that.run(l, data);
      });
    }

    if (!Phi.isLogic(logic)) {
      return logic;
    }

    const [operationKey, value] = Object.entries(logic)[0];
    let values = value;

    // easy syntax for unary operators, like {"get" : "x"} instead of strict {"get" : ["x"]}
    if (!Array.isArray(value)) {
      values = [value];
    }

    // if it is a complex function don't run it's arguments instead give them to specified function
    if (Phi.isIn(Phi.#complexOperationsMap, operationKey)) {
      return Phi.#complexOperationsMap.get(operationKey).apply(that, [values, data]);
    }

    // recursively run arguments with using depth-first search approach
    values = values.map((val) => {
      return that.run(val, data);
    });

    // if it is an operation apply data and provided values
    if (Phi.isIn(Phi.#operationsMap, operationKey)) {
      return Phi.#operationsMap.get(operationKey).apply(data, values);
    }

    // if it is a customOperations apply data and provided values
    if (Phi.isIn(this.#customOperationsMap, operationKey)) {
      return this.#customOperationsMap.get(operationKey).apply(data, values);
    }

    // if none of them match throw error
    throw new Error(`Unrecognized operation ${operationKey}`);
  }

  execute(logic, data = {}) {
    try {
      return this.run(logic, data);
    } catch (error) {
      const { message } = error;
      if (Object.prototype.hasOwnProperty.call(this.#errors, message)) {
        return this.#errors[message];
      }
      return message;
    }
  }

  static stringify(formula) {
    // currently we are processing first key of the formula and assuming there will be always one key at the root
    // if formula is not object or array that means it needs to be processed as a raw.
    if (typeof formula !== 'object' && Array.isArray(formula) === false) {
      return JSON.stringify(formula);
    }
    if (Array.isArray(formula)) {
      return `[${formula.map((p) => Phi.stringify(p))}]`;
    }
    return `${Object.keys(formula).map((firstKey) => {
      const params = formula[firstKey];
      return `${firstKey}(${
        Array.isArray(params) ? params.map(Phi.stringify).join(', ') : params
      })`;
    })}`;
  }

  static parse(string) {
    const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
    parser.feed(string);
    // results is all the possible outcomes array // if grammar is not ambiguous it will only have one value
    return parser.results[0];
  }
}

export default Phi;
