import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Lightbox from 'lightbox-react';

import auth from '../../utils/auth';
import { documentType, imageType } from '../../utils/types';
import VideoPlayer from './VideoPlayer';

function ImageCarousel({ index, images, videoDocuments, toolbarButtons, onClose }) {
  const [photoIndex, setPhotoIndex] = useState(index);
  const isDisplayingImage = photoIndex < images.length;

  const getLightboxComponent = (i) => {
    const totalLength = images.length + videoDocuments.length;
    // debugger;
    if (i >= totalLength) {
      return auth.getCachedImageUrl(images[i].url);
    }
    if (i > images.length - 1) {
      const doc = videoDocuments[i - images.length];
      return (
        <div style={{ width: '100%', height: 'calc(100% - 50px)', marginTop: 50 }}>
          <VideoPlayer src={doc.url} type={doc.mimetype} />
        </div>
      );
    }
    return auth.getCachedImageUrl(images[i].url);
  };

  return (
    <Lightbox
      mainSrc={getLightboxComponent(photoIndex)}
      nextSrc={getLightboxComponent((photoIndex + 1) % (images.length + videoDocuments.length))}
      prevSrc={getLightboxComponent(
        (photoIndex + images.length + videoDocuments.length - 1) %
          (images.length + videoDocuments.length),
      )}
      toolbarButtons={isDisplayingImage ? toolbarButtons : []}
      // Enable zoom only for images. index bigger than image array length corresponds to video documents.
      enableZoom={isDisplayingImage}
      onCloseRequest={onClose}
      onMovePrevRequest={() => {
        setPhotoIndex(
          (photoIndex + images.length + videoDocuments.length - 1) %
            (images.length + videoDocuments.length),
        );
      }}
      onMoveNextRequest={() => {
        setPhotoIndex((photoIndex + 1) % (images.length + videoDocuments.length));
      }}
    />
  );
}

ImageCarousel.defaultProps = {
  index: 0,
  images: [],
  videoDocuments: [],
  toolbarButtons: undefined,
  onClose: () => {},
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(imageType),
  videoDocuments: PropTypes.arrayOf(documentType),
  index: PropTypes.number,
  toolbarButtons: PropTypes.node,
  onClose: PropTypes.func,
};

export function openImageCarousel(images, index, toolbarButtons, videoDocuments) {
  const element = document.createElement('div');
  const portal = document.getElementById('portal');

  const handleCancel = () => {
    // unmounts react component and cleans up event handlers
    ReactDOM.unmountComponentAtNode(element);
    // removes container element from portal
    portal.removeChild(element);
  };

  ReactDOM.render(
    <ImageCarousel
      images={images}
      videoDocuments={videoDocuments}
      index={index}
      toolbarButtons={toolbarButtons}
      onClose={handleCancel}
    />,
    element,
  );
  portal.appendChild(element);
  return { element, handleCancel };
}

export default ImageCarousel;
