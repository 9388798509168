import raw from 'raw.macro';
import {
  CANONICAL_LANDMARK_ORDER,
  LANDMARK_DISPLAY_NAMES,
  LANDMARK_FULL_NAMES,
} from '../../analyses';

// set constants in-line based on the values in the given files.
export const VERSION = raw('../../VERSION').trim();

export const ACCOUNT_TYPES = {
  ORTHO_TX: 'ortho-tx',
  ORTHO_DX: 'ortho-dx',
};

export const isOrthoTx = process.env.REACT_APP_ACCOUNT_TYPE === ACCOUNT_TYPES.ORTHO_TX;
export const isOrthoDx = process.env.REACT_APP_ACCOUNT_TYPE === ACCOUNT_TYPES.ORTHO_DX;
export const APP_NAME = isOrthoTx ? 'OrthoTx' : 'OrthoDx';

export const CONTACT_SUPPORT_URL = 'https://phimentum.freshdesk.com/support/tickets/new';
export const SIGNUP_URL = `${window.location.origin}/signup`;

export const LOGO_URL = isOrthoTx
  ? `${process.env.PUBLIC_URL}/images/orthotx/logo-orthotx-small.png`
  : `${process.env.PUBLIC_URL}/images/orthodx/logo-orthodx-small.png`;

export const VISIT_KINDS = {
  DENTIST_INPUT: 'DENTIST_INPUT',
  DIAGNOSIS: 'DIAGNOSIS',
  TREATMENT_PLAN: 'TREATMENT_PLAN',
  EXECUTION_PLAN: 'EXECUTION_PLAN',
  REQUEST_REVISION: 'REQUEST_REVISION',
  REQUEST_REVIEW: 'REQUEST_REVIEW',
  REQUEST_RETAINER: 'REQUEST_RETAINER',
};

export const DEFAULT_TAGS = {
  CONSULTATION: 'consultation',
  RECORDS: 'records',
  TREATMENT_PLAN: 'treatment-plan',
  INITIAL: 'initial',
  PROGRESS: 'progress',
  FINAL: 'final',
  NEW_CASE: 'new-case',
  DIAGNOSIS: 'patient-analysis',
  EXECUTION_PLAN: 'execution-plan',
  PRIMARY_PLAN: 'primary-plan',
  REQUEST_RETAINER: 'retainer-request',
  REQUEST_REVIEW: 'review-request',
  REQUEST_REVISION: 'revision-request',
};

export const TAG_CONFIGS = {
  [DEFAULT_TAGS.CONSULTATION]: {
    color: 'is-success',
  },
  [DEFAULT_TAGS.RECORDS]: {
    color: 'is-warning',
  },
  [DEFAULT_TAGS.INITIAL]: {
    color: 'is-info',
  },
  [DEFAULT_TAGS.PROGRESS]: {
    color: '',
  },
  [DEFAULT_TAGS.FINAL]: {
    color: 'is-danger',
  },
  [DEFAULT_TAGS.NEW_CASE]: {
    color: 'button is-outlined is-info is-not-clickable',
  },
  [DEFAULT_TAGS.DIAGNOSIS]: {
    color: 'button is-outlined is-primary is-not-clickable',
  },
  [DEFAULT_TAGS.TREATMENT_PLAN]: {
    color: 'button is-outlined is-info is-not-clickable',
  },
  [DEFAULT_TAGS.EXECUTION_PLAN]: {
    color: 'button is-outlined is-success is-not-clickable',
  },
  [DEFAULT_TAGS.PRIMARY_PLAN]: {
    color: 'button is-outlined is-primary is-not-clickable',
  },
};

export const IMAGE_LABELS = {
  PROFILE: 'profile',
  PROFILE_SMILE: 'profile-smile',
  FRONT: 'front',
  SMILE: 'smile',
  MOUTH_RIGHT: 'mouth-right',
  MOUTH_CENTER: 'mouth-center',
  MOUTH_LEFT: 'mouth-left',
  MOUTH_TOP: 'mouth-top',
  MOUTH_BOTTOM: 'mouth-bottom',
  CEPH: 'ceph',
  PANORAMIC_XRAY: 'panoramic-xray',
  PA_CEPH: 'pa-ceph',
  STO: 'sto',
  OTHER: 'other',
};

// order defines which label will place before which one
// starts from 0 to Infinity (0 will be in the first place)
export const LABEL_CONFIGS = {
  [IMAGE_LABELS.PROFILE]: { name: 'profile', order: 0 },
  [IMAGE_LABELS.PROFILE_SMILE]: { name: 'profile-smile', order: 1 },
  [IMAGE_LABELS.FRONT]: { name: 'front', order: 2 },
  [IMAGE_LABELS.SMILE]: { name: 'smile', order: 3 },
  [IMAGE_LABELS.MOUTH_TOP]: { name: 'mouth-top', order: 4 },
  [IMAGE_LABELS.MOUTH_BOTTOM]: { name: 'mouth-bottom', order: 5 },
  [IMAGE_LABELS.MOUTH_RIGHT]: { name: 'mouth-right', order: 6 },
  [IMAGE_LABELS.MOUTH_CENTER]: { name: 'mouth-center', order: 7 },
  [IMAGE_LABELS.MOUTH_LEFT]: { name: 'mouth-left', order: 8 },
  [IMAGE_LABELS.CEPH]: { name: 'ceph', order: 9 },
  [IMAGE_LABELS.PANORAMIC_XRAY]: { name: 'panoramic-xray', order: 10 },
  [IMAGE_LABELS.PA_CEPH]: { name: 'pa-ceph', order: 11 },
  [IMAGE_LABELS.STO]: { name: 'sto', order: 12 },
  [IMAGE_LABELS.OTHER]: { name: 'other', order: 999 },
};

export const faceLabels = [
  IMAGE_LABELS.PROFILE,
  IMAGE_LABELS.PROFILE_SMILE,
  IMAGE_LABELS.FRONT,
  IMAGE_LABELS.SMILE,
];
export const occlusalLabels = [IMAGE_LABELS.MOUTH_TOP, IMAGE_LABELS.MOUTH_BOTTOM];
export const intraoralLabels = [
  IMAGE_LABELS.MOUTH_RIGHT,
  IMAGE_LABELS.MOUTH_CENTER,
  IMAGE_LABELS.MOUTH_LEFT,
];
export const cephLabels = [IMAGE_LABELS.CEPH, IMAGE_LABELS.PANORAMIC_XRAY, IMAGE_LABELS.PA_CEPH];

export const imageLabelsWithAnalyses = [IMAGE_LABELS.CEPH, IMAGE_LABELS.PA_CEPH];
export const imageLabelsWithAnalysesAndReports = [
  IMAGE_LABELS.PA_CEPH,
  IMAGE_LABELS.CEPH,
  IMAGE_LABELS.STO,
];

export const FILE_TYPES = {
  SVG: { NAME: 'image/svg+xml', TYPES: ['image/svg+xml'] },
  PDF: { NAME: 'application/pdf', TYPES: ['application/pdf'] },
  CSV: { NAME: 'text/csv', TYPES: ['text/csv'] },
  RAR: { NAME: 'application/x-rar-compressed', TYPES: ['application/x-rar-compressed'] },
  ZIP: { NAME: 'application/zip', TYPES: ['application/zip'] },
  AUDIO: { NAME: 'audio', TYPES: [] },
  IMAGE: { NAME: 'image', TYPES: [] },
  VIDEO: { NAME: 'video', TYPES: [] },
  WORD: {
    NAME: 'word',
    TYPES: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
    ],
  },
  EXCEL: {
    NAME: 'application/vnd.ms-excel',
    TYPES: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    ],
  },
  POWERPOINT: {
    NAME: 'powerpoint',
    TYPES: [
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.ms-powerpoint.addin.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.template.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    ],
  },
  STL: {
    NAME: 'STL',
    TYPES: [],
  },
  OBJ: {
    NAME: 'OBJ',
    TYPES: [],
  },
  PLY: {
    NAME: 'PLY',
    TYPES: [],
  },
};

export const FILE_TYPE_CONFIGS = {
  [FILE_TYPES.SVG.NAME]: {
    icon: 'file-image',
  },
  [FILE_TYPES.PDF.NAME]: {
    icon: 'file-pdf',
  },
  [FILE_TYPES.CSV.NAME]: {
    icon: 'file-csv',
  },
  [FILE_TYPES.RAR.NAME]: {
    icon: 'file-archive',
  },
  [FILE_TYPES.ZIP.NAME]: {
    icon: 'file-archive',
  },
  [FILE_TYPES.AUDIO.NAME]: {
    icon: 'file-audio',
  },
  [FILE_TYPES.IMAGE.NAME]: {
    icon: 'file-image',
  },
  [FILE_TYPES.VIDEO.NAME]: {
    icon: 'file-video',
  },
  [FILE_TYPES.WORD.NAME]: {
    icon: 'file-word',
  },
  [FILE_TYPES.EXCEL.NAME]: {
    icon: 'file-excel',
  },
  [FILE_TYPES.POWERPOINT.NAME]: {
    icon: 'file-powerpoint',
  },
  getFileTypeConfig(fileType) {
    if (fileType == null) {
      return false;
    }
    if (FILE_TYPE_CONFIGS[fileType] != null) {
      return FILE_TYPE_CONFIGS[fileType];
    }

    const fileTypeScope = fileType.split('/')[0];
    if (fileTypeScope === 'audio') {
      return FILE_TYPE_CONFIGS[FILE_TYPES.AUDIO.NAME];
    }
    if (fileTypeScope === 'video') {
      return FILE_TYPE_CONFIGS[FILE_TYPES.VIDEO.NAME];
    }
    if (fileTypeScope === 'image') {
      return FILE_TYPE_CONFIGS[FILE_TYPES.IMAGE.NAME];
    }
    if (FILE_TYPES.EXCEL.TYPES.includes(fileType)) {
      return FILE_TYPE_CONFIGS[FILE_TYPES.EXCEL.NAME];
    }
    if (FILE_TYPES.WORD.TYPES.includes(fileType)) {
      return FILE_TYPE_CONFIGS[FILE_TYPES.WORD.NAME];
    }
    if (FILE_TYPES.POWERPOINT.TYPES.includes(fileType)) {
      return FILE_TYPE_CONFIGS[FILE_TYPES.POWERPOINT.NAME];
    }
    return false;
  },
  getFileTypeIcon(fileType) {
    const config = this.getFileTypeConfig(fileType);
    return config ? config.icon : 'file';
  },
  isFileAcceptedAsVideo(fileType) {
    if (typeof fileType !== 'string') return false;

    const fileTypeScope = fileType.split('/')[0];
    return fileTypeScope === 'video';
  },
  isFileAcceptedAsPDF(fileType) {
    const fileTypeScope = fileType.split('/')[1];
    return fileTypeScope === 'pdf';
  },
  isFileAcceptedAsImage(fileType, fileName) {
    const acceptedMimetypes = [
      'application/dicom',
      'application/pdf',
      'image/bmp',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'image/x-ms-bmp',
    ];
    // if the mimetype is not accepted, we fallback to use file extension to determine if file is accepted as image
    const acceptedFileExtensionsRegex = /\.(dcm|pdf|png|jpeg|jpg|bmp|tiff|tif)$/i;
    const isFileExtensionAccepted = fileName != null && acceptedFileExtensionsRegex.test(fileName);

    return acceptedMimetypes.includes(fileType) || isFileExtensionAccepted;
  },
};

export const CONNECTION_TYPES = {
  HEARTBEAT: 'heartbeat',
  LABEL_IMAGE: 'label_image',
  ANNOTATE_IMAGE: 'annotate_image',
  UPDATE_ALL_NOTIFICATIONS: 'update_all_notifications',
  POST_NOTIFICATION: 'post_notification',
};
export const SIDEBAR_ID = 'pageSidebar';
export const LEFT_DRAWER_ID = 'leftDrawer';

export const TUTORIAL_KEYS = {
  PATIENT_LIST_VIEW: 'PATIENT_LIST_VIEW',
  PATIENT_VIEW: 'PATIENT_VIEW',
  ANALYSIS_VIEW: 'ANALYSIS_VIEW',
  STO_VIEW: 'STO_VIEW',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
};

export default {
  faceLabels,
  occlusalLabels,
  intraoralLabels,
  cephLabels,
};

export const DEFAULT_FEATURES = [
  {
    key: 'automatic-cephalometric-tracing-analysis',
  },
  {
    key: 'automated-patient-image-organization',
  },
  {
    key: 'unlimited-number-of-patients',
  },
  {
    key: 'unlimited-patient-charting',
  },
  {
    key: 'unlimited-storage',
  },
  {
    key: 'up-to-five-active-users-month',
  },
  {
    key: 'custom-analysis-generation-support',
  },
  {
    key: 'pdf-export-for-communication-and-marketing',
  },
  {
    key: 'total-email-support',
  },
  {
    key: 'all-version-updates-included',
  },
];

export const PLAN_FREE_TRIAL_NAME_PATTERN = /free trial/i;

const PLAN_NAMES = {
  MONTHLY: 'Unlimited Monthly Plan',
  QUARTERLY: 'Unlimited Quarterly Plan',
  ANNUAL: 'Unlimited Annual Plan',
};

export const ENABLE_PLAN_SELECTION = false;

export const DEFAULT_PLAN_CONFIGS = {
  [PLAN_NAMES.MONTHLY]: {
    title: 'monthly',
    features: DEFAULT_FEATURES,
    currency: '$',
    forEach: 'month',
  },
  [PLAN_NAMES.QUARTERLY]: {
    title: 'quarterly',
    features: DEFAULT_FEATURES,
    currency: '$',
    forEach: 'month',
    priceDesc: 'billed-quarterly',
  },
  [PLAN_NAMES.ANNUAL]: {
    title: 'annually',
    features: DEFAULT_FEATURES,
    currency: '$',
    forEach: 'month',
    priceDesc: 'billed-annually',
    badge: {
      text: 'most-savings',
    },
  },
};

// key and value needs to be same because it is used in pdfReport
export const ANALYSIS_TABLE_ERRORS = {
  AGE_REQUIRED: 'AGE_REQUIRED',
  RULERS_AND_CALIBRATION_REQUIRED: 'RULERS_AND_CALIBRATION_REQUIRED',
};

export const POINT_MARKER_TYPES = {
  BUTTON_WITH_CHECKBOX: 'BUTTON_WITH_CHECKBOX',
};

export const getLocalKeysOfOptions = (data, options, config = {}) => {
  const { textKey = 'text' } = config;
  if (data == null || (typeof data === 'string' && data.length === 0)) {
    return ['n-a'];
  }
  if (!Array.isArray(data)) {
    return [options.find((i) => i.value === data)?.[textKey] ?? data];
  }
  return data.map((n) => options.find((i) => i.value === n)?.[textKey] ?? n);
};

export const getSelectedOption = (data, options) => {
  if (!Array.isArray(data)) {
    return options.find((i) => i.value === data);
  }
  return data.map((n) => options.find((i) => i.value === n));
};

export const TAB_KEY = 9;
export const ENTER_KEY = 13;
export const ESCAPE_KEY = 27;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const RIGHT_KEY = 39;
export const DOWN_KEY = 40;
export const DELETE_KEY = 46;
export const BACKSPACE_KEY = 8;

export const DRAGGABLE_ITEM_TYPES = {
  ANALYSIS_ROW: 'ANALYSIS_ROW',
  EXECUTION_PLAN_STEP: 'EXECUTION_PLAN_STEP',
};

export const ANALYSIS_SELECTION_DISABLE_REASONS = {
  ANALYSIS_TABLE_DIRTY: 'ANALYSIS_TABLE_DIRTY',
};

export const ANALYSIS_SELECTION_DISABLE_REASON_LOCAL_KEYS = {
  [ANALYSIS_SELECTION_DISABLE_REASONS.ANALYSIS_TABLE_DIRTY]: 'analysis-table-has-unsaved-changes',
};

function getLandmarkConfig(arr = []) {
  return arr.reduce((acc, l) => {
    acc[l] = {
      key: l,
      name: LANDMARK_DISPLAY_NAMES[l],
      fullName: LANDMARK_FULL_NAMES[l],
    };
    return acc;
  }, {});
}

const sharedVariableConfigs = {
  // add default variables to here
  calibration: {
    key: 'calibration',
    name: 'calibration',
    fullName: 'calibration',
  },
  age: {
    key: 'age',
    name: 'age',
    fullName: 'age',
  },
};

export const defaultVariableConfigs = {
  [IMAGE_LABELS.CEPH]: sharedVariableConfigs,
  [IMAGE_LABELS.PA_CEPH]: sharedVariableConfigs,
  ALL: sharedVariableConfigs,
};

export const landmarkVariableConfigs = {
  [IMAGE_LABELS.CEPH]: getLandmarkConfig(CANONICAL_LANDMARK_ORDER[IMAGE_LABELS.CEPH]),
  [IMAGE_LABELS.PA_CEPH]: getLandmarkConfig(CANONICAL_LANDMARK_ORDER[IMAGE_LABELS.PA_CEPH]),
  ALL: {
    ...getLandmarkConfig(CANONICAL_LANDMARK_ORDER[IMAGE_LABELS.CEPH]),
    ...getLandmarkConfig(CANONICAL_LANDMARK_ORDER[IMAGE_LABELS.PA_CEPH]),
  },
};

const cephLogicFunctionNames = [
  'get',
  'dist',
  'angle',
  'angle4',
  'rotate',
  'x',
  'y',
  'point',
  'parallel',
  'perpendicular',
  'intersection',
  'project',
  'normal',
  'meanByAge',
  'vectorAdd',
  'vectorSubtract',
  'sign',
  'sign4',
  'sin',
  'cos',
  'add',
  'subtract',
  'product',
  'divide',
  'mod',
  'if',
  'isTrue',
  'isFalse',
  'lessThan',
  'lessThanOrEqualTo',
  'greaterThan',
  'greaterThanOrEqualTo',
  'equals',
  'notEquals',
  'and',
  'or',
  'max',
  'min',
  'map',
  'reduce',
  'filter',
  'all',
  'none',
  'some',
  'merge',
  'in',
  'missing',
  'missingSome',
  'cat',
  'substr',
  'set',
  // 'mm',
  // 'log',
];

export const functionConfigs = {
  ALL: {
    ...cephLogicFunctionNames.reduce((acc, key) => {
      acc[key] = {
        key,
        name: `${key}()`,
        data: key,
      };
      return acc;
    }, {}),
  },
};

export const NO_FORMULA_COLUMN_NAMES = ['analyses.name', 'analyses.unit'];

export const PATIENT_STATUS = {
  ACTIVE: 'active',
  FOLLOW_UP: 'follow-up',
  RETENTION: 'retention',
  INACTIVE: 'inactive',
};

export const USER_ROLES = {
  AUTOCEPH_ADMIN: 'autoceph-admin',
  MEMBER: 'member',
  OWNER: 'owner',
  ORTHOTX_MANAGER: 'orthotx-manager',
  ORTHOTX_PACKAGING: 'orthotx-packaging',
  ORTHOTX_ORTHODONTIST: 'orthotx-orthodontist',
  ORTHOTX_DENTIST: 'orthotx-dentist',
  PHIMENTUM_DEV: 'phimentum-dev',
  orthotxRoles() {
    return [
      this.ORTHOTX_MANAGER,
      this.ORTHOTX_ORTHODONTIST,
      this.ORTHOTX_PACKAGING,
      this.ORTHOTX_DENTIST,
    ];
  },
  // add other roles here
};

// index matters: maps to element ordering in paper elements after svg import
export const CHILD_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'UAA',
  'LAA',
  'URAB',
  'ULAB',
  'LRAB',
  'LLAB',
  'URBC',
  'ULBC',
  'LRBC',
  'LLBC',
  'URCD',
  'ULCD',
  'LRCD',
  'LLCD',
  'URDE',
  'ULDE',
  'LRDE',
  'LLDE',
];

export const CHILD_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'URA',
  'LRA',
  'ULA',
  'LLA',
  'URB',
  'LRB',
  'ULB',
  'LLB',
  'URC',
  'LRC',
  'ULC',
  'LLC',
  'URD',
  'LRD',
  'ULD',
  'LLD',
  'URE',
  'LRE',
  'ULE',
  'LLE',
];

export const ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'U11',
  'L11',
  'UR12',
  'UL12',
  'LR12',
  'LL12',
  'UR23',
  'UL23',
  'LR23',
  'LL23',
  'UR34',
  'UL34',
  'LR34',
  'LL34',
  'UR45',
  'UL45',
  'LR45',
  'LL45',
  'UR56',
  'UL56',
  'LR56',
  'LL56',
  'UR67',
  'UL67',
  'LR67',
  'LL67',
  'UR78',
  'UL78',
  'LR78',
  'LL78',
  // 'LL8',
  // 'LR8',
  // 'UL8',
  // 'UR8',
];

export const ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE = [
  'UR8',
  'LR8',
  'UL8',
  'LL8',
  'UR7',
  'LR7',
  'UL7',
  'LL7',
  'UR6',
  'LR6',
  'UL6',
  'LL6',
  'UR5',
  'LR5',
  'UL5',
  'LL5',
  'UR4',
  'LR4',
  'UL4',
  'LL4',
  'UR3',
  'LR3',
  'UL3',
  'LL3',
  'UR2',
  'LR2',
  'UL2',
  'LL2',
  'UR1',
  'LR1',
  'UL1',
  'LL1',
];

export const DENTAL_CHART_ITEM_TYPES = {
  GAP: 'GAP',
  GAP_LABEL: 'GAP_LABEL',
  TOOTH: 'TOOTH',
  // items on teeth
  CLOSED_COIL: 'CLOSED_COIL',
  OPEN_COIL: 'OPEN_COIL',
  BRACKET: 'BRACKET',
  BUTTON: 'BUTTON',
  CHAIN: 'CHAIN',
  STOP: 'STOP',
  ELASTIC: 'ELASTIC',
  INTERPROXIMAL_REDUCTION: 'INTERPROXIMAL_REDUCTION',
  WIRE: 'WIRE',
  WIRE_BEND: 'WIRE_BEND',
  SINGLE_WIRE_BEND: 'SINGLE_WIRE_BEND',
};

export const DENTAL_ITEM_TYPES_KEY_IN_STEP = {
  [DENTAL_CHART_ITEM_TYPES.CLOSED_COIL]: 'closedCoils',
  [DENTAL_CHART_ITEM_TYPES.OPEN_COIL]: 'openCoils',
  [DENTAL_CHART_ITEM_TYPES.CHAIN]: 'chains',
  [DENTAL_CHART_ITEM_TYPES.STOP]: 'stops',
  [DENTAL_CHART_ITEM_TYPES.BUTTON]: 'buttons',
  [DENTAL_CHART_ITEM_TYPES.ELASTIC]: 'elastics',
  [DENTAL_CHART_ITEM_TYPES.INTERPROXIMAL_REDUCTION]: 'interproximalReductions',
  [DENTAL_CHART_ITEM_TYPES.WIRE]: 'wires',
  [DENTAL_CHART_ITEM_TYPES.WIRE_BEND]: 'wireBends',
};

export const PATIENT_CASE_STATES = {
  AWAITING_CASE_ASSIGNMENT: 'awaiting_case_assignment',
  CASE_ASSIGNED: 'case_assigned',
  AWAITING_DX: 'awaiting_dx',
  AWAITING_TX: 'awaiting_tx',
  AWAITING_VIDEO: 'awaiting_video',
  DENTIST_REVIEWING: 'dentist_reviewing',
  TX_REVISION_REQUESTED: 'tx_revision_requested',
  AWAITING_EXEC_PLAN: 'awaiting_exec_plan',
  AWAITING_PACKAGING: 'awaiting_packaging',
  PATIENT_IN_TREATMENT: 'patient_in_treatment',
  AWAITING_REVIEW_ASSIGNMENT: 'awaiting_review_assignment',
  REVIEW_ASSIGNED: 'review_assigned',
  TREATMENT_COMPLETE: 'treatment_complete',
  CASE_BEING_REVIEWED: 'case_being_reviewed',
  PACKAGING_UPDATE_IN_PROGRESS: 'packaging_update_in_progress',
  RETAINER_REQUESTED: 'retainer_requested',
  TREATMENT_CLOSED: 'treatment_closed',
};

export const PATIENT_CASE_TRANSITIONS = {
  NEW_CASE: 'new_case',
  ASSIGN_CASE: 'assign_case',
  ACCEPT_CASE: 'accept_case',
  SUBMIT_DX: 'submit_dx',
  SUBMIT_TX_PLANS: 'submit_tx_plans',
  SUBMIT_VIDEO: 'submit_video',
  REQUEST_REVISION: 'request_revision',
  REVISE_TX_PLANS: 'revise_tx_plans',
  ACCEPT_TX_PLAN: 'accept_tx_plan',
  SUBMIT_EXECUTION_PLAN: 'submit_execution_plan',
  SHIP_BOX: 'ship_box',
  REQUEST_REVIEW: 'request_review',
  ASSIGN_REVIEW: 'assign_review',
  DECLINE_REVIEW: 'decline_review',
  ACCEPT_REVIEW: 'accept_review',
  SUBMIT_REVIEW_WITHOUT_BOX: 'submit_review_without_box',
  SUBMIT_REVIEW_WITH_BOX: 'submit_review_with_box',
  SHIP_REVIEW_BOX: 'ship_review_box',
  CASE_COMPLETE: 'case_complete',
  DECLINE_CASE: 'decline_case',
  REQUEST_RETAINER: 'request_retainer',
  SHIP_RETAINER: 'ship_retainer',
  CLOSE_CASE: 'close_case',
  ANNOTATE_CASE: 'annotate_case',
};

export const PATIENT_CASE_STATE_CONFIGS = {
  [PATIENT_CASE_STATES.AWAITING_CASE_ASSIGNMENT]: {
    // level indicates the position of the state in the state graph
    // why we start from the 10 is maybe in the future we can have another state in the middle of the two state,
    level: 10,
  },
  [PATIENT_CASE_STATES.CASE_ASSIGNED]: {
    level: 20,
  },
  [PATIENT_CASE_STATES.AWAITING_DX]: {
    level: 30,
  },
  [PATIENT_CASE_STATES.AWAITING_TX]: {
    level: 40,
  },
  [PATIENT_CASE_STATES.AWAITING_VIDEO]: {
    level: 45,
  },
  [PATIENT_CASE_STATES.DENTIST_REVIEWING]: {
    level: 50,
  },
  [PATIENT_CASE_STATES.TX_REVISION_REQUESTED]: {
    level: 50,
  },
  [PATIENT_CASE_STATES.AWAITING_EXEC_PLAN]: {
    level: 60,
  },
  [PATIENT_CASE_STATES.AWAITING_PACKAGING]: {
    level: 70,
  },
  [PATIENT_CASE_STATES.PATIENT_IN_TREATMENT]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.RETAINER_REQUESTED]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.AWAITING_REVIEW_ASSIGNMENT]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.REVIEW_ASSIGNED]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.CASE_BEING_REVIEWED]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.PACKAGING_UPDATE_IN_PROGRESS]: {
    level: 80,
  },
  [PATIENT_CASE_STATES.TREATMENT_COMPLETE]: {
    level: 100,
  },
  [PATIENT_CASE_STATES.TREATMENT_CLOSED]: {
    level: 1000,
  },
  isStateCompleted(state, currentState) {
    // if current state is at the beginning or not found in the config
    if (currentState == null || this[currentState] == null) return false;
    // if state is at the beginnning
    if (state == null) return true;
    // if given state is not found in the config
    if (this[state] == null) return false;

    return this[state].level < this[currentState].level;
  },
};

export const ADDRESS_TYPES = {
  PHYSICAL_ADDRESS: 'PHYSICAL_ADDRESS',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
};

// eslint-disable-next-line func-names
export const windowVariables = (function () {
  // eslint-disable-next-line no-underscore-dangle
  window._ACM_SHOULD_SEND_EMAIL_ON_ACTION = null;
  return {
    keys: {
      _ACM_SHOULD_SEND_EMAIL_ON_ACTION: '_ACM_SHOULD_SEND_EMAIL_ON_ACTION',
    },
    setValue(key, value) {
      window[key] = value;
    },
    getValue(key, defaultValue) {
      return window[key] ?? defaultValue;
    },
    clear() {
      Object.keys(this.keys).forEach((k) => {
        window[k] = undefined;
      });
    },
  };
})();

export const IMAGE_CV_TYPES = {
  LABEL: 'label',
  CEPH: 'ceph',
  PA_CEPH: 'pa-ceph',
  AUTOCEPH: 'autoceph',
  NONE: 'none',
};

export const DOCUMENT_KINDS = {
  IMAGE_DOC: 'IMAGE_DOC',
  PERIODONTAL_CHARTING: 'PERIODONTAL_CHARTING',
  DENTAL_MEDICAL_HISTORY: 'DENTAL_MEDICAL_HISTORY',
};

export const TOOTH_NUMBERING_SYSTEMS = {
  ORTHODONTIC: 'ORTHODONTIC',
  UNIVERSAL: 'UNIVERSAL',
};

export const USER_PREFERENCE_KINDS = {
  TOOTH_NUMBERING_SYSTEM: 'toothNumberingSystem',
};

export const SITE_PREFERENCE_KINDS = {
  CLEAR_BRACES_COST: 'clear-braces-cost',
};

export const TOOTH_NUMBERING_SYSTEM_TRANSLATION_KEYS = {
  [TOOTH_NUMBERING_SYSTEMS.ORTHODONTIC]: 'orthodontic',
  [TOOTH_NUMBERING_SYSTEMS.UNIVERSAL]: 'universal',
};

export const ADULT_TEETH_NAMES_UNIVERSAL_MAPPED = {
  UR8: '1',
  UR7: '2',
  UR6: '3',
  UR5: '4',
  UR4: '5',
  UR3: '6',
  UR2: '7',
  UR1: '8',
  UL1: '9',
  UL2: '10',
  UL3: '11',
  UL4: '12',
  UL5: '13',
  UL6: '14',
  UL7: '15',
  UL8: '16',
  LL8: '17',
  LL7: '18',
  LL6: '19',
  LL5: '20',
  LL4: '21',
  LL3: '22',
  LL2: '23',
  LL1: '24',
  LR1: '25',
  LR2: '26',
  LR3: '27',
  LR4: '28',
  LR5: '29',
  LR6: '30',
  LR7: '31',
  LR8: '32',
};

function getDocumentRoutes() {
  const str = process.env.REACT_APP_DOCUMENT_ROUTES;
  if (str == null || str.length === 0) return null;

  const routes = str.split(',');

  return routes.reduce((acc, routeString) => {
    const [path, documentKey] = routeString.split(':');
    acc[path] = { documentKey };
    return acc;
  }, {});
}

export const DOCUMENT_ROUTE_CONFIG = getDocumentRoutes();
