/* eslint import/prefer-default-export: 0 */
/* eslint no-param-reassign: ["error", { "props": false }] */ // for immer

import React, { useEffect, useRef, useState, useCallback } from 'react';
import produce from 'immer';
import moment from 'moment';
import { useParams, useHistory, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import api, { includeImageUrls } from './api';
import auth from './auth';
import { DEFAULT_ANALYSIS_NAMES } from '../../analyses';
import { getCurrentAnalysis } from '../ceph/utils';
import services from './services';
import { message } from '../ui/views/Message';
import { SITE_PREFERENCE_KINDS, USER_PREFERENCE_KINDS, VISIT_KINDS } from '../configs/constants';
import navigation from './navigation';
import { appScroll } from './utils';

// define a hook for getting previous props/state values
// see: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value, initialValue) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// implements simple toggle logic out of the box
export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue((prevVal) => !prevVal);
  }, []);
  return [value, toggle, setValue];
};

export const useApiVersion = () => {
  const [apiVersion, setApiVersion] = useState('');
  useEffect(() => {
    api
      .call('status')
      .then((data) => {
        const rev = data.rev ? ` (${data.rev})` : '';
        setApiVersion(`API ${data.version}${rev}`);
      })
      .catch((error) => {
        console.error(`ERROR. ${JSON.stringify(error)}`); // eslint-disable-line no-console
        api.error('hooks.js', 'could not get API version', { error });
      });
  }, []);
  return apiVersion;
};

export const usePatientImages = (orgId, patientId) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (orgId != null && patientId != null) {
      api
        .call(`orgs/${orgId}/patients/${patientId}/images`)
        .then((data) => {
          setImages(data.map((i) => includeImageUrls(i)));
        })
        .catch((error) => {
          console.error(`ERROR. ${JSON.stringify(error)}`); // eslint-disable-line no-console
          api.error('hooks.js', 'could not get patient images', { error });
        });
    }
  }, [orgId, patientId]);
  return images;
};

export const useCombinedRefs = (...refs) => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

// calls the callback after setState runs
export const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    if (typeof callback === 'function') {
      callback(state);
    }
  }, [callback, state]);
  return [state, setState];
};

// calls the callback after setState runs
export const useStateWithSetStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(null);
  const setStateWithCallback = useCallback((value, callback) => {
    setState(value);
    ref.current = callback;
  }, []);
  useEffect(() => {
    if (typeof ref.current === 'function') {
      ref.current(state);
    }
  }, [state]);
  return [state, setStateWithCallback];
};

// takes an array of keys to check whether it is loaded
// takes a callback that runs when all the keys values are true
export const useMultiOnLoad = (list, callback) => {
  const initialState = list.reduce((acc, name) => {
    acc[name] = false;
    return acc;
  }, {});
  const innerCallback = useCallback(
    (state) => {
      if (Object.values(state).filter((value) => value === false).length === 0) {
        callback(state);
      }
    },
    [callback],
  );
  const [loaded, setLoaded] = useStateWithCallback(initialState, innerCallback);
  // reset function is async careful using that because it can create race condition
  const reset = () => {
    setLoaded({});
  };
  const handleSetState = useCallback(
    (name) => {
      setLoaded((prev) =>
        produce(prev, (draft) => {
          draft[name] = true;
        }),
      );
    },
    [setLoaded],
  );
  return [handleSetState, reset, loaded];
};

// see: https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
export const useTraceUpdate = (props) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps); // eslint-disable-line no-console
    }
    prev.current = props;
  });
};

export const usePatientData = (patientKey) => {
  const [patientData, setPatientData] = useState({
    patient: {
      id: null,
      key: null,
      referenceID: null,
      name: null,
      dob: null,
      organization: null,
      image: {
        url: null,
      },
    },
  });
  useEffect(() => {
    services.getPatientWithKey(patientKey).then((patient) => {
      setPatientData((prev) =>
        produce(prev, (draft) => {
          draft.patient = {
            id: patient.id,
            key: patient.key,
            name: `${patient.first_name} ${patient.last_name}`,
            dob: patient.birthdate,
            organization: patient.organization,
            referenceID: patient.reference_id,
            image: {
              url: patient.profile_image_thumbnail_url,
            },
          };
        }),
      );
    });
  }, [patientKey]);
  return patientData;
};

export const useStateChange = (savableState, callback) => {
  // get the previous savable data as of the last render
  const previousSavableState = usePrevious(savableState, {});
  // check if state has changed from previous rendering, and
  // if necessary save state to database
  // compare using object identity (thanks to immer)
  useEffect(() => {
    const hasChanged = !Object.keys(savableState)
      .map((k) => previousSavableState[k] === savableState[k])
      .every((x) => x);

    if (hasChanged) {
      callback();
    }
  });
};

export const useNetwork = (onlineFunction, offlineFunction) => {
  useEffect(() => {
    window.addEventListener('offline', offlineFunction);
    window.addEventListener('online', onlineFunction);
    return () => {
      window.removeEventListener('offline', offlineFunction);
      window.removeEventListener('online', onlineFunction);
    };
  }, [offlineFunction, onlineFunction]);
};

export const useEventListener = (key, listener, options) => {
  useEffect(() => {
    window.addEventListener(key, listener, options);
    return () => {
      window.removeEventListener(key, listener);
    };
  }, [key, listener, options]);
};

export const useResize = (listener, options) => {
  useEventListener('resize', listener, options);
};

export const useScroll = (listener, options) => {
  useEventListener('scroll', listener, options);
};

export const useKeyDown = (listener) => {
  useEventListener('keydown', listener);
};

export const useMatchMedia = (listener, media) => {
  useEffect(() => {
    const x = window.matchMedia(media) ?? {};
    // call initial
    if (x != null) {
      listener(x);

      if (x.addEventListener != null) {
        listener(x);
        x.addEventListener('change', listener);
      } else if (x.addListener != null) {
        // addListener is used by Safari and
        // Safari still does not have a support for addEventListener
        x.addListener(listener);
      }
    }
    return () => {
      if (x != null) {
        if (x.removeEventListener != null) {
          x.removeEventListener('change', listener);
        } else if (x.removeListener != null) {
          x.removeListener(listener);
        }
      }
    };
  }, [listener, media]);
};

export const useIsMobile = (initialValue = false) => {
  const [isMobile, setIsMobile] = useState(initialValue);
  const listener = useCallback((screen) => {
    setIsMobile(screen.matches);
  }, []);
  useMatchMedia(listener, 'screen and (max-width:768px)');

  return [isMobile, setIsMobile];
};

export const useLastAnalysis = (analyses, callback, imageId, imageLabel) => {
  // effects
  useEffect(() => {
    if (analyses.loading) return;
    // const { id: cephDataImageId, label: imageLabel } = state.cephData.image;
    // Get last selected analysis for this page
    // or get last selected analysis
    // or use the default analysis per label
    let initialAnalysis =
      auth.getLocalPreference(['lastAnalysisForImage', imageId]).analysis ||
      auth.getLocalPreference(['lastAnalysisForLabel', imageLabel]).analysis;

    // if analysis is invalid, use default instead
    initialAnalysis =
      getCurrentAnalysis(analyses, initialAnalysis) != null
        ? initialAnalysis
        : DEFAULT_ANALYSIS_NAMES[imageLabel];
    callback(initialAnalysis);
  }, [analyses, imageId, imageLabel, callback]);
};

export const useBeforeUnload = (callback) => {
  // prevent navigating when there are unsaved changes
  // Note: we use 'beforeunload' to prevent navigation away from
  // the page by the /browser/, and we use react-router's <Prompt>
  // component to prevent navigation while within react's control.
  // Both are needed to effectively prevent losing data accidentally.
  useEffect(() => {
    window.addEventListener('beforeunload', callback);
    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);
};

export const useFetchAnalyses = (orgId, setAnalyses) => {
  // get organization 'analyses' preferences
  useEffect(() => {
    if (orgId == null) return;

    const getAnalyses = async () => {
      try {
        const response = await services.getOrgPreferences(orgId, 'analyses');
        setAnalyses((prev) =>
          produce(prev, (draft) => {
            draft.org = response;
            draft.loading = false;
          }),
        );
      } catch (error) {
        console.error(error);
        api.error('useFetchAnalyses', 'unable to fetch analyses preferences', { error });
        setAnalyses((prev) =>
          produce(prev, (draft) => {
            draft.loading = false;
          }),
        );
      }
    };
    getAnalyses();
  }, [orgId, setAnalyses]);
};

export const usePinnedVisits = (patientId) => {
  const [pinnedVisitsByPatientId, setPinnedVisitsByPatientId] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (patientId != null) {
      setPinnedVisitsByPatientId(
        auth.getLocalPreference('pinnedVisitIds', { [patientId]: [] }) || {},
      );
    }
  }, [patientId]);

  const handlePinVisit = useCallback(
    (visit) => {
      setPinnedVisitsByPatientId((prev) => {
        const currentPatientPinnedVisits = prev[patientId] || [];
        if (currentPatientPinnedVisits.find((visitId) => visitId === visit.id) == null) {
          message.info(
            t('pinned-visit-to-top', { date: t('date', { date: moment(visit.date) }) }),
            t('you-can-see-visit-on-top-of-the-page'),
            'pin',
          );
          const ids = [...currentPatientPinnedVisits, visit.id];
          const pinnedVisitsMappedByPatientId = { ...prev, [patientId]: ids };
          auth.setLocalPreference('pinnedVisitIds', pinnedVisitsMappedByPatientId);
          return pinnedVisitsMappedByPatientId;
        }
        const ids = currentPatientPinnedVisits.filter((visitId) => visitId !== visit.id);
        const pinnedVisitsMappedByPatientId = { ...prev, [patientId]: ids };
        auth.setLocalPreference('pinnedVisitIds', pinnedVisitsMappedByPatientId);
        return pinnedVisitsMappedByPatientId;
      });
    },
    [t, patientId],
  );

  return [pinnedVisitsByPatientId, handlePinVisit];
};

export const usePatientAndVisits = (patientKey, t) => {
  const [visits, setVisits] = useState([]);
  const [patient, setPatient] = useState({
    id: null,
    name: null,
    dob: null,
    referenceID: null,
    image: null,
    info: {},
    organization: null,
  });
  const [loading, setLoading] = useState(true);
  const page = 1;
  const pageSize = 1000;

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const patientData = await services.patients.getPatientWithKey(patientKey);
        const orgId = patientData.organization.id;
        const patientId = patientData.id;
        const casesData = await services.getPatientCases(patientData.key);
        const acceptedTreatmentPlanKey = casesData?.[0]?.data?.approved_tx_plan_visit_key;

        const patientObject = {
          id: patientData.id,
          name: `${patientData.first_name} ${patientData.last_name}`,
          image: patientData.profile_image_thumbnail_url,
          dob: patientData.birthdate,
          referenceID: patientData.reference_id,
          info: patientData.info || {},
          profileImageManuallySet: patientData.profile_image_manually_set,
          profileImage: patientData.profile_image,
          organization: patientData.organization,
        };
        // set patient data
        setPatient(patientObject);

        const visitsData = await services.getVisits(orgId, patientId, page, pageSize, true, true);

        const updatedVisits = visitsData.results.map((o) => {
          const conditionalData = {};

          if (o.kind === VISIT_KINDS.TREATMENT_PLAN) {
            conditionalData.isAccepted =
              acceptedTreatmentPlanKey != null ? o.key === acceptedTreatmentPlanKey : null;
          }

          return {
            id: o.id,
            key: o.key,
            date: o.date,
            dateCreated: o.date_created,
            dateUpdated: o.date_updated,
            lastUpdatedBy: o.last_updated_by,
            author: o.author,
            documents: o.documents.map((doc) => {
              return {
                ...doc,
                image:
                  doc.image != null
                    ? {
                        ...includeImageUrls(doc.image),
                        href: navigation.pages.ANALYSIS_VIEW.getUrl(
                          orgId,
                          patientData.key,
                          o.id,
                          doc.image.id,
                        ),
                      }
                    : null,
              };
            }),

            kind: o.kind,
            data: o.data,
            chartNotes: o.text || '',
            tags: o.tags,
            ...conditionalData,
          };
        });
        // update state
        setVisits(updatedVisits);
      } catch (error) {
        console.error('unable to get patient data', error); // eslint-disable-line no-console
        api.error('PatientView', 'unable to get patient data', { error });
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [patientKey, t]);

  return { loading, patient, visits, setVisits };
};

export const useRefetch = () => {
  const [refetchTrigger, setRefetchTrigger] = useState(new Date());

  const refetch = useCallback(() => {
    setRefetchTrigger(new Date());
  }, []);

  return [refetchTrigger, refetch];
};

export const useStateWithLocalStorage = (key, initialValue) => {
  const val = auth.getLocalPreference(key, initialValue);
  const [value, setValue] = useState(val);

  useEffect(() => {
    auth.setLocalPreference(key, value);
  }, [key, value]);

  return [value, setValue];
};

export const usePatient = (patientKey) => {
  const [state, setState] = useState({ loading: false, data: null, error: null });

  useEffect(() => {
    if (patientKey == null) return;
    setState((prev) => ({ ...prev, loading: true }));
    services.patients
      .getPatientWithKey(patientKey)
      .then((patient) => {
        setState({
          loading: false,
          data: patient,
          error: null,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          data: null,
          error,
        });
      });
  }, [patientKey]);

  return [state];
};

export const useOrthodontist = () => {
  const [state, setState] = useState({ loading: true, data: [], error: null });

  useEffect(() => {
    services.cases
      .getOrthodontists()
      .then((response) => {
        setState((prev) =>
          produce(prev, (draft) => {
            draft.data = response;
            draft.loading = false;
            draft.error = null;
          }),
        );
      })
      .catch((error) => {
        console.error(error);
        api.error('useOrthodontist', 'unable to get orthodontists', { error });
        setState((prev) =>
          produce(prev, (draft) => {
            draft.loading = false;
            draft.data = null;
            draft.error = null;
          }),
        );
      });
  }, []);

  return [state, setState];
};

export const useOrganizationUsers = (orgId, page, pageSize) => {
  const [state, setState] = useState({ loading: true, data: [], error: null, page: 1, pages: 1 });

  useEffect(() => {
    services.users
      .getOrgUsers(orgId, page, pageSize)
      .then((response) => {
        setState((prev) =>
          produce(prev, (draft) => {
            draft.data = response.results;
            draft.page = response.page;
            draft.pages = response.pages;
            draft.loading = false;
            draft.error = null;
          }),
        );
      })
      .catch((error) => {
        console.error(error);
        api.error('useOrthodontist', 'unable to get orthodontists', { error });
        setState((prev) =>
          produce(prev, (draft) => {
            draft.loading = false;
            draft.data = null;
            draft.error = null;
          }),
        );
      });
  }, [orgId, page, pageSize]);

  return [state, setState];
};

export const useContentShadow = (scrollableElementRef) => {
  const varRef = useRef({ isHeaderShadow: false, isFooterShadow: false });
  const [state, setContentShadow] = useState({ isHeaderShadow: false, isFooterShadow: false });

  useEffect(() => {
    if (scrollableElementRef?.current == null) return () => {};

    const element = scrollableElementRef.current;
    const listener = element.addEventListener('scroll', (event) => {
      // For shadow that is on top of the content
      if (event.target.scrollTop > 20) {
        if (varRef.current.isHeaderShadow === false) {
          setContentShadow((prev) => ({ ...prev, isHeaderShadow: true }));
          varRef.current.isHeaderShadow = true;
        }
      } else if (varRef.current.isHeaderShadow === true) {
        setContentShadow((prev) => ({ ...prev, isHeaderShadow: false }));
        varRef.current.isHeaderShadow = false;
      }

      const maxScrollHeight =
        event.target.scrollHeight - event.target.getBoundingClientRect().height;
      // For shadow that located on bottom of the content
      if (event.target.scrollTop < maxScrollHeight - 20) {
        if (varRef.current.isFooterShadow === false) {
          setContentShadow((prev) => ({ ...prev, isFooterShadow: true }));
          varRef.current.isFooterShadow = true;
        }
      } else if (varRef.current.isFooterShadow === true) {
        setContentShadow((prev) => ({ ...prev, isFooterShadow: false }));
        varRef.current.isFooterShadow = false;
      }
    });

    return () => {
      element.removeEventListener('scroll', listener);
    };
  }, [scrollableElementRef]);

  return [state, setContentShadow];
};

const userPreferenceArray = [USER_PREFERENCE_KINDS.TOOTH_NUMBERING_SYSTEM];
// Fetch all user preferences as a default behavior
export const useUserPreference = (kinds = userPreferenceArray) => {
  const [state, setState] = useState({ loading: false, data: {}, error: null });

  useEffect(() => {
    if (!Array.isArray(kinds)) return;

    setState((prev) => ({ ...prev, loading: true }));
    const data = {};
    const promises = kinds.map((kind) =>
      services.preferences.getUserPreferences(kind).then((result) => {
        data[kind] = result;
        return result;
      }),
    );

    Promise.all(promises)
      .then(() => {
        setState({
          loading: false,
          data,
          error: null,
        });
      })
      .catch((error) => {
        console.error(error);
        api.error('useUserPreference', 'unable to get user preferences', { error });
        message.danger('unable-to-get-your-preferences', 'please-try-again');
        setState({
          loading: false,
          data: {},
          error,
        });
      });
  }, [kinds]);

  return [state, setState];
};

const sitePreferenceArray = [SITE_PREFERENCE_KINDS.CLEAR_BRACES_COST];
// Fetch all site preferences as a default behavior
export const useSitePreference = (kinds = sitePreferenceArray) => {
  const [state, setState] = useState({ loading: false, data: {}, error: null });

  useEffect(() => {
    if (!Array.isArray(kinds)) return;

    setState((prev) => ({ ...prev, loading: true }));
    const data = {};
    const promises = kinds.map((kind) =>
      services.preferences.getSitePreferences(kind).then((result) => {
        data[kind] = result;
        return result;
      }),
    );

    Promise.all(promises)
      .then(() => {
        setState({
          loading: false,
          data,
          error: null,
        });
      })
      .catch((error) => {
        console.error(error);
        api.error('useSitePreference', 'unable to get site preferences', { error });
        message.danger('unable-to-get-your-preferences', 'please-try-again');
        setState({
          loading: false,
          data: {},
          error,
        });
      });
  }, [kinds]);

  return [state, setState];
};

export const usePouchCardsHTML = (executionPlan) => {
  const [state, setState] = useState({ data: null, loading: true });

  useEffect(() => {
    services.exports
      .getPouchCardsHTML(executionPlan)
      .then((response) => {
        setState((prev) => ({ ...prev, data: response, loading: false }));
      })
      .catch((error) => {
        console.error(error);
        api.error('usePouchCardsHTML', 'unable to get pouch cards html', { error });
        setState((prev) => ({ ...prev, loading: false }));
      });
  }, [executionPlan]);

  return [state, setState];
};

export function useValidateOrgParam(orgParamKey) {
  const { [orgParamKey]: paramOrgKey } = useParams();
  const history = useHistory();

  // If the organization key does not match with the current logged in user's organization key,
  // then, replace the wrong one with the correct one.
  if (auth.orgInfo.key !== paramOrgKey) {
    const correctPath = history.location.pathname.replace(paramOrgKey, auth.orgInfo.key);
    history.replace({
      pathname: correctPath,
      hash: history.location.hash,
      search: history.location.search,
    });
  }
}

export function useHandleNotification(isAuthenticated, history, callback) {
  useEffect(() => {
    const { hash } = history.location;
    const params = new URLSearchParams(hash?.slice(1));
    const match = matchPath(history.location.pathname, {
      path: navigation.pages.PATIENT_VIEW.path,
      exact: true,
      strict: false,
    });
    const { patient_key: patientKey } = match?.params ?? {};
    const notificationKey = params.get('notification');

    if (isAuthenticated && notificationKey?.length > 0) {
      callback(history, patientKey, notificationKey);
    }
  }, [isAuthenticated, history, callback]);
}

export function useNotification(notificationKey) {
  const [state, setState] = useState({ data: null, loading: true, error: null });

  useEffect(() => {
    services.notifications
      .getNotification(notificationKey)
      .then((notification) => {
        setState((prev) => ({
          ...prev,
          data: notification,
          loading: false,
          error: null,
        }));
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          error: error.message,
          loading: false,
        }));
      });
  }, [notificationKey]);

  return [state, setState];
}

export function useDisableAppScroll(condition) {
  useEffect(() => {
    if (condition) {
      appScroll.hide();
    } else {
      appScroll.scroll();
    }
  }, [condition]);
}
