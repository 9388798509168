import React, { useCallback, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import CallbackSpinner from './common/ui/views/CallbackSpinner';
import auth from './common/utils/auth';
import TutorialProvider from './orthodx/components/TutorialProvider';
import './App.css';
import navigation from './common/utils/navigation';
import getLegalDocumentRoutes from './common/utils/getLegalDocumentRoutes';

// orthodx pages
const EnrollUserView = lazy(() => import('./orthodx/pages/EnrollUserView'));
const EditUserView = lazy(() => import('./orthodx/pages/EditUserView'));
const PatientView = lazy(() => import('./orthodx/pages/PatientView'));
const EnrollPatientView = lazy(() => import('./orthodx/pages/EnrollPatientView'));
const EditPatientView = lazy(() => import('./orthodx/pages/EditPatientView'));
const AnalysisView = lazy(() => import('./orthodx/pages/AnalysisView'));
const PatientListView = lazy(() => import('./orthodx/pages/PatientListView'));
const OrgSignIn = lazy(() => import('./orthodx/pages/OrgSignIn'));
const GetStarted = lazy(() => import('./orthodx/pages/GetStarted'));
const AccountSettings = lazy(() => import('./orthodx/pages/AccountSettings'));
const ChangeLog = lazy(() => import('./orthodx/pages/ChangeLog'));
const Payment = lazy(() => import('./orthodx/pages/Payment'));
const NoMatch = lazy(() => import('./common/ui/views/NoMatch'));
const SignUp = lazy(() => import('./orthodx/pages/SignUp'));
const SurgicalTreatmentView = lazy(() => import('./orthodx/pages/SurgicalTreatmentView'));
const PaymentSuccess = lazy(() => import('./orthodx/pages/PaymentSuccess'));
const NotSignedIn = lazy(() => import('./common/ui/pages/NotSignedIn'));
const ModelEditor = lazy(() => import('./orthodx/pages/ModelEditor'));
const DistributorAdmin = lazy(() => import('./orthodx/pages/DistributorAdmin'));
const CallbackView = lazy(() => import('./common/ui/pages/CallbackView'));

// See: https://github.com/ReactTraining/react-router/issues/4105 for
//      difference between <Route component /> vs <Route render />
const App = ({ location, history, isAuthenticated, isRenewingSession }) => {
  let signedInComponents = null;

  const generateRouteKey = useCallback(
    (key) => {
      return `${key}${location.pathname}`;
    },
    [location.pathname],
  );
  // Show spinner if trying to access a protected route until authentication
  // is complete.
  if (isAuthenticated) {
    signedInComponents = [
      <Route
        exact
        key={generateRouteKey('patients')}
        path={navigation.pages.PATIENT_LIST_VIEW.path}
        component={PatientListView}
      />,
      <Route
        exact
        key={generateRouteKey('enroll-patient')}
        path={navigation.pages.ENROLL_PATIENT_VIEW.path}
        component={EnrollPatientView}
      />,
      <Route
        exact
        key={generateRouteKey('enroll-user')}
        path={navigation.pages.ENROLL_USER_VIEW.path}
        component={EnrollUserView}
      />,
      <Route
        exact
        key={generateRouteKey('edit-user')}
        path={navigation.pages.EDIT_USER_VIEW.path}
        component={EditUserView}
      />,
      <Route
        exact
        key={generateRouteKey('edit-patient')}
        path={navigation.pages.EDIT_PATIENT_VIEW.path}
        component={EditPatientView}
      />,
      <Route
        exact
        key={generateRouteKey('patient')}
        path={navigation.pages.PATIENT_VIEW.path}
        component={PatientView}
      />,
      <Route
        exact
        key={generateRouteKey('analysis')}
        path={navigation.pages.ANALYSIS_VIEW.path}
        component={AnalysisView}
      />,
      <Route
        exact
        key={generateRouteKey('surgicalTreatment')}
        path={navigation.pages.STO_VIEW.path}
        component={SurgicalTreatmentView}
      />,
      <Route
        exact
        key={generateRouteKey('account-settings')}
        path={navigation.pages.ACCOUNT_SETTINGS.path}
        component={AccountSettings}
      />,
      <Route
        exact
        key={generateRouteKey('changes')}
        path={navigation.pages.CHANGELOG_VIEW.path}
        component={ChangeLog}
      />,
      <Route
        exact
        key={generateRouteKey('logout')}
        path={navigation.pages.LOGOUT_VIEW.path}
        render={() => {
          auth.logout();
          return <CallbackSpinner />;
        }}
      />,
      <Route
        exact
        key={generateRouteKey('payment')}
        path={navigation.pages.PAYMENT_VIEW.path}
        component={Payment}
      />,
      <Route
        exact
        key={generateRouteKey('paymentSuccess')}
        path={navigation.pages.PAYMENT_SUCCESS_VIEW.path}
        component={PaymentSuccess}
      />,
      <Route
        exact
        key={generateRouteKey('modelViewer')}
        path={navigation.pages.MODEL_VIEWER_VIEW.path}
        component={ModelEditor}
      />,
      <Route
        exact
        key={generateRouteKey('admin')}
        path={navigation.pages.DISTRIBUTOR_ADMIN_VIEW.path}
        component={DistributorAdmin}
      />,
      ...getLegalDocumentRoutes(),
      <Route
        exact
        path="/home"
        render={() => {
          history.push(navigation.pages.PATIENT_LIST_VIEW.getUrl(auth.orgInfo.key));
          return <CallbackSpinner />;
        }}
      />,
    ];
  } else {
    signedInComponents = [
      isRenewingSession ? (
        <React.Fragment key="isRenewing">
          <Route key={generateRouteKey('initialCallback')} path="/" component={CallbackSpinner} />
          <Route
            key={generateRouteKey('patientCallback')}
            path="/:org_id"
            component={CallbackSpinner}
          />
        </React.Fragment>
      ) : (
        <React.Fragment key="isNotRenewing">
          <Route
            key={generateRouteKey('patients')}
            path="/:org_id"
            render={() => <NotSignedIn history={history} />}
          />
          <Route exact path="/" component={GetStarted} />
        </React.Fragment>
      ),
    ];
  }

  return (
    <TutorialProvider location={location} isAuthenticated={isAuthenticated}>
      <div className="App">
        <Suspense fallback={<CallbackSpinner />}>
          <Switch>
            {/* index */}
            <Route
              exact
              path={navigation.pages.SIGN_IN_VIEW.path}
              render={() => <NotSignedIn history={history} forceLogin />}
            />
            {/* authentication */}
            <Route
              path="/callback"
              render={() => <CallbackView isRenewingSession={isRenewingSession} />}
            />
            <Route exact path="/orgs" component={OrgSignIn} />

            <Route exact path={navigation.pages.SIGN_UP_VIEW.path} component={SignUp} />

            {/* signup aliases -- for promotion */}
            <Route exact path="/tod" component={SignUp} />

            {/* 404 */}
            <Route exact path={navigation.pages.UNKNOWN_PAGE.path} component={NoMatch} />

            {/* application -- only after signing in */}
            {signedInComponents}
            {/* redirect to patient list page */}
            <Redirect exact from="/:org_id" to="/:org_id/patients" />
            {/* 404 */}
            <Route component={NoMatch} />
          </Switch>
        </Suspense>
      </div>
    </TutorialProvider>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isRenewingSession: PropTypes.bool.isRequired,
};

export default App;
