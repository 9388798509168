import React from 'react';
import PropTypes from 'prop-types';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { animateElementIn, animateElementOut, downloadFile } from '../../utils/utils';
import VisitImageCard from '../../../orthodx/components/VisitImageCard';
import { documentType, numberOrStringType } from '../../utils/types';

function FlippedImageCollection({
  orgId,
  imageDocuments,
  children,
  isEditing,
  onImageDelete,
  onImageEdit,
  onImageClick,
  onLabelChange,
  onDownload,
}) {
  return (
    <Flipper flipKey={imageDocuments.map((document) => document.image.id).join('-')}>
      <div className="is-flex is-flex-wrap-wrap">
        {imageDocuments.map((document, index) => (
          <Flipped
            key={document.image.id}
            flipId={document.image.id}
            onAppear={animateElementIn}
            onExit={animateElementOut}
          >
            {/* This div is important for flipping to work */}
            <div>
              <VisitImageCard
                key={document.key}
                orgId={orgId}
                index={index}
                document={document}
                isEditing={isEditing}
                onImageEdit={onImageEdit}
                onImageDelete={onImageDelete}
                onImageClick={onImageClick}
                onDownload={onDownload}
                onLabelChange={onLabelChange}
              />
            </div>
          </Flipped>
        ))}
        {children}
      </div>
    </Flipper>
  );
}

FlippedImageCollection.defaultProps = {
  children: undefined,
  isEditing: false,
  onDownload: downloadFile,
  onImageDelete: undefined,
  onImageEdit: undefined,
  onImageClick: undefined,
  onLabelChange: undefined,
};

FlippedImageCollection.propTypes = {
  orgId: numberOrStringType.isRequired,
  imageDocuments: PropTypes.arrayOf(documentType).isRequired,
  children: PropTypes.node,
  isEditing: PropTypes.bool,
  onImageDelete: PropTypes.func,
  onImageEdit: PropTypes.func,
  onImageClick: PropTypes.func,
  onLabelChange: PropTypes.func,
  onDownload: PropTypes.func,
};

export default FlippedImageCollection;
