/* eslint no-param-reassign: ["error", { "props": false }] */ // for immer

import React, { useState, useEffect, useContext, useCallback, createContext } from 'react';
import ReactJoyride from 'react-joyride';
import { produce } from 'immer';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import services from '../../common/utils/services';
import api from '../../common/utils/api';
import { TUTORIAL_KEYS } from '../../common/configs/constants';
import { useIsMobile } from '../../common/utils/hooks';
import { ResetPageTutorialProvider } from '../../common/utils/contexts';

const locale = {
  back: i18n.t('back'),
  close: i18n.t('close'),
  last: i18n.t('last'),
  next: i18n.t('next'),
  open: i18n.t('open-the-dialog'),
  skip: i18n.t('skip'),
};
const styles = {
  buttonNext: {
    WebkitAppearance: 'none',
    backgroundColor: '#3298dc',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    cursor: 'pointer',
    fontSize: 16,
    lineHeight: 1,
    padding: 8,
  },
};

const commonReactJoyrideStepConfig = {
  disableBeacon: true,
};

const tutorials = {
  [TUTORIAL_KEYS.PATIENT_LIST_VIEW]: () => ({
    steps: [
      {
        ...commonReactJoyrideStepConfig,
        key: 'initial',
        target: 'body',
        placement: 'center',
        content: <h2>Let&apos;s begin our journey!</h2>,
        locale,
        styles,
      },
      // {
      //   target: '[data-test=drawer-profile-sbutton]',
      //   placement: 'right',
      //   content: 'You can click here to open drawer!',
      //   locale,
      //   styles,
      // },
      // {
      //   target: '[data-test=drawer]',
      //   placement: 'right',
      //   content: '',
      //   locale,
      //   styles,
      // },
      // {
      //   target: '[data-test=drawer] [data-test=create-new-patient]',
      //   placement: 'right',
      //   content: 'You can create new patient from here!',
      //   locale,
      //   styles,
      // },
      {
        ...commonReactJoyrideStepConfig,
        key: 'createNewPatient',
        target: '[data-test=create-new-patient]',
        placement: 'right',
        content: 'You can add a new patient from here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'searchPatient',
        target: '[data-test=search-patient]',
        placement: 'left',
        content: 'You can search your patients from here!',
        spotlightPadding: 0,
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'userMenu',
        target: '[data-test=user-menu]',
        placement: 'left',
        content: 'You can manage your settings from the menu!',
        locale,
        styles,
      },
      // {
      //   target: '[data-test=hamburger-menu]',
      //   placement: 'left',
      //   content: 'You can manage your settings from here!',
      //   locale,
      //   styles,
      // },
      {
        ...commonReactJoyrideStepConfig,
        key: 'getHelp',
        target: '[data-test=get-help]',
        placement: 'left',
        content: "We're here to help! You can reach support from here!",
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'patientRow',
        target: 'tbody tr',
        placement: 'top',
        content: 'Click on a patient to go to their chart notes!',
        locale,
        styles,
      },
    ],
    callback: (data) => {
      if (data.step == null) return;
      // if (data.type === 'step:after' && data.step.target === '[data-test=drawer-profile-button]') {
      //   const menuElement = document.querySelector('[data-test=drawer-profile-button]');
      //   // menuElement.classList.add('slide-in');
      //   menuElement.click();
      // }
      // if (data.type === 'step:after' && data.step.target === '[data-test=drawer] [data-test=create-new-patient]') {
      //   // const menuElement = document.querySelector('[data-test=drawer]');
      //   // menuElement.style.left = null;
      //
      //   const menuElement = document.querySelector('[data-test=drawer-profile-button]');
      //   // menuElement.classList.add('slide-in');
      //   menuElement.click();
      // }

      if (data.type === 'step:before' && data.step.target === '[data-test=user-menu]') {
        const menuElement = document.querySelector('[data-test=user-menu]');
        const hamburgerElement = document.querySelector('[data-test=hamburger-menu]');
        menuElement.classList.add('is-active');
        hamburgerElement.click();
      }
      if (data.type === 'step:after' && data.step.target === '[data-test=get-help]') {
        const menuElement = document.querySelector('[data-test=user-menu]');
        menuElement.classList.remove('is-active');
      }
    },
  }),
  [TUTORIAL_KEYS.PATIENT_VIEW]: () => ({
    steps: [
      {
        ...commonReactJoyrideStepConfig,
        key: 'patientInfo',
        target: '[data-test=patient-info]',
        placement: 'right',
        content: `Click on "${i18n.t(
          'view-patient-details',
        )}" to see or edit the patient's information.`,
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'createNewVisit',
        target: '[data-test=create-new-visit]',
        placement: 'right',
        content: 'You can add new chart notes or images from here!',
        spotlightPadding: 0,
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'searchVisit',
        target: '[data-test=search-visit]',
        placement: 'bottom',
        content: 'You can search your chart notes from here!',
        locale,
        styles,
      },
      // {
      //   key: 'visitCard',
      //   target: '.Visit:not([data-test=create-new-visit])',
      //   content: 'You can manage visit from here!',
      //   locale,
      //   styles,
      // },
      {
        ...commonReactJoyrideStepConfig,
        key: 'pinImageButton',
        target: '.Visit [data-test=pin-image-button]',
        content: 'You can pin a note to the top by clicking this!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'openImageGridButton',
        target: '.Visit [data-test=open-image-grid-button]',
        content: "View this note's images in a template format by clicking this!",
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'visitMenuButton',
        target: '.Visit [data-test=visit-menu-button]',
        content: 'Edit, delete, or export this note as a PDF from this menu!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'cephImage',
        target: '.Visit [data-test=visit-image-card-analysis]',
        placement: 'left',
        content: `Images that support analysis, such as a lateral ceph, will show the "${i18n.t(
          'go-to-analysis',
        )}" button.
          Click the "${i18n.t('go-to-analysis')}" button to trace and analyze the image.`,
        locale,
        styles,
      },
    ],
  }),
  [TUTORIAL_KEYS.ANALYSIS_VIEW]: () => ({
    steps: [
      {
        ...commonReactJoyrideStepConfig,
        key: 'analysisSelector',
        target: '[data-test=analysis-selector]',
        content: 'This is selected analysis. You can change the analysis by clicking here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'autoTraceButton',
        target: '[data-test=auto-trace-button]',
        content: 'Automatically trace the image by clicking this button!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'landmarkList',
        target: '[data-key=header-0]', // this is data-key because AccordionList uses data-key internally
        placement: 'right',
        content: 'You can select or place landmarks from here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'annotationList',
        target: '[data-key=header-1]',
        placement: 'right',
        content: `Planes and curves will appear after the image has been traced.
          You can show or hide individual annotations from here!`,
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'controls',
        target: '[data-key=header-2]',
        placement: 'right',
        content: 'You can control and calibrate the image display from here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'analysisTable',
        target: '[data-test=analysis-table]',
        placement: 'left',
        content: 'Here you can see the selected analysis!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'resizeHandle',
        target: '[data-test=right-sidebar-resize-handle]',
        content: 'Resize the table by dragging this handle.',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'cephMoveLandmark',
        target: '[data-test=ceph-canvas]',
        content: (
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/gifs/move-landmark.gif`}
              alt="move landmark gif"
              width={300}
            />
            <h4 className="subtitle is-6">
              You can adjust landmarks by clicking and dragging them.
            </h4>
          </div>
        ),
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'navigateBetweenAnalysis',
        target: '[data-test=navigate-between-analysis]',
        content: 'If a patient has multiple images to analyze, you can navigate between them here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'createSTO',
        target: '[data-test=create-sto]',
        content: 'You can create Surgical Treatment Objectives from here!',
        locale,
        styles,
      },
      // {
      //   key: 'exportPDF',
      //   target: '[data-test=export-pdf]',
      //   content: 'You can export PDF from here!',
      //   locale,
      //   styles,
      // },
    ],
    callback: (data) => {
      if (data.step == null) return;
      if (data.type === 'step:before' && data.step.target === '[data-key=header-1]') {
        const annotationListElement = document.querySelector('[data-key=header-1]');
        annotationListElement.children[0].children[0].click();
      }
      if (data.type === 'step:after' && data.step.target === '[data-key=header-1]') {
        const annotationListElement = document.querySelector('[data-key=header-1]');
        annotationListElement.children[0].children[0].click();
      }
      if (data.type === 'step:before' && data.step.target === '[data-key=header-2]') {
        const controlsElement = document.querySelector('[data-key=header-2]');
        controlsElement.children[0].children[0].click();
      }
      if (data.type === 'step:after' && data.step.target === '[data-key=header-2]') {
        const controlsElement = document.querySelector('[data-key=header-2]');
        controlsElement.children[0].children[0].click();
      }

      if (
        (data.type === 'step:after' &&
          data.step?.target === '[data-test=navigate-between-analysis]') ||
        (data.type === 'tour:status' && data.step?.target === '[data-test=create-sto]') ||
        (data.type === 'tour:status' && data.step?.target === '[data-test=export-pdf]')
      ) {
        const menuElement = document.querySelector('[data-test=user-menu]');
        menuElement.classList.add('is-active');
      }
      if (
        (data.type === 'step:after' && data.step?.target === '[data-test=export-pdf]') ||
        data.type === 'tour:end'
      ) {
        const menuElement = document.querySelector('[data-test=user-menu]');
        menuElement.classList.remove('is-active');
      }
    },
  }),
  [TUTORIAL_KEYS.STO_VIEW]: () => ({
    steps: [
      {
        ...commonReactJoyrideStepConfig,
        key: 'analysisSelector',
        target: '[data-test=analysis-selector]',
        content: 'This is selected analysis. You can change the analysis by clicking here.',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'cephCanvas',
        target: '[data-test=ceph-canvas]',
        content:
          'Click on a curve or a blue dot to select a curve. Drag the curve to move it. An arrow will appear to apply a rotation!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'stoGroupOptions',
        target: '[data-test=sto-group-options]',
        content: 'You can see or edit your changes here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'duplicateSTO',
        target: '[data-test=duplicate-sto]',
        content: 'You can duplicate the current STO from them menu here!',
        locale,
        styles,
      },
      {
        ...commonReactJoyrideStepConfig,
        key: 'exportPDF',
        target: '[data-test=export-pdf]',
        content: 'You can export a PDF from here!',
        locale,
        styles,
      },
    ],
    callback: (data) => {
      if (data.step == null) return;
      if (data.type === 'step:after' && data.step?.target === '[data-test=sto-group-options]') {
        const menuElement = document.querySelector('[data-test=user-menu]');
        menuElement.classList.add('is-active');
      }
      if (data.type === 'step:after' && data.step?.target === '[data-test=export-pdf]') {
        const menuElement = document.querySelector('[data-test=user-menu]');
        menuElement.classList.remove('is-active');
      }
    },
  }),
  [TUTORIAL_KEYS.ACCOUNT_SETTINGS]: () => ({
    steps: [
      {
        ...commonReactJoyrideStepConfig,
        key: 'createNewUser',
        target: '[data-test=add-new-user]',
        content: 'You can add new user by clicking this!',
        locale,
        styles,
      },
      // TODO: select plan
    ],
  }),
};

// TODO: make mobile compatible // currently disabled in mobile

const joyrideStyle = {
  options: {
    zIndex: 100000,
  },
};

const TutorialContext = createContext();
export const useTutorial = () => useContext(TutorialContext);

const TutorialProvider = ({ location, isAuthenticated, children }) => {
  const preferences = React.useRef({});
  const [isMobile] = useIsMobile();
  const [state, setState] = useState({
    configKey: null,
    run: true,
    loading: true,
    closed: {},
    key: 0,
  });

  useEffect(() => {
    setState((prev) =>
      produce(prev, (draft) => {
        draft.configKey = null;
      }),
    );
  }, [location.pathname]);

  const fetchTutorialPreferences = useCallback(() => {
    return services
      .getUserPreferences('tutorials')
      .then((data) => {
        preferences.current = data;
        setState((prev) =>
          produce(prev, (draft) => {
            draft.loading = false;
          }),
        );
      })
      .catch((error) => {
        console.error('ERROR', error); // eslint-disable-line no-console
        api.error('TutorialProvider', 'unable to get tutorial preferences', { error });
        setState((prev) =>
          produce(prev, (draft) => {
            draft.loading = false;
          }),
        );
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // services.setUserPreferences('tutorials', {});
      fetchTutorialPreferences();
    }
  }, [isAuthenticated, fetchTutorialPreferences]);

  const config = tutorials[state.configKey];
  const checkAndSetState = useCallback(({ configKey, key }) => {
    setState((prev) =>
      produce(prev, (draft) => {
        draft.configKey = configKey;
        draft.key = key;
        draft.run = true;
      }),
    );
  }, []);

  // const updatePreferences = (configKey, key) => {
  //   if (preferences.current[configKey] == null) {
  //     preferences.current[configKey] = {
  //       steps: {},
  //     };
  //   }
  //   preferences.current[configKey][key] = true;
  //   services.setUserPreferences('tutorials', preferences.current);
  // };

  const updatePreferenceSteps = (configKey, key) => {
    if (preferences.current[configKey] == null) {
      preferences.current[configKey] = {
        steps: {},
      };
    }
    preferences.current[configKey].steps[key] = true;
    services.setUserPreferences('tutorials', preferences.current);
  };

  const skipAllSteps = (configKey) => {
    if (preferences.current[configKey] == null) {
      preferences.current[configKey] = {
        steps: {},
      };
    }
    if (config != null) {
      config().steps.forEach((step) => {
        preferences.current[configKey].steps[step.key] = true;
      });
      services.setUserPreferences('tutorials', preferences.current);
    }
  };

  const resetPageSteps = (configKey) => {
    if (preferences.current?.[configKey]) {
      delete preferences.current[configKey];
    }
    return services.setUserPreferences('tutorials', preferences.current);
  };

  const callback = (data) => {
    if (data.action === 'skip') {
      window.gtag('event', 'tutorial_skip', {
        event_category: 'Tutorial',
        event_label: data.step?.key,
      }); // google analytics
      skipAllSteps(state.configKey);
    } else if (data.action === 'close' && data.step != null) {
      window.gtag('event', 'tutorial_close', {
        event_category: 'Tutorial',
        event_label: data.step?.key,
      }); // google analytics
      // updatePreferences(state.configKey, 'closed');
      // if tutorial is closed by user, then skip all steps for that page
      skipAllSteps(state.configKey);
      setState((prev) =>
        produce(prev, (draft) => {
          draft.run = false;
          // enabling closed state will close the tutorial for that page until app is reloaded
          // draft.closed[state.configKey] = true;
        }),
      );
    } else if (
      data.type === 'step:before' ||
      (data.status === 'running' && data.type !== 'error:target_not_found')
    ) {
      window.gtag('event', 'tutorial_next', {
        event_category: 'Tutorial',
        event_label: data.step?.key,
      }); // google analytics
      if (data.step?.key != null) {
        updatePreferenceSteps(state.configKey, data.step.key);
      }
    }

    // if target is not found we are stopping the tutorial because for some reason react-joyride shows the tooltip
    // if (data.type === 'error:target_not_found') {
    //   setState(prev => produce(prev, draft => {
    //     draft.run = false;
    //   }));
    //   return;
    // }
    if (config?.().callback) {
      config().callback(data);
    }
  };

  const resetPageTutorial = useCallback(
    async (pageKey) => {
      try {
        setState((prev) => ({
          ...prev,
          run: false,
        }));
        await resetPageSteps(pageKey);
        await fetchTutorialPreferences();
        setState((prev) =>
          produce(prev, (draft) => {
            draft.run = true;
            draft.closed[pageKey] = false;
          }),
        );
      } catch (error) {
        console.error(error);
        api.error('TutorialProvider', 'unable to reset tutorial', { error });
      }
    },
    [fetchTutorialPreferences],
  );

  if (!isAuthenticated) {
    return children;
  }

  const isClosed = state.closed[state.configKey] === true;
  const steps = isMobile
    ? []
    : config?.().steps.filter(
        (step) =>
          !(preferences.current && preferences.current?.[state.configKey]?.steps?.[step.key]),
      );
  return (
    <TutorialContext.Provider value={checkAndSetState}>
      <ResetPageTutorialProvider value={resetPageTutorial}>
        {state.loading === false && (
          <>
            {children}
            <ReactJoyride
              key={location.pathname + state.key}
              styles={joyrideStyle}
              steps={steps}
              callback={callback}
              continuous
              run={isClosed === true ? false : state.run}
              scrollToFirstStep={false}
              showProgress
              showSkipButton
              disableScrollParentFix
              scrollOffset={186 + 52}
            />
          </>
        )}
      </ResetPageTutorialProvider>
    </TutorialContext.Provider>
  );
};

TutorialProvider.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default TutorialProvider;
