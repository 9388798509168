import React from 'react';

const ThreeDotLoader = () => (
  <div key="loader" className="extra-column-gap-br">
    <figure className="image is-128x128" style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: 32, left: 32 }}>
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </figure>
  </div>
);

export default ThreeDotLoader;
