import lunr from 'lunr';
import { differenceInMonths } from 'date-fns';
import { sort } from 'ramda';
import cephLogic, { mm } from '../ceph/cephLogic';
import { CONSTRUCTED_LANDMARKS, UNITS, STO_REQUIRED_LANDMARKS } from '../../analyses';
import { uniformCoordinate } from '../ceph/utils';
import Phi from '../phi/Phi';
import {
  ADULT_TEETH_NAMES_UNIVERSAL_MAPPED,
  CONNECTION_TYPES,
  FILE_TYPES,
  LABEL_CONFIGS,
  TOOTH_NUMBERING_SYSTEMS,
  VISIT_KINDS,
} from '../configs/constants';
import services from './services';
import api from './api';
import { ANNOTATION_AUTO_CEPH, ANNOTATION_MANUAL_CEPH } from '../ceph/constants';
import { message } from '../ui/views/Message';

export const download = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadFile = async (documentInfo, orgId) => {
  try {
    let documentFile = documentInfo;
    // if document id is provided instead of document object
    if (typeof documentInfo === 'number') {
      documentFile = await services.documents.getDocument(orgId, documentInfo);
    }
    const response = await window.fetch(documentFile.url);
    const b = await response.blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(b);
    a.setAttribute('download', documentFile.name);
    a.click();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR', error);
    api.error('downloadFile', 'unable to download file', { error });
  }
};

export const preventDefault = (event) => event.preventDefault();

export const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/[^0-9a-z-]/g, '') // Remove any remaining non-accepted characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const scrollToElement = (id, options = {}) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center', ...options });
  }
};

export const calculateRemainingDaysWithGivenDate = (date) =>
  Math.ceil((new Date(date).valueOf() - Date.now()) / 1000 / 60 / 60 / 24);

export const validateEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

/**
 * index given data and create search method
 * @param {string} ref field of reference to object
 * @param {string[]} fields fields to index for search
 * @param {*} data data to search
 */
export const textIndexSearch = (ref, fields, data) => {
  const dataMap = Object.fromEntries(data.map((datum) => [datum[ref], datum]));
  const lunrInstance = lunr(function initLunr() {
    this.ref(ref);
    fields.forEach((field) => this.field(field.name, field.attributes), this);
    data.forEach((datum) => this.add(datum), this);
  });

  return (searchQuery) =>
    lunrInstance.search(searchQuery).map((item) => ({ ...dataMap[item.ref], score: item.score }));
};

/**
 * find which object is `created` and which one is `deleted`
 * @param original - original object
 * @param secondObject - object to compare
 * @param mapFunc - compare objects with given map function, default is mapped by `id`
 * @returns {{deleted: *, created: *}}
 */
export const findDifferences = (original, secondObject, mapFunc = (o) => o.id) => {
  const existingObjects = original.map(mapFunc);
  const newObjects = secondObject.map(mapFunc);
  const deleted = existingObjects.filter((x) => !newObjects.includes(x));
  const created = newObjects.filter((x) => !existingObjects.includes(x));
  return { deleted, created };
};

export const eventSourceMessageListener =
  (connectionType, condition = () => true) =>
  (value, callback) =>
  (event) => {
    const { type, data } = JSON.parse(event.data);
    if (type === connectionType && condition(data, value)) {
      callback(data);
    }
  };

export const createLabelMessageListener = eventSourceMessageListener(
  CONNECTION_TYPES.LABEL_IMAGE,
  (data, value) => data.image_id === value,
);

export const createAnnotationMessageListener = eventSourceMessageListener(
  CONNECTION_TYPES.ANNOTATE_IMAGE,
  (data, value) => data.image_id === value,
);

export const createUpdateAllNotificationsMessageListener = eventSourceMessageListener(
  CONNECTION_TYPES.UPDATE_ALL_NOTIFICATIONS,
);

export const createPostNotificationMessageListener = eventSourceMessageListener(
  CONNECTION_TYPES.POST_NOTIFICATION,
);

// see: https://css-tricks.com/converting-color-spaces-in-javascript/
export function RGBToHex(rgbString) {
  // Choose correct separator
  const sep = rgbString.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgb = rgbString.substr(4).split(')')[0].split(sep);

  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

// a = point1
// b = point2
// c = check against
// take the cross product of two vectors if the value is positive it will tell us that point c in the left
// if the result is negative it will say that point is in the right side
// if the result zero point is parallel to that vector
// this function assumes point is not in the given vector
export function isLeft(a, b, c) {
  let bottomPoint;
  let topPoint;
  // decide which one is bottom point
  if (a.y > b.y) {
    bottomPoint = b;
    topPoint = a;
  } else {
    bottomPoint = a;
    topPoint = b;
  }
  return (
    (topPoint.x - bottomPoint.x) * (c.y - bottomPoint.y) -
      (topPoint.y - bottomPoint.y) * (c.x - bottomPoint.x) >
    0
  );
}

/**
 * Decimal adjustment of a number. see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Number} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  let newValue = +value;
  const newExp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (Number.isNaN(value) || !(typeof newExp === 'number' && newExp % 1 === 0)) {
    return NaN;
  }
  // Shift
  newValue = newValue.toString().split('e');
  newValue = Math[type](+`${newValue[0]}e${newValue[1] ? +newValue[1] - newExp : -newExp}`);
  // Shift back
  newValue = newValue.toString().split('e');
  return +`${newValue[0]}e${newValue[1] ? +newValue[1] + newExp : newExp}`;
}

// Decimal floor
// example: round10(55.549, -1);  // 55.5
export const floor10 = (value, exp) => decimalAdjust('floor', value, exp);

export function getModelDocumentTypeByExtension(name) {
  if (/\.stl$/i.test(name)) {
    return FILE_TYPES.STL.NAME;
  }
  if (/\.obj$/i.test(name)) {
    return FILE_TYPES.OBJ.NAME;
  }
  if (/\.ply$/i.test(name)) {
    return FILE_TYPES.PLY.NAME;
  }
  return null;
}

export const getAgeInYears = (dateOfBirth, visitDate = null, floor = true, roundToDecimal = 1) => {
  if (dateOfBirth == null) return null;
  const visitDateOrNow = visitDate ? new Date(visitDate) : new Date();
  const patientAgeInMonths = differenceInMonths(visitDateOrNow, new Date(dateOfBirth));
  const patientAgeInYears =
    Math.round((patientAgeInMonths / 12) * 10 ** roundToDecimal) / 10 ** roundToDecimal;
  return floor ? Math.floor(patientAgeInYears) : patientAgeInYears;
};

// TODO: add analyses when exporting pdf
export const getTable = (
  analysesObj,
  selectedAnalysis,
  cephData,
  displayedColumns = null,
  patient,
  visit,
) => {
  const analyses = { ...analysesObj.common, ...analysesObj.org };
  const analysis = selectedAnalysis;
  // check that given analysis exists
  if (!analyses[analysis]) {
    return { analysis, header: [], columns: [] };
  }

  // any metadata for the table goes here.
  const tableMeta = {
    displayedColumns: displayedColumns || analyses[analysis].displayedColumns,
    editableColumns: analyses[analysis].editableColumns,
    descriptionColumn: analyses[analysis].descriptionColumn,
  };

  // Variables accessible to cephLogic/jsonLogic formulas
  const vars = {
    // landmarks will be added here as a flat key value pairs
    calibration: cephData.meta.calibration,
    age: patient.dob != null ? getAgeInYears(patient.dob, visit.date, false, 2) : null,
  };

  // convert landmarks' relative coordinates into a uniform coordinate system
  // and add them to the available vars.
  // Note that we must merge with auto-landmarks (and regular landmarks override
  // auto-landmarks).
  let landmarkLists = [];
  if (cephData.visibility.showAutoLandmarks) {
    landmarkLists = [
      cephData.autoLandmarks,
      cephData.landmarks,
      cephData.surgicalTreatment.adjustedLandmarks,
    ];
  } else {
    landmarkLists = [cephData.landmarks];
  }

  landmarkLists.forEach((lm) => {
    Object.keys(lm).forEach((k) => {
      if (lm[k] && lm[k].position) {
        vars[k] = uniformCoordinate(
          lm[k] && lm[k].position,
          cephData.image.width,
          cephData.image.height,
        );
      }
    });
  });

  // add constructed landmarks in uniform coordinate system.
  // note that landmarks this is based on are already in a uniform coordinate
  // system, so we don't re-transform the constructed coordinates.
  (analyses[analysis].constructedLandmarks || []).forEach((k) => {
    if (CONSTRUCTED_LANDMARKS[k]) {
      let logic = CONSTRUCTED_LANDMARKS[k];
      if (logic?.code) {
        // constructed landmarks parsed with using ceph grammar
        logic = Phi.parse(logic.code);
      }
      vars[k] = cephLogic.execute(logic, vars);
    }
  });

  const { columns } = analyses[analysis];

  // The values for the header row of the table
  const header = columns.map((o) => (o instanceof Object ? cephLogic.execute(o, vars) : o));

  // The values for the table rows
  const rows = analyses[analysis].rows.map((row) => {
    // any metadata for the row goes here.
    const rowMeta = {
      unit: '',
      backgroundColor: 'initial',
    };

    const cells = row.map((c, i) => {
      // any metadata for the row goes here.
      const cellMeta = {
        // unit: '',
        isUnavailable: false,
        // cell is heading if column index is 0 and cell value starts with #
        isHeading: i === 0 && c?.[0] === '#',
      };

      // the value of the cell
      let value;

      // if the cell `c` is an Object, treat it as a cephLogic expression,
      // and evaluate it.
      if (c instanceof Object && c.code != null) {
        // compute value of cell
        value = cephLogic.execute(Phi.parse(c.code), vars);

        // // set default units based on formula used
        // // (can be overridden explicitly in metadata)
        // if (c.mm) {
        //   cellMeta.unit = 'mm';
        // } else if (c.angle || c.angle4) {
        //   cellMeta.unit = '\xB0';
        // }
      } else if (Array.isArray(c) && c.length === 2) {
        // if the value is an *array*, we will interpret it as
        // a wrapped value: [originalValue, metadataEntries],
        // where metadataEntries is a list of key/value pairs.

        // assign metadata from key/value pairs
        c[1].forEach(([k, v]) => {
          cellMeta[k] = v;
        });

        // extract original value from wrapped value
        // eslint-disable-next-line prefer-destructuring
        value = c[0]; // (same as value = value[0])
      } else {
        value = c;

        // set unit for row
        if (UNITS(value)) {
          rowMeta.unit = value;
        }
      }

      // apply heading info to row, if present in cell
      if (cellMeta.isHeading === true) {
        rowMeta.isHeading = true;
      }

      // get value ready for display (e.g., localize)
      if (typeof value === 'number') {
        if (Number.isNaN(value)) {
          value = '\u2013'; // en-dash
          cellMeta.isUnavailable = true;
        } else {
          value = value.toFixed(1);

          // TODO: localize numbers here (e.g., "3,1415")
        }
      }

      // return cell info
      return { value, meta: cellMeta };
    });

    // specify backgroundColor of row with calculating standard error
    const mean = Number(cells[2].value);
    const std = Number(cells[3].value);
    const measurement = Number(cells[4].value);
    if (
      Number.isNaN(mean) === false &&
      Number.isNaN(Number(measurement)) === false &&
      Number.isNaN(Number(std)) === false
    ) {
      if (std !== 0) {
        const standardDeviations = Math.floor(Math.abs(mean - measurement) / std);
        if (standardDeviations > 0) {
          const redAndGreen = 160 - (standardDeviations > 5 ? 5 : standardDeviations) * 20;
          rowMeta.backgroundColor = `rgb(229, ${redAndGreen}, ${redAndGreen})`;
        }
      }
    }

    // return row info
    return { cells, meta: rowMeta };
  });

  // return table info
  return {
    analysis,
    analysisName: analyses[analysis]?.name,
    header,
    rows,
    meta: tableMeta,
  };
};

export function getSTOTable(cephData, paper) {
  const GROUP_LOCAL_KEYS = {
    'BSSO Upper Parent Group': 'mandibular-rotation',
    'Middle Mandible Parent Group': 'bsso',
    'Genioplasty Lower Parent Group': 'genioplasty',
    'Maxilla Compound Path Clone': 'leFort1',
    'Maxilla Upper Parent Group': 'maxilla-posterior-segment',
    'Maxilla Lower Parent Group': 'maxilla-anterior-segment',
  };
  const { surgicalTreatment, landmarks, meta } = cephData;
  const { Ruler1, Ruler2 } = landmarks;
  const { calibration } = meta;
  const orderedGroups = [
    'BSSO Upper Parent Group',
    'Middle Mandible Parent Group',
    'Genioplasty Lower Parent Group',
    'Maxilla Compound Path Clone',
    'Maxilla Upper Parent Group',
    'Maxilla Lower Parent Group',
  ];
  return Object.entries(surgicalTreatment.groups).length > 0
    ? orderedGroups.map((groupKey) => {
        const group = surgicalTreatment.groups[groupKey];
        if (group == null)
          return {
            key: groupKey,
            name: {
              value: groupKey,
              isAvailable: false,
            },
            horizontal: {},
            vertical: {},
            rotation: {},
          };
        const nameKey = GROUP_LOCAL_KEYS[groupKey];
        const { offset, rotation = 0 } = group;

        const rowData = {
          key: groupKey,
          name: {
            value: nameKey,
            isAvailable: true,
          },
          horizontal: {},
          vertical: {},
          rotation: {},
        };

        let item;
        if (paper?.project) {
          item = paper.project.getItem({ name: groupKey });
          if (item == null || item.data == null) {
            return rowData;
          }
        }

        if (offset?.x != null) {
          let num;
          if (offset.x !== 0) {
            num = mm(Ruler1.position, Ruler2.position, calibration, Math.abs(offset.x));
            num *= offset.x < 0 ? -1 : 1; // set direction
          } else {
            num = 0;
          }
          rowData.horizontal = {
            value: num,
            isAvailable: item?.movable,
          };
        }
        if (offset?.y != null) {
          let num;
          if (offset.y !== 0) {
            num = mm(Ruler1.position, Ruler2.position, calibration, Math.abs(offset.y));
            num *= offset.y < 0 ? -1 : 1; // set direction
          } else {
            num = 0;
          }
          rowData.vertical = {
            displayValue: num !== 0 ? -1 * num : num,
            value: num,
            isAvailable: item?.movable,
          };
        }
        rowData.rotation = {
          value: rotation,
          isAvailable: item?.rotatable,
        };
        return rowData;
      })
    : [];
}

export function getDisplayName(user) {
  return [user.title, user.first_name, user.last_name].filter((x) => x).join(' ');
}

export const getLabelOrder = (label) =>
  LABEL_CONFIGS[label] != null ? LABEL_CONFIGS[label].order : 999;
export const sortImagesByLabel = (images) =>
  sort((a, b) => getLabelOrder(a.label) - getLabelOrder(b.label), images);

export const animateElementIn = (el) => {
  // eslint-disable-next-line no-param-reassign
  el.style.opacity = 1;
  // animate function is NOT SUPPORTED IN SAFARI
  if (typeof el.animate === 'function') {
    el.animate(
      [
        // keyframes
        { transform: 'scale(0)' },
        { transform: 'scale(1)' },
      ],
      {
        // timing options
        duration: 300,
        iterations: 1,
      },
    );
  }
};

export const animateElementOut = (el, i, onComplete) => {
  // animate function is NOT SUPPORTED IN SAFARI
  if (typeof el.animate === 'function') {
    el.animate(
      [
        // keyframes
        { transform: 'scale(1)' },
        { transform: 'scale(0)' },
      ],
      {
        // timing options
        duration: 300,
        iterations: 1,
      },
    );
    setTimeout(() => onComplete(), 300);
  } else {
    onComplete();
  }
};

const updateImageWithNewLabel = (images, imageId, label) =>
  images.map((image) => {
    if (image.id === imageId) {
      return {
        ...image,
        label,
      };
    }
    return image;
  });

export const updateLabelAndSortImages = (images, imageId, label) =>
  sortImagesByLabel(updateImageWithNewLabel(images, imageId, label));

export const isCephImageAvailableForSTO = async (orgId, patientId, visitId, imageId) => {
  const data = await services.getAnnotations(orgId, patientId, visitId, imageId);
  let manualData;
  let autoData;

  // index results by "kind" (auto vs manual)
  data.forEach((d) => {
    if (d.kind === ANNOTATION_MANUAL_CEPH) {
      manualData = d.data; // meta
    } else if (d.kind === ANNOTATION_AUTO_CEPH) {
      autoData = d.data; // landmarks
    }
  });

  const placedLandmarks = [];

  const isCalibrated = manualData?.meta?.calibration > 0;

  if (manualData?.landmarks) {
    Object.entries(manualData.landmarks).forEach(([landmarkName, landmarkData]) => {
      if (landmarkData.position) {
        placedLandmarks.push(landmarkName);
      }
    });
  }
  if (autoData?.landmarks) {
    Object.entries(autoData.landmarks).forEach(([landmarkName, landmarkData]) => {
      if (landmarkData.position) {
        placedLandmarks.push(landmarkName);
      }
    });
  }
  const notPlacedLandmarks = [];
  STO_REQUIRED_LANDMARKS.forEach((requiredLandmark) => {
    if (!placedLandmarks.includes(requiredLandmark)) {
      notPlacedLandmarks.push(requiredLandmark);
    }
  });
  return {
    isAvailable: notPlacedLandmarks.length === 0 && isCalibrated,
    isCalibrated,
    notPlacedLandmarks,
  };
};

// see: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function trimArrayFromTop(arr, conditionFunc) {
  let isFinished = false;
  return arr.reduce((acc, item) => {
    if (isFinished === false) {
      const isEmptyRow = conditionFunc(item);
      if (!isEmptyRow) {
        isFinished = true;
        acc.push(item);
      }
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
}

export function trimArray(arr, conditionFunc) {
  const trimmedFromTop = trimArrayFromTop(arr, conditionFunc);
  trimmedFromTop.reverse();
  const trimmedFromTopAndBottom = trimArrayFromTop(trimmedFromTop, conditionFunc);
  // make it original order again
  trimmedFromTopAndBottom.reverse();
  return trimmedFromTopAndBottom;
}

// format actual date according to language (MM/dd/yyy for US and dd/MM/yyy for TR) and add time afterwards
export const formatDateWithTime = (dateString, t) =>
  `${t('date', { date: new Date(dateString) })} ${t('date-time', { date: new Date(dateString) })}`;

export function getUserFullName(user) {
  return [user.title, user.first_name, user.last_name]
    .filter((d) => d != null)
    .join(' ')
    .trim();
}

export const highlightElementById = (id) => {
  const element = document.getElementById(id);
  if (element) {
    if (Array.from(element.classList).includes('highlight-with-color')) {
      element.classList.remove('highlight-with-color');
    }
    // instantly removing class name and re-adding it does not show the effect of the class
    // so we are waiting just a little to take effect
    // this situation only occurs when we already highlighted an element
    // which means 'element' has already 'highlight-with-color' class
    setTimeout(() => {
      element.classList.add('highlight-with-color');
    }, 10);
  }
};

// This function moves primary treatment plan to on top of the first encountered treatment plan in the visit list.
export const movePrimaryTreatmentPlanVisitToTop = (visits) => {
  const primaryPlanVisit = visits.find(
    (v) =>
      v.kind === VISIT_KINDS.TREATMENT_PLAN &&
      v.data?.[VISIT_KINDS.TREATMENT_PLAN]?.isPrimary === true,
  );
  const treatmentPlanVisits = visits.filter((v) => v.kind === VISIT_KINDS.TREATMENT_PLAN);

  // Move primary treatment plan to top only if there are 2 or more treatment plans.
  if (primaryPlanVisit != null && treatmentPlanVisits.length > 1) {
    const visitsFilteredPrimaryTreatmentPlan = visits.filter(
      (v) => v.data?.[VISIT_KINDS.TREATMENT_PLAN]?.isPrimary !== true,
    );

    let isPrimaryTreatmentPlanAdded = false;
    return visitsFilteredPrimaryTreatmentPlan.reduce((acc, visit) => {
      if (isPrimaryTreatmentPlanAdded === false && visit.kind === VISIT_KINDS.TREATMENT_PLAN) {
        acc.push(primaryPlanVisit);
        isPrimaryTreatmentPlanAdded = true;
      }
      acc.push(visit);
      return acc;
    }, []);
  }
  return visits;
};

// see: https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number#:~:text=Try%20the%20isNan%20function%3A,Otherwise%20it%20returns%20false.
// Since Number.isNaN looks for also type of the value to be "number", we will use global isNaN.
export const isNumeric = (num) =>
  // eslint-disable-next-line no-restricted-globals
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num);

export const getSortLinkLocation = (name, orderBy, key = 'order_by') => {
  const p2 = new window.URLSearchParams(window.location.search);
  if (orderBy === name) {
    p2.set(key, `-${name}`);
  } else {
    p2.set(key, name);
  }
  return p2.toString();
};

export async function downloadLabels(
  t,
  organization,
  dentist,
  patientObject,
  executionPlanData,
  options,
) {
  // Response is a ReadableStream
  const response = await services.exports.exportLabels(
    organization,
    dentist,
    patientObject,
    executionPlanData,
    options,
  );
  // get the filename from header
  const filename = response.headers.get('content-disposition')?.split('filename=')[1];
  // We are converting ReadableStream to Blob to download the PDF.
  const blob = await response.blob();
  // Create the file from blob.
  const file = window.URL.createObjectURL(blob);
  // Create a `a` element to download the file
  const a = document.createElement('a');
  a.href = file;
  a.download = filename ?? 'labels.pdf';
  a.click();
  message.success(t('successfully-downloaded-labels'));
}

export async function downloadPouchCards(
  t,
  organization,
  dentist,
  patientObject,
  executionPlanData,
  options,
) {
  // Response is a ReadableStream
  const response = await services.exports.exportPouchCards(
    organization,
    dentist,
    patientObject,
    executionPlanData,
    options,
  );
  // get the filename from header
  const filename = response.headers.get('content-disposition')?.split('filename=')[1];
  // We are converting ReadableStream to Blob to download the PDF.
  const blob = await response.blob();
  // Create the file from blob.
  const file = window.URL.createObjectURL(blob);
  // Create a `a` element to download the file
  const a = document.createElement('a');
  a.href = file;
  a.download = filename ?? 'pouch-cards.pdf';
  a.click();
  message.success(t('successfully-downloaded-pouch-cards'));
}

export function dispatchCrossBrowserEvent(element, eventName) {
  // see: https://stackoverflow.com/questions/1818474/how-to-trigger-the-window-resize-event-in-javascript
  // For modern browsers that has 'Event'
  if (typeof Event === 'function') {
    element.dispatchEvent(new Event(eventName));
  } else {
    // To work in all devices
    const event = window.document.createEvent('UIEvents');
    event.initUIEvent(eventName, true, false, window, 0);
    element.dispatchEvent(event);
  }
}

export function getBase64(file, includeData = false) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function onLoad() {
      if (includeData) {
        resolve(reader.result);
      } else {
        const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
        resolve(base64String);
      }
    };
    reader.onerror = function onError(error) {
      reject(error);
    };
  });
}

export const TOOTH_NUMBERING_SYSTEM_MAP = {
  [TOOTH_NUMBERING_SYSTEMS.UNIVERSAL]: ADULT_TEETH_NAMES_UNIVERSAL_MAPPED,
};

export const getTranslateToothFunc = (toothNumberingSystemPreference) => {
  const toothTranslationObject = TOOTH_NUMBERING_SYSTEM_MAP[toothNumberingSystemPreference];
  return (tooth) => (toothTranslationObject != null ? toothTranslationObject[tooth] : tooth);
};

export const conditionallyAddObject = (condition, object) => {
  if (condition) return [object];
  return [];
};

export function parseSchemaVersion(str) {
  const [, name, version] = str.split('/');
  return { name, version };
}

export const stopPropagation = (e) => e.stopPropagation();

export const appScroll = {
  getElements() {
    const html = document.querySelector('html');
    const { body } = document;

    return {
      html,
      body,
    };
  },
  hide() {
    const elements = this.getElements();
    const { html, body } = elements;
    // Check if html is found. HTML element should be found in all cases.
    // So this shouldn't be the case but added to be extra cautious.
    if (html == null || body == null) {
      console.error("html or body element couldn't found");
      return;
    }

    html.classList.add('html-overflow-hidden');
    body.classList.add('html-overflow-hidden');
  },
  scroll() {
    const elements = this.getElements();
    const { html, body } = elements;
    // Check if html is found. HTML element should be found in all cases.
    // So this shouldn't be the case but added to be extra cautious.
    if (html == null || body == null) {
      console.error("html element couldn't found");
      return;
    }

    html.classList.remove('html-overflow-hidden');
    body.classList.remove('html-overflow-hidden');
  },
};

export function lowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function removeParantheses(string) {
  return string.replace(/ *\([^)]*\) */g, '');
}

function mapPerson(person) {
  if (person == null) return null;

  return {
    id: person.id,
    key: person.key,
    fullName: getUserFullName(person),
    firstName: person.first_name,
    lastName: person.last_name,
    active: person.active,
    referenceId: person.reference_id, // patient-specific
    status: person.status, // patient-specific
    lastVisit: person.last_visit, // patient-specific
  };
}

export function mapCases(cases) {
  return cases.map((singleCase) => {
    return {
      ...singleCase,
      id: singleCase.id,
      key: singleCase.key,
      kind: singleCase.kind,
      state: singleCase.state,
      dateCreated: singleCase.date_created,
      patient: mapPerson(singleCase.patient),
      dentist: mapPerson(singleCase.dentist),
      orthodontist: mapPerson(singleCase.orthodontist),
    };
  });
}
