import React from 'react';
import ReactDOM from 'react-dom';
import SinglePageButton from './SinglePageButton';
import i18n from '../../../i18n';

// eslint-disable-next-line import/prefer-default-export
export const loadDataCallback = (data) => {
  const redirectDefined =
    process.env.REACT_APP_REDIRECT_TO_ORTHOTX_URL && process.env.REACT_APP_REDIRECT_TO_ORTHODX_URL;
  const { account_type: accountType /* ortho-tx | ortho-dx */ } = data.organization ?? {};
  if (process.env.NODE_ENV === 'production' && redirectDefined && accountType != null) {
    if (process.env.REACT_APP_ACCOUNT_TYPE !== accountType) {
      // show message and redirect
      // message.info(t('you-are-signed-in-to-redirecting-to-correct-one', { value: data.organization.account_type }));
      const accountName = {
        'ortho-dx': 'OrthoDx',
        'ortho-tx': 'OrthoTx',
      };
      ReactDOM.render(
        <SinglePageButton
          title={i18n.t('you-are-signed-in-app-please-go-to-correct-app', {
            value: accountName[accountType],
          })}
          buttonText={i18n.t('redirect')}
          onClick={() => {
            if (accountType === 'ortho-tx') {
              window.location.replace(process.env.REACT_APP_REDIRECT_TO_ORTHOTX_URL);
            } else {
              window.location.replace(process.env.REACT_APP_REDIRECT_TO_ORTHODX_URL);
            }
          }}
        />,
        document.getElementById('root'),
      );
    }
  }
};
