import React from 'react';
import PropTypes from 'prop-types';

const DeleteButton = ({ style, onClick }) => (
  <div style={{ position: 'absolute', right: 0, padding: 4, ...style }}>
    <button type="button" onClick={onClick} aria-label="delete image" className="delete" />
  </div>
);

DeleteButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  style: undefined,
  onClick: () => {},
};

export default DeleteButton;
