import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector'; // detect lang automatically
import moment from 'moment'; // for locale support: 'moment/min/moment-with-locales'
import numeral from 'numeral';
import { setDefaultLocale } from 'react-datepicker';

// load translations for datepicker. ('en' is default)
import dateFnsEnUS from 'date-fns/locale/en-US';
import dateFnsTr from 'date-fns/locale/tr';
import dateFnsEs from 'date-fns/locale/es';

// note that create-react-app (via ModuleScopePlugin) requires all imports
// to be within the ./src folder. Eventually translations will be loaded
// asynchronously, which is why they are symlinked to the public folder.
// See: https://react.i18next.com/guides/step-by-step-guide
import translationEN from './common/locales/en/translation.json';
import translationES from './common/locales/es/translation.json';
import translationTR from './common/locales/tr/translation.json';
import { APP_NAME } from './common/configs/constants';

// the translations (eventually: load asynchronously)
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  tr: {
    translation: translationTR,
  },
};

// formatter: (value, format, lng) => new value
const formatter = (value, format) => {
  if (value instanceof Date || value instanceof moment) return moment(value).format(format);
  if (typeof value === 'number') return numeral(value).format(format);
  return value;
};

i18n
  .use(detector) // initialize with the browser's locale
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en", // unneeded because auto-detecting langauge
    fallbackLng: 'en',
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: formatter,
      // this variable will be reachable globally from all the other key translations
      defaultVariables: { APP_NAME },
    },
  });

// // below, we register translations for date picker
// // registering locale allows datepicker to
// // set the locale prop with the given strings.
// // (we don't currently do this; instead we rely
// // on setting the default locale below.)
// // IMPORTANT: the default locale (setDefaultLocale)
// // does NOT accept registered locale strings, and will
// // instead lead to exceptions when parsing dates
// // (e.g., while typing). Instead, the default locales
// // are set using the imported locale objects, which DO work.
// // (registerLocale from 'react-datepicker')
// registerLocale('en', dateFnsEnUS);
// registerLocale('tr', dateFnsTr);
// registerLocale('es', dateFnsEs);

// Below, we set (and update, as necessary) the locale
// of the react-datepicker component. Important: we must
// set the locale using the imported locale objects (not
// registered strings using registerLocale).
const setDatePickerDefaultLocale = (lng) => {
  if (lng.toLowerCase().startsWith('en')) {
    setDefaultLocale(dateFnsEnUS);
  } else if (lng.toLowerCase().startsWith('es')) {
    setDefaultLocale(dateFnsEs);
  } else if (lng.toLowerCase().startsWith('tr')) {
    setDefaultLocale(dateFnsTr);
  } else {
    setDefaultLocale(dateFnsEnUS);
  }
};
// Change datepicker locale when i18n changes:
i18n.on('languageChanged', (lng) => {
  setDatePickerDefaultLocale(lng);
});

// Set locale of datepicker on startup to the initialized locale.
setDatePickerDefaultLocale(i18n.language);

export default i18n;
