// import auth0 from 'auth0-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import i18n from '../../i18n';
import api from './api';
import services from './services';
import { message } from '../ui/views/Message';
import { USER_ROLES, windowVariables } from '../configs/constants';
import navigation from './navigation';
import { makeId } from './utils';

const DEFAULT_CALLBACK_URL = `${window.location.origin}/callback`;
const DEFAULT_LOGOUT_URL = window.location.origin;

// i18next no longer exports the `t` function anymore,
// and there seems to be a possible race condition when accessing
// the t function when not yet initialized.
const t = (...args) => {
  try {
    return i18n.t(...args);
  } catch (e) {
    console.error('Tried to use t function before initialized', e); // eslint-disable-line no-console
    return args.join(' '); // fallback
  }
};

const MAX_EXPIRATION_TIME_IN_MILLISECONDS = 6 * 60 * 60 * 1000;

export const storeStateInSessionStorage = (state) => {
  const nonce = makeId(32);
  const body = {
    expires: Date.now() + 60 * 60 * 1000,
    ...state,
  };
  sessionStorage.setItem(nonce, JSON.stringify(body));
  return nonce;
};

// TODO: refactor functions to arrow function and delete the bindings
class Auth {
  constructor() {
    this.auth0 = null;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getIdTokenPayload = this.getIdTokenPayload.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.loadUserAndOrgData = this.loadUserAndOrgData.bind(this);
    this.getUserExternalID = this.getUserExternalID.bind(this);
    this.getUsername = this.getUsername.bind(this);
    this.getUserPic = this.getUserPic.bind(this);
    this.getRoleNames = this.getRoleNames.bind(this);
    this.onAuthStateChange = null; // callback for when authentication state changes
    this.orgInfo = null;
    this.currentUserInfo = null;
    this.managementAccounts = [];
    this.streamId = Math.floor(Math.random() * 10000000000);
    this.cachedImages = {};
    this.redirectNonce = null;
    this.appState = null;
    this.makeKey = this.makeKey.bind(this);
    this.getLocalPreference = this.getLocalPreference.bind(this);
    this.setLocalPreference = this.setLocalPreference.bind(this);
    this.removeLocalPreference = this.removeLocalPreference.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.getCachedImageUrl = this.getCachedImageUrl.bind(this);
    this.getCachedGifData = this.getCachedGifData.bind(this);
    this.cacheGifData = this.cacheGifData.bind(this);
    // only for development add global function for getting token
    if (process.env.REACT_APP_ADD_JWT_TO_WINDOW === '1') {
      window.getIdToken = this.getIdToken;
    }
  }

  createAuth0 = async (config) => {
    const { domain, clientID, redirectUri, scope } = config ?? {};

    this.auth0 = await createAuth0Client({
      domain: domain ?? process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: clientID ?? process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirect_uri: redirectUri ?? process.env.REACT_APP_AUTH0_CALLBACK_URL ?? DEFAULT_CALLBACK_URL,
      advancedOptions: {
        defaultScope: scope ?? 'openid name profile picture email email_verified',
      },
      // useRefreshTokens: true
    });
  };

  async login() {
    const week = 7 * 24 * 60 * 60;
    const config = { max_age: week };

    // Add appState
    if (this.appState != null) {
      config.appState = { ...this.appState };
    }

    await this.auth0.loginWithRedirect(config);
  }

  loadUserAndOrgData(history, callback) {
    // Call API to check that user has access to
    // given organization and load user/org data.
    const promises = [];
    const ths = this;

    promises.push(
      api.call('users/current').then((data) => {
        ths.currentUserInfo = data;
        ths.orgInfo = data.organization;
        if (typeof callback === 'function') {
          callback(data);
          return null;
        }
        return ths.orgInfo;
      }),
    );

    promises.push(
      services
        .getManagementAccounts()
        .then((data) => {
          ths.managementAccounts = data;
          return data;
        })
        .catch((error) => {
          console.error(error); // eslint-disable-line no-console
          api.error('auth', 'unable to get management accounts', { error });
        }),
    );

    promises.push(
      // set the user preferred language in here to prevent user to select preferred language again and again.
      api.call('users/current/preferences/language').then((data) => {
        if (data?.language != null) {
          i18n.changeLanguage(data.language);
        }
      }),
    );

    promises.push(
      services
        .getPlans()
        .then((response) => {
          ths.plans = response;
          ths.plansMappedById = response.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});
        })
        .catch((error) => {
          console.error('ERROR', error); // eslint-disable-line no-console
          api.error('auth', 'unable to get plans', { error });
        }),
    );

    return Promise.all(promises).then((value) => {
      const planId = ths.orgInfo?.current_subscription?.plan;
      const planName = ths.plansMappedById[planId]?.name;
      const eventLabel = planId ? `${planName} (plan_id=${planId})` : 'no_subscription';
      window.gtag('event', 'plan_name', {
        event_label: eventLabel,
        non_interaction: true,
      }); // google analytics
      return value;
    });
  }

  loginToOrgByName(history, orgName) {
    if (orgName !== undefined) {
      window.localStorage.setItem('orgName', orgName);
      window.localStorage.setItem('defaultOrgName', orgName);
    }

    // get orgInfo by orgName
    const promises = [];
    const ths = this;
    promises.push(
      api.call(`orgs/name/${orgName}`).then((data) => {
        ths.orgInfo = data;
      }),
    );

    // once we have orgInfo, redirect to correct page
    Promise.all(promises)
      .then(() => {
        // trigger UI re-render after authentication;
        if (this.onAuthStateChange !== null) this.onAuthStateChange(true);
        history.replace(navigation.pages.PATIENT_LIST_VIEW.getUrl(ths.orgInfo.key));
      })
      .catch((error) => {
        console.error('ERROR', error); // eslint-disable-line no-console
        window.localStorage.removeItem('orgName');
        api.error('auth.js', 'unable to sign into org', {
          error,
          orgName,
        });
        setTimeout(ths.logout, 3000);
      });
  }

  claimIdToken = async () => {
    const { __raw: idToken } = await this.auth0.getIdTokenClaims();
    return idToken;
  };

  async handleAuthentication(history, loadDataCallback) {
    try {
      const { appState } = await this.auth0.handleRedirectCallback();
      await this.setSession();
      const { redirectNonce } = appState ?? {};

      this.redirectNonce = redirectNonce;
      // Immediately clear hash with key and remove from history
      // don't remove for security purposes
      history.replace(history.location.pathname);

      window.gtag('event', 'login'); // google analytics

      // Call API to check that user has access to
      // given organization; then redirect to organization page.
      const orgName = window.localStorage.getItem('orgName');
      const ths = this;
      this.loadUserAndOrgData(history, loadDataCallback)
        .then(() => {
          if (ths.orgInfo === null) {
            // trigger UI re-render after authentication; get org name
            if (this.onAuthStateChange !== null) this.onAuthStateChange(false);
            history.replace('/orgs');
          } else {
            // trigger UI re-render after authentication;
            if (this.onAuthStateChange !== null) this.onAuthStateChange(true);

            const stateConfig = JSON.parse(sessionStorage.getItem(ths.redirectNonce));
            // If nonce stored in the state coming from the callback is matches with the localStorage nonce.
            // This means user is originally want to go to specific url but not logged in yet.
            // So, we are redirecting user to where they want to go originally.
            if (stateConfig != null && Date.now() < stateConfig.expires) {
              const { location } = stateConfig;
              const { pathname, search, hash } = location;
              const completePath = `${pathname}${search}${hash}`;
              history.replace(completePath);
            } else {
              history.replace(navigation.pages.PATIENT_LIST_VIEW.getUrl(ths.orgInfo.key));
            }
            ths.redirectNonce = null;
            ths.appState = null;
          }
        })
        .catch((error) => {
          console.error('ERROR', error); // eslint-disable-line no-console
          window.localStorage.removeItem('orgName');
          api.error('auth.js', 'unable to sign into org', {
            error,
            orgName,
          });
          message.danger(
            t('unable-to-sign-in-to-account'),
            `${t('organization-name')}: ${orgName}`,
          );
          setTimeout(ths.logout, 3000);
        });
    } catch (error) {
      // NOTE: This error path can occur even when there is no error
      // because of how react-router may render more than once.
      // Therefore, we don't alert here (but simply log to console).
      // see: https://community.auth0.com/t/invalid-token-state-does-not-match/12212/38
      console.error('handleAuthentication', error);
    }
  }

  getIdToken() {
    return this.idToken;
  }

  getIdTokenPayload() {
    return this.idTokenPayload;
  }

  setTokenPayload = async () => {
    const user = await this.auth0.getUser();
    this.idTokenPayload = user;
  };

  async setSession() {
    try {
      // Sets idTokenPayload
      await this.setTokenPayload();

      // Get the API token and store it.
      this.idToken = await this.claimIdToken();

      // Set isLoggedIn flag in localStorage
      window.localStorage.setItem('isLoggedIn', 'true');
    } catch (e) {
      console.error('setSession', e);
      this.logout();
    }
  }

  async renewSession(history, callback, loadDataCallback) {
    try {
      const isAuthenticated = await this.auth0.isAuthenticated();
      if (!isAuthenticated) {
        await this.auth0.getTokenSilently();
      }
      await this.setSession();

      // Call API to check that user has access to
      // given organization; then redirect to organization page.
      const orgName = window.localStorage.getItem('orgName');
      const ths = this;

      this.loadUserAndOrgData(history, loadDataCallback)
        .then(() => {
          if (ths.orgInfo === null && !orgName) {
            // trigger UI re-render after authentication; get org name
            if (this.onAuthStateChange !== null) this.onAuthStateChange(false);
            history.replace('/orgs');
          } else if (this.onAuthStateChange !== null) {
            // trigger UI re-render after authentication;
            this.onAuthStateChange(true);
          }
        })
        .catch((error) => {
          console.error('ERROR', error); // eslint-disable-line no-console
          window.localStorage.removeItem('orgName');
          api.error('auth.js', 'unable to renew session for org', {
            error,
            orgName,
          });
          message.danger(
            t('unable-to-sign-in-to-account'),
            `${t('organization-name')}: ${orgName}`,
          );
          setTimeout(ths.logout, 3000);
        })
        .finally(() => {
          if (typeof callback === 'function') callback();
        });
    } catch (err) {
      // Save app state if the url is different than
      if (history?.location?.pathname != null && history?.location?.pathname !== '/') {
        const nonce = storeStateInSessionStorage({ location: history.location });
        this.setAppState({ redirectNonce: nonce });
      }
      // Note, this will sign out of Auth0;
      // Maybe we only want to sign out of application session?
      console.error('renewSession', err); // eslint-disable-line no-console
      api.error('auth.js', 'could not get a new token', { err });
      if (typeof callback === 'function') callback(err);
    }
  }

  logout(returnToUrl) {
    // Remove isLoggedIn, orgName, deviceRef and everything else from localStorage
    // except for defaultOrgName
    const defaultOrgName = window.localStorage.getItem('defaultOrgName') || '';
    window.localStorage.clear();
    window.localStorage.setItem('defaultOrgName', defaultOrgName);
    // Clearing all global flags that we are using
    windowVariables.clear();

    // Remove tokens and expiry time
    this.idToken = null;

    // actually logout from authentication service
    this.auth0.logout({
      returnTo: returnToUrl ?? process.env.REACT_APP_AUTH0_LOGOUT_URL ?? DEFAULT_LOGOUT_URL,
    });
  }

  resetPassword() {
    fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        connection: process.env.REACT_APP_AUTH0_CONNECTION,
        email: this.idTokenPayload.email,
      }),
    })
      .then((res) => res.text())
      .then((res) => {
        message.success(t('password-change-initiated'), res, 'reset-password-success');
        console.log(res); // eslint-disable-line no-console
      })
      .catch((err) => {
        message.danger(t('there-was-a-problem-while-trying-to-change-password'), err.message);
        console.error(err.message); // eslint-disable-line no-console
      });
  }

  isAuthenticated() {
    return this.auth0.isAuthenticated();
  }

  getUserExternalID() {
    return this.currentUserInfo ? this.currentUserInfo.external_id : '';
  }

  getUsername() {
    const auth0TokenPayload = this.getIdTokenPayload();
    let name;
    if (this.currentUserInfo) {
      const { title, first_name: firstName, last_name: lastName } = this.currentUserInfo;
      name = [title, firstName, lastName].join(' ');
    }
    name = name || auth0TokenPayload.name || auth0TokenPayload.nickname;
    return name;
  }

  getUserPic() {
    const auth0TokenPayload = this.getIdTokenPayload();
    return auth0TokenPayload.picture || '/images/blank-avatar.png';
  }

  getRoleNames() {
    return this.currentUserInfo ? this.currentUserInfo.role_names : [];
  }

  getRoles() {
    const userRoles = this.getRoleNames();
    const isOrthoTxManager = userRoles.includes(USER_ROLES.ORTHOTX_MANAGER);
    const isOrthoTxOrthodontist = userRoles.includes(USER_ROLES.ORTHOTX_ORTHODONTIST);
    const isOrthoTxPackaging = userRoles.includes(USER_ROLES.ORTHOTX_PACKAGING);
    const isOrthoTxDeveloper = userRoles.includes(USER_ROLES.PHIMENTUM_DEV);
    const isOwner = userRoles.includes(USER_ROLES.OWNER);
    const isAutoCephAdmin = userRoles.includes(USER_ROLES.AUTOCEPH_ADMIN);
    return {
      isOrthoTxDentist: !(
        isOrthoTxManager ||
        isOrthoTxOrthodontist ||
        isOrthoTxPackaging ||
        isOrthoTxDeveloper
      ),
      isOrthoTxManager,
      isOrthoTxOrthodontist,
      isOrthoTxPackaging,
      isOrthoTxDeveloper,
      isOwner,
      isAutoCephAdmin,
    };
  }

  getUserId() {
    return this.currentUserInfo.id;
  }

  getSubscription() {
    return this.orgInfo?.current_subscription;
  }

  makeKey(keyOrKeyArray) {
    const key = typeof keyOrKeyArray === 'string' ? keyOrKeyArray : keyOrKeyArray.join('.');
    return `${this.getUserExternalID()}:${key}`;
  }

  getLocalPreference(keyOrKeyArray, defaultValue) {
    const d = defaultValue === undefined ? {} : defaultValue;
    try {
      const key = this.makeKey(keyOrKeyArray);
      const str = window.localStorage.getItem(key);
      return str ? JSON.parse(str) : d;
    } catch (err) {
      return d;
    }
  }

  setLocalPreference(keyOrKeyArray, value) {
    try {
      const key = this.makeKey(keyOrKeyArray);
      const str = JSON.stringify(value);
      window.localStorage.setItem(key, str);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }

  removeLocalPreference(keyOrKeyArray) {
    try {
      const key = this.makeKey(keyOrKeyArray);
      window.localStorage.removeItem(key);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }

  // takes image url and looks if that image url cached or not
  // why we are manually checking is because
  // we are requesting same image with different signature every time (signature provided by api)
  // so, we are taking image's baseUrl which obtained by extracting query parameters
  // Example url:
  // https://s3.amazonaws.com/autoceph-uploads/zUxlJVVbRerD0R7R.med.jpg?AWSAccessKeyId=AKIAIRORWRCTFSLR2HRQ&Signature=xprHgxvf4QzTT%2FVxRAuB08eGMaw%3D&Expires=1582566162
  getCachedImageUrl(url) {
    if (url == null || typeof url !== 'string') {
      return url;
    }
    const [baseUrl, queryParamsString] = url.split('?');
    const cachedImageConfig = this.cachedImages[baseUrl];
    // if url is cached check for expiration date
    if (cachedImageConfig != null) {
      const { expires } = cachedImageConfig.queryParams;
      if (expires == null || cachedImageConfig.cachedTime == null) {
        return url;
      }
      if (Date.now() > cachedImageConfig.cachedTime + MAX_EXPIRATION_TIME_IN_MILLISECONDS) {
        return url;
      }
      // 13 is the timestamp length ~> Date.now() produces 13 length number
      // if expires is in seconds not ms convert it to ms in order to compare with now()
      if (expires.length === 13) {
        if (Date.now() <= expires) {
          return cachedImageConfig.url;
        }
      } else {
        const expiresInMilliseconds = Number(`${expires}000`);
        if (Date.now() <= Number(expiresInMilliseconds)) {
          return cachedImageConfig.url;
        }
      }
    }
    // if there is no query parameter return given url
    if (queryParamsString == null) {
      return url;
    }
    const queryParams = queryParamsString.split('&').reduce((acc, query) => {
      const [key, value] = query.split('=');
      acc[key.toLowerCase()] = value;
      return acc;
    }, {});
    // if expire is not defined dont cache
    if (queryParams.expires == null) {
      return url;
    }
    // store it in localStorage
    const data = {
      url,
      cachedTime: Date.now(),
      queryParams: {
        expires: queryParams.expires,
        awsaccesskeyid: queryParams.awsaccesskeyid,
        signature: queryParams.signature,
      },
    };
    this.cachedImages[baseUrl] = data;
    return url;
  }

  getCachedGifData(url) {
    const [baseUrl] = url.split('?');
    const cachedGifConfig = this.getLocalPreference(baseUrl, null);

    if (cachedGifConfig == null) {
      return undefined;
    }

    if (Date.now() <= cachedGifConfig.expires) {
      return cachedGifConfig.data;
    }
    // remove from local storage if it is expired
    this.removeLocalPreference(baseUrl);
    return undefined;
  }

  cacheGifData(url, data) {
    const [baseUrl] = url.split('?');
    const config = {
      data,
      expires: Date.now() + MAX_EXPIRATION_TIME_IN_MILLISECONDS,
    };

    this.setLocalPreference(baseUrl, config);
  }

  setAppState = (appState) => {
    this.appState = { ...(this.appState ?? {}), ...appState };
  };
}

const auth = new Auth();

// configure API with auth singleton
api.configure(auth);

export default auth;
