import React, { createContext, useContext } from 'react';

const PatientContext = createContext();
export const PatientProvider = PatientContext.Provider;
export const usePatient = () => useContext(PatientContext);

const PlanContext = createContext();
export const PlanProvider = PlanContext.Provider;
export const usePlan = () => useContext(PlanContext);

export const AnalysesContext = createContext();
export const useAnalyses = () => useContext(AnalysesContext);

const FilesContext = createContext();
export const FilesProvider = FilesContext.Provider;
export const useFiles = () => useContext(FilesContext);

const PaperCanvasContext = createContext();
export const PaperProvider = PaperCanvasContext.Provider;
export const usePaperContext = () => {
  const paper = useContext(PaperCanvasContext);
  // We need to activate current paperScope to avoid any issues.
  // Activate assigns current paper to internally used paper object by paperjs.
  // This way any operation will directly effect the 'paper'.
  if (paper != null) {
    paper.activate();
  }
  return paper;
};

const ResizeContext = createContext();
export const ResizeProvider = ResizeContext.Provider;
export const useResize = () => useContext(ResizeContext);

const VisitConfigContext = createContext();
export const VisitConfigProvider = VisitConfigContext.Provider;
export const useVisitConfig = () => useContext(VisitConfigContext);

const ResetPageTutorialContext = createContext();
export const ResetPageTutorialProvider = ResetPageTutorialContext.Provider;
export const useResetPageTutorial = () => useContext(ResetPageTutorialContext);

const ConfigContext = createContext();
export const ConfigProvider = ConfigContext.Provider;
export const useConfig = () => useContext(ConfigContext);

const SetSelectionsContext = createContext();
export const SetSelectionsProvider = SetSelectionsContext.Provider;
export const useSetSelections = () => useContext(SetSelectionsContext);
const SelectionsContext = createContext();
export const SelectionsProvider = SelectionsContext.Provider;
export const useSelections = () => useContext(SelectionsContext);

const DroppableAreaFilesContext = createContext();
export const DroppableAreaFilesProvider = DroppableAreaFilesContext.Provider;
export const useDroppableAreaFiles = () => useContext(DroppableAreaFilesContext);

const AcceptedTreatmentPlanVisitContext = createContext();
export const AcceptedTreatmentPlanVisitProvider = AcceptedTreatmentPlanVisitContext.Provider;
export const useAcceptedTreatmentPlanVisit = () => useContext(AcceptedTreatmentPlanVisitContext);

const AccountSettingsConfigContext = createContext();
export const AccountSettingsConfigProvider = AccountSettingsConfigContext.Provider;
export const useAccountSettingsConfig = () => useContext(AccountSettingsConfigContext);

const UserPreferenceContext = createContext();
export const UserPreferenceProvider = UserPreferenceContext.Provider;
export const useUserPreferenceContext = () => useContext(UserPreferenceContext);

const SitePreferenceContext = createContext();
export const SitePreferenceProvider = SitePreferenceContext.Provider;
export const useSitePreferenceContext = () => useContext(SitePreferenceContext);

const DiagnosisContext = React.createContext();
export const DiagnosisProvider = DiagnosisContext.Provider;
export const useDiagnosis = () => useContext(DiagnosisContext);
