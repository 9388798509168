import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { IMAGE_LABELS, LABEL_CONFIGS } from '../../common/configs/constants';
import services from '../../common/utils/services';
import { conditionallyAddObject, createLabelMessageListener } from '../../common/utils/utils';
import api from '../../common/utils/api';
import DeleteButton from '../../common/ui/views/DeleteButton';
import VisitImage from '../../common/ui/views/VisitImage';
import Dropdown from './Dropdown';
import auth from '../../common/utils/auth';
import EditableSelector from './EditableSelector';
import { duplicateSTO } from '../../common/ui/views/DuplicateSTO';
import { useVisitConfig } from '../../common/utils/contexts';
import navigation from '../../common/utils/navigation';
import { documentType } from '../../common/utils/types';

const calculateImageRatio = (image) => {
  const { height, width } = image;
  if (
    image.transformations &&
    image.transformations.crop_height &&
    image.transformations.crop_width
  ) {
    const { crop_width: cropWidth, crop_height: cropHeight } = image.transformations;
    return (width * cropWidth) / (height * cropHeight);
  }
  return width / height;
};

const VisitImageCard = ({
  index,
  orgId,
  document,
  isEditing,
  onImageEdit,
  onImageDelete,
  onImageClick,
  onDownload,
  onLabelChange,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [downloading, setDownloading] = useState(false);
  const configs = useVisitConfig();
  const { image } = document;
  const { openAnalysisInNewWindow = false, openImageInNewWindow = false } = configs ?? {};
  const isImageLabelSTO = image.label === LABEL_CONFIGS[IMAGE_LABELS.STO].name;
  const isImageLabelCeph =
    image.label === LABEL_CONFIGS[IMAGE_LABELS.CEPH].name ||
    image.label === LABEL_CONFIGS[IMAGE_LABELS.PA_CEPH].name;

  useEffect(() => {
    if (image.label == null) {
      services.analyzeImage(orgId, image.id, 'label');
    }
  }, [orgId, image.id, image.label]);

  const labelOptions = [
    { key: 'emptyLabel', value: '', label: null },
    ...Object.entries(LABEL_CONFIGS).map(([key, { name }]) => ({
      key,
      value: key,
      label: t(name),
    })),
  ].filter((option) => option.key !== IMAGE_LABELS.STO);

  const stoLabelOptions = [
    {
      key: IMAGE_LABELS.STO,
      value: IMAGE_LABELS.STO,
      label: t(LABEL_CONFIGS[IMAGE_LABELS.STO].name),
    },
  ];

  const handleLabelChange = useCallback(
    (label) => {
      if (typeof onLabelChange === 'function') {
        onLabelChange(image.id, label);
      }
    },
    [image.id, onLabelChange],
  );

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true);
      await onDownload(image.document, orgId);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  }, [orgId, image, onDownload]);

  useEffect(() => {
    const listener = createLabelMessageListener(image.id, (data) => {
      if (image.label == null) {
        handleLabelChange(data.label);
      }
    });
    api.connectionSource.addEventListener('message', listener);
    return () => {
      api.connectionSource.removeEventListener('message', listener);
    };
  }, [image, handleLabelChange]);

  const dropdownItems = [
    {
      label: t('download'),
      onClick: handleDownload,
      className: classNames({ 'is-disabled': isEditing || downloading }),
    },
    ...conditionallyAddObject(typeof onImageEdit === 'function', {
      label: t('interface.edit'),
      onClick: () => onImageEdit(image),
      className: isEditing ? 'is-disabled' : '',
    }),
  ];

  if (image.label === LABEL_CONFIGS[IMAGE_LABELS.CEPH].name && image.visit != null) {
    dropdownItems.push({
      label: t('sto'),
      onClick: () => duplicateSTO(orgId, image, history, t),
    });
  }

  if (isImageLabelSTO && image.visit != null) {
    dropdownItems.push({
      label: t('duplicate-sto'),
      onClick: () => duplicateSTO(orgId, image, history, t),
    });
  }

  const isCephImage = image.label === 'ceph' || image.label === 'pa-ceph';

  const handleImageClick = useCallback(
    (imageIndex, imageData) => {
      if (openImageInNewWindow) {
        window.open(imageData.url, '_blank', 'height=200;width=200;');
      } else {
        onImageClick(imageIndex, imageData);
      }
    },
    [openImageInNewWindow, onImageClick],
  );

  return (
    <div
      data-test={isCephImage ? 'visit-image-card-analysis' : 'visit-image-card'}
      key={image.url}
      className="Visit__document extra-column-gap-br"
      style={{ position: 'relative' }}
    >
      <div>
        {isEditing && <DeleteButton onClick={() => onImageDelete(document)} />}
        <VisitImage
          imageClassName={isEditing ? 'Visit__thumbnail_cover' : 'Visit__thumbnail'}
          key={image.url}
          index={index}
          imageData={image}
          url={image.thumbnail}
          onImageClick={handleImageClick}
        />
        {(isImageLabelCeph || isImageLabelSTO) && !isEditing && (
          <div
            style={{
              position: 'absolute',
              top: 8,
              marginLeft: -8,
            }}
          >
            <button
              type="button"
              className={classNames('button is-small', {
                'is-info': !isImageLabelSTO,
                'is-success': isImageLabelSTO,
              })}
              onClick={() => {
                if (openAnalysisInNewWindow) {
                  window.open(image.href, '_blank', 'height=200;width=200;');
                } else {
                  history.push(
                    isImageLabelSTO
                      ? navigation.pages.STO_VIEW.getUrl(
                          orgId,
                          image.patientKey,
                          image.visit,
                          image.id,
                        )
                      : image.href,
                  );
                }
              }}
            >
              {isImageLabelSTO ? t('go-to-sto') : t('go-to-analysis')}
            </button>
          </div>
        )}
        {image.document && !isEditing && (
          <div
            className="Visit__hover_icon"
            style={downloading ? { display: 'inline-block' } : undefined}
          >
            <Dropdown
              items={dropdownItems}
              label={
                <FontAwesomeIcon icon="ellipsis-h" /> /* horizontal ellpse: \u2026   vertical: \u22EE */
              }
              buttonClassName={classNames('is-small is-light', { 'is-loading': downloading })}
            />
          </div>
        )}
      </div>
      <img
        className="Visit__fullImage is-hidden-not-printing"
        src={auth.getCachedImageUrl(image.url)}
        alt={`${image.label}`}
      />
      <EditableSelector
        className="is-small is-fullwidth"
        initialValue={image.label || ''}
        customTooltipText={isImageLabelSTO ? t('surgical-treatment-objective') : null}
        width={92 * calculateImageRatio(image)}
        data={isImageLabelSTO ? stoLabelOptions : labelOptions}
        disabled={typeof onLabelChange !== 'function'}
        onChange={handleLabelChange}
      />
    </div>
  );
};

VisitImageCard.defaultProps = {
  onImageDelete: () => {},
  onLabelChange: () => {},
};

VisitImageCard.propTypes = {
  index: PropTypes.number.isRequired,
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  document: documentType.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onImageEdit: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func,
  onImageClick: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func,
};

export default VisitImageCard;
