import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CallbackSpinner from '../views/CallbackSpinner';
import services from '../../utils/services';
import { DOCUMENT_ROUTE_CONFIG } from '../../configs/constants';
import api from '../../utils/api';

function LegalDocuments() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isNotAvailable, setIsNotAvailable] = useState(
    DOCUMENT_ROUTE_CONFIG == null || DOCUMENT_ROUTE_CONFIG[currentPath] == null,
  );
  const routeConfig = DOCUMENT_ROUTE_CONFIG[currentPath];

  useEffect(() => {
    if (routeConfig?.documentKey != null) {
      const { documentKey } = routeConfig;

      services.documents
        .getDocumentWithKey(documentKey)
        .then((doc) => {
          if (doc?.url == null) {
            setIsNotAvailable(true);
          } else {
            const element = window.document.createElement('a');
            element.setAttribute('href', doc.url);
            element.click();
            setIsNotAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
          api.error('LegalDocuments', 'unable to get legal documents', { error });
          setIsNotAvailable(true);
        });
    }
  }, [routeConfig]);

  if (isNotAvailable) {
    return (
      <div className="level mt-4">
        <h4 className="is-title is-4 level-item">{t('document-is-not-available')}</h4>
      </div>
    );
  }

  return <CallbackSpinner />;
}

export default LegalDocuments;
