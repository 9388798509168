import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';

const CustomTextareaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  innerRef,
  ...props
}) => (
  <div>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <TextareaAutosize ref={innerRef} {...field} {...props} rows={1} maxRows={16} />
    {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </div>
);

CustomTextareaComponent.defaultProps = {
  innerRef: null,
};

CustomTextareaComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  innerRef: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default CustomTextareaComponent;
