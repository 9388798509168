import { EventSourcePolyfill } from 'event-source-polyfill';
import errorHandler from './errorHandler';
import { message } from '../ui/views/Message';
import i18n from '../../i18n';
import { VERSION } from '../configs/constants';

// helpers
const parseJSON = (response) => response.json();

const checkStatus = async (response, configs) => {
  if (response && response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response == null || response.statusText == null) {
    throw new Error('Something went wrong. Please try again');
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  try {
    error.responseJson = await response.json();
  } catch (err) {
    error.responseJson = null;
  }
  if (configs.handleError) {
    errorHandler(error, configs);
  }
  console.error(error); // eslint-disable-line no-console
  throw error;
};

export const includeImageUrls = (imageDataFromAPI) => {
  // shallow copy image data
  const result = { ...imageDataFromAPI };

  // add image urls and thumbnail to data
  if (imageDataFromAPI.transformations && imageDataFromAPI.transformations.prefix) {
    result.editedUrl = imageDataFromAPI.urls[`${imageDataFromAPI.transformations.prefix}med`];
    result.editedThumbnail =
      imageDataFromAPI.urls[`${imageDataFromAPI.transformations.prefix}small`];
  } else {
    result.editedUrl = null;
    result.editedThumbnail = null;
  }
  result.uneditedUrl = imageDataFromAPI.urls.med;
  result.uneditedThumbnail = imageDataFromAPI.urls.small;
  result.url = result.editedUrl || result.uneditedUrl;
  result.thumbnail = result.editedThumbnail || result.uneditedThumbnail;

  return result;
};

// API class
class API {
  constructor() {
    this.auth = null;
    // for SSE connection
    this.isConnectionStarted = false;
    this.connectionSource = null;
    // create reference id to help collate log events
    if (window.localStorage.getItem('reference')) {
      this.reference = window.localStorage.getItem('reference');
    } else {
      const s = Math.random().toString(36).substring(2, 15);
      window.localStorage.setItem('reference', s);
      this.reference = s;
    }

    this.call = this.call.bind(this);
    this.log = this.log.bind(this);

    // syntactic sugar...
    this.debug = (...args) => this.log('debug', ...args);
    this.info = (...args) => this.log('info', ...args);
    this.warning = (...args) => this.log('warning', ...args);
    this.error = (...args) => this.log('error', ...args);
    this.critical = (...args) => this.log('critical', ...args);
  }

  configure(auth) {
    this.auth = auth; // a reference to singleton auth object (auth.js)
  }

  // TODO: refactor so all options are named in object; let `headers` be
  // one of those options.
  /**
   *
   * @param path
   * @param options
   * @param noJSONReturned
   * @param isMultipartFormData
   * @param configs: object that contains set of configurations
   *  {
   *    handleError: whether to use errorHandler or not to display message box in ui
   *  }
   * @returns {Promise<Response>|Promise<Response>|undefined}
   */
  defaultConfigs = {
    handleError: true,
    messageGroupKey: undefined,
  };

  call(
    path,
    options = null,
    noJSONReturned = false,
    isMultipartFormData = false,
    configs = this.defaultConfigs,
  ) {
    if (this.auth === null) {
      console.error('API called but not configured'); // eslint-disable-line no-console
      return undefined;
    }

    const idToken = this.auth.getIdToken();

    const opt = options || {};

    // default headers (that can be overridden by options)
    const headers = {
      Authorization: `bearer ${idToken}`,
      Accept: 'application/json',
      ...opt.headers,
    };

    // NOTE: if content type IS multipart/form-data, we OMIT setting
    // the `Content-Type` header explicitly and let `fetch` do it for us,
    // so it can properly deal with the `boundary` value.
    // https://github.com/github/fetch/issues/505#issuecomment-293064470
    if (!isMultipartFormData) headers['Content-Type'] = 'application/json';

    const mergedOptions = Object.assign(opt, { headers });
    const mergedConfigs = { ...this.defaultConfigs, ...configs };

    const promise = window
      .fetch(`${process.env.REACT_APP_API_URL}/${path}`, mergedOptions)
      .catch((error) => {
        if (mergedConfigs.handleError) {
          message.danger(
            i18n.t('unknown-error'),
            i18n.t('could-not-complete-action'),
            mergedConfigs.messageGroupKey,
          );
        }
        this.error('api.js', 'Unknown error (fetch failed) while calling API', { error });
        console.error('Unknown error while calling API', error); // eslint-disable-line no-console
        throw error;
      })
      .then((response) => checkStatus(response, mergedConfigs));

    if (noJSONReturned) {
      return promise;
    }
    return promise.then(parseJSON);
  }

  log(logLevel, logName, logMessage, data = null, encryptedData) {
    const path = 'logging/logs';
    const idToken = this.auth.getIdToken() || 'Anon';
    const { nonce } = this.auth.getIdTokenPayload() || {};
    const body = {
      log_level: logLevel,
      log_name: logName,
      message: logMessage,
      encrypted_data: encryptedData,
      data: {
        client_version: VERSION,
        time: new Date().toISOString(),
        reference: this.reference,
        ...data,
      },
    };

    if (nonce) body.nonce = nonce;

    const options = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${idToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      /*
       * From MDN: (see: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch)
       * "The keepalive option can be used to allow the request to outlive the page.
       * Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API."
       * */
      keepalive: true,
    };

    window
      .fetch(`${process.env.REACT_APP_API_URL}/${path}`, options)
      .then(checkStatus)
      .catch((error) => {
        console.error('Unable to reach server.', error); // eslint-disable-line no-console
      });
  }

  openNotificationConnection = () => {
    if (this.isConnectionStarted) {
      // eslint-disable-next-line no-console
      console.warn('Connection is already started. Will not open second one.');
      return this.connectionSource;
    }
    this.isConnectionStarted = true;
    const idToken = this.auth.getIdToken();
    const orgId = this.auth.orgInfo.id;
    const { streamId } = this.auth;
    const headers = {
      Authorization: `bearer ${idToken}`,
      Accept: 'application/json',
    };
    this.connectionSource = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_URL}/orgs/${orgId}/stream_id/${streamId}/notifications`,
      {
        headers,
      },
    );
    return this.connectionSource;
  };

  closeNotificationConnection = () => {
    if (this.connectionSource != null) {
      this.connectionSource.close();
      this.isConnectionStarted = false;
    }
  };
}

// create singleton object and export
const api = new API();
export default api;

// window.auth = auth;
window.api = api;
