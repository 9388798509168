/* eslint jsx-a11y/anchor-is-valid: 0 */ // for bulma

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { styleType } from '../../common/utils/types';

const Dropdown = ({ style, items, dataTest, className, buttonClassName, label }) => {
  const [visible, setVisible] = useState(false);
  const inputEl = useRef(null);
  const handleClickOutside = (event) => {
    if (visible) {
      if (inputEl.current && !inputEl.current.contains(event.target)) {
        setVisible(false); // set state to not visible
      }
    }
  };

  useEffect(() => {
    // console.log("registering dropdown...");
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // console.log("de-registering dropdown...");
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const closeAndCall = (fn) => (event) => {
    setVisible(false);
    if (fn) fn(event);
  };

  const itemComponents = items.map((item) => (
    <a
      role="button"
      tabIndex={0}
      data-test={item.dataTest}
      key={item.label}
      title={item.label}
      onClick={closeAndCall(item.onClick)}
      className={`dropdown-item ${item.className}`}
      onKeyPress={(e) => {
        e.stopPropagation();
        if (e.charCode === 13) {
          closeAndCall(item.onClick)(e);
          e.preventDefault();
        }
      }}
    >
      {item.icon && <FontAwesomeIcon className="mr-3" icon={item.icon} size="sm" />}
      {item.label}
    </a>
  ));

  return (
    <div
      ref={inputEl}
      className={classNames('dropdown', { 'is-active': visible, [className]: className != null })}
      style={style}
    >
      <div className="dropdown-trigger">
        <button
          data-test={dataTest}
          onClick={(event) => {
            event.stopPropagation();
            setVisible(!visible);
          }}
          type="button"
          className={`button ${buttonClassName}`}
          aria-haspopup="true"
          aria-controls="dropdown-menu4"
        >
          <span>{label}</span>
        </button>
      </div>
      <div className="dropdown-menu is-z-40" id="dropdown-menu4" role="menu">
        <div className="dropdown-content" style={{ textAlign: 'left' }}>
          {itemComponents}
        </div>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  style: undefined,
  dataTest: undefined,
  className: undefined,
};

Dropdown.propTypes = {
  style: styleType,
  dataTest: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
};

export default Dropdown;
