import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import { DroppableAreaFilesProvider } from '../../utils/contexts';
import './FileDroppableArea.css';
import { preventDefault } from '../../utils/utils';

const style = (isDragging) => ({
  position: 'fixed',
  zIndex: 10000,
  backgroundColor: 'rgba(256,256,256,0.8)',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  ...(isDragging
    ? { cursor: 'copy', borderColor: '#209cee', borderWidth: 1, borderStyle: 'solid' }
    : {}),
});

function FileDroppableArea({ children }) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    title: t('drop-here-to-upload'),
    files: [],
  });
  const [isDragging, setDragging] = useState(false);
  const { title } = state;

  const clearFiles = useCallback(() => {
    setState((prev) =>
      produce(prev, (draft) => {
        draft.files = [];
      }),
    );
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'copy';
    } else {
      document.body.style.cursor = 'initial';
    }
  }, [isDragging]);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleMouseLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event) => {
    // required to e.g., not open dropped links
    event.preventDefault();

    const {
      dataTransfer: { files },
    } = event;

    setState((prev) =>
      produce(prev, (draft) => {
        draft.files = files;
      }),
    );
    setDragging(false);
  };

  const providerValue = useMemo(
    () => ({ state, setState, clearFiles }),
    [state, setState, clearFiles],
  );

  return (
    <DroppableAreaFilesProvider value={providerValue}>
      {isDragging && (
        /* added preventDefault to onDragOver because in some cases dropping file does the default behavior s */
        <div
          style={style(isDragging)}
          onMouseLeave={handleMouseLeave}
          onDrop={handleDrop}
          onDragOver={preventDefault}
        >
          <div className="level" style={{ marginTop: '30vh' }}>
            <div className="level-item">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  className="FileDroppableArea__images"
                  src={`${process.env.PUBLIC_URL}/images/image-gallery.svg`}
                  alt="gallery"
                />
                <h5 className="title is-5 mt-4">{title}</h5>
              </div>
            </div>
          </div>
        </div>
      )}
      <div onDragEnter={handleDragEnter}>{children}</div>
    </DroppableAreaFilesProvider>
  );
}

FileDroppableArea.defaultProps = {
  children: undefined,
};

FileDroppableArea.propTypes = {
  children: PropTypes.node,
};

export default FileDroppableArea;
