import PropTypes from 'prop-types';
import {
  ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE,
  ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE,
} from './constants';
import { getTitleOfGap } from './utils';

export const numberOrStringType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const styleType = PropTypes.object; // eslint-disable-line react/forbid-prop-types

export const wireType = PropTypes.shape({
  key: numberOrStringType,
  label: PropTypes.string,
  wireType: PropTypes.string,
  isCinched: PropTypes.bool,
});

export const wireBendType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  startGapName: PropTypes.string,
  endGapName: PropTypes.string,
  amount: PropTypes.string,
});

export const springType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
});

export const chainType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
});

export const interproximalReductionType = PropTypes.shape({
  key: numberOrStringType,
  reduceNumber: numberOrStringType,
  gapName: PropTypes.string,
});

export const buttonType = PropTypes.shape({
  key: numberOrStringType,
  tooth: PropTypes.string,
  placement: PropTypes.string,
});

export const stopType = PropTypes.shape({
  key: numberOrStringType,
  gapName: PropTypes.string,
});

export const dentalButtonType = PropTypes.shape({
  key: numberOrStringType,
  tooth: PropTypes.string,
  placement: PropTypes.string,
});

export const contextMenuOptionType = PropTypes.shape({
  key: numberOrStringType,
  title: numberOrStringType,
  onClick: PropTypes.func,
});

export const objectRefsType = PropTypes.shape({
  current: PropTypes.shape({
    adultTeeth: PropTypes.arrayOf(PropTypes.object),
    adultTeethGaps: PropTypes.arrayOf(PropTypes.object),
    adultTeethLabel: PropTypes.arrayOf(PropTypes.object),
    baseLines: PropTypes.shape({
      baseLineX: PropTypes.object,
      baseLineY: PropTypes.object,
    }),
    layers: PropTypes.object, // layers object mapped by its name
    containerElement: PropTypes.object, // loaded SVG element
  }),
});

export const toothType = PropTypes.oneOf([
  ...ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE,
  ...ADULT_TEETH_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE.map((toothName) => `${toothName}-lingual`),
]);
export const gapType = PropTypes.oneOf(ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE);

export const gapTitleType = PropTypes.oneOf(
  ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE.map((key) => getTitleOfGap(key)),
);

export const gapKeyAndTitleType = PropTypes.oneOf([
  ...ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE,
  ...ADULT_GAP_NAMES_ORDERED_ACCORDING_TO_SVG_IMAGE.map((key) => getTitleOfGap(key)),
]);

export const toothItemType = PropTypes.shape({
  key: numberOrStringType,
  name: toothType,
});

export const gapItemType = PropTypes.shape({
  key: numberOrStringType,
  gapName: gapType,
});

export const bracketType = PropTypes.shape({
  key: numberOrStringType,
  name: PropTypes.string,
  props: PropTypes.object,
});

export const elasticType = PropTypes.shape({
  key: numberOrStringType,
  type: PropTypes.string,
  teeth: PropTypes.arrayOf(toothType),
});

export const circleTextType = PropTypes.shape({
  itemKey: numberOrStringType,
  name: numberOrStringType,
  text: numberOrStringType,
  props: PropTypes.object,
});
